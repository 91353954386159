import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Select, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { OPERATION, TOOLTIP_BG_COLOR, splitValue } from "../../../../../Constants";
import {
  CROSS_REMOVE_ICON,
  DEEP_BLUE_COLOR,
  DISABLE_COLOR,
  DISABLE_ICON_COLOR,
  RED_COLOR,
  SELECT_TEST_DATA_ICON,
} from "../../../../../Constants/SvgConstants";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../../../Util";
import SvgLoader from "../../../../../Util/SvgLoader";
import {
  getListOfLocalesForApplicationStrings,
  selectTestData,
  updateApplicationStringsLocale,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { selectRecTestSteps } from "../../../../../actions/RecTestStepAction";
import { visibleTestDataDrawer } from "../../../../../actions/TestDataAction";
import ManageTestDataV2 from "../../ManageTestDataV2/ManageTestDataV2";
import styles from "./QuickActionsV2.module.scss";

const SetAppLocaleVariableV2 = (props) => {
  const dispatch = useDispatch();
  const { disabled, data } = props;
  const { testDataList, unSavedRecStepTag, getListOfLocales } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  const { openTestDataSetDrawerByTabName, tagForOpenDrawer } = useSelector((props) => props.TestDataReducer);

  const [showTestData, setShowTestData] = useState(false);

  //Destructure object
  const { testData } = data && data.recTestStepProperty ? data.recTestStepProperty : {};

  useEffect(() => {
    dispatch(getListOfLocalesForApplicationStrings());
    setShowTestData(testData && testData.id ? true : false);
  }, []);

  useEffect(() => {
    setShowTestData(testData && testData.id ? true : false);
  }, [data?.recTestStepProperty]);

  const disableRemoveSelectVariable = disabled || data?.recTestStepProperty?.testData === undefined;
  const disabledInputOrSelectBox =
    disabled || (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data.tag);
  return (
    <div className={styles["set_App_Locale_Variable_Details"]}>
      <span className={styles["actions_Label"]}>Set Application String Locale Value</span>
      <div className={`${styles["select_Actions_And_Label"]}`}>
        <Select
          showSearch={true}
          value={data?.recTestStepProperty?.changeApplicationStringsLocale}
          placeholder="Select Locale"
          disabled={showTestData}
          onChange={(e) => {
            dispatch(updateApplicationStringsLocale(e, data?.tag));
            setShowTestData(false);
            dispatch(selectTestData(data.tag, undefined, undefined));
          }}
          className={`${styles["select_Locale"]} ${showTestData && styles["selectBoxBgColor"]}`}
        >
          {getListOfLocales &&
            getListOfLocales?.map((i) => <Select.Option value={i?.locales}>{i?.locales}</Select.Option>)}
        </Select>
        <div className={styles.checkBoxOrText}>
          <div className={styles["use_Test_Data_CheckBox"]}>
            <Checkbox
              disabled={disabled}
              onChange={(e) => {
                setShowTestData(e.target.checked);
                dispatch(updateApplicationStringsLocale(undefined, data?.tag));
                if (!e.target.checked) {
                  dispatch(selectTestData(data.tag, undefined, undefined));
                }
              }}
              checked={showTestData}
              className="selection-checkBox"
            >
              <span className={styles["checkBox_Text"]}> Use Test Data</span>
            </Checkbox>
          </div>
        </div>
      </div>
      {showTestData ? (
        <div className={styles["label_And_Select_Box"]}>
          <span className={styles["actions_Label"]}> Test Data Variable (To update locale run-time)</span>{" "}
          <div className={`${styles["select_Box_And_Remove_Btn"]} mt-10`}>
            <div
              className={`${styles["select_Test_Data_And_Icon"]} ${
                disabledInputOrSelectBox && styles["selectBoxBgColor"]
              }`}
            >
              <Select
                placeholder="Select a Variable to update value runtime"
                className={styles["select_Test_Data"]}
                disabled={
                  disabled || (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data.tag)
                }
                value={testData && testData?.id ? testData.id + splitValue + testData.value : undefined}
                onChange={(value) =>
                  dispatch(selectTestData(data.tag, value.split(splitValue)[0], value.split(splitValue)[1]))
                }
              >
                {testDataList &&
                  !isEmpty(testDataList) &&
                  testDataList.map((v, k) => {
                    return (
                      <Select.Option key={v.id + splitValue + v.value} value={v.id + splitValue + v.value}>
                        {v.name + (v.value ? " (" + v.value + ")" : "")}
                      </Select.Option>
                    );
                  })}
              </Select>
              <Tooltip title="Select Test Data" color={TOOLTIP_BG_COLOR}>
                <div
                  className={`${styles["selectTestData"]} ${disabled && styles["disableSelectTestData"]}`}
                  onClick={() => {
                    if (!props.disabled) {
                      dispatch(visibleTestDataDrawer(OPERATION, data?.tag));
                      dispatch(selectRecTestSteps(data));
                    }
                  }}
                >
                  <SvgLoader
                    iconName={SELECT_TEST_DATA_ICON}
                    disabled={disabled}
                    iconColor={disabled ? DISABLE_ICON_COLOR : DEEP_BLUE_COLOR}
                  />
                </div>
              </Tooltip>
            </div>
            <div
              className={`${styles["remove_Select_Variable_Btn"]}  ${
                disableRemoveSelectVariable && styles["disable_Button"]
              }
           `}
              onClick={(e) => !disableRemoveSelectVariable && dispatch(selectTestData(data.tag, undefined, undefined))}
            >
              <SvgLoader
                iconName={CROSS_REMOVE_ICON}
                disabled={disableRemoveSelectVariable}
                iconColor={disableRemoveSelectVariable ? DISABLE_COLOR : RED_COLOR}
                width="1.13rem"
                height="1rem"
              />
              <span>Remove</span>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {openTestDataSetDrawerByTabName === OPERATION && data?.tag === tagForOpenDrawer}
      <ManageTestDataV2 {...props} tabName={OPERATION} />
    </div>
  );
};

export default SetAppLocaleVariableV2;
