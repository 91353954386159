import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Spin } from "antd";
import isEmpty from "lodash/isEmpty";
import { recordingStop, resetElement } from "../../../actions/Recording";
import { resetEditRecTestStep } from "../../../actions/RecTestStep/RecTestStepCommonAction";
import { stopPreview, updateStepName } from "../../../actions/RecTestStepAction";
import { changeTestBlockStepName } from "../../../actions/TestBlocksAction";
import {
  setApplicationAssigneeDetail,
  setTestDataSetAssigneeDetail,
} from "../../../actions/TestDataSet/TestDataSetCommonAction";
import { resetPreviousTestDataSetForEdit } from "../../../actions/TestDataSetAction";
import {
  ALL_DEFINED_BLOCKS,
  CONFIRM_MODAL_CONTENT,
  GENERATE_AI_STEPS_CONFIRM_MODEL_TEXT,
  PLAY_TYPE,
  RESET_TEST_DATA_SET_VIEW,
  STOP_PREVIEW_ERROR_MSG_FOR_TEST_BLOCK,
} from "../../../Constants";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import EditTestHeaderOptionsV2 from "../../CommonComponents/EditTestHeaderOptionsV2/EditTestHeaderOptionsV2";
import RecTestStepSelectionBoxV2 from "./RecTestStepSelectionBoxV2";
import styles from "./TestBlockAndScenarioHeaderV2.module.scss";
import {
  hideAIGenerateStepsRow,
  stopGenerateStepsFromAIModal,
} from "../../../actions/RecTestStep/RecTestStepRedirectAction";

const TestBlocksHeaderV2 = (props) => {
  const dispatch = useDispatch();
  const { setCurrentOptions, projectId, orgId } = props;

  const {
    isPreviewRunning,
    selectedRecTestScenarioName,
    previous_TestBlockStepName,
    requestingForPreview,
    recTestStepList,
    selectedDeviceByUdid,
  } = useSelector((state) => state.RecTestStepReducer);
  const { updateTestBlockNameLoading } = useSelector((state) => state.TestBlockReducer);
  const {
    unSavedRecStepTag,
    requestingPerformRecTestStepAction,
    isLoadingForAIStep,
    scenarioGenerateRequestLoading,
    scenarioObjective,
    scenarioGenerateInstructions,
    selectedModelType,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { recordingState } = useSelector((state) => state.RecordingReducer);
  const { testDataSetAssigneeDetail, previous_testDataSetForEdit } = useSelector((state) => state.TestDataSetReducer);

  const [isInputActive, setIsInputActive] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const disabled = useMemo(() => {
    return unSavedRecStepTag !== undefined || isPreviewRunning || isLoadingForAIStep !== null;
  }, [unSavedRecStepTag, isPreviewRunning, isLoadingForAIStep]);

  const disableTestBlockUpdate = useMemo(() => {
    return (
      selectedRecTestScenarioName === "" ||
      previous_TestBlockStepName === undefined ||
      (previous_TestBlockStepName !== undefined && previous_TestBlockStepName === selectedRecTestScenarioName)
    );
  }, [selectedRecTestScenarioName, previous_TestBlockStepName]);

  const changeTestStepBlockStepName = useCallback(
    (e) => {
      dispatch(changeTestBlockStepName(e.target.value));
      setIsInputActive(true);
    },
    [dispatch]
  );
  const isStepRunning = isPreviewRunning || (requestingForPreview.includes(PLAY_TYPE.ALL_STEPS) && !isPreviewRunning);

  const handleConfirmBack = () => {
    setCurrentOptions(ALL_DEFINED_BLOCKS);
    if (recordingState === "RECORDING" || unSavedRecStepTag || previous_testDataSetForEdit) {
      dispatch({ type: RESET_TEST_DATA_SET_VIEW });
      dispatch(recordingStop());
      dispatch(resetElement());
      dispatch(resetEditRecTestStep());
      dispatch(setTestDataSetAssigneeDetail(undefined));
      dispatch(resetPreviousTestDataSetForEdit());
      dispatch(setApplicationAssigneeDetail(undefined));
    } else if (isStepRunning) {
      dispatch(stopPreview());
    } else if (scenarioGenerateRequestLoading) {
      dispatch(
        stopGenerateStepsFromAIModal({
          objective: scenarioObjective,
          rules: scenarioGenerateInstructions,
          deviceId: selectedDeviceByUdid?.targetUniqueId,
          modelType: selectedModelType,
        })
      );
      dispatch(hideAIGenerateStepsRow());
    }
  };

  const onBackClick = () => {
    if (
      recordingState === "RECORDING" ||
      unSavedRecStepTag ||
      previous_testDataSetForEdit ||
      isStepRunning ||
      scenarioGenerateRequestLoading
    ) {
      setOpenConfirmModal(true);
    } else {
      setCurrentOptions(ALL_DEFINED_BLOCKS);
      dispatch({ type: RESET_TEST_DATA_SET_VIEW });
      dispatch(setTestDataSetAssigneeDetail(undefined));
      dispatch(setApplicationAssigneeDetail(undefined));
    }
  };

  const handleSaveTestBlockName = () => {
    setIsInputActive(false);
    !disableTestBlockUpdate
      ? dispatch(updateStepName())
      : dispatch(changeTestBlockStepName(previous_TestBlockStepName));
  };

  return (
    <>
      <div className={styles["header"]}>
        {!isEmpty(recTestStepList) && <RecTestStepSelectionBoxV2 />}
        <div className={`${styles["header_Title"]} ${isInputActive && styles["action_Button_Hide"]}`}>
          <div className={styles["text_Input"]}>
            <div className={styles["step_Text"]}>Test Block: </div>
            <Input
              className={styles["input_Style"]}
              onChange={(e) => changeTestStepBlockStepName(e)}
              value={selectedRecTestScenarioName}
              onBlur={() => {
                handleSaveTestBlockName();
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSaveTestBlockName();
                }
              }}
              autoFocus
              disabled={isPreviewRunning}
            />
            <Spin spinning={updateTestBlockNameLoading}></Spin>
          </div>
        </div>
        {!isInputActive && (
          <EditTestHeaderOptionsV2
            disabled={disabled}
            requestingPerformRecTestStepAction={requestingPerformRecTestStepAction}
            testDataSetAssigneeDetail={testDataSetAssigneeDetail}
            projectId={projectId}
            orgId={orgId}
            onBackClick={onBackClick}
          />
        )}
      </div>
      <CommonConfirmationModalV2
        handleOk={() => {
          handleConfirmBack();
          setOpenConfirmModal(false);
        }}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={
          scenarioGenerateRequestLoading
            ? GENERATE_AI_STEPS_CONFIRM_MODEL_TEXT
            : isStepRunning
            ? STOP_PREVIEW_ERROR_MSG_FOR_TEST_BLOCK
            : CONFIRM_MODAL_CONTENT
        }
        modalTitle={
          scenarioGenerateRequestLoading ? "Stop AI Generate Steps" : isStepRunning ? "Stop Preview" : "Discard Changes"
        }
        okText={"Confirm"}
        isDeleteModal={true}
      />
    </>
  );
};

export default TestBlocksHeaderV2;
