import { Fragment, default as React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Collapse, Popover, Row, Spin, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import {
  ACCESSIBILITY_REPORT,
  LOCAL_REPORT,
  RUN_RESULT_STATUS,
  TEST_PLAN_RESULT_TYPE,
  TOOLTIP_BG_COLOR,
  defaultDateTimeFormat,
} from "../../../Constants";
import { ACCESSIBILITY_ICON, LOCAL_REPORT_ICON } from "../../../Constants/SvgConstants";
import {
  accessibilityOrLocalIconColor,
  addTimes,
  fetchParentSteps,
  getDateTimeInFormat,
  getDateTimeInUTCFormat,
  isNotNullAndNotEmptyAndNotUndefined,
  leftBorderColor,
  navigateToTestRail,
  renderAccessibilityOrLocalIcon,
} from "../../../Util";
import { getTestPlanStepsList, setRunResultScenarioId, testPlanStepsListId } from "../../../actions/TestPlanAction";
import DeviceHoverCard from "../../CommonComponents/DeviceHoverCard/DeviceHoverCard";
import ErrorInvestigationModal from "../../ErrorInvestigation/ErrorInvestigationModal";
import ExecutionProfileDetailsModal from "../../TestPlan/TestPlanResultDetails/ExecutionProfileDetails/ExecutionProfileDetailsModal";
import ExternalFarmTestResultLink from "../../TestPlan/TestPlanResultDetails/ExternalFarmTestResultLink";
import {
  DeviceCardHeader,
  DeviceCardTitle,
  StyledCol,
} from "../../TestPlan/TestPlanResultDetails/TestPlanResultDetailsStyled";
import TestPlanResultScenarioEvidence from "../../TestPlan/TestPlanResultDetails/TestPlanResultScenarioEvidence";
import AIReportDetailsModalV2 from "./AIReportDetailsModalV2";
import ParentTestPlanResultDetailsStepV2 from "./ParentTestPlanResultDetailsStepV2";
import styles from "./TestPlanResultDetailsV2.module.scss";

const { Panel } = Collapse;

const ScenarioDataRow = styled(Row)`
  display: flex;
  border-radius: 5px;
  width: 100%;
  color: "#65676b";
  background-color: "#338fcc";
`;

export const TestPlanScenarioHeader = styled.div`
  display: flex;
  border-radius: 5px;
  margin-left: 10px;
  color: "#65676b";
  background-color: "#338fcc";
`;

export const StyledScenarioDataCol = styled.div`
  width: 80%;
  height: auto;
`;

export const StyledScenarioDataIndexCol = styled.div`
  width: 3%;
  height: auto;
`;

export const StyledDiv = styled.span`
  margin-left: 60px;
  height: auto;
`;

export const getTimeTitle = (title, duration, startTime, endTime) => {
  return duration && (startTime || endTime) ? (
    <Popover
      placement="bottom"
      title={
        <DeviceCardHeader>
          <DeviceCardTitle>{title}</DeviceCardTitle>
        </DeviceCardHeader>
      }
      content={
        <Row gutter={[16, 16]}>
          <StyledCol>
            <Col className="p-0" span={12}>
              Start Time:
            </Col>
            {getDateTimeInFormat(startTime)} ({getDateTimeInUTCFormat(startTime, defaultDateTimeFormat)} GMT)
          </StyledCol>
          <Row className="w-100 pl-10 font-size-12">
            <Col className="p-0" span={12}>
              End Time:
            </Col>
            {getDateTimeInFormat(endTime)} ({getDateTimeInUTCFormat(endTime, defaultDateTimeFormat)} GMT)
          </Row>
        </Row>
      }
    >
      <span className="font-weight-500 ml-2">{duration || "0 secs"}</span>
    </Popover>
  ) : (
    <span className="font-weight-500 ml-2">{duration || "0 secs"}</span>
  );
};

export const highlightResult = (
  resultString,
  setOpenErrorInvestigationModal,
  testStepId,
  setFailedTestStepId,
  testExecutionErrorInvestigationDetails,
  data,
  isShowAccessibilityIcon,
  isShowLocaleReportIcon,
  isSpinVisible,
  runResultDetails
) => {
  const renderIconsOrSpin = () => {
    const tooltipTitle =
      (runResultDetails?.accessibilityReport === 1 || runResultDetails?.accessibilityReport === 2) &&
      (runResultDetails?.localeReport === 1 || runResultDetails?.localeReport === 2)
        ? "Accessibility & Locale Reports are being generated, please wait"
        : (runResultDetails?.accessibilityReport === 1 || runResultDetails?.accessibilityReport === 2) &&
          runResultDetails?.localeReport === 0
        ? "Accessibility Report is being generated, please wait"
        : runResultDetails?.accessibilityReport === 0 &&
          (runResultDetails?.localeReport === 1 || runResultDetails?.localeReport === 2)
        ? "Locale Report is being generated, please wait"
        : "";
    return isSpinVisible ? (
      <Tooltip title={tooltipTitle} color={TOOLTIP_BG_COLOR}>
        <div>
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        </div>
      </Tooltip>
    ) : (
      <>
        {isShowAccessibilityIcon}
        {isShowLocaleReportIcon}
      </>
    );
  };

  const renderBadge = (text, badgeClass) => <span className={`badge ${badgeClass} p-5 font-weight-900`}>{text}</span>;

  const renderInvestigationButton = () => (
    <div className="m-5">
      <Button
        className="investigation-button bg-info pull-right"
        type="primary"
        onClick={(e) => {
          e.stopPropagation();
          setOpenErrorInvestigationModal(true);
          setFailedTestStepId(testStepId);
        }}
      >
        {isNotNullAndNotEmptyAndNotUndefined(testExecutionErrorInvestigationDetails)
          ? testExecutionErrorInvestigationDetails?.failureReason
          : "Needs investigation"}
      </Button>
    </div>
  );

  const resultBadges = {
    [TEST_PLAN_RESULT_TYPE.IN_PROGRESS]: "bg-info",
    Passed: "bg-success",
    TERMINATED: "bg-abort",
    QUEUED_WAITING_FOR_DEVICE_AVAILABILITY: "bg-secondary",
    Failed: "bg-danger",
    [RUN_RESULT_STATUS.WAITING_FOR_DEVICE_TO_EXECUTED]: "bg-secondary",
    [TEST_PLAN_RESULT_TYPE.UPLOADING_RESULT]: "bg-uploading",
    [RUN_RESULT_STATUS.ABORTED]: "bg-abort",
  };

  const resultTextMapping = {
    [TEST_PLAN_RESULT_TYPE.IN_PROGRESS]: TEST_PLAN_RESULT_TYPE.IN_PROGRESS_TXT,
    QUEUED_WAITING_FOR_DEVICE_AVAILABILITY: TEST_PLAN_RESULT_TYPE.QUEUED_WAITING_FOR_DEVICE_AVAILABILITY_TXT,
    [RUN_RESULT_STATUS.WAITING_FOR_DEVICE_TO_EXECUTED]: TEST_PLAN_RESULT_TYPE.WAITING_FOR_DEVICE_TO_EXECUTED_TXT,
    [TEST_PLAN_RESULT_TYPE.UPLOADING_RESULT]: TEST_PLAN_RESULT_TYPE.UPLOADING_RESULT_TXT,
    [RUN_RESULT_STATUS.ABORTED]: TEST_PLAN_RESULT_TYPE.ABORTED,
  };

  const badgeClass = resultBadges[resultString] || "default-result-text";
  const badgeText = resultTextMapping[resultString] || resultString;

  return (
    <div>
      <div className={styles["icons_And_Result_Tag"]}>
        {renderIconsOrSpin()}
        {resultString === TEST_PLAN_RESULT_TYPE.IN_PROGRESS && <Spin size="small" className="mr-5" />}
        {renderBadge(badgeText, badgeClass)}
      </div>
      {resultString === "Failed" && !data && renderInvestigationButton()}
    </div>
  );
};

export const durationTime = (durationTime) => {
  let time = "";
  if (durationTime && durationTime.length > 0) {
    time += durationTime[0] !== "00" && durationTime[0] !== "-00" ? durationTime[0] + " Hours " : "";
    time += durationTime[1] !== "00" && durationTime[0] !== "-00" ? durationTime[1] + " Minutes " : "";
    time += durationTime[2] !== "00" && durationTime[0] !== "-00" ? durationTime[2] + " Seconds  " : "";
  }
  return time;
};

export const isShowDuration = (durationTime) => {
  if (durationTime && durationTime.length > 0) {
    return (
      (durationTime[0] !== "00" && durationTime[0] !== "-00") || durationTime[1] !== "00" || durationTime[2] !== "00"
    );
  }
};

const isTestRailData = (data) => {
  return (
    isNotNullAndNotEmptyAndNotUndefined(data.testRailTestCaseLabel) &&
    isNotNullAndNotEmptyAndNotUndefined(data.testRailTestCaseUrl)
  );
};

const TestPlanResultScenarioDetailsV2 = (props) => {
  const dispatch = useDispatch();
  const { scenariosList, runResultDetails, isTestBlock, runNumber } = props;
  const { testPlanResultStepsDetails, testStepResults, isLoadingAIReportStatus } = useSelector(
    (state) => state.TestPlanReducer
  );

  const [openErrorInvestigationModal, setOpenErrorInvestigationModal] = useState(false);
  const [failedTestStepId, setFailedTestStepId] = useState(null);
  const [ActiveScenario, setActiveScenario] = useState(null);
  const [activeScenarioId, setActiveScenarioId] = useState([]);
  const [displayExecutionProfile, setDisplayExecutionProfile] = useState(false);
  const [currentExecutionProfile, setCurrentExecutionProfile] = useState({ id: 0, name: "" });
  const [currentActiveScenarioId, setCurrentActiveScenarioId] = useState(null);
  const [visibleReportModal, setVisibleReportModal] = useState(null);

  const getTestStepsDetailsOnClick = (data, k) => {
    const scenarioId = data?.scenarioId;
    dispatch(setRunResultScenarioId(scenarioId));
    let scenarioIds = [...activeScenarioId];
    if (scenarioIds.includes(scenarioId)) {
      const dId = scenarioIds.indexOf(scenarioId);
      scenarioIds.splice(dId, 1);
    } else {
      scenarioIds.push(scenarioId);
    }
    if (
      data?.state === TEST_PLAN_RESULT_TYPE.IN_PROGRESS ||
      data?.state === TEST_PLAN_RESULT_TYPE.UPLOADING_RESULT ||
      data?.state === TEST_PLAN_RESULT_TYPE.WAITING_FOR_DEVICE_TO_EXECUTED
    ) {
      setActiveScenarioId(scenarioIds);
    }
    if (k !== ActiveScenario) {
      dispatch(getTestPlanStepsList({ scenarioId: scenarioId }, () => {}, "", true));
      dispatch(testPlanStepsListId(scenarioId));
      setActiveScenario(k);
      setCurrentActiveScenarioId(scenarioId);
    } else {
      setActiveScenario(null);
      setCurrentActiveScenarioId(null);
    }
  };

  const getScenarioRow = (data, index) => {
    const duration = data && data.duration ? data.duration.split(":") : "";
    const totalDuration = addTimes(addTimes(data.waitDuration, data.duration), data?.reportProcessingDuration);
    const runId = runResultDetails?.find((i) => i)?.runId;
    const parentSteps =
      isTestBlock === 0
        ? fetchParentSteps(testPlanResultStepsDetails, data?.scenarioId)
        : fetchParentSteps(testStepResults, runId);

    let accessibilityIconColor = accessibilityOrLocalIconColor(
      data?.accessibilityReport,
      data?.reportData?.accessibilityStatus,
      data?.reportData
    );
    let localReportIconColor = accessibilityOrLocalIconColor(data?.localeReport, data?.reportData?.localeStatus);

    const isShowAccessibilityIcon = renderAccessibilityOrLocalIcon(
      data?.reportData != null &&
        runResultDetails[0]?.accessibilityReport !== 0 &&
        !isLoadingAIReportStatus &&
        data?.reportData?.accessibilityStatus !== "NONE",
      data?.reportData?.errorMessage
        ? data?.reportData?.errorMessage
        : data?.reportData?.accessibilityStatus === "PENDING"
        ? "Accessibility Report Pending"
        : "Accessibility Report",
      ACCESSIBILITY_ICON,
      accessibilityIconColor,
      (e) => {
        if (data?.reportData?.accessibilityStatus === "DONE") {
          e.stopPropagation();
          setVisibleReportModal({ type: ACCESSIBILITY_REPORT, data });
        }
      }
    );

    const isShowLocaleReportIcon = renderAccessibilityOrLocalIcon(
      data?.reportData != null &&
        runResultDetails[0]?.localeReport !== 0 &&
        !isLoadingAIReportStatus &&
        data?.reportData?.localeStatus !== "none",
        data?.reportData?.errorMessage
        ? data?.reportData?.errorMessage
        : data?.reportData?.localeStatus === "PENDING" ? "Locale Report Pending" : "Locale Report",
      LOCAL_REPORT_ICON,
      localReportIconColor,
      (e) => {
        if (data?.reportData?.localeStatus === "DONE") {
          e.stopPropagation();
          setVisibleReportModal({ type: LOCAL_REPORT, data });
        }
      }
    );
    return (
      <Collapse
        className={leftBorderColor(data.result)}
        key={index}
        accordion
        ghost
        onChange={(e) => getTestStepsDetailsOnClick(data, index)}
        expandIconPosition="right"
      >
        <Panel
          className="mt-10"
          showArrow={true}
          header={
            <ScenarioDataRow>
              <Col span={1}>{index + 1}</Col>
              <Col span={15} className="font-weight-700">
                Scenario:<span className="font-weight-500 ml-2">{data.name}</span>
                <div>
                  {data.deviceName &&
                  data.deviceName.length > 0 &&
                  data.state &&
                  data.state === TEST_PLAN_RESULT_TYPE.IN_PROGRESS ? (
                    <span className="font-weight-700">Executing on: </span>
                  ) : (
                    data.deviceName &&
                    data.deviceName.length > 0 && <span className="font-weight700">Executed On: </span>
                  )}
                  <DeviceHoverCard
                    deviceDetails={{
                      ...data,
                      brandName: data?.deviceBrandName,
                      name: data?.deviceName,
                      platformName: data?.platform,
                    }}
                  />
                </div>
                <div>
                  Total Steps:<span className="font-weight-500 ml-2">{data.totalSteps ? data.totalSteps : 0}</span>
                </div>
                <div>
                  Screenshots Captured:
                  <span className="font-weight-500 ml-2">
                    {data.totalCapturedScreenShotCounts ? data.totalCapturedScreenShotCounts : 0} of{" "}
                  </span>
                  <span className="font-weight-500 ml-2">
                    {data.totalExpectedScreenShotCounts ? data.totalExpectedScreenShotCounts : 0}
                  </span>
                </div>
                {data?.state !== TEST_PLAN_RESULT_TYPE.IN_PROGRESS && (
                  <>
                    <span className="font-weight-700">Total Duration:</span>
                    {getTimeTitle(
                      "Scenario Execution Details",
                      totalDuration && durationTime(totalDuration.split(":")),
                      undefined,
                      undefined
                    )}
                    (<span className="font-weight-500">Waiting for Device Availability:</span>
                    {isShowDuration(duration) &&
                      getTimeTitle(
                        "Waiting for Device Availability",
                        durationTime(data?.waitDuration?.split(":")),
                        undefined,
                        undefined
                      )}
                    ,<span className="font-weight-500 ml-5">Execution:</span>
                    {isShowDuration(duration) &&
                      getTimeTitle(
                        "Scenario Execution Timing Details",
                        durationTime(duration),
                        data?.startTime,
                        data?.endTime
                      )}
                    ,<span className="font-weight-500 ml-5">Result Processing:</span>
                    {getTimeTitle(
                      "Report Processing Details",
                      data?.reportProcessingDuration && durationTime(data.reportProcessingDuration.split(":")),
                      data?.awsUploadStartTime,
                      data?.awsUploadEndTime
                    )}
                    )
                  </>
                )}
                <div className="display-flex align-items-center">
                  Test Data Profile:
                  <span className="font-weight-700">
                    <Button
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrentExecutionProfile({
                          ...currentExecutionProfile,
                          name: data?.executionTestDataSetName,
                          id: data?.executionTestDataSetId,
                        });
                        setDisplayExecutionProfile(true);
                      }}
                      type="link"
                      block
                    >
                      {data?.executionTestDataSetName}
                    </Button>
                  </span>
                </div>
                <div>
                  <span className="font-weight-700 mr-2">TestRail Case:</span>
                  {isTestRailData(data) ? (
                    data.testRailTestCaseLabel.split(",").map((label, i) => {
                      return (
                        <span
                          className="font-color-blue cursor-pointer text-decoration-underline mr-5"
                          onClick={(e) => {
                            navigateToTestRail(e, data, i);
                          }}
                        >
                          {label}
                        </span>
                      );
                    })
                  ) : (
                    <span className="font-weight-500 ml-2">0</span>
                  )}
                </div>
                {data?.externalFarmTestCaseUrl && (
                  <ExternalFarmTestResultLink data={data} runResultDetails={runResultDetails} />
                )}
              </Col>
              <Col span={8} className="text-right">
                {data.state
                  ? highlightResult(
                      data.state !== "UPLOADING_RESULT" && data.result ? data.result : data.state,
                      setOpenErrorInvestigationModal,
                      data.failedTestStepId,
                      setFailedTestStepId,
                      data?.testExecutionErrorInvestigationDetails,
                      "",
                      data?.reportData !== null && isShowAccessibilityIcon,
                      data?.reportData !== null && isShowLocaleReportIcon,
                      data?.reportData !== null &&
                        isLoadingAIReportStatus &&
                        (data?.reportData?.localeStatus === "IN_PROGRESS" ||
                          data?.reportData?.accessibilityStatus === "IN_PROGRESS"),
                      runResultDetails[0]
                    )
                  : "N.A."}
              </Col>
            </ScenarioDataRow>
          }
        >
          <ParentTestPlanResultDetailsStepV2
            parentSteps={parentSteps}
            data={data}
            isTestBlock={isTestBlock}
            activeScenarioId={activeScenarioId}
            runResultDetails={runResultDetails}
            setCurrentActiveScenarioId={setCurrentActiveScenarioId}
            currentActiveScenarioId={currentActiveScenarioId}
          />

          {isTestBlock === 0 && (
            <div className="mt-10">
              <TestPlanResultScenarioEvidence scenarioData={data} />
            </div>
          )}
        </Panel>
      </Collapse>
    );
  };

  return (
    <Fragment>
      {scenariosList
        ? scenariosList?.map((v, k) => {
            return getScenarioRow(v, k);
          })
        : null}
      {displayExecutionProfile && (
        <ExecutionProfileDetailsModal
          setDisplayExecutionProfile={setDisplayExecutionProfile}
          displayExecutionProfile={displayExecutionProfile}
          currentExecutionProfile={currentExecutionProfile}
        />
      )}
      {openErrorInvestigationModal && (
        <ErrorInvestigationModal
          openErrorInvestigationModal={openErrorInvestigationModal}
          setOpenErrorInvestigationModal={setOpenErrorInvestigationModal}
          failedTestStepId={failedTestStepId}
          projectId={runResultDetails[0]?.projectId}
          orgId={runResultDetails[0]?.organizationId}
          runNumber={runNumber}
        />
      )}
      {!!visibleReportModal && (
        <AIReportDetailsModalV2
          visibleReportModal={visibleReportModal}
          setVisibleReportModal={setVisibleReportModal}
          isTestBlock={isTestBlock}
        />
      )}
    </Fragment>
  );
};

export default TestPlanResultScenarioDetailsV2;
