import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faStopCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Modal, Popover, Spin, Tooltip } from "antd";
import _ from "lodash";
import { ICON_DELETE, ICON_REPORT } from "../../../../CdnImagePath";
import {
  RUN_RESULT_STATUS,
  TEST_PLAN_LOADING,
  TEST_PLAN_RESULT_BY_RUN_NUMBER_RESET,
  TEST_PLAN_RESULT_TYPE,
  TOOLTIP_BG_COLOR,
  defaultDateTimeFormat,
  executionModeShortText,
} from "../../../../Constants";
import {
  checkNotUndefinedAndNull,
  customDateRangeFilterList,
  deleteModal,
  displayUTCDateFormatWithToolTip,
  getSelectedJobStatus,
  isSelectedAllIds,
  removeSelectedIds,
} from "../../../../Util";
import {
  abortTestPlanRunResult,
  changeTestPlanResultListPageNumber,
  deleteTestPlanRunResult,
  getTestPlanAndScenarioResultList,
  handleChangeReportManagerSearch,
  resetReportManagerSearch,
} from "../../../../actions/TestPlanAction";
import DatePickerBox from "../../../CommonComponents/DatePickerBox";
import SelectAllBox from "../../../CommonComponents/SelectAllBox";
import CustomDatePicker from "../../../CoreComponents/CustomDatePicker/CustomDatePicker";
import FilterComponent from "../../../CoreComponents/FilterComponent/FilterComponent";
import PaginationComponent from "../../../CoreComponents/PaginationComponent/PaginationComponent";
import CommonSearch from "../../../CoreComponents/SearchComponent/CommonSearch";
import {
  AbortTag,
  BadgeStyle,
  FailTag,
  InQueueTag,
  PassTag,
  RunningTag,
  UploadingResultTag
} from "../../../CoreComponents/StyledComponent/StyledComponents";
import TableComponent from "../../../CoreComponents/TableComponent/TableComponent";
import TestPlanResultDeviceDetails from "../../TestPlanResultDetails/TestPlanResultDeviceDetails";
import { durationTime } from "../../TestPlanResultDetails/TestPlanResultScenarioDetails";
import styles from "./TestPlanResult.module.scss";

const { confirm } = Modal;

export const resultTag = (resultObj, row) => {
  const labels = [];
  let pendingScenarioToCreate = row && row?.testCoverageAcrossDevices?.scenarioCount;
  let totalProcessed = 0;

  if (resultObj) {
    if (resultObj?.IN_PROGRESS) {
      labels.push(
        <RunningTag key="IN_PROGRESS">
          <BadgeStyle color="#306a9f" />
          {resultObj?.IN_PROGRESS} {TEST_PLAN_RESULT_TYPE?.IN_PROGRESS_TXT}
        </RunningTag>
      );
      totalProcessed = resultObj?.IN_PROGRESS + totalProcessed;
    }

    if (resultObj?.Passed) {
      labels.push(
        <PassTag key="Passed">
          <BadgeStyle color="#297042" />
          {resultObj?.Passed} {TEST_PLAN_RESULT_TYPE?.PASSED}
        </PassTag>
      );
      totalProcessed = resultObj?.Passed + totalProcessed;
    }

    if (resultObj?.ABORTED) {
      labels.push(
        <AbortTag key="ABORTED">
          <BadgeStyle color="#7D5E0F" />
          {resultObj?.ABORTED} {TEST_PLAN_RESULT_TYPE?.ABORTED}
        </AbortTag>
      );
      totalProcessed = resultObj?.ABORTED + totalProcessed;
    }
    if (resultObj?.TERMINATED) {
      labels.push(
        <AbortTag key="ABORTED">
          <BadgeStyle color="#7D5E0F" />
          {resultObj?.TERMINATED} {TEST_PLAN_RESULT_TYPE?.TERMINATED}
        </AbortTag>
      );
      totalProcessed = resultObj?.TERMINATED + totalProcessed;
    }

    if (resultObj?.QUEUED_WAITING_FOR_DEVICE_AVAILABILITY) {
      labels.push(
        <span key="CONTINUE_AFTER_FAILED" className="CONTINUE_AFTER_FAILED-text ml-5">
          {resultObj?.QUEUED_WAITING_FOR_DEVICE_AVAILABILITY}{" "}
          {TEST_PLAN_RESULT_TYPE?.QUEUED_WAITING_FOR_DEVICE_AVAILABILITY_TXT}
        </span>
      );
      totalProcessed = resultObj?.QUEUED_WAITING_FOR_DEVICE_AVAILABILITY + totalProcessed;
    }

    if (resultObj?.WAITING_FOR_DEVICE_TO_EXECUTED) {
      labels.push(
        <InQueueTag key="WAITING_FOR_DEVICE_TO_EXECUTED">
          <BadgeStyle color="#808080" />
          {resultObj?.WAITING_FOR_DEVICE_TO_EXECUTED} {TEST_PLAN_RESULT_TYPE?.WAITING_FOR_DEVICE_TO_EXECUTED_TXT}
        </InQueueTag>
      );
      totalProcessed = resultObj?.WAITING_FOR_DEVICE_TO_EXECUTED + totalProcessed;
    }
    if (resultObj?.UPLOADING_RESULT) {
      labels.push(
        <UploadingResultTag key="UPLOADING_RESULT">
          <BadgeStyle />
          {resultObj?.UPLOADING_RESULT} {TEST_PLAN_RESULT_TYPE?.UPLOADING_RESULT_TXT}
        </UploadingResultTag>
      );
      totalProcessed = resultObj?.UPLOADING_RESULT + totalProcessed;
    }

    if (resultObj?.Failed) {
      labels.push(
        <FailTag key="Failed">
          <BadgeStyle color="#b43131" />
          {resultObj?.Failed} {TEST_PLAN_RESULT_TYPE?.FAILED}
        </FailTag>
      );
      totalProcessed = resultObj?.Failed + totalProcessed;
    }

    if (resultObj?.Skipped) {
      labels.push(
        <span key="Skipped" className="skipped-text ml-5">
          {resultObj?.Skipped} {TEST_PLAN_RESULT_TYPE?.SKIPPED}
        </span>
      );
      totalProcessed = resultObj?.Skipped + totalProcessed;
    }

    if (resultObj?.CONTINUE_AFTER_FAILED) {
      labels.push(
        <span key="CONTINUE_AFTER_FAILED" className="CONTINUE_AFTER_FAILED-text ml-5">
          {resultObj?.CONTINUE_AFTER_FAILED} {TEST_PLAN_RESULT_TYPE?.CONTINUE_AFTER_FAILED}
        </span>
      );
      totalProcessed = resultObj?.CONTINUE_AFTER_FAILED + totalProcessed;
    }

    if (resultObj?.SCHEDULED) {
      labels.push(
        <span key="CONTINUE_AFTER_FAILED" className="CONTINUE_AFTER_FAILED-text ml-5">
          {resultObj?.SCHEDULED} {TEST_PLAN_RESULT_TYPE?.SCHEDULED}
        </span>
      );
      totalProcessed = resultObj?.CONTINUE_AFTER_FAILED + totalProcessed;
    }

    if (pendingScenarioToCreate - totalProcessed > 0) {
      labels.push(
        <InQueueTag>
          <BadgeStyle color="#808080" />
          {pendingScenarioToCreate - totalProcessed} Preparing For Execution
        </InQueueTag>
      );
    }
  }
  return labels;
};

const TestPlanResult = (props) => {
  const dispatch = useDispatch();
  const {
    testPlanResultData,
    currentTestPlanResultPage,
    totalTestPlanResultPage,
    testPlanLoading,
    totalPageItem,
    reportManagerSearch,
    totalTestPlanResultItems,
  } = useSelector((state) => state.TestPlanReducer);
  const { projectId, executionReportProjectId, executionReportOrgId } = props;
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [isCustomRangePicker, setIsCustomRangePicker] = useState(false);
  const [selectAllRun, setSelectAllRun] = useState([]);
  const intervalRef = useRef(null);
  useEffect(() => {
    dispatch(resetReportManagerSearch());
    dispatch(changeTestPlanResultListPageNumber(1));
    dispatch({ type: TEST_PLAN_LOADING, response: { isLoading: true } });
    if (!executionReportProjectId) {
      dispatch(getTestPlanAndScenarioResultList(projectId, "", true));
    }
    setSelectAllRun([]);
  }, [projectId]);

  useEffect(() => {
    if (testPlanResultData && !_.isEmpty(testPlanResultData) && !executionReportProjectId) {
      intervalRef.current = setInterval(() => dispatch(getTestPlanAndScenarioResultList(projectId)), 4000);
      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [testPlanResultData]);

  const deleteConfirm = (id, name, testRailTestRunId) => {
    deleteModal(
      name
        ? `Do you want to delete Run Result "${id + "-" + name}" ?`
        : `Do you want to delete the selected ${selectAllRun?.length > 1 ? "Run Results" : "Run Result"}?`,
      () => {
        dispatch(
          deleteTestPlanRunResult(
            {
              runIds: id ? [id] : selectAllRun,
              projectId: projectId,
            },
            projectId,
            executionReportProjectId,
            setSelectAllRun
          )
        );
      },
      testRailTestRunId && testRailTestRunId !== 0 ? (
        <div>
          <Checkbox name="removeFromTestRail">Remove From Testrail</Checkbox>
        </div>
      ) : null
    );
  };

  function abortConfirm(id, name) {
    deleteModal(
      name ? `Do you want to abort job "${name}" ?` : "Do you want to abort job ?",
      () => {
        dispatch(abortTestPlanRunResult({ runId: id }, projectId, executionReportProjectId));
      },
      "",
      true
    );
  }

  const handleHideDeleteOnInProgress = (runResultDetails) => {
    if (
      (runResultDetails?.runResult === "ABORTED" || runResultDetails?.runResult === "DONE") &&
      !runResultDetails?.resultObj?.UPLOADING_RESULT
    ) {
      return (
        <Tooltip color={TOOLTIP_BG_COLOR} title="Delete">
          <img
            src={ICON_DELETE}
            alt="Delete"
            className={styles.deleteIcon}
            onClick={() =>
              deleteConfirm(
                runResultDetails?.runId,
                runResultDetails?.testJobExecName,
                runResultDetails.testRailTestRunId
              )
            }
          />
        </Tooltip>
      );
    } else if (
      runResultDetails?.runResult &&
      ["IN_PROGRESS", "TERMINATED", "ASSIGNED_TO_DEVICES", "PREPARING_RUN_TO_EXECUTE"].includes(
        runResultDetails?.runResult
      )
    ) {
      return (
        <Tooltip color={TOOLTIP_BG_COLOR} title="Abort">
          <FontAwesomeIcon
            className={styles.abortIcon}
            onClick={(event) =>
              abortConfirm(runResultDetails.runId, runResultDetails.name, runResultDetails.testRailTestRunId)
            }
            icon={faStopCircle}
          ></FontAwesomeIcon>
        </Tooltip>
      );
    }
  };

  const openTestPlanResultDetails = (data, orgId) => {
    dispatch({ type: TEST_PLAN_RESULT_BY_RUN_NUMBER_RESET });
    const url = `/${orgId || executionReportOrgId}/project/${
      projectId || executionReportProjectId
    }/TestPlanResultDetails/${data.RunNumber}`;
    var win = window.open(url, "_blank");
    win.focus();
  };

  const checkIsNotInProgress =
    testPlanResultData &&
    testPlanResultData?.filter(
      (j) =>
        j?.runResult &&
        (["DONE", "IN_QUEUE"].includes(j?.runResult) ||
          (j?.runResult === "ABORTED" &&
            (j?.scenarios === undefined ||
              j?.scenarios?.filter((scenario) =>
                ["WAITING_FOR_DEVICE_TO_EXECUTED", "IN-PROGRESS"].includes(scenario.state)
              ).length === 0)))
    );

  const testJobSelectionCheckboxType = (reportData) => {
    if (
      (reportData?.runResult === "ABORTED" || reportData?.runResult === "DONE") &&
      !reportData?.resultObj?.UPLOADING_RESULT
    ) {
      return (
        <div>
          <span className={styles.checkBox}>
            <Checkbox
              checked={selectAllRun && selectAllRun?.includes(reportData?.runId)}
              onClick={(e) => {
                e.stopPropagation();
                e.target.checked
                  ? setSelectAllRun([...selectAllRun, reportData?.runId])
                  : setSelectAllRun(selectAllRun && selectAllRun?.filter((i) => i !== reportData?.runId));
              }}
            />
          </span>
        </div>
      );
    }
  };

  const showDeviceList = (devices) => {
    return (
      <Popover
        content={devices?.map((i, index) => {
          return (
            <TestPlanResultDeviceDetails
              deviceResultDetails={{
                targetUniqueId: i?.targetUniqueId,
                brandName: i?.brand,
                name: i?.name,
                locale: i?.locale,
                platformName: i?.platformName,
                osVer: i?.osVersion,
                devicesLength: devices?.length,
                currentIndex: index,
              }}
            />
          );
        })}
        title="Devices"
        placement="bottom"
        overlayClassName={devices?.length > 3 && "showDeviceDetails"}
      >
        <span className={styles.deviceListPopover}>{devices?.length}</span>
      </Popover>
    );
  };

  const filterList = [
    {
      title: "All Jobs",
      name: "",
    },
    {
      title: "Running Jobs",
      name: "IN_PROGRESS",
    },
    {
      title: "In-Queue Jobs",
      name: "IN_QUEUE",
    },
    {
      title: "Completed Jobs",
      name: "DONE",
    },
  ];

  const columns = [
    {
      title: (
        <div>
          {_.isEmpty(selectAllRun) && !executionReportProjectId && !_.isEmpty(checkIsNotInProgress) && (
            <span>
              <Checkbox
                className={styles.marginRight}
                onChange={(isChecked) => {
                  if (isChecked) {
                    setSelectAllRun([
                      ...selectAllRun,
                      ...checkIsNotInProgress
                        ?.filter((i) => !selectAllRun.includes(i?.runId) && i?.runId)
                        ?.map((i) => i?.runId),
                    ]);
                  } else {
                    setSelectAllRun(
                      removeSelectedIds(
                        selectAllRun,
                        checkIsNotInProgress?.map((i) => ({ id: i?.runId }))
                      )
                    );
                  }
                }}
              />
            </span>
          )}
          Job Info
        </div>
      ),
      dataIndex: "jobInfo",
      width: "30%",
    },
    {
      title: "Test Plan",
      dataIndex: "testPlan",
    },
    {
      title: "Test Coverage",
      dataIndex: "testCoverage",
    },
    {
      title: "Results",
      dataIndex: "results",
    },
    {
      title: "Actions",
      dataIndex: "action",
      align: "center",
    },
  ];

  const testResultDetails =
    testPlanResultData &&
    testPlanResultData?.map((row) => ({
      key: row?.runId,
      jobInfo: (
        <div className="display__flex">
          {testJobSelectionCheckboxType(row)}
          <div
            className={
              ((row?.runResult !== "ABORTED" && row?.runResult !== "DONE") || row?.resultObj?.UPLOADING_RESULT) &&
              styles.inProgressResult
            }
          >
            <div>
              <h3 className={styles.blueFontColor} onClick={() => openTestPlanResultDetails(row, props.orgId)}>
                {row?.testJobExecName || "NA"}
              </h3>
            </div>
            <div>
              <span className={styles.label}>Execution Mode: </span>
              {executionModeShortText[row?.testJobExecutionType]}
            </div>
            <div>
              <span className={styles.label}>Build to be used: </span>
              <Tooltip color={TOOLTIP_BG_COLOR} title={row?.buildToBeUsedTitle === "URL" && row?.buildToBeUsed}>
                <span className={row?.buildToBeUsedTitle === "URL" ? styles.buildTOBeUsedText : ""}>
                  {row?.buildToBeUsedTitle}
                </span>
              </Tooltip>
            </div>
            <div>
              <span className={styles.label}>Execution Farm: </span>
              {row?.deviceServiceFarm}
            </div>
            <div>
              <span className={styles.label}>Devices to Run on: </span>
              {!_.isEmpty(row?.devices) ? (
                showDeviceList(row?.devices)
              ) : (
                <span className={styles.noDeviceText}> Unknown </span>
              )}
            </div>
          </div>
        </div>
      ),
      testCoverage: (
        // row?.runResult !== "PREPARING_RUN_TO_EXECUTE" ? (
        <>
          {/* <div className={styles.headerOfTestCoverage}>Test Coverage</div> */}

          <div>
            <span className={styles.label}>
              Devices:
              {!_.isEmpty(row?.devices) ? (
                showDeviceList(row?.devices)
              ) : (
                <span className={styles.noDeviceText}> Unknown </span>
              )}{" "}
            </span>
          </div>
          <div>
            <span className={styles.label}>OS: </span>
            {row?.osCovered?.replaceAll(",", ", ")}
          </div>
          {row?.localeCovered && (
            <div>
              <span className={styles.label}>Locales:</span> {row?.localeCovered?.replaceAll(",", ", ")}
            </div>
          )}
          <div>
            <span>Tests: </span>
            <span className={styles.marginRight}>Scenarios: {row?.testCoverageAcrossDevices?.scenarioCount},</span>
            <span className={styles.marginRight}>Steps: {row?.testCoverageAcrossDevices?.steps?.stepsCount},</span>
            <span className={styles.marginRight}>
              Screenshots: {row?.testCoverageAcrossDevices?.steps?.screenshotCount}
            </span>
          </div>
        </>
      ),
      // ) : (
      //   ""
      // ),
      testPlan: (
        // row?.runResult !== "PREPARING_RUN_TO_EXECUTE" ? (
        <>
          <div>
            <span className={styles.label}>Test Plan Name: </span>
            {row?.name}
          </div>
          {checkNotUndefinedAndNull(row?.testCoveragePerDevice) && (
            <div>
              <span className={styles.label}>Test Details: </span>
              {Object.values(row?.testCoveragePerDevice)?.map((i) => (
                <>
                  <span className={styles.marginRight}>Scenarios: {i?.scenarioCount},</span>
                  <span className={styles.marginRight}>Steps: {i?.steps?.stepsCount},</span>
                  <span className={styles.marginRight}>Screenshots: {i?.steps?.screenshotCount}</span>
                </>
              ))}
            </div>
          )}
          <div>
            <span className={styles.label}>Test Data Profile: </span>
            {row?.selectedTestDataProfileName}
          </div>
        </>
      ),
      // ) : (""),
      results: (
        <>
          <div>
            {row?.runResult === "ABORT_REQUEST" ? (
              <AbortTag>
                <BadgeStyle color="#7D5E0F" />
                Aborting in process
              </AbortTag>
            ) : row?.runResult === "ABORTED" ? (
              <AbortTag>
                <BadgeStyle color="#7D5E0F" />
                Run Aborted
              </AbortTag>
            ) : (
              <div className="result-tag">{resultTag(row?.resultObj, row)}</div>
            )}
            {row?.runResult !== "PREPARING_RUN_TO_EXECUTE" &&
              row?.screenShotCounts?.totalExpectedScreenShotCounts !== 0 && (
                <Popover
                  content={
                    <>
                      <div className={styles.runResultDetails}>
                        <span className={styles.label}>Passed Scenarios Screenshots: </span>{" "}
                        {row?.screenShotCounts?.totalPassedCapturedScreenShotCounts} /{" "}
                        {row?.screenShotCounts?.totalPassedExpectedScreenShotCounts
                          ? row?.screenShotCounts?.totalPassedExpectedScreenShotCounts
                          : 0}
                      </div>
                      <div className={styles.runResultDetails}>
                        <span className={styles.label}>Failed/Aborted Scenarios Screenshots: </span>{" "}
                        {row?.screenShotCounts?.totalOtherCapturedScreenShotCounts} /{" "}
                        {row?.screenShotCounts?.totalOtherExpectedScreenShotCounts
                          ? row?.screenShotCounts?.totalOtherExpectedScreenShotCounts
                          : 0}
                      </div>
                    </>
                  }
                >
                  <div className={styles.runResultDetails}>
                    <span className={styles.label}>Total Screenshots Captured: </span>{" "}
                    {row?.screenShotCounts?.totalCapturedScreenShotCounts} /{" "}
                    {row?.screenShotCounts?.totalExpectedScreenShotCounts}
                  </div>
                </Popover>
              )}
            <div className={styles.runResultDetails}>
              <span className={styles.label}>Run # : </span>
              {row?.RunNumber} <span className={styles.label}>Started by: </span>
              {row?.startedBy} {row?.scheduleTime && <span className={styles.label}> on </span>}
              {row?.scheduleTime && displayUTCDateFormatWithToolTip(row.scheduleTime, defaultDateTimeFormat)}
            </div>
            {row?.startTime && (
              <div className={styles.runResultDetails}>
                <span className={styles.label}>Execution Started: </span>
                {row?.startTime && displayUTCDateFormatWithToolTip(row.startTime, defaultDateTimeFormat)}
                {row?.executionTime &&
                  row?.runResult &&
                  (row.runResult === RUN_RESULT_STATUS.DONE ? (
                    <>
                      <span className={styles.label}> and took</span> {durationTime(row?.executionTime.split(":"))}
                    </>
                  ) : (
                    ""
                  ))}
              </div>
            )}
          </div>
        </>
      ),
      action: (
        <div className={styles.actionIcon}>
          <Tooltip color={TOOLTIP_BG_COLOR} title="Report Details">
            <img
              src={ICON_REPORT}
              alt="Detail Result"
              className={styles.icons}
              onClick={() => openTestPlanResultDetails(row, props.orgId)}
            />
          </Tooltip>
          {handleHideDeleteOnInProgress(row)}
        </div>
      ),
    }));

  return (
    <>
      <Spin spinning={testPlanLoading} tip="Loading" className={styles.spinnerCenter}>
        {!executionReportProjectId && (
          <div className={styles.innerHeader}>
            <div className={styles.mainHeaderText}>Test Reports</div>
          </div>
        )}
        <div className={!executionReportProjectId && styles.pageScroll}>
          {!executionReportProjectId && (
            <div className={styles.searchBox}>
              <div>
                <CommonSearch
                  placeHolder="Search By Run Number, Test Plan Name"
                  searchDetails={(searchText) => {
                    dispatch(handleChangeReportManagerSearch({ ...reportManagerSearch, searchKeyword: searchText }));
                    dispatch(getTestPlanAndScenarioResultList(projectId, true));
                  }}
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                />
              </div>
              <div className={styles.datePickerBox}>
                {isCustomRangePicker ? (
                  <DatePickerBox
                    dateRange={[reportManagerSearch?.startDate, reportManagerSearch?.endDate]}
                    dateChange={(dateStrings) => {
                      dispatch(
                        handleChangeReportManagerSearch({
                          ...reportManagerSearch,
                          startDate: dateStrings !== null ? dateStrings[0] : "",
                          endDate: dateStrings !== null ? dateStrings[1] : "",
                        })
                      );
                      dateStrings === null && setIsCustomRangePicker(false);
                      dispatch(getTestPlanAndScenarioResultList(projectId, true));
                    }}
                  />
                ) : (
                  <CustomDatePicker
                    filterList={customDateRangeFilterList}
                    handleChangeCustomDateFilter={(date) => {
                      dispatch(
                        handleChangeReportManagerSearch({
                          ...reportManagerSearch,
                          startDate: date !== "custom" ? date[0] : "",
                          endDate: date !== "custom" ? date[1] : "",
                        })
                      );
                      date === "custom"
                        ? setIsCustomRangePicker(true)
                        : dispatch(getTestPlanAndScenarioResultList(projectId, true));
                    }}
                  />
                )}
              </div>

              <FilterComponent
                filterList={filterList}
                handleFilterChange={(filter) => {
                  dispatch(changeTestPlanResultListPageNumber(1));
                  dispatch(
                    handleChangeReportManagerSearch({
                      ...reportManagerSearch,
                      status: filter,
                    })
                  );
                  dispatch(getTestPlanAndScenarioResultList(projectId, true));
                }}
                selectedFilter={getSelectedJobStatus(reportManagerSearch?.status)}
              />
            </div>
          )}
          <div className={executionReportProjectId ? styles.selectBoxInReport : styles.selectAllBox}>
            {!executionReportProjectId && !_.isEmpty(selectAllRun) && (
              <div>
                <SelectAllBox
                  isChecked={isSelectedAllIds(
                    selectAllRun,
                    checkIsNotInProgress?.map((i) => ({ id: i?.runId }))
                  )}
                  isDisable={
                    _.isEmpty(testPlanResultData) ||
                    (testPlanResultData &&
                      testPlanResultData?.filter((j) => j?.runResult === "IN_PROGRESS")?.length ===
                        testPlanResultData?.length)
                  }
                  selectAllIds={selectAllRun}
                  handleChangeCheck={(isChecked) => {
                    if (isChecked) {
                      setSelectAllRun([
                        ...selectAllRun,
                        ...checkIsNotInProgress
                          ?.filter((i) => !selectAllRun.includes(i?.runId) && i?.runId)
                          ?.map((i) => i?.runId),
                      ]);
                    } else {
                      setSelectAllRun(
                        removeSelectedIds(
                          selectAllRun,
                          checkIsNotInProgress?.map((i) => ({ id: i?.runId }))
                        )
                      );
                    }
                  }}
                  deleteConfirm={deleteConfirm}
                />
              </div>
            )}
          </div>
          <div className={styles.tableComponent}>
            <TableComponent columns={columns} data={testResultDetails} selected={selectAllRun} />
          </div>
          <div className={`${styles.pagination} ${executionReportProjectId && styles.paginationInExecutionReport}`}>
            {!_.isEmpty(testPlanResultData) && (
              <PaginationComponent
                currentPageItems={totalPageItem}
                totalItems={totalTestPlanResultItems}
                currentPage={currentTestPlanResultPage}
                totalPage={totalTestPlanResultPage}
                callPaginationAPI={(pageNumber) => {
                  dispatch(changeTestPlanResultListPageNumber(pageNumber));
                  dispatch(getTestPlanAndScenarioResultList(projectId, executionReportProjectId));
                }}
              />
            )}
          </div>
          {window.pageYOffset > 300 && (
            <div className={executionReportProjectId ? styles.pullRight : styles.selectBoxInFooter}>
              {!executionReportProjectId && (
                <div>
                  <SelectAllBox
                    isChecked={isSelectedAllIds(
                      selectAllRun,
                      checkIsNotInProgress?.map((i) => ({ id: i?.runId }))
                    )}
                    isDisable={
                      _.isEmpty(testPlanResultData) ||
                      (testPlanResultData &&
                        testPlanResultData?.filter((j) => j?.runResult === "IN_PROGRESS")?.length ===
                          testPlanResultData?.length)
                    }
                    selectAllIds={selectAllRun}
                    handleChangeCheck={(isChecked) => {
                      if (isChecked) {
                        setSelectAllRun([
                          ...selectAllRun,
                          ...checkIsNotInProgress
                            ?.filter((i) => !selectAllRun.includes(i?.runId) && i?.runId)
                            ?.map((i) => i?.runId),
                        ]);
                      } else {
                        setSelectAllRun(
                          removeSelectedIds(
                            selectAllRun,
                            checkIsNotInProgress?.map((i) => ({ id: i?.runId }))
                          )
                        );
                      }
                    }}
                    deleteConfirm={deleteConfirm}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </Spin>
    </>
  );
};
export default TestPlanResult;
