import _ from "lodash";
import {
  ACTION,
  ELEMENT_ACTION,
  LAUNCH_APP,
  SELECTOR_TYPE,
  STEP,
  VERIFICATIONS,
  recElementSettings,
  recTestStepSettings,
  verificationExistenceValues,
} from "../../Constants";
import {
  isActionNeedToSelectElement,
  isNotNullAndNotEmptyAndNotUndefined,
  isNullOrUndefinedOrEmpty,
  isStepHaveLoopStep,
  isStepHavePrecondition,
  isValidVerificationProperties,
} from "../../Util";

const errorInSavedVerificationProperty = (unSavedRecTestStep) => {
  if (unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_recStepVerifyElementProperties")) {
    if (unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length === 0) {
      return true;
    }
    if (
      (unSavedRecTestStep.recTestStepProperty?.addLoopCondition ||
        unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0) &&
      !isValidVerificationProperties(unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties)
    ) {
      return true;
    }
  }
  return false;
};

const errorInPreConditionStep = (unSavedRecTestStep) => {
  if (isStepHavePrecondition(unSavedRecTestStep)) {
    if (isNullOrUndefinedOrEmpty(unSavedRecTestStep.recTestStepProperty?.preConditionTitle)) {
      return true;
    }
  }
};

const errorInLoopStep = (unSavedRecTestStep) => {
  if (isStepHaveLoopStep(unSavedRecTestStep)) {
    if (isNullOrUndefinedOrEmpty(unSavedRecTestStep.recTestStepProperty?.loopCount)) {
      return true;
    }
  }
};

const isRecTestStepPropertySettingsChanged = (unSavedRecTestStep) => {
  if (
    unSavedRecTestStep?.id &&
    !unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_recElements") &&
    recTestStepSettings.filter((recTestStepPropertyKey) =>
      unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty(recTestStepPropertyKey)
    ).length > 0
  ) {
    return true;
  }
  return false;
};

const errorInCommonSingleValueAction = (unSavedRecTestStep,unSavedRecElement) => {
  if (
    [
      LAUNCH_APP,
      ELEMENT_ACTION.PAUSE,
      ELEMENT_ACTION.CAPTURE_SCREENSHOT,
      ELEMENT_ACTION.SWIPE,
      ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA,
      ELEMENT_ACTION.SEND_KEYS,
    ].includes(unSavedRecTestStep?.recTestStepProperty?.action)
  ) {
    if (unSavedRecTestStep?.recTestStepProperty?.action === LAUNCH_APP) {
      return isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.packageName);
    }
    if (unSavedRecTestStep?.recTestStepProperty?.action === ELEMENT_ACTION.PAUSE) {
      return isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.pauseTime);
    }
    if (unSavedRecTestStep?.recTestStepProperty?.action === ELEMENT_ACTION.CAPTURE_SCREENSHOT) {
      return isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.captureScreenShotDelayTime);
    }
    if (unSavedRecTestStep?.recTestStepProperty?.action === ELEMENT_ACTION.SWIPE) {
      return (
        isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.recSwipeAttributes?.direction) ||
        isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.recSwipeAttributes?.percentage)
      );
    }
    if (unSavedRecTestStep?.recTestStepProperty?.action === ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA) {
      return (
        isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.insertValueToTestData) ||
        (isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.outputTestData) &&
          isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.outputTestDataId))
      );
    }
    if (unSavedRecTestStep?.recTestStepProperty?.isAIStep==0 && unSavedRecTestStep?.recTestStepProperty?.action === ELEMENT_ACTION.SEND_KEYS) {
      return (
        isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.userInputValue) &&
        isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.testData)
      );
    }
    if (isAISavedFlagChanged(unSavedRecTestStep) && isRecElementLocatorPropertiesSaved(unSavedRecElement)) {
      return true;
    }
  }
  return false;
};

const commonErrorCheckInSavedStep = (unSavedRecTestStep,unSavedRecElement) => {
  if (isRecTestStepPropertySettingsChanged(unSavedRecTestStep)) {
    return false;
  }
  if (errorInCommonSingleValueAction(unSavedRecTestStep,unSavedRecElement)) {
    return true;
  }

  return false;
};

const errorInCustomRecElement = (unSavedRecTestStep, unSavedRecElement) => {
  if (unSavedRecElement?.elementSelectorType === SELECTOR_TYPE.CUSTOM_ELEMENT) {
    if (
      (unSavedRecElement?.testData === undefined || unSavedRecElement?.useTestData === 0) &&
      (unSavedRecElement?.applicationStrings === undefined || unSavedRecElement?.useApplicationString === 0) &&
      isNullOrUndefinedOrEmpty(unSavedRecElement?.searchValue)
    ) {
      return true;
    }
    return false;
  }
};
const isVerificationAndNoErrorInImageRecElement = (unSavedRecTestStep, unSavedRecElement) => {
  if (isVerificationAndImageRecElement(unSavedRecTestStep, unSavedRecElement)) {
    return (
      (unSavedRecElement?.id === undefined &&
        isNotNullAndNotEmptyAndNotUndefined(unSavedRecElement?.elementImage) &&
        isNotNullAndNotEmptyAndNotUndefined(unSavedRecElement?.selectedImageCoordinates) &&
        isNotNullAndNotEmptyAndNotUndefined(unSavedRecElement?.selectedImageCoordinates?.height) &&
        isNotNullAndNotEmptyAndNotUndefined(unSavedRecElement?.selectedImageCoordinates?.width)) ||
      (![0, undefined].includes(unSavedRecElement?.id) &&
        isNotNullAndNotEmptyAndNotUndefined(unSavedRecElement?.elementImage))
    );
  }
  return false;
};

const checkIsForVerificationElementDoesNotExist = (unSavedRecTestStep, unSavedRecElement) => {
  return (
    [0, undefined].includes(unSavedRecElement?.id) &&
    unSavedRecTestStep?.recTestStepProperty?.action === VERIFICATIONS &&
    unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties &&
    unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length === 1 &&
    unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties[0].key ===
      verificationExistenceValues.does_not_exist
  );
};

const errorInSavedRecElement = (unSavedRecTestStep, unSavedRecElement) => {
  // if (
  //   unSavedRecTestStep?.id &&
  //   (unSavedRecElement?.id || checkIsForVerificationElementDoesNotExist(unSavedRecTestStep, unSavedRecElement))
  // ) {
  if(unSavedRecElement==undefined){
    return true;
  }
  if (isVerificationAndImageRecElement(unSavedRecTestStep, unSavedRecElement)) {
    return recElementSettings.filter((recElementKey) => unSavedRecElement.hasOwnProperty(recElementKey)).length === 0;
  }
  if (
    isActionNeedToSelectElement(unSavedRecTestStep?.recTestStepProperty?.action) &&
    unSavedRecTestStep?.recTestStepProperty?.recElements.length === 0
  ) {
    return true;
  }

  //check attach application string or device string key
  if (unSavedRecElement?.applicationStrings !== undefined) {
    if (
      unSavedRecElement?.recStepVerifyElementProperties?.some(
        (i) => i?.key === "Equals to Locale value of App String" || i?.key === "Contains Locale value of App String"
      ) &&
      unSavedRecElement?.recStepVerifyElementProperties?.every((i) => {
        return i?.key !== "";
      })
    ) {
      return false;
    } else {
      return true;
    }
  } else if (unSavedRecElement?.deviceStrings) {
    if (
      unSavedRecElement?.recStepVerifyElementProperties?.some(
        (i) =>
          i?.key === "Equals to Locale value of Device String" || i?.key !== "Contains Locale value of Device String"
      ) &&
      unSavedRecElement?.recStepVerifyElementProperties?.every((i) => {
        return i?.key !== "";
      })
    ) {
      return false;
    } else {
      return true;
    }
  }

  if (unSavedRecTestStep?.id && unSavedRecElement?.id) {
    if (unSavedRecElement?.hasOwnProperty("previous_recStepVerifyElementProperties")) {
      if (
        unSavedRecElement?.recStepVerifyElementProperties?.length > 0 &&
        !isValidVerificationProperties(unSavedRecElement?.recStepVerifyElementProperties)
      ) {
        return true;
      }
    }
    if (errorInCustomRecElement(unSavedRecTestStep, unSavedRecElement)) {
      return true;
    }
    if (recElementSettings.filter((recElementKey) => unSavedRecElement?.hasOwnProperty(recElementKey)).length > 0) {
      return false;
    }
    return false;
  } else if (errorInCustomRecElement(unSavedRecTestStep, unSavedRecElement)) {
    return true;
  }
  return false;
};

const errorInUnSavedRecElement = (unSavedRecTestStep, unSavedRecElement) => {
  // Check for New Element
  if (
    unSavedRecElement.recElementLocatorProperties.filter((recElementLocatorProperty) =>
      [0, undefined].includes(recElementLocatorProperty.id)
    ).length === 0
  ) {
    if (
      recTestStepSettings.filter((recTestStepPropertyKey) =>
        unSavedRecTestStep.recTestStepProperty?.hasOwnProperty(recTestStepPropertyKey)
      ).length > 0
    ) {
      return true;
    }
    if (recElementSettings.filter((recElementKey) => unSavedRecElement.hasOwnProperty(recElementKey)).length > 0) {
      return true;
    }
    if (unSavedRecElement?.hasOwnProperty("previous_recStepVerifyElementProperties")) {
      return true;
    }
  }
  // Check Element is selected on Device or not
  if (
    unSavedRecElement.elementSelectorType === SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN &&
    (unSavedRecElement.recElementLocatorProperties.length === 0 || unSavedRecElement.recElementProperties === 0)
  ) {
    return true;
  }
  return false;
};

// Check Element RecElementLocatorProperties is saved no change in it
const isRecElementLocatorPropertiesSaved = (unSavedRecTestStep, unSavedRecElement) => {
  return (
    unSavedRecElement?.id &&
    unSavedRecElement.elementSelectorType === SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN &&
    unSavedRecElement.recElementLocatorProperties.length > 0 &&
    unSavedRecElement.recElementLocatorProperties.filter((recElementLocatorProperty) =>
      [0, undefined].includes(recElementLocatorProperty.id)
    ).length === 0
  );
};

// Check Step satisfies condition, its for edit not new Step
const isStepForEdit = (unSavedRecTestStep, unSavedRecElement) => {
  if (
    (unSavedRecTestStep && unSavedRecElement === undefined) ||
    (unSavedRecTestStep &&
      unSavedRecElement &&
      (unSavedRecElement.elementSelectorType === SELECTOR_TYPE.CUSTOM_ELEMENT ||
        isRecElementLocatorPropertiesSaved(unSavedRecTestStep, unSavedRecElement) ||
        (unSavedRecElement.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN &&
          isNotNullAndNotEmptyAndNotUndefined(unSavedRecElement?.id))))
  ) {
    return true;
  }
  return false;
};

// Creating mapping of step action with validation methods applied on step
const validationsForSaveButton = {
  // Non-RecElement Related Step
  HOME: [commonErrorCheckInSavedStep],
  BACK: [commonErrorCheckInSavedStep],
  ENTER: [commonErrorCheckInSavedStep],
  pause: [commonErrorCheckInSavedStep],
  captureScreenshot: [commonErrorCheckInSavedStep],
  insertValueToOutputTestData: [commonErrorCheckInSavedStep],
  setAppLocale: [commonErrorCheckInSavedStep],
  Swipe: [commonErrorCheckInSavedStep],
  deviceInteraction: [commonErrorCheckInSavedStep],
  launchApp: [commonErrorCheckInSavedStep],
  // Element Related Step
  swipeOverElement: [commonErrorCheckInSavedStep, errorInSavedRecElement],
  tap: [commonErrorCheckInSavedStep, errorInSavedRecElement],
  longPress: [commonErrorCheckInSavedStep, errorInSavedRecElement],
  tapByIndex: [commonErrorCheckInSavedStep, errorInSavedRecElement],
  tapByCoordinates: [commonErrorCheckInSavedStep, errorInSavedRecElement],
  sendKeys: [commonErrorCheckInSavedStep, errorInSavedRecElement],
  clearText: [commonErrorCheckInSavedStep],
  verifications: [errorInSavedVerificationProperty, commonErrorCheckInSavedStep, errorInSavedRecElement],
  step: [
    errorInPreConditionStep,
    errorInLoopStep,
    errorInSavedVerificationProperty,
    commonErrorCheckInSavedStep,
    errorInSavedRecElement
  ],
};

const errorInUnSavedVerificationProperty = (unSavedRecTestStep) => {
  // Check Verification properties is partially added during Verification Step
  if (unSavedRecTestStep.recTestStepProperty?.action === VERIFICATIONS) {
    if (
      unSavedRecTestStep?.recTestStepProperty?.recStepVerifyElementProperties?.length > 0 &&
      !isValidVerificationProperties(unSavedRecTestStep?.recTestStepProperty?.recStepVerifyElementProperties)
    ) {
      return true;
    }
    if (unSavedRecTestStep?.recTestStepProperty?.recStepVerifyElementProperties?.length === 0) {
      return true;
    }
  }
};

const errorInSavedRecElementVerificationProperty = (unSavedRecTestStep, unSavedRecElement) => {
  // Check Verification properties is partially added during recElement
  if (unSavedRecElement?.hasOwnProperty("previous_recStepVerifyElementProperties")) {
    if (errorInUnSavedRecElementVerificationProperty()) {
      return true;
    }
    return false;
  }
  return false;
};

const disablePerformAndSaveButtonWithAI = (unSavedRecTestStep) => {
  if (
    (unSavedRecTestStep?.id &&
      unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_stepName") &&
      recTestStepSettings.filter((recTestStepPropertyKey) =>
        unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty(recTestStepPropertyKey)
      ).length === 1) ||
    recTestStepSettings.filter((recTestStepPropertyKey) =>
      unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty(recTestStepPropertyKey)
    ).length > 0
  ) {
    return false;
  }
  return errorInAIStep(unSavedRecTestStep);
};

const errorInAIStep = (unSavedRecTestStep) => {
  if (unSavedRecTestStep?.recTestStepProperty?.isAIStep == 1) {
    if(isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.aiStepObjective)){
      return true;
    }
    if (
      unSavedRecTestStep?.recTestStepProperty?.aiUseTestData == 1 &&
      (unSavedRecTestStep?.recTestStepProperty?.aiTargetElementTestDataId==undefined && (unSavedRecTestStep?.recTestStepProperty?.aiTargetElementTestData == undefined ||
        unSavedRecTestStep?.recTestStepProperty?.aiTargetElementTestData?.id == undefined)) &&
      ((unSavedRecTestStep?.recTestStepProperty?.aiUseTestData == 1 &&
        unSavedRecTestStep?.recTestStepProperty?.aiApplicationStringKey == undefined) ||
        unSavedRecTestStep?.recTestStepProperty?.aiApplicationStringKey == undefined)
    ) {
      return true;
    }
  }
  return false;
};

const errorInUnSavedRecElementVerificationProperty = (unSavedRecTestStep, unSavedRecElement) => {
  // Check Verification properties is partially added during recElement
  if (
    unSavedRecElement?.recStepVerifyElementProperties?.length > 0 &&
    !isValidVerificationProperties(unSavedRecElement?.recStepVerifyElementProperties)
  ) {
    return true;
  }
};

const errorInUnSavedPreConditionStep = (unSavedRecTestStep) => {
  // Check Verification properties is partially added during Verification Step in Precondition
  if (isStepHavePrecondition(unSavedRecTestStep)) {
    if (isNullOrUndefinedOrEmpty(unSavedRecTestStep.recTestStepProperty?.preConditionTitle)) {
      return true;
    }
    if (unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length === 0) {
      return true;
    }
    if (
      unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0 &&
      !isValidVerificationProperties(unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties)
    ) {
      return true;
    }
  }
};

const errorInUnSavedLoopStep = (unSavedRecTestStep) => {
  // Check Verification properties is partially added during Verification Step in Loop Condition
  if (
    isStepHaveLoopStep(unSavedRecTestStep) &&
    (unSavedRecTestStep.recTestStepProperty?.addLoopCondition ||
      unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0)
  ) {
    if (unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length === 0) {
      return true;
    }
    if (
      unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0 &&
      !isValidVerificationProperties(unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties)
    ) {
      return true;
    }
  }
};

const unSavedElementValidationList = (unSavedRecTestStep, unSavedRecElement, selectedDeviceByUdid) => {
  let validationList = [];
  if (unSavedRecElement) {
    if (unSavedRecElement?.elementSelectorType === SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN) {
      validationList = [
        errorInUnSavedRecElement,
        errorInUnSavedVerificationProperty,
        errorInUnSavedRecElementVerificationProperty,
        errorInUnSavedPreConditionStep,
        errorInUnSavedLoopStep,
      ];
    }

    if (unSavedRecElement?.elementSelectorType === SELECTOR_TYPE.CUSTOM_ELEMENT) {
      validationList = [
        errorInUnSavedPreConditionStep,
        errorInUnSavedLoopStep,
        errorInUnSavedVerificationProperty,
        errorInUnSavedRecElement,
        errorInCustomRecElement,
        errorInSavedRecElementVerificationProperty,
      ];
    }

    if (unSavedRecElement?.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN) {
      validationList = [
        (unSavedRecTestStep, unSavedRecElement) => {
          if (
            isNotNullAndNotEmptyAndNotUndefined(unSavedRecElement.id) &&
            unSavedRecElement?.elementImage?.includes("https")
          ) {
            return true;
          } else if (isNullOrUndefinedOrEmpty(unSavedRecElement?.elementImage)) {
            return true;
          }
          return false;
        },
      ];
    }
  }
  if (unSavedRecTestStep?.recTestStepProperty?.isAIStep == 1 && unSavedRecTestStep?.recTestStepProperty?.useManualElementSelection==0) {
    if (selectedDeviceByUdid === undefined) {
      validationList = [];
    } else {
      validationList = [errorInAIStep];
    }
  }
  if (unSavedRecTestStep?.recTestStepProperty?.isAIStep == 1 && unSavedRecTestStep?.recTestStepProperty?.useManualElementSelection==1) {
    validationList.push(errorInAIStep);
  }
  if (
    ["HOME", "BACK", "ENTER", "Swipe", "deviceInteraction", "launchApp"].includes(
      unSavedRecTestStep?.recTestStepProperty?.action
    )
  ) {
    validationList = validationsForSaveButton[unSavedRecTestStep?.recTestStepProperty?.action];
  }
  return validationList;
};

export const disableStepResetButton = (unSavedRecTestStep, unSavedRecElement) => {
  // Check RecTestStep is saved Step
  if (unSavedRecTestStep?.id === undefined) {
    return true;
  }
  // Check user is replacing the Elements or Add new element
  if (unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_recElements") && !unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_useManualElementSelection")) {
    if (unSavedRecTestStep?.recTestStepProperty?.previous_recElements?.length === 0) {
      return true;
    } else {
      return false;
    }
  }
  if (
    unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_aiTargetElementTestData") ||
    unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_aiApplicationStringKey")
  ) {
    return false;
  }

  //check add VerificationTestData condition
  if (
    unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties?.some((i) => i?.type === "VerifyTestData") &&
    unSavedRecTestStep?.recTestStepProperty?.previous_preConditionTitle === ""
  ) {
    return true;
  }
  if (unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_recElementOperator")) {
    return false;
  }
  //check insertValue to testData
  if (unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_insertValueToTestData")) {
    return false;
  }

  if (unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_userInputValue")) {
    return false;
  }

  if (unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_appendRandomText")) {
    return false;
  }

  if (unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_removeAppPreviousSession")) {
    return false;
  }

  //check add condition
  if (
    unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_isStepHasPrecondition") ||
    unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_isLoopStep")
  ) {
    return true;
  }
  //check preConditionTitle
  if (
    unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_preConditionTitle") &&
    unSavedRecTestStep?.recTestStepProperty?.previous_preConditionTitle !== "" &&
    unSavedRecTestStep?.recTestStepProperty?.previous_preConditionTitle !== null
  ) {
    return false;
  }

  // Check Any Change in Verification Properties of PreCondition or Loop Step or verification step
  if (unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_recStepVerifyElementProperties")) {
    if (
      (isStepHavePrecondition(unSavedRecTestStep) || isStepHaveLoopStep(unSavedRecTestStep)) &&
      unSavedRecTestStep?.recTestStepProperty?.previous_recStepVerifyElementProperties.length === 0
    ) {
      return true;
    }
    return false;
  }
  // Check Any Change in RecTestStep Post Operation Settings
  if (
    recTestStepSettings.filter((recTestStepPropertyKey) =>
      unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty(recTestStepPropertyKey)
    ).length > 0
  ) {
    return false;
  }
  // Check Any Change in Rec Element settings
  if (
    unSavedRecElement?.id &&
    recElementSettings.filter((recElementKey) => unSavedRecElement?.hasOwnProperty(recElementKey)).length > 0
  ) {
    return false;
  }
  // Check Any Change in Verification Properties
  if (unSavedRecElement?.id && unSavedRecElement?.hasOwnProperty("previous_recStepVerifyElementProperties")) {
    return false;
  }
  if (unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_testData")) {
    return false;
  }
  if (unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_isAIStep")) {
    return false;
  }
   if (unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_useManualElementSelection")) {
    return false;
  }
   if (unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_executeBeforeAiStep")) {
    return false;
  }
  if (
    unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_aiStepObjective") &&
    unSavedRecTestStep?.recTestStepProperty?.previous_aiStepObjective !== "" &&
    unSavedRecTestStep?.recTestStepProperty?.previous_aiStepObjective !== null
  ) {
    return false;
  }
  if (unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_reverseSwipeElementNotFound")) {
    return false;
  }
  return true;
};

export const disableStepSaveButton = (unSavedRecTestStep, unSavedRecElement) => {
  if(unSavedRecTestStep?.recTestStepProperty?.useManualElementSelection==0 && unSavedRecTestStep?.recTestStepProperty?.isAIStep==0){
    return true;
  }
  // Check step is saved or its custom element
  if (isStepForEdit(unSavedRecTestStep, unSavedRecElement)) {
    // Fetching the validation methods as per RecTestStep Action
    let validationTypes = [];
    if (unSavedRecTestStep?.recTestStepProperty?.isAIStep == 1 && unSavedRecTestStep?.recTestStepProperty?.useManualElementSelection==0) {
      validationTypes = [disablePerformAndSaveButtonWithAI];
    } 
    if(unSavedRecTestStep?.recTestStepProperty?.useManualElementSelection==1 || (unSavedRecTestStep?.recTestStepProperty?.isAIStep == 1 && unSavedRecTestStep?.recTestStepProperty?.useManualElementSelection==1)){
      validationTypes = validationsForSaveButton[unSavedRecTestStep?.recTestStepProperty?.action];
      if(unSavedRecElement==undefined){
        validationTypes = validationTypes.filter(item => item!==errorInSavedRecElement)
      }
    }
    // return validationTypes.some((vaidationType) => validationType(unSavedRecTestStep, unSavedRecElement));
    // console.log(
    //   "Step Name = " + unSavedRecTestStep?.recTestStepProperty?.name + " Rec Element = " + unSavedRecElement?.name
    // );
    // console.log("===============Save===============");
    for (let i = 0; i < validationTypes?.length; i++) {
      if (validationTypes[i](unSavedRecTestStep, unSavedRecElement)) {
        // console.log(validationTypes[i].name + true);
        return true;
      } else {
        // console.log(validationTypes[i].name + false);
      }
    }
    // console.log("===================================");
    return false;
  }
  return true;
};

export const isVerificationAndImageRecElement = (unSavedRecTestStep, unSavedRecElement) => {
  if (
    [VERIFICATIONS, STEP].includes(unSavedRecTestStep?.recTestStepProperty?.action) &&
    unSavedRecElement?.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN
  ) {
    return true;
  }
  return false;
};

export const disableStepPerformSaveButton = (unSavedRecTestStep, unSavedRecElement, selectedDeviceByUdid) => {
  if (selectedDeviceByUdid === undefined) {
    return true;
  }
  if (
    (unSavedRecTestStep?.id === undefined ||
      unSavedRecTestStep?.recTestStepProperty?.isStepHasPrecondition === 1 ||
      unSavedRecTestStep?.recTestStepProperty?.isLoopStep === 1) &&
    selectedDeviceByUdid === undefined
  ) {
    return true;
  }
  if(unSavedRecElement && isAISavedFlagChanged(unSavedRecTestStep)){
    return true; 
  }
  let validationList = unSavedElementValidationList(unSavedRecTestStep, unSavedRecElement, selectedDeviceByUdid);
  // Error if no validation List found
  if (validationList.length === 0) {
    return true;
  }
  if (unSavedRecElement?.id) {
    validationList.push(isRecTestStepPropertySettingsChanged);
  }
  validationList.push(errorInCommonSingleValueAction);
  // console.log("===============Perform & Save===============");
  for (let i = 0; i < validationList.length; i++) {
    if (validationList[i](unSavedRecTestStep, unSavedRecElement)) {
      // console.log(validationList[i].name + " = " + true);
      return true;
    }
    // console.log(validationList[i].name + " = " + false);
  }
  // console.log("=========================================");
  return false;
};

function isAISavedFlagChanged(unSavedRecTestStep){
  return unSavedRecTestStep?.id!=undefined && unSavedRecTestStep?.recTestStepProperty?.previous_isAIStep !=undefined && unSavedRecTestStep?.recTestStepProperty?.isAIStep != unSavedRecTestStep?.recTestStepProperty?.previous_isAIStep
}