import React from "react";
import { Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addRecTestStepAction,
  showImportRecTestStepModal,
  performAndSaveRecTestStepAction,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import {
  ANDROID,
  CAPTURE_SCREENSHOT,
  SET_APP_LOCALE,
  SET_APP_LOCALE_TXT,
  DEVICE_INTERACTION,
  ELEMENT_ACTION,
  LAUNCH_APP,
  SWIPE_OVER_ELEMENT,
  VERIFICATIONS,
} from "../../../../../Constants";
import {
  ADD_ACTION_ELEMENT,
  ADD_GENERAL_ACTION,
  ADD_VERIFICATION,
  CLEAR_TEXT,
  ENTER_TEXT,
  IMPORT_ACTION_IN_STEP,
  LONG_PRESS,
  SCREENSHOT_ELEMENT,
  SWIPE_ELEMENT,
  TAP,
  TAP_BY_INDEX,
} from "../../../../../Constants/TooltipConstants";
import {
  ADD_ACTION_ICON_ON_HOVER,
  ICON_ADD_ACTION,
  ICON_ADD_DELAY_ACTION,
  ICON_ADD_VERIFICATION,
  ICON_ASSIGN_VALUE_TO_TEST_DATA_ACTION,
  ICON_CLEAR_TEXT_ACTION,
  ICON_ENTER_TEXT_ACTION,
  ICON_GENERAL_ACTION,
  ICON_IMPORT_ACTION,
  ICON_KEYPAD_ENTER_ACTION,
  ICON_LAUNCH_APP_ACTION,
  ICON_LONG_PRESS_ACTION,
  ICON_NAVIGATE_BACK_ACTION,
  ICON_NAVIGATE_HOME_ACTION,
  ICON_SCREENSHOT_ELEMENT_ACTION,
  ICON_SCREENSHOT_PAGE_ACTION,
  ICON_SCROLL_PAGE_ACTION,
  ICON_SWIPE_ELEMENT_ACTION,
  ICON_TAP_ACTION,
  ICON_TAP_BY_INDEX_ACTION,
} from "../../../../../Constants/SvgConstants";
import SvgLoader from "../../../../../Util/SvgLoader";
import RecTestStepOperationV2 from "./RecTestStepOperationV2";
import styles from "./StepActionsComponents.module.scss";

export const addActionOnElementMenu = (recTestStep, dispatch) => {
  const actionItems = [
    {
      key: ELEMENT_ACTION.TAP,
      action: ELEMENT_ACTION.TAP,
      icon: ICON_TAP_ACTION,
      title: TAP,
    },
    // {
    //   key: ELEMENT_ACTION.TAP_BY_INDEX,
    //   action: ELEMENT_ACTION.TAP_BY_INDEX,
    //   icon: ICON_TAP_BY_INDEX_ACTION,
    //   title: TAP_BY_INDEX,
    // },
    {
      key: ELEMENT_ACTION.LONG_PRESS,
      action: ELEMENT_ACTION.LONG_PRESS,
      icon: ICON_LONG_PRESS_ACTION,
      title: LONG_PRESS,
    },
    {
      key: ELEMENT_ACTION.SEND_KEYS,
      action: ELEMENT_ACTION.SEND_KEYS,
      icon: ICON_ENTER_TEXT_ACTION,
      title: ENTER_TEXT,
    },
    {
      key: ELEMENT_ACTION.CLEAR_TEXT,
      action: ELEMENT_ACTION.CLEAR_TEXT,
      icon: ICON_CLEAR_TEXT_ACTION,
      title: CLEAR_TEXT,
    },
    {
      key: SWIPE_OVER_ELEMENT,
      action: SWIPE_OVER_ELEMENT,
      icon: ICON_SWIPE_ELEMENT_ACTION,
      title: SWIPE_ELEMENT,
    },
    {
      key: ELEMENT_ACTION.SCREENSHOT_ELEMENT,
      icon: ICON_SCREENSHOT_ELEMENT_ACTION,
      title: SCREENSHOT_ELEMENT,
    },
  ];

  const handleItemClick = (action) => {
    if (action) {
      dispatch(addRecTestStepAction(action, recTestStep));
    }
  };

  const menus = actionItems.map(({ key, action, icon, title }) => (
    <Menu.Item key={key} onClick={() => handleItemClick(action)}>
      <div className={styles.menuItems}>
        <SvgLoader iconName={icon} />
        <span className={styles.menuItemsTitle}>{title}</span>
      </div>
    </Menu.Item>
  ));

  return <Menu className={styles.actionsDropdown}>{menus}</Menu>;
};

export const generalActionMenu = (recTestStep, platform, dispatch) => {
  let deviceActionItems = [
    {
      key: DEVICE_INTERACTION.HOME,
      action: DEVICE_INTERACTION.HOME,
      icon: ICON_NAVIGATE_HOME_ACTION,
      title: "Navigate to Home",
      dispatchAction: performAndSaveRecTestStepAction,
    },
  ];
  if (platform === ANDROID) {
    deviceActionItems = [
      ...deviceActionItems,
      {
        key: DEVICE_INTERACTION.BACK,
        action: DEVICE_INTERACTION.BACK,
        icon: ICON_NAVIGATE_BACK_ACTION,
        title: "Navigate Back",
        dispatchAction: performAndSaveRecTestStepAction,
      },
      {
        key: DEVICE_INTERACTION.ENTER,
        action: DEVICE_INTERACTION.ENTER,
        icon: ICON_KEYPAD_ENTER_ACTION,
        title: "Keypad Enter",
        dispatchAction: performAndSaveRecTestStepAction,
      },
    ];
  }
  let actionItems = [
    {
      key: LAUNCH_APP,
      action: LAUNCH_APP,
      icon: ICON_LAUNCH_APP_ACTION,
      title: "Launch App",
      dispatchAction: addRecTestStepAction,
    },
    {
      key: ELEMENT_ACTION.PAUSE,
      action: ELEMENT_ACTION.PAUSE,
      icon: ICON_ADD_DELAY_ACTION,
      title: "Add Delay",
      dispatchAction: addRecTestStepAction,
    },
    {
      key: "Scroll Page",
      action: ELEMENT_ACTION.SWIPE,
      icon: ICON_SCROLL_PAGE_ACTION,
      title: "Scroll Page",
      dispatchAction: addRecTestStepAction,
    },
    {
      key: CAPTURE_SCREENSHOT,
      action: CAPTURE_SCREENSHOT,
      icon: ICON_SCREENSHOT_PAGE_ACTION,
      title: "Screenshot Page",
      dispatchAction: addRecTestStepAction,
    },
    ...deviceActionItems,
    {
      key: ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA,
      action: ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA,
      icon: ICON_ASSIGN_VALUE_TO_TEST_DATA_ACTION,
      title: "Assign Value to Test Data",
      dispatchAction: addRecTestStepAction,
    },
    {
      key: SET_APP_LOCALE,
      action: SET_APP_LOCALE,
      icon: ICON_ASSIGN_VALUE_TO_TEST_DATA_ACTION,
      title: SET_APP_LOCALE_TXT,
      dispatchAction: addRecTestStepAction,
    },
  ];

  const handleItemClick = (action, dispatchAction) => {
    dispatch(dispatchAction(action, recTestStep));
  };

  const menus = actionItems.map(({ key, action, icon, title, dispatchAction }) => (
    <Menu.Item key={key} onClick={() => handleItemClick(action, dispatchAction)}>
      <div className={styles['menuItems']}>
        <SvgLoader iconName={icon} />
        <span className={styles['menuItemsTitle']}>{title}</span>
      </div>
    </Menu.Item>
  ));

  return <Menu className={styles['actionsDropdown']}>{menus}</Menu>;
};

const RecTestStepAddActionsV2 = (props) => {
  const {
    disabled,
    projectId,
    actionStep,
    platform,
    recTestStep,
    recTestStepOnHover,
    actionMenuVisible,
    setActionMenuVisible,
    generalActionMenuVisible,
    setGeneralActionMenuVisible,
  } = props;
  const { visibleAIGenerateRecTestStep } = useSelector((state) => state.RecTestStepCommonViewReducer);

  const disabledIcons = visibleAIGenerateRecTestStep?.includes(recTestStep.id);

  const dispatch = useDispatch();

  return (
    <div className={`${styles.subHeader} ${!recTestStepOnHover && styles.actionIconInHeader}`}>
      <RecTestStepOperationV2
        name="Actions"
        handleClick={addActionOnElementMenu(recTestStep, dispatch)}
        disabled={disabled || disabledIcons}
        tooltipTitle={ADD_ACTION_ELEMENT}
        imageName={recTestStepOnHover ? ADD_ACTION_ICON_ON_HOVER : ICON_ADD_ACTION}
        menuVisible={actionMenuVisible}
        setMenuVisible={setActionMenuVisible}
        isMenuItems
        recTestStepOnHover={recTestStepOnHover}
      />

      <RecTestStepOperationV2
        name="Verification"
        handleClick={() => {
          !disabled && dispatch(addRecTestStepAction(VERIFICATIONS, recTestStep));
        }}
        disabled={disabled || disabledIcons}
        tooltipTitle={ADD_VERIFICATION}
        imageName={ICON_ADD_VERIFICATION}
        recTestStepOnHover={recTestStepOnHover}
      />
      <RecTestStepOperationV2
        name="General Action"
        handleClick={generalActionMenu(recTestStep, platform, dispatch)}
        disabled={disabled || disabledIcons}
        tooltipTitle={ADD_GENERAL_ACTION}
        imageName={ICON_GENERAL_ACTION}
        menuVisible={generalActionMenuVisible}
        setMenuVisible={setGeneralActionMenuVisible}
        isMenuItems
        recTestStepOnHover={recTestStepOnHover}
      />
      {!recTestStepOnHover && (
        <RecTestStepOperationV2
          name="Import"
          disabled={disabled || disabledIcons}
          tooltipTitle={IMPORT_ACTION_IN_STEP}
          imageName={ICON_IMPORT_ACTION}
          handleClick={() =>
            !disabled &&
            dispatch(showImportRecTestStepModal("", projectId, recTestStep, actionStep, recTestStep?.recTestSteps))
          }
        />
      )}
    </div>
  );
};

export default RecTestStepAddActionsV2;
