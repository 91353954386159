import { Redirect } from "react-router-dom";
import { async, sync } from "../api/automation";
import { BuildRegisterServerModal } from "../Components/ServerManagement/BuildRegisterServerModal";
import {
  ALL_SERVER,
  CHANGE_REGISTER_SERVER_DATA,
  CHANGE_SERVER_MANAGER_TAB,
  CLOSE_ADD_EMULATOR_MODAL_VISIBLE,
  CREATE_VIRTUAL_DEVICE_LOADER,
  EMULATOR_SUPPORTED_DEVICE_LIST,
  EMULATOR_SUPPORTED_OS_LIST,
  ERROR_TYPE,
  FETCH_ALL_ORG_LIST,
  FETCH_ALL_PROJECTS,
  FETCH_ALL_SERVER,
  FETCH_DEVICE_DETAILS,
  FETCH_SERVER_TYPES,
  HIDE_REGISTER_SERVER_MODAL,
  LOGIN_STATUS_LOADING,
  OPEN_ADD_EMULATOR_MODAL_VISIBLE,
  PREVIOUS_REGISTER_SERVER_DATA,
  RECORD_10_PER_PAGE,
  SELECTED_ORG,
  SERVER_DEVICE_LOADING,
  SERVER_LOADING,
  START_STOP_VIRTUAL_DEVICE_LOADER,
  SUCCESS_LOCAL_DEVICE_SERVER_LOGIN,
  SUCCESS_STATUS,
  SUCCESS_TYPE,
  UPDATE_ADD_EMULATOR,
  VISIBLE_REGISTER_SERVER_MODAL,
} from "../Constants";
import history from "../history";
import { showNotification, updateResponse } from "../Util";
import { goToProjects } from "./ProjectsAction";
import { getOrgList } from "./TeamsAction";

export function changeServerManagerTab(selectedTab) {
  return async (dispatch, getState) => {
    dispatch({ type: CHANGE_SERVER_MANAGER_TAB, selectedTab });
  };
}

export function handleChangeRegisterServerData(registerServerData) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_REGISTER_SERVER_DATA, data: registerServerData });
  };
}

export function fetchServerType(id) {
  return async (dispatch, getState) => {
    return async("serverType/getAll", "GET").then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: FETCH_SERVER_TYPES, response });
      }
    });
  };
}

export function fetchAllServer(orgId, serverType, pageNumber) {
  return async (dispatch, getState) => {
    dispatch({ type: SERVER_LOADING, isLoading: true });
    let obj = {
      orgId: orgId,
      serverName: serverType ? serverType : "",
      pageNumber: pageNumber || 1,
      recordsPerPage: RECORD_10_PER_PAGE,
    };
    return async("server/findByOrgId", "GET", obj).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: FETCH_ALL_SERVER, response, pageNumber });
      }
      dispatch({ type: SERVER_LOADING, isLoading: false });
    });
  };
}

function saveServer(preObj, orgId, uuid) {
  return (async () => {
    preObj.orgId = orgId;
    preObj.uuid = uuid;
    let response = await async("server/save", "POST", preObj);
    response = updateResponse(response);

    if (response.status === "Success") {
      showNotification("Success", response.message);
      return true;
    } else {
      showNotification("error", response.message);
      return false;
    }
  })();
}


export function saveRegisterServer(orgId, serverData = null, currentServerListPage) {
  return async (dispatch, getState) => {
    const stateServerData = getState().ServerReducer.registerServerData;
    let token =
      localStorage.getItem("xpressToken") &&
      localStorage.getItem("xpressToken") !== "undefined" &&
      localStorage.getItem("xpressToken") != null
        ? "Bearer " + localStorage.getItem("xpressToken")
        : "";
    let requestedUser = localStorage.getItem("requestedUser");

    let preObj = serverData
      ? {
          id: serverData.id,
          serverName: serverData.serverName,
          serverType: serverData.serverType?.id,
          localIp: serverData.localIp,
          isActive: serverData.isActive === 1 ? 0 : 1,
          serverIp: serverData.serverIp,
        }
      : stateServerData;

    try {
      if (!serverData) {
        const url = new URL(preObj.serverIp);
        url.pathname = "/server/info";
        const apiUrl = url.toString();

        dispatch({ type: SERVER_LOADING, isLoading: true });

        let response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            requestedUser,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        });
        response = await response.json();
        response = updateResponse(response);

        if (response.status === "Success") {
          const success = await saveServer(preObj, orgId, response.data?.serverId);
          if (success) {
            dispatch(fetchAllServer(orgId, "", currentServerListPage));
            dispatch(changeServerManagerTab(ALL_SERVER));
          }
        } else {
          showNotification("error", response.message);
        }
        dispatch({ type: SERVER_LOADING, isLoading: false });
      } else {
        const success = await saveServer(preObj, orgId, serverData?.uuid);
        if (success) {
          dispatch(fetchAllServer(orgId, "", currentServerListPage));
          dispatch(changeServerManagerTab(ALL_SERVER));
        }
        dispatch({ type: SERVER_LOADING, isLoading: false });
      }
    } catch (error) {
      showNotification("error", "Please start the server for your device first.");
      dispatch({ type: SERVER_LOADING, isLoading: false });
    } finally {
      dispatch(hideRegisterServerModal());
    }
  };
}

export function getServerDataById(id) {
  return async (dispatch, getState) => {
    dispatch({ type: SERVER_LOADING, isLoading: true });
    return async("server/findById", "GET", { id }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({
          type: CHANGE_REGISTER_SERVER_DATA,
          data: new BuildRegisterServerModal(response?.data),
        });
        dispatch({
          type: PREVIOUS_REGISTER_SERVER_DATA,
          data: new BuildRegisterServerModal(response?.data),
        });
      }
      dispatch({ type: SERVER_LOADING, isLoading: false });
    });
  };
}

export function getAllDeviceByServerId(serverId, serverIp) {
  return async (dispatch, getState) => {
    let response = await sync(
      "device/getConnectedDevices",
      "GET",
      { serverId },
      serverIp
    );
    response = updateResponse(response);
    response.serverId = serverId;
    if (response.status === SUCCESS_STATUS) {
      dispatch({ type: FETCH_DEVICE_DETAILS, response });
    }
    dispatch({ type: SERVER_DEVICE_LOADING, isLoading: false });
  };
}

export function fetchProjectList(orgId) {
  return async (dispatch, getState) => {
    return async("projectUserAssoc/getByUserIdAndOrgId", "GET", { orgId }).then(
      (response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          dispatch({ type: FETCH_ALL_PROJECTS, response });
        }
      }
    );
  };
}

export function saveProjects(
  orgId,
  serverId,
  serverIp,
  deviceData,
  deAuthorization,
  selectedProject,
  iosTeamId
) {
  return async (dispatch, getState) => {
    let preObj = deviceData;
    preObj.serverId = serverId;
    preObj.orgId = parseInt(orgId);
    if (deAuthorization) {
      preObj.isActive = 0;
      preObj.prevListOfprojects = deviceData?.listOfProjectIds || [];
      preObj.listOfProjectIds = [];
    } else {
      preObj.isActive = 1;
      preObj.prevListOfprojects = deviceData?.listOfProjectIds || [];
      preObj.listOfProjectIds = selectedProject || [];
      preObj.iosDevelopmentTeamId = iosTeamId;
    }

    return async("device/save", "POST", preObj).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch(getAllDeviceByServerId(serverId, serverIp));
        showNotification(SUCCESS_TYPE, response?.message);
      } else {
        showNotification(ERROR_TYPE, response?.message);
      }
    });
  };
}

export function startVirtualDevice(data, serverId, serverIp) {
  return async (dispatch, getState) => {
    dispatch({
      type: START_STOP_VIRTUAL_DEVICE_LOADER,
      visible: true,
      serverId,
    });
    let requestData = {
      name: data.name,
      targetUniqueId: data.targetUniqueId,
      platformType: data?.platform,
      wipePreviousData: "false",
    };
    return async(
      "virtualDevice/startVirtualDevice",
      "POST",
      requestData,
      serverIp
    ).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        new Promise((resolve) =>
          resolve(dispatch(getAllDeviceByServerId(serverId, serverIp)))
        ).then((resp) => {
          dispatch({
            type: START_STOP_VIRTUAL_DEVICE_LOADER,
            visible: false,
            serverId,
          });
        });
      }
    });
  };
}

export function stopVirtualDevice(data, serverId, serverIp) {
  return async (dispatch, getState) => {
    dispatch({
      type: START_STOP_VIRTUAL_DEVICE_LOADER,
      visible: true,
      serverId,
    });
    let requestData = {
      name: data.name,
      targetUniqueId: data.targetUniqueId,
      platformType: data?.platform,
      wipePreviousData: "false",
    };
    return async(
      "virtualDevice/stopVirtualDevice",
      "POST",
      requestData,
      serverIp
    ).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        new Promise((resolve) =>
          resolve(dispatch(getAllDeviceByServerId(serverId, serverIp)))
        ).then((resp) => {
          dispatch({
            type: START_STOP_VIRTUAL_DEVICE_LOADER,
            visible: false,
            serverId,
          });
        });
      }
    });
  };
}
export function openAddEmulatorModalVisible(serverId, serverIp, serverArmType) {
  return async (dispatch, getState) => {
    dispatch({
      type: OPEN_ADD_EMULATOR_MODAL_VISIBLE,
      serverId,
      serverIp,
      serverArmType,
    });
  };
}
export function closeAddEmulatorModalVisible() {
  return async (dispatch, getState) => {
    dispatch({ type: CLOSE_ADD_EMULATOR_MODAL_VISIBLE });
  };
}
export function createVirtualDevice(orgId) {
  return async (dispatch, getState) => {
    const {
      addEmulatorServerId,
      addEmulatorServerIp,
      addEmulatorServerArmType,
    } = getState().ServerReducer;
    let { addEmulatorObj}= getState().ServerReducer;

    const deviceSaveObj = {
      name: addEmulatorObj.deviceName,
      osVersion: addEmulatorObj.osVersion,
      platform: addEmulatorObj.platformType,
      udid: null,
      serverId: addEmulatorServerId,
      isActive: 1,
      listOfProjectIds: [],
      prevListOfprojects: [],
      isVirtualDevice: 1,
      orgId: parseInt(orgId),
      brand:"google",
      model:addEmulatorObj?.androidAvdDeviceName
    };
    return async("device/save", "POST", deviceSaveObj).then((resp) => {
      resp = updateResponse(resp);
      if (resp.status === SUCCESS_STATUS) {
        addEmulatorObj=resp?.data?.name?{...addEmulatorObj,deviceName:resp?.data?.name}:{...addEmulatorObj};
        console.log(addEmulatorObj)
        return async(
          "virtualDevice/createVirtualDevice",
          "POST",
          { ...addEmulatorObj, androidArmDevice: addEmulatorServerArmType },
          addEmulatorServerIp
        ).then((response) => {
          response = updateResponse(response);
          if (response.status === SUCCESS_STATUS) {
            dispatch(
              getAllDeviceByServerId(addEmulatorServerId, addEmulatorServerIp)
            );
          }
        });
      }
    });
  };
}
export function getSupportedDeviceOSVersion(platform) {
  return async (dispatch, getState) => {
    const { addEmulatorServerId, addEmulatorServerIp } =
      getState().ServerReducer;
    dispatch({ type: CREATE_VIRTUAL_DEVICE_LOADER, visible: true });
    return async(
      "virtualDevice/getAvailableOSVersion",
      "GET",
      { platform },
      addEmulatorServerIp
    ).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: EMULATOR_SUPPORTED_OS_LIST, data: response.data });
      }
    });
  };
}
export function getSupportedVirtualDevice(platform) {
  return async (dispatch, getState) => {
    const { addEmulatorServerId, addEmulatorServerIp } =
      getState().ServerReducer;
    return async(
      "virtualDevice/getAvailableDevicesForVirtual",
      "GET",
      { platform },
      addEmulatorServerIp
    ).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: EMULATOR_SUPPORTED_DEVICE_LIST, data: response.data });
      }
    });
  };
}
export function updateAddEmulator(data) {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_ADD_EMULATOR, data });
  };
}

export const visibleRegisterServerModal=()=>{
  return async(dispatch)=>{
    dispatch({ type: VISIBLE_REGISTER_SERVER_MODAL  });

  }
}

export const hideRegisterServerModal=()=>{
  return async(dispatch)=>{
    dispatch({ type: HIDE_REGISTER_SERVER_MODAL  });

  }
}

export const fetchOrgList=(targetUniqueId) =>{
  return async (dispatch, getState) => {
    return async("admin/getAllOrgList", "POST", { targetUniqueId }).then(
      (response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          dispatch({ type: FETCH_ALL_ORG_LIST, response });
        }
      }
    );
  };
}

export const assignDeviceToOrg=(targetUniqueId,orgIds,serverId,serverIp)=>{
  return async (dispatch, getState) => {
    return async("admin/assignDeviceToOrg", "POST", { targetUniqueId, orgIds }).then(
      (response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          dispatch(getAllDeviceByServerId(serverId, serverIp));
          showNotification(SUCCESS_TYPE, response?.message,true);
        } else {
          showNotification(ERROR_TYPE, response?.message);
        }
      }
    );
  };
}

//local device server(Electron app)
export const selectedOrg = (value) => {
  return async (dispatch) => {
    dispatch({ type: SELECTED_ORG, value });
  };
};

export function cancelLogin() {
  return async(dispatch,getState) => {
    const orgId = getState().TeamsReducer?.orgList[0]?.organization?.id;
    const loginSearch = window.location;
    const loginToken = loginSearch.pathname.split("/").pop();
    return async("localDeviceServer/cancelLogin", "POST", {
      token: loginToken.toString(),
    }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch(goToProjects(orgId));
        dispatch(getOrgList());
        showNotification(SUCCESS_TYPE, response?.message);
      } else {
        showNotification(ERROR_TYPE, response?.message);
      }
    });
    
  };
}

export const loginLocalDeviceServer = () => {
  return async (dispatch, getState) => {
    const { selectedOrgId } = getState().ServerReducer;
    const loginSearch = window.location;
    const loginToken = loginSearch.pathname.split("/").pop();

    const payload = {
      token: loginToken.toString(),
      orgId: selectedOrgId,
    };
    const response = await async("localDeviceServer/login", "POST", payload);
    const updatedResponse = updateResponse(response);

    if (updatedResponse.status === SUCCESS_STATUS) {
      showNotification(SUCCESS_TYPE, updatedResponse?.message);
      dispatch(loginStatusLocalDeviceServer(loginToken));
    } else {
      // Show error notification
      showNotification(ERROR_TYPE, updatedResponse?.message);
    }
  };
};

export const loginStatusLocalDeviceServer = (loginToken) => {
  return (dispatch, getState) => {
    const { selectedOrgId } = getState().ServerReducer;
    const payload = {
      token: loginToken.toString(),
      orgId: selectedOrgId,
    };
    // Set up polling to check the login status
    dispatch({ type: LOGIN_STATUS_LOADING, isLoading: true });
    const intervalId = setInterval(async () => {
      const response = await async("localDeviceServer/loginStatus", "GET", payload);
      const updatedResponse = updateResponse(response);
      if (updatedResponse.data === "DONE" || updatedResponse.data === "CANCELLED") {
        // Clear the interval once the status is successful
        clearInterval(intervalId);
        showNotification(SUCCESS_TYPE, updatedResponse?.message);
        dispatch({ type: LOGIN_STATUS_LOADING, isLoading: false });
        dispatch({ type: SUCCESS_LOCAL_DEVICE_SERVER_LOGIN , successFullLogin:true });
      } else if (updatedResponse.data === 'PENDING') {
        console.log("Status is still pending...");
      } else {
        // Clear interval on any other status and show error notification
        clearInterval(intervalId);
        showNotification(ERROR_TYPE, updatedResponse?.message);
        dispatch({ type: LOGIN_STATUS_LOADING, isLoading: false });
      }
    }, 5000);
  };
};


//delete device server
export const deleteDeviceServer = (serverId,orgId,searchText) => {
  return async (dispatch, getState) => {
    const {currentServerListPage} = getState().ServerReducer;
    const response = await async("server/delete", "POST", {id:serverId});
    const updatedResponse = updateResponse(response);
    if (updatedResponse.status === SUCCESS_STATUS) {
      dispatch(
        fetchAllServer(orgId, searchText, currentServerListPage)
      )
    } else {
      // Show error notification
      showNotification(ERROR_TYPE, updatedResponse?.message);
    }
  };
};
