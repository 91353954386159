import { default as React, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  performRecTestStepAction,
  resetRecTestStepAction,
  saveRecTestStepActionWithCurrentDeviceImage,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";

import {
  ACTIONS,
  ELEMENT_ACTION,
  LAUNCH_APP,
  STEP,
  SWIPE_OVER_ELEMENT,
  VERIFICATIONS,
} from "../../../../Constants";
import { getRecElement } from "../../../../Util";
import { findRecTestStepByTag } from "../../../../reducer/RecTestStepReducer";
import {
  disableStepPerformSaveButton,
  disableStepResetButton,
  disableStepSaveButton,
} from "../../../RecTestStep/ValidateStepMandatoryProperty";
import TextButtonV2 from "../../../CommonComponents/TextButtonV2/TextButtonV2";
import styles from "./PerformRecTestStepActionV2.module.scss";

const PerformRecTestStepActionV2 = (props) => {
  const {
    action,
    recTestStepId,
    recTestStepTag,
    data,
    resetImage,
    saveImage,
    SaveAndPerformImage,
    saveIcon,
    performSaveIcon,
    resetIcon,
  } = props;
  const dispatch = useDispatch();

  const { recTestStepList, selectedDeviceByUdid, testBlockStep } = useSelector(
    (state) => state.RecTestStepReducer
  );
  const { unSavedRecStepTag, unSavedRecElementGuid } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );

  const unSavedRecTestStep = findRecTestStepByTag(
    recTestStepList,
    unSavedRecStepTag,
    testBlockStep
  );
  const unSavedRecElement = getRecElement(
    unSavedRecTestStep?.recTestStepProperty?.recElements,
    unSavedRecElementGuid
  );

  //Destructure of data object
  const { tag: tagOfData, id: idOfData } = data ? data : {};

  const { stepName } =
    data && data?.recTestStepProperty ? data?.recTestStepProperty : {};

  const { tag: tagOfUnsavedStep } = unSavedRecTestStep
    ? unSavedRecTestStep
    : {};

  const isSameStepForEdit = useMemo(
    () => tagOfUnsavedStep === tagOfData,
    [tagOfUnsavedStep, tagOfData]
  );
  const resetButtonDisable = useMemo(() => {
    return (
      idOfData === undefined ||
      !isSameStepForEdit ||
      disableStepResetButton(unSavedRecTestStep, unSavedRecElement)
    );
  }, [isSameStepForEdit, unSavedRecTestStep, unSavedRecElement, idOfData]);

  const saveButtonDisableMemoized = useCallback(() => {
    return (
      !isSameStepForEdit ||
      disableStepSaveButton(unSavedRecTestStep, unSavedRecElement) ||
      stepName >= 0
    );
  }, [isSameStepForEdit, unSavedRecTestStep, unSavedRecElement, stepName]);

  const performAndSaveButtonDisableMemoized = useCallback(() => {
    return (
      !isSameStepForEdit ||
      disableStepPerformSaveButton(
        unSavedRecTestStep,
        unSavedRecElement,
        selectedDeviceByUdid
      )
    );
  }, [
    isSameStepForEdit,
    unSavedRecTestStep,
    unSavedRecElement,
    selectedDeviceByUdid,
  ]);

  const performSaveButtonDisable = performAndSaveButtonDisableMemoized();
  const saveButtonDisable = saveButtonDisableMemoized();

  return (
    <div className={styles["perform-Actions-Button"]}>
      {!resetButtonDisable && (
        <TextButtonV2
          title="Reset"
          disabled={resetButtonDisable}
          image={resetImage}
          icon={resetIcon}
          onClick={() =>
            dispatch(resetRecTestStepAction(recTestStepTag, recTestStepList))
          }
        />
      )}
      <TextButtonV2
        title="Save"
        disabled={saveButtonDisable}
        image={saveImage}
        icon={saveIcon}
        onClick={() =>
          dispatch(
            saveRecTestStepActionWithCurrentDeviceImage(
              recTestStepTag,
              undefined
            )
          )
        }
      />
      {!performSaveButtonDisable && (
        <TextButtonV2
          title={getPerformActionText(action)}
          disabled={performSaveButtonDisable}
          image={SaveAndPerformImage}
          icon={performSaveIcon}
          onClick={() =>
            dispatch(performRecTestStepAction(recTestStepId, recTestStepTag))
          }
        />
      )}
    </div>
  );
};

export default PerformRecTestStepActionV2;

function getPerformActionText(action) {
  switch (action) {
    case ACTIONS:
    case ELEMENT_ACTION.TAP:
    case ELEMENT_ACTION.LONG_PRESS:
    case ELEMENT_ACTION.TAP_BY_INDEX:
    case ELEMENT_ACTION.SEND_KEYS:
    case ELEMENT_ACTION.CLEAR_TEXT:
    case ELEMENT_ACTION.SWIPE:
    case SWIPE_OVER_ELEMENT:
      return "Perform & Save";
    case VERIFICATIONS:
    case STEP:
      return "Verify & Save";
    case LAUNCH_APP:
      return "Launch App & Save";
    default:
      break;
  }
}
