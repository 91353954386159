import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Divider, Drawer, Input } from "antd";
import {
  ACTIVE_SWIPE_DOWN_ICON,
  ACTIVE_SWIPE_LEFT_ICON,
  ACTIVE_SWIPE_RIGHT_ICON,
  ACTIVE_SWIPE_UP_ICON,
  SWIPE_DOWN_ICON,
  SWIPE_LEFT_ICON,
  SWIPE_RIGHT_ICON,
  SWIPE_UP_ICON,
} from "../../../../CdnImagePath";
import { CONFIRM_MODAL_CONTENT, SWIPE_DIRECTION, SWIPE_DIRECTION_TEXT } from "../../../../Constants";
import {
  checkActionHaveRelativePointOption,
  disableStepForGeneralSettingsDrawer,
  getRecElement,
  hasNotAllowedNegativeValue,
  isActionNeedToSelectElement,
  isNotNullAndNotEmptyAndNotUndefined,
  isStepHaveLoopStep,
  isStepHavePrecondition,
} from "../../../../Util";
import {
  changeRetryCount,
  changeReverseSwipeElementNotFound,
  changeSwipeCountToFindElement,
  changeSwipeDirectionToFindElement,
  changeSwipeToFindElement,
  resetRecTestStepAction,
  saveRecTestStepActionWithCurrentDeviceImage,
  visibleGeneralSettingsDrawer,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { findRecTestStepByTag } from "../../../../reducer/RecTestStepReducer";
import { ButtonContainer } from "../../SingleColumnView/ImportRecTestStepActionModal/ImportRecTestStepActionModal";
import ActionOnElementRelativePoint from "../ActionOnElementRelativePoint/ActionOnElementRelativePoint";
import styles from "./RecStepGeneralSetting.module.scss";
import { disableStepSaveButton } from "../../ValidateStepMandatoryProperty";
import CommonConfirmationModalV2 from "../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";

const RecStepGeneralSetting = (props) => {
  const dispatch = useDispatch();
  const { data, isPreviewRunning, isSkipStep, recElement, disabled } = props;
  const { unSavedRecStepTag, visibleGeneralSettingDrawer, actionForGeneralActionDrawer, tagForOpenDrawer, unSavedRecElementGuid } =
    useSelector((state) => state.RecTestStepCommonViewReducer);
  const { recTestStepList, testBlockStep } = useSelector((state) => state.RecTestStepReducer);

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const action = actionForGeneralActionDrawer ? actionForGeneralActionDrawer : data?.recTestStepProperty?.action;
  const unSavedRecTestStep = findRecTestStepByTag(recTestStepList, unSavedRecStepTag, testBlockStep);
  const buttonDisable = disableStepForGeneralSettingsDrawer(unSavedRecTestStep);
  const isSameStepForEdit = unSavedRecStepTag === data?.tag;
  const unSavedRecElement = getRecElement(unSavedRecTestStep?.recTestStepProperty?.recElements, unSavedRecElementGuid);
  const saveButtonDisable = useMemo(() => {
    return !isSameStepForEdit || disableStepSaveButton(unSavedRecTestStep, unSavedRecElement);
  }, [isSameStepForEdit, unSavedRecTestStep, unSavedRecElement]);
  const updateSwipeDirectionOrTime = disabled || data?.recTestStepProperty?.swipeToFindElement === 0;

  const handleClose = () => {
    if (!saveButtonDisable) {
      setOpenConfirmationModal(true);
    } else {
      dispatch(visibleGeneralSettingsDrawer(false))
    }
  }

  return (
    <Drawer
      className={`settingsDrawer ${tagForOpenDrawer !== data?.tag && styles.hideDrawer} `}
      visible={visibleGeneralSettingDrawer}
      title="General Settings"
      onClose={() => handleClose()}
      maskClosable={false}
      extra={
        <div className={styles.footer}>
          <>
            {![undefined, 0].includes(data?.id) && (
              <Button
                type="text"
                className="resetButton"
                disabled={buttonDisable}
                onClick={() => {
                  !buttonDisable && dispatch(resetRecTestStepAction(data?.tag));
                }}
              >
                Reset
              </Button>
            )}
            <Button
              type="primary"
              className={styles.saveButton}
              disabled={saveButtonDisable}
              onClick={() => {
                !saveButtonDisable && dispatch(saveRecTestStepActionWithCurrentDeviceImage(data?.tag, undefined));
                dispatch(visibleGeneralSettingsDrawer(false));
              }}
            >
              Save
            </Button>
          </>
        </div>
      }
      width={"600"}
    >
      <div>
        <div className={styles.inputOrText}>
          <div className={styles.elementText}>If unable to identify Element, Retry for</div>
          <div className={styles.retryCountOrText}>
            <Input
              disabled={
                disabled ||
                isSkipStep ||
                isPreviewRunning ||
                (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data.tag)
              }
              value={data.recTestStepProperty?.retryCount}
              type="number"
              onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
              min={0}
              onChange={(e) => dispatch(changeRetryCount(e.target.value, data.tag, recElement?.guid))}
              placeholder="Retry Count"
              className={styles.inputRetryCount}
            />
            <span className={styles.time}>{"times"}</span>
          </div>
          {data.recTestStepProperty?.retryCount !== 2 && (
            <ButtonContainer>
              <Button
                type="primary"
                className={styles.setDefault}
                disabled={disabled}
                onClick={(e) => dispatch(changeRetryCount(2, data.tag, recElement?.guid))}
              >
                Set Default
              </Button>
            </ButtonContainer>
          )}
        </div>
      </div>
      {(isActionNeedToSelectElement(action) || isStepHavePrecondition(data) || isStepHaveLoopStep(data)) && (
        <>
          <div className={styles.scroll}>
            <div className={styles.scrollToLocate}>
              <Divider orientation="left" className={styles.settingsModalDivider}>
                <div>
                  <Checkbox
                    disabled={
                      isPreviewRunning ||
                      disabled ||
                      isSkipStep ||
                      isPreviewRunning ||
                      (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data.tag)
                    }
                    checked={data?.recTestStepProperty?.swipeToFindElement === 1 ? true : false}
                    onChange={(e) => dispatch(changeSwipeToFindElement(e.target.checked, data.tag, recElement?.guid))}
                  />
                  <span className={styles.checkbox_Text}>Scroll to locate Element</span>
                </div>
              </Divider>
            </div>
            <div className={styles.swipeDirectionOrCount}>
              <div className={styles.swipeDirection}>
                <div className={styles.styledSwipeLabel}>Swipe Direction</div>
                <div className={updateSwipeDirectionOrTime ? "cursor__not_allowed" : "cursor__pointer"}>
                  <div
                    className={styles.swipeUp}
                    onClick={() =>
                      !updateSwipeDirectionOrTime
                        ? dispatch(changeSwipeDirectionToFindElement(SWIPE_DIRECTION.UP, data.tag, recElement?.guid))
                        : undefined
                    }
                  >
                    {data?.recTestStepProperty?.swipeDirection === "UP" ? (
                      <img src={ACTIVE_SWIPE_UP_ICON} alt="arrow up" />
                    ) : (
                      <img src={SWIPE_UP_ICON} alt="arrow up" />
                    )}
                  </div>
                  <div className="display__flex">
                    <div
                      onClick={() =>
                        !updateSwipeDirectionOrTime
                          ? dispatch(
                            changeSwipeDirectionToFindElement(SWIPE_DIRECTION.LEFT, data.tag, recElement?.guid)
                          )
                          : undefined
                      }
                    >
                      {data?.recTestStepProperty?.swipeDirection === "LEFT" ? (
                        <img src={ACTIVE_SWIPE_LEFT_ICON} alt="arrow left" />
                      ) : (
                        <img src={SWIPE_LEFT_ICON} alt="arrow left" />
                      )}
                    </div>
                    <div
                      className={styles.swipeRight}
                      onClick={() =>
                        !updateSwipeDirectionOrTime
                          ? dispatch(
                            changeSwipeDirectionToFindElement(SWIPE_DIRECTION.RIGHT, data.tag, recElement?.guid)
                          )
                          : undefined
                      }
                    >
                      {data?.recTestStepProperty?.swipeDirection === "RIGHT" ? (
                        <img src={ACTIVE_SWIPE_RIGHT_ICON} alt="arrow right" />
                      ) : (
                        <img src={SWIPE_RIGHT_ICON} alt="arrow right" />
                      )}
                    </div>
                  </div>
                  <div
                    className={styles.swipeDown}
                    onClick={() =>
                      !updateSwipeDirectionOrTime
                        ? dispatch(changeSwipeDirectionToFindElement(SWIPE_DIRECTION.DOWN, data.tag, recElement?.guid))
                        : undefined
                    }
                  >
                    {data?.recTestStepProperty?.swipeDirection === "DOWN" ? (
                      <img src={ACTIVE_SWIPE_DOWN_ICON} alt="arrow down" />
                    ) : (
                      <img src={SWIPE_DOWN_ICON} alt="arrow down" />
                    )}
                  </div>
                </div>
                <span>
                  {SWIPE_DIRECTION_TEXT[data?.recTestStepProperty ? data?.recTestStepProperty?.swipeDirection : ""]}
                </span>
              </div>
              <div className={styles.swipeCountOrInput}>
                <div className={styles.styledInputOuter}>Swipe for</div>
                <div className={styles.swipeCountInputOrText}>
                  <Input
                    className={styles.percentageInput}
                    value={data.recTestStepProperty?.swipeCountToFindElement}
                    type="number"
                    min={0}
                    disabled={updateSwipeDirectionOrTime}
                    onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
                    onChange={(e) =>
                      dispatch(changeSwipeCountToFindElement(e.target.value, data.tag, recElement?.guid))
                    }
                    placeholder="Swipe Count"
                  />
                  <span className={styles.time}>{"times"}</span>
                </div>
              </div>
            </div>
            <div className={styles.reverseScroll}>
              <Checkbox
                disabled={disabled}
                checked={data?.recTestStepProperty?.reverseSwipeElementNotFound === 1 ? true : false}
                onChange={(e) =>
                  dispatch(changeReverseSwipeElementNotFound(e.target.checked, data.tag, recElement?.guid))
                }
              >
                {"Reverse Scroll if element not found"}
              </Checkbox>
            </div>
          </div>
        </>
      )}
      {checkActionHaveRelativePointOption(action) ? <ActionOnElementRelativePoint {...props} /> : undefined}
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(resetRecTestStepAction(data?.tag));
          dispatch(visibleGeneralSettingsDrawer(false));
          setOpenConfirmationModal(false);
        }}
        handleCancel={() => setOpenConfirmationModal(false)}
        modalOpen={!!openConfirmationModal}
        modalContent={CONFIRM_MODAL_CONTENT}
        modalTitle="Discard Changes"
        okText="Confirm"
        isDeleteModal
      />
    </Drawer>
  );
};

export default RecStepGeneralSetting;
