import { async, prepareEventSource } from "../../api/automation";
import AppendRandomText from "../../Components/RecTestStep/StepInnerComponents/Actions/EnterText/AppendRandomText";
import {
  ADD_REC_TEST_STEP,
  ADD_REC_TEST_STEP_ACTION_SINGLE_COLUMN_VIEW,
  AI_SCENARIO_GENERATE_STEP_TYPE,
  CHANGE_DEVICE_INTERACTION_MODE,
  CHANGE_DISABLE_REC_STEP,
  CHANGE_DISABLE_STEP,
  CHANGE_PARENT_REC_TEST_STEP_FOR_AI_GENERATED_STEP,
  CLEAR_PREVIEW_IN_SINGLE_COLUMN_VIEW,
  DELETE_REC_TEST_STEP,
  DEVICE_INTERACTION_MODE,
  DEVICE_STATUS,
  ELEMENT_ACTION,
  ELEMENT_DEFAULT_LOAD_TIME,
  ERROR_STATUS,
  FETCHED_TEST_STEP_LIST,
  FETCHING_TEST_STEP_LIST,
  HIDE_IMPORT_REC_TEST_STEP_MODAL,
  MAKE_SUB_REC_TEST_STEP,
  PREVIEW_COMPLETED,
  REC_TEST_STEP_LIST,
  REMOVE_SELECTED_REC_TEST_STEP_OR_ACTION,
  REQUEST_DELETE_REC_TEST_STEP,
  REQUEST_DRAG_AND_DROP,
  REQUEST_IMPORT_REC_TEST_STEP_ACTION,
  REQUEST_PERFORM_REC_TEST_STEP_ACTION,
  REQUESTED_DELETE_REC_TEST_STEP,
  REQUESTED_DRAG_AND_DROP,
  REQUESTED_IMPORT_REC_TEST_STEP_ACTION,
  REQUESTED_PERFORM_REC_TEST_STEP_ACTION,
  REQUESTED_UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE,
  RESET_EDIT_STEP,
  RunKilled,
  SAVE_REC_TEST_STEP_ACTION,
  STEP,
  STEP_ITEMS,
  SUCCESS_STATUS,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
  UPDATE_DEVICE_STATE,
  UPDATE_PREVIEW_STATUS,
  UPDATE_PREVIEW_STATUS_WITH_REC_TEST_STEP,
  UPDATE_REC_ELEMENT_DETAIL_IN_REC_TEST_STEP_SINGLE_COLUMN_VIEW,
  UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE,
  UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW,
} from "../../Constants";
import {
  findParentRecTestStep,
  findRecTestStepById,
  findRecTestStepByRecTestScenarioRecTestStepAssocId,
} from "../../reducer/RecTestStepReducer";
import {
  getRecElement,
  getUnSavedRecElement,
  handleShowDragError,
  isNotNullAndNotEmptyAndNotUndefined,
  removeSpaceFromVerifications,
  showNotification,
  updateRecElement,
  updateResponse,
  updateUnSavedRecElement,
} from "../../Util";
import { performDeviceInteraction, recordingStart, recordingStop, resetElement } from "../Recording";
import { resetAllRecScenarioStepIds } from "../RecTestScenarioAction";
import {
  getRecTestStepListByRecTestScenarioId,
  prepareObjectInScenarioScreen,
  resetRecTestStepActionElement,
  selectRecTestSteps,
  showDragError,
  stopPreview,
  validateDragInDesignerScreen,
} from "../RecTestStepAction";
import { fetchRecTestStepDetail } from "../RecTestStepMultiColumnViewAction";
import { fetchTestData } from "../TestDataAction";
import * as RecTestStepCommonAction from "./RecTestStepCommonAction";
import {
  clearPreview,
  hideDeleteRecElementConfirmationModal,
  hideDeleteRecTestStepConfirmationModal,
  hideGenerateStepsFromAIModal,
} from "./RecTestStepRedirectAction";
import _ from "lodash";
let previewResultEventSource = "";
export function addRecTestStep(recParentStep, stepItemType) {
  return (dispatch, getState) => {
    const { recTestStepList, selectedRecTestScenarioId } = getState().RecTestStepReducer;
    const { stepType } = getState().RecTestStepCommonViewReducer;
    const projectId = getState().ProjectsReducer.projectId;
    let recTestStep = getRecTestStepObjForSingleColumn(recTestStepList, recParentStep, projectId, stepItemType);
    recTestStep = {
      ...recTestStep,
      recTestScenario: {
        id: selectedRecTestScenarioId,
      },
    };
    dispatch({ type: REQUEST_PERFORM_REC_TEST_STEP_ACTION });
    RecTestStepCommonAction.callAddRecTestStep(recTestStep).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        response.data = RecTestStepCommonAction.addUnsavedValueAfterRecTestStepSave(response.data, stepItemType);
        dispatch(clearPreview());
        if (
          [STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_ELEMENT, STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_TEST_DATA].includes(
            stepItemType
          )
        ) {
          dispatch(RecTestStepCommonAction.editRecTestStep(response.data.tag));
        }

        dispatch({
          type: ADD_REC_TEST_STEP,
          response,
          stepItemType,
          stepType,
        });
        if (stepItemType === STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_ELEMENT) {
          dispatch(recordingStart());
        }
        if (recParentStep) {
          dispatch(
            RecTestStepCommonAction._captureExpandedSteps(
              recParentStep.tag,
              recParentStep.recTestScenarioRecTestStepAssocId,
              true
            )
          );
        }
        dispatch(
          RecTestStepCommonAction._captureExpandedSteps(
            response.data.tag,
            response.data.recTestScenarioRecTestStepAssocId,
            true
          )
        );
      }
      dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
    });
  };
}

export function deleteRecTestStep(selectedRectTestScenarioStepId) {
  return (dispatch, getState) => {
    dispatch({ type: REQUEST_DELETE_REC_TEST_STEP });
    let recTestScenarioRecTestStepsAssoc = null;
    const { selectedRectTestScenarioStepId, selectedRecTestScenarioId } = getState().RecTestStepReducer;
    const { selectedRecTestStepIdForDelete, selectedRecTestStepTagForDelete, selectedRecTestStepAssocForDelete } =
      getState().RecTestStepCommonViewReducer;

    if (selectedRecTestStepIdForDelete === undefined && selectedRecTestStepTagForDelete !== undefined) {
      dispatch({
        type: DELETE_REC_TEST_STEP,
        deleteFromUnSavedStep: true,
        selectedRecTestStepIdForDelete,
        selectedRecTestStepTagForDelete,
        selectedRecTestStepAssocForDelete,
        selectedRectTestScenarioStepId,
        stepType: getState().RecTestStepCommonViewReducer.stepType,
      });
      dispatch(hideDeleteRecTestStepConfirmationModal());
    } else if (
      (selectedRecTestStepIdForDelete !== undefined && selectedRecTestStepTagForDelete !== undefined) ||
      selectedRecTestStepAssocForDelete ||
      selectedRecTestStepAssocForDelete !== undefined ||
      selectedRectTestScenarioStepId !== undefined
    ) {
      recTestScenarioRecTestStepsAssoc = RecTestStepCommonAction.commonDeleteRecTestStep(getState);
      recTestScenarioRecTestStepsAssoc = {
        ...recTestScenarioRecTestStepsAssoc,
        recTestScenarioRecTestStepsAssocs: selectedRecTestStepAssocForDelete
          ? [{ id: selectedRecTestStepAssocForDelete }]
          : selectedRectTestScenarioStepId && selectedRectTestScenarioStepId?.map((i) => ({ id: i })),
        recTestScenarioId: selectedRecTestScenarioId,
      };
      RecTestStepCommonAction.callDeleteRecTestStep(recTestScenarioRecTestStepsAssoc).then((response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          dispatch({
            type: DELETE_REC_TEST_STEP,
            deleteFromUnSavedStep: false,
            selectedRecTestStepIdForDelete,
            selectedRecTestStepTagForDelete,
            response,
            selectedRectTestScenarioStepId,
            stepType: getState().RecTestStepCommonViewReducer.stepType,
          });

          showNotification("success", "Successfully Deleted Step.", "");
        } else {
          showNotification("error", response.message, "");
        }
        dispatch({ type: REQUESTED_DELETE_REC_TEST_STEP });
        dispatch(hideDeleteRecTestStepConfirmationModal());
        dispatch(hideDeleteRecElementConfirmationModal());
        dispatch(resetAllRecScenarioStepIds());
      });
    } else {
      dispatch({ type: REQUESTED_DELETE_REC_TEST_STEP });
      showNotification("error", "Error In Deleting The Step.", "");
    }
    dispatch(RecTestStepCommonAction.resetEditRecTestStep());
    dispatch(resetElement());
    dispatch(recordingStop());
  };
}

export function duplicateRecTestStep(duplicateRecTestStep) {
  return (dispatch, getState) => {
    const { recTestStepList, selectedRecTestScenarioId, selectedRecTestScenarioName } = getState().RecTestStepReducer;

    let recTestStep = RecTestStepCommonAction.getDuplicateRecTestStepObj(recTestStepList, duplicateRecTestStep);
    recTestStep = {
      ...recTestStep,
      recTestScenarioRecTestStepsAssoc: { id: recTestStep.recTestScenarioRecTestStepAssocId },
      parentRecTestScenarioRecTestStepsAssoc: {
        id: duplicateRecTestStep?.parentRecTestStep?.recTestScenarioRecTestStepAssocId,
      },
      recTestScenario: { id: selectedRecTestScenarioId },
    };

    dispatch({ type: FETCHING_TEST_STEP_LIST });
    RecTestStepCommonAction.callDuplicateRecTestStep(recTestStep).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch(RecTestStepCommonAction.resetEditRecTestStep());
        dispatch({
          type: REC_TEST_STEP_LIST,
          response: { data: response.data, status: response.status },
          recTestScenarioId: selectedRecTestScenarioId,
          recTestScenarioName: selectedRecTestScenarioName,
          duplicateRecTestStep,
        });
      }
      dispatch({ type: FETCHED_TEST_STEP_LIST });
    });
  };
}

export function makeSubStep(recTestStep, recTestScenarioRecTestStepAssocId, parentRecTestStep) {
  return (dispatch, getState) => {
    let recTestStepList = getState().RecTestStepReducer.recTestStepList;
    let newRecTestStep = RecTestStepCommonAction.makeSubRecTestStepObj(
      recTestStep,
      recTestScenarioRecTestStepAssocId,
      getState,
      parentRecTestStep?.recTestScenarioRecTestStepAssocId,
      recTestStep?.id,
      recTestStepList
    );

    dispatch({ type: FETCHING_TEST_STEP_LIST });
    RecTestStepCommonAction.callMakeGroupRecTestStep(newRecTestStep).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: MAKE_SUB_REC_TEST_STEP, response, existingRecTestStep: { ...recTestStep } });
        dispatch({ type: FETCHED_TEST_STEP_LIST });
      }
    });
  };
}

export function _importStepFromJson(jsonData){
  return (dispatch,getState) =>{
    const { selectedRecTestScenarioId,selectedDeviceByUdid } = getState().RecTestStepReducer;
    const { selectedParentStepforAIGenerateStep } = getState().RecTestStepCommonViewReducer;
    console.log("Previous",jsonData)
    jsonData = jsonData.map(item => {
      let _aiGeneratedStep = _.cloneDeep(item)
      delete _aiGeneratedStep["screenshot"]
      delete _aiGeneratedStep["pagesource"]
      item = {
        ...item,
        aiGeneratedStep:JSON.stringify(_aiGeneratedStep),
        otherXpathGenerated:RecTestStepCommonAction.generateOtherXpathFromAIStep(item,selectedDeviceByUdid?.platformType?.name)
      }
      return item;
    })
    console.log("After",jsonData)
    let data={
      steps:jsonData,
      recTestScenarioId:selectedRecTestScenarioId,
      recTestScenarioRecTestStepAssocId:selectedParentStepforAIGenerateStep?.recTestScenarioRecTestStepAssocId

    }
    async("recTestScenarioRecTestStepAssoc/importFromJson", "POST", data).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        showNotification("success", response.message, "");
        dispatch(getRecTestStepListByRecTestScenarioId(selectedRecTestScenarioId))
      }else{
        showNotification("error", response.message, "");
      }
      dispatch({ type: HIDE_IMPORT_REC_TEST_STEP_MODAL });
    })
  }
}

export function saveStepAsNLP(jsonData,unSavedRecTestStep){
  return (dispatch,getState) =>{
    const { selectedRecTestScenarioId,recTestStepList} = getState().RecTestStepReducer;
    const { unSavedRecStepTag } = getState().RecTestStepCommonViewReducer;
    let parentRecTestStepDetail = findParentRecTestStep(recTestStepList, undefined ,unSavedRecStepTag);
      let _aiGeneratedStep = _.cloneDeep(jsonData)
      _aiGeneratedStep.steps.map(item =>{
        delete item["screenshot"]
        delete item["pagesource"]
      })
      jsonData = {
        ...jsonData,
        stepTitle:unSavedRecTestStep?.recTestStepProperty?.isStepHasPrecondition==1 || unSavedRecTestStep?.recTestStepProperty?.isLoopStep==1 ? unSavedRecTestStep?.recTestStepProperty?.preConditionTitle:unSavedRecTestStep?.recTestStepProperty?.stepName,
        appendRandomText:unSavedRecTestStep?.recTestStepProperty?.appendRandomText,
        aiGeneratedStep:JSON.stringify(_aiGeneratedStep.steps),
        expectedAction : unSavedRecTestStep?.recTestStepProperty?.action,
        recTestScenarioId:selectedRecTestScenarioId,
        parentRecTestScenarioRecTestStepAssocId:parentRecTestStepDetail?.recTestScenarioRecTestStepAssocId
      }
    console.log("After",jsonData)
    async("recTestScenarioRecTestStepAssoc/saveStepAsNLP", "POST", jsonData).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        showNotification("success", response.message, "");
        dispatch(getRecTestStepListByRecTestScenarioId(selectedRecTestScenarioId))
      }else{
        showNotification("error", response.message, "");
      }
      dispatch({ type: HIDE_IMPORT_REC_TEST_STEP_MODAL });
    })
  }
}

export function importRecTestStepOrAction(isImportOriginal, requestData) {
  return (dispatch, getState) => {
    const { selectedRecTestScenarioId, selectedRecTestScenarioName } = getState().RecTestStepReducer;
    const { disableRecTestStepActionsForImport, selectedRecTestStepDetail } = getState().RecTestStepCommonViewReducer;
    const { recTestStepList } = getState().RecTestStepReducer;
    const { unSavedRecStepTag } = getState().RecTestStepCommonViewReducer;
    let unSavedRecTestStep = _findRecTestStepByTag(recTestStepList, unSavedRecStepTag);
    const { projectId } = getState().ProjectsReducer;
    dispatch({ type: REQUEST_IMPORT_REC_TEST_STEP_ACTION });
    let data = RecTestStepCommonAction.getImportRecTestStepOrActionObj(
      requestData,
      isImportOriginal,
      projectId,
      selectedRecTestScenarioId,
      selectedRecTestStepDetail,
      unSavedRecTestStep
    );
    // data = {
    //   ...data,
    //   parentRecTestStep: { id: selectRecTestStepId },
    // };
    RecTestStepCommonAction.callImportRecTestStep(data).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch(fetchTestData(projectId));
        dispatch({
          type: REC_TEST_STEP_LIST,
          response: { data: response.data.data, status: response.status },
          recTestScenarioId: selectedRecTestScenarioId,
          recTestScenarioName: selectedRecTestScenarioName,
          updateSelectedRecTestStepDetail: !disableRecTestStepActionsForImport,
        });
        dispatch(getRecTestStepListByRecTestScenarioId(selectedRecTestScenarioId))
      } else {
        showNotification("error", response.message, "");
      }
      dispatch({ type: RESET_EDIT_STEP });
      dispatch({ type: REMOVE_SELECTED_REC_TEST_STEP_OR_ACTION });
      dispatch({ type: REQUESTED_IMPORT_REC_TEST_STEP_ACTION });
      dispatch({ type: HIDE_IMPORT_REC_TEST_STEP_MODAL });
    });
  };
}

export function onRecTestStepDrop(event, recTestStep, parentRecTestStep, index, innerRecTestStep) {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_DRAG_AND_DROP });
    let dropBeforeRecTestStep =
      event.clientY < event.target.getBoundingClientRect().top + event.target.getBoundingClientRect().height / 2;
    event.stopPropagation();

    const { recTestStepList, selectedRecTestScenarioId, selectedRecTestScenarioName } = getState().RecTestStepReducer;
    const { stepType } = getState().RecTestStepCommonViewReducer;
    let dragRecTestStep = undefined;
    let dragParentRecTestStep = undefined;
    let droppingRecTestStep = recTestStep;
    let droppingParentRecTestStep = parentRecTestStep;
    if(recTestStep === undefined && parentRecTestStep===undefined && recTestStepList.length>0){
      droppingRecTestStep= recTestStepList[recTestStepList.length-1]
    }
    let data = {};

    dragRecTestStep =
      event.dataTransfer.getData("dragRecTestScenarioRecTestStepAssocId") !== undefined
        ? findRecTestStepByRecTestScenarioRecTestStepAssocId(
            recTestStepList,
            parseInt(event.dataTransfer.getData("dragRecTestScenarioRecTestStepAssocId"))
          )
        : undefined;

    dragParentRecTestStep =
      event.dataTransfer.getData("dragParentRecTestScenarioRecTestStepAssocId") !== undefined
        ? findRecTestStepByRecTestScenarioRecTestStepAssocId(
            recTestStepList,
            parseInt(event.dataTransfer.getData("dragParentRecTestScenarioRecTestStepAssocId"))
          )
        : undefined;
    if (dragRecTestStep === undefined) {
      showDragError("Error in drag element", dispatch);
      dispatch({ type: REQUESTED_DRAG_AND_DROP });
      return;
    }
    data = prepareObjectInScenarioScreen(
      dragRecTestStep,
      dragParentRecTestStep,
      droppingRecTestStep,
      droppingParentRecTestStep,
      dropBeforeRecTestStep,
      index,
      selectedRecTestScenarioId,
      innerRecTestStep,
      stepType
    );

    if (dragRecTestStep?.id === droppingRecTestStep?.id) {
      dispatch({ type: REQUESTED_DRAG_AND_DROP });
    } else if (!validateDragInDesignerScreen(
      dragRecTestStep,
      dragParentRecTestStep,
      droppingRecTestStep,
      droppingParentRecTestStep,
      dropBeforeRecTestStep,
      innerRecTestStep,
      stepType
    )) {
      handleShowDragError(dispatch,dragRecTestStep,droppingRecTestStep,droppingParentRecTestStep,stepType)     
  } else {
      RecTestStepCommonAction.callDragAndDropRecTestStep(
        data,
        "recTestScenarioRecTestStepAssoc/updateRecTestStepOrder"
      ).then((response) => {
        if (response.status === SUCCESS_STATUS) {
          new Promise((resolve) =>
            resolve(
              dispatch({
                type: REC_TEST_STEP_LIST,
                response: { data: response.data, status: response.status },
                recTestScenarioId: selectedRecTestScenarioId,
                recTestScenarioName: selectedRecTestScenarioName,
              })
            )
          ).then(() => {
            dispatch(selectRecTestSteps(dragRecTestStep));
          });
          dispatch({ type: REQUESTED_DRAG_AND_DROP });
        } else {
          showNotification("error", response.message, "");
          dispatch({ type: REQUESTED_DRAG_AND_DROP });
        }
      });
    }
    return;
  };
}

export function _changeDisableStep(checked, recTestStep) {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_PERFORM_REC_TEST_STEP_ACTION });
    const { selectedRecTestStepDetail } = getState().RecTestStepMultiColumnViewReducer;
    const { stepType } = getState().RecTestStepCommonViewReducer;
    const data = {
      id: recTestStep.recTestScenarioRecTestStepAssocId,
      isSkipStep: checked,
    };
    return RecTestStepCommonAction.callDisabledRecTestScenarioRecTestStep(data).then((response) => {
      if (selectedRecTestStepDetail !== undefined) {
        dispatch(fetchRecTestStepDetail(selectedRecTestStepDetail));
      }
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: CHANGE_DISABLE_REC_STEP, checked, recTestStep, stepType });
      } else {
        showNotification("error", response.message, "");
      }
      dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
    });
  };
}

export function getRecTestStepObjForSingleColumn(recTestStepList, recParentStep, projectId, stepItemType) {
  let parentRecTestStep = undefined;
  if (recTestStepList) {
    parentRecTestStep = findRecTestStepById(recTestStepList, recParentStep?.id);
  }

  let orderId = 1;
  if (parentRecTestStep) {
    orderId =
      parentRecTestStep.recTestSteps && parentRecTestStep.recTestSteps.length > 0
        ? parentRecTestStep.recTestSteps.length + 1
        : 1;
  } else {
    orderId = recTestStepList ? recTestStepList.length + 1 : 1;
  }
  let recTestStep = RecTestStepCommonAction.getNewRecTestStepObj(orderId, projectId, stepItemType);

  if (parentRecTestStep) {
    recTestStep = {
      ...recTestStep,
      parentRecTestStep: { id: parentRecTestStep.id },
      parentRecTestScenarioRecTestStepsAssoc: { id: parentRecTestStep.recTestScenarioRecTestStepAssocId },
    };
  }
  return recTestStep;
}

export function performAndSaveRecTestStepAction(stepAction, parentRecTestStep) {
  return (dispatch, getState) => {
    const { selectedDeviceIdForRecording, selectedServerIdForRecording } = getState().RecTestStepReducer;
    const projectId = getState().ProjectsReducer.projectId;
    new Promise((resolve) => resolve(dispatch(_addRecTestStepAction(stepAction, parentRecTestStep)))).then(
      (recTestStep) => {
        dispatch(
          performDeviceInteraction(
            {
              deviceInteractionAction: stepAction,
              serverId: selectedServerIdForRecording,
              targetUniqueId: selectedDeviceIdForRecording,
            },
            recTestStep.tag,
            projectId
          )
        );
      }
    );
  };
}

export function _addRecTestStepAction(stepAction, parentRecTestStep) {
  return (dispatch, getState) => {
    const { selectedRecTestScenarioId, recTestStepList } = getState().RecTestStepReducer;
    const projectId = getState().ProjectsReducer.projectId;

    // Prepare the Rec Test Step Object
    let recTestStep = RecTestStepCommonAction.getNewRecTestStepActionObj(stepAction, parentRecTestStep);
    let unSavedRecTestStep = _findRecTestStepByTag([...recTestStepList], parentRecTestStep.tag);
    let orderId = 1;
    if (unSavedRecTestStep?.recTestSteps) {
      orderId = unSavedRecTestStep.recTestSteps.length + 1;
    }
    recTestStep = {
      ...recTestStep,
      orderId,
      recTestScenario: { id: selectedRecTestScenarioId },
      project: { id: projectId },
    };
    dispatch(clearPreview());
    let unSavedRecElement = getUnSavedRecElement(recTestStep.recTestStepProperty?.recElements);
    dispatch(RecTestStepCommonAction.editRecTestStepElement(recTestStep.tag, unSavedRecElement?.guid));
    dispatch({ type: ADD_REC_TEST_STEP_ACTION_SINGLE_COLUMN_VIEW, stepAction, parentRecTestStep, recTestStep });
    if (parentRecTestStep) {
      dispatch(
        RecTestStepCommonAction._captureExpandedSteps(
          parentRecTestStep.tag,
          parentRecTestStep?.recTestScenarioRecTestStepAssocId,
          true
        )
      );
    }
    dispatch(
      RecTestStepCommonAction._captureExpandedSteps(
        recTestStep.tag,
        recTestStep?.recTestScenarioRecTestStepAssocId,
        true
      )
    );
    if (unSavedRecElement) {
      dispatch(
        RecTestStepCommonAction._captureExpandedElements(
          unSavedRecElement.guid,
          recTestStep.tag,
          recTestStep.recTestScenarioRecTestStepAssocId,
          true
        )
      );
    }
    return recTestStep;
  };
}

export function findRecTestStepByTag(state) {
  const { recTestStepList } = state.RecTestStepReducer;
  const { unSavedRecStepTag } = state.RecTestStepCommonViewReducer;
  return _findRecTestStepByTag(recTestStepList, unSavedRecStepTag);
}

function _findRecTestStepByTag(recTestStepList, tag) {
  let selectedRecTestStep = undefined;
  for (let i = 0; i < recTestStepList.length; i++) {
    if (recTestStepList[i].tag === tag) {
      selectedRecTestStep = recTestStepList[i];
      break;
    } else {
      if (
        recTestStepList[i].recTestStepProperty?.action === STEP &&
        recTestStepList[i].recTestSteps &&
        recTestStepList[i].recTestSteps.length > 0
      ) {
        selectedRecTestStep = _findRecTestStepByTag(recTestStepList[i].recTestSteps, tag);
        if (selectedRecTestStep) {
          break;
        }
      }
    }
  }
  return selectedRecTestStep;
}

export function updateRecElementDetailInRecTestStep(
  elementScreenShot,
  selectedElementAttributes,
  elementDetailsFromServer,
  elementDetailsFromPageSource
) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_REC_ELEMENT_DETAIL_IN_REC_TEST_STEP_SINGLE_COLUMN_VIEW,
      elementScreenShot,
      selectedElementAttributes,
      elementDetailsFromServer,
      elementDetailsFromPageSource,
      unSavedRecStepTag: getState().recTestStepCommonViewReducer.unSavedRecStepTag,
      unSavedRecElementGuid: getState().recTestStepCommonViewReducer.unSavedRecElementGuid,
    });
  };
}

function validateRecTestStepActionDetail(newRecTestStepActionDetail) {
  let validated = true;
  let action = newRecTestStepActionDetail["recTestStepProperty"]["action"];
  let uniqueByIndex = newRecTestStepActionDetail["recTestStepProperty"]["uniqueByIndex"];
  if (action === ELEMENT_ACTION.TAP_BY_INDEX && (uniqueByIndex === undefined || uniqueByIndex === "")) {
    validated = false;
    showNotification("error", "Error in performing actions.", "Please Select The Element.");
  }
  return validated;
}

function addSelectedElementDetailIntoRecTestStepActionDetail(
  recTestStepActionSelectedElement,
  newRecTestStepActionDetail
) {
  let uniqueByIndex = newRecTestStepActionDetail["recTestStepProperty"]["uniqueByIndex"]
    ? newRecTestStepActionDetail["recTestStepProperty"]["uniqueByIndex"]
    : -1;
  if (newRecTestStepActionDetail["recTestStepProperty"]["stepName"] === undefined) {
    newRecTestStepActionDetail["recTestStepProperty"]["stepName"] =
      newRecTestStepActionDetail["recTestStepProperty"]["action"];
  }
  if (recTestStepActionSelectedElement) {
    newRecTestStepActionDetail["recTestStepProperty"] = {
      ...newRecTestStepActionDetail["recTestStepProperty"],
      actionValue:
        newRecTestStepActionDetail["recTestStepProperty"]["action"] === ELEMENT_ACTION.SEND_KEYS &&
        recTestStepActionSelectedElement.args &&
        recTestStepActionSelectedElement.args.length === 1
          ? recTestStepActionSelectedElement.args[0]
          : "",
      // For Unique by text
      uniqueByText: recTestStepActionSelectedElement.uniqueByText,
      // Screenshot of Element
      screenShot: recTestStepActionSelectedElement["screenShot"].split("data:image/png;base64,")[1],
    };
  }
  newRecTestStepActionDetail["recTestStepProperty"] = {
    ...newRecTestStepActionDetail["recTestStepProperty"],
    // If unique By Index is empty pass -1
    uniqueByIndex,
    //Default Value to load element
    elementMaxLoadTime: ELEMENT_DEFAULT_LOAD_TIME,
  };
}

export function performRecTestStepAction(unSavedRecTestStep) {
  return (dispatch, getState) => {
    const { unSavedRecStepTag} = getState().RecTestStepCommonViewReducer;
    const { selectedDeviceByUdid} = getState().RecTestStepReducer;
    new Promise((resolve) =>
      resolve(dispatch(RecTestStepCommonAction._performRecTestStepAction(unSavedRecTestStep)))
    ).then((performResp) => {
      if (performResp) {
        performResp = updateResponse(performResp);
        if (performResp.status === SUCCESS_STATUS) {
          if(performResp?.data?.recElements==undefined && unSavedRecTestStep["recTestStepProperty"]["isAIStep"]==1){
              let jsonData = performResp.data;
              jsonData = jsonData.map(item => {
                item = {
                  ...item,
                  otherXpathGenerated:RecTestStepCommonAction.generateOtherXpathFromAIStep(item,selectedDeviceByUdid?.platformType?.name)
                }
                return item;
              })
              jsonData = {
                steps:[...jsonData],
                recTestStepPropertyId:unSavedRecTestStep?.recTestStepProperty?.id,
                recTestStepId:unSavedRecTestStep?.id,
                isForPrecondition:unSavedRecTestStep?.recTestStepProperty?.isStepHasPrecondition,
                isForLoopStep:unSavedRecTestStep?.recTestStepProperty?.isLoopStep,
                loopCount:unSavedRecTestStep?.recTestStepProperty?.loopCount,
                recTestScenarioRecTestStepAssocId:unSavedRecTestStep?.recTestScenarioRecTestStepAssocId
              }
              console.log("jsonData",jsonData)
              new Promise(resolve => resolve(dispatch(saveStepAsNLP(jsonData,unSavedRecTestStep)))).then(resp => resp)
              dispatch(recordingStop());
              dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
          }else{
            new Promise((resolve) =>
              resolve(
                dispatch(
                  RecTestStepCommonAction._saveRecTestStepAction(
                    unSavedRecTestStep,
                    performResp.data,
                    unSavedRecTestStep?.id,
                    unSavedRecStepTag
                  )
                )
              )
            ).then((response) => {
              if (response.status === SUCCESS_STATUS) {
                dispatch({ type: SAVE_REC_TEST_STEP_ACTION, response });
                dispatch(resetRecTestStepActionElement(response.data.id, response.data.tag));
                dispatch(clearPreview());
              } else {
                showNotification("error", response.message, "");
              }
              dispatch(recordingStop());
              dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
            });
          }
          
        } else {
          dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
          showNotification("error", performResp.message, "");
        }
      }
    });
  };
}

export function saveRecTestStepAction(responseData, recTestStepId, recTestStepTag, recElementGuid) {
  return (dispatch, getState) => {
    let { recTestStepList, selectedRecTestScenarioId, testBlockStep } = getState().RecTestStepReducer;
    let { unSavedRecStepTag, stepType } = getState().RecTestStepCommonViewReducer;
    let projectId = getState().ProjectsReducer.projectId;
    let newRecTestStepActionDetail = _findRecTestStepByTag(recTestStepList, unSavedRecStepTag);
    let parentRecTestStepDetail = findParentRecTestStep(recTestStepList, recTestStepId, unSavedRecStepTag);
    if (newRecTestStepActionDetail) {
      newRecTestStepActionDetail = {
        ...newRecTestStepActionDetail,
        project: { id: projectId },
      };
      if (
        unSavedRecStepTag &&
        isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) &&
        parentRecTestStepDetail &&
        newRecTestStepActionDetail.id !== parentRecTestStepDetail.id
      ) {
        newRecTestStepActionDetail["parentRecTestStep"] = {
          id: parentRecTestStepDetail.id,
        };
        newRecTestStepActionDetail["parentRecTestScenarioRecTestStepsAssoc"] = {
          id: parentRecTestStepDetail.recTestScenarioRecTestStepAssocId,
        };
      }
      if (
        newRecTestStepActionDetail.recTestStepProperty?.isStepHasPrecondition === 1 &&
        testBlockStep &&
        testBlockStep.tag === newRecTestStepActionDetail.tag
      ) {
        newRecTestStepActionDetail["recTestSteps"] = [...recTestStepList];
      } else {
        //Add Rec Test Scenario reference
        newRecTestStepActionDetail["recTestScenario"] = {
          id: selectedRecTestScenarioId,
        };
      }
      if (newRecTestStepActionDetail["recTestStepProperty"]["recStepVerifyElementProperties"]) {
        newRecTestStepActionDetail["recTestStepProperty"]["recStepVerifyElementProperties"] =
          removeSpaceFromVerifications(
            newRecTestStepActionDetail["recTestStepProperty"]["recStepVerifyElementProperties"]
          );
      }

      if (responseData && responseData["recElements"]) {
        newRecTestStepActionDetail["recTestStepProperty"]["recElements"] = updateUnSavedRecElement(
          newRecTestStepActionDetail["recTestStepProperty"]["recElements"],
          responseData["recElements"][0]
        );
      } else if (responseData && responseData["imageData"] && recElementGuid) {
        let recElement = getRecElement(
          newRecTestStepActionDetail["recTestStepProperty"]["recElements"],
          recElementGuid
        );
        recElement.elementImage = responseData["imageData"];
        newRecTestStepActionDetail["recTestStepProperty"]["recElements"] = updateRecElement(
          newRecTestStepActionDetail["recTestStepProperty"]["recElements"],
          recElement
        );
      }
      if (stepType === TEST_STEP_BLOCKS) {
        const projectId = getState().ProjectsReducer.projectId;
        newRecTestStepActionDetail = {
          ...newRecTestStepActionDetail,
          recTestScenario: null,
          project: { id: projectId },
          testBlockStep: newRecTestStepActionDetail.id !== testBlockStep.id ? { id: testBlockStep.id } : undefined,
          isTestBlock: 1,
        };
      }
      dispatch({ type: REQUEST_PERFORM_REC_TEST_STEP_ACTION });
      return async("recTestStep/customSave", "POST", newRecTestStepActionDetail).then((response) => {
        if (response.status === SUCCESS_STATUS) {
          dispatch({ type: SAVE_REC_TEST_STEP_ACTION, response });
          dispatch(recordingStop());
          dispatch(resetRecTestStepActionElement(recTestStepId, recTestStepTag));
          dispatch(clearPreview());
        } else {
          showNotification("error", response.message, "");
        }
        dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
        return true;
      });
    }
  };
}

export function getRecTestStepFromList(recTestStepTag) {
  return (dispatch, getState) => {
    const { recTestStepList } = getState().RecTestStepReducer;
    return _findRecTestStepByTag(recTestStepList, recTestStepTag);
  };
}

export function _updateUnSavedRecTestStep(updatedRecTestStep) {
  return (dispatch, getState) => {
    if (updatedRecTestStep === undefined) {
      const { recTestStepList } = getState().RecTestStepReducer;
      const { unSavedRecStepTag } = getState().RecTestStepCommonViewReducer;
      updatedRecTestStep = _findRecTestStepByTag(recTestStepList, unSavedRecStepTag);
    }
    dispatch(RecTestStepCommonAction._updateUnSavedRecTestStep(updatedRecTestStep));
    dispatch({ type: UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW, updatedRecTestStep });
  };
}

export function _updateRecTestStepAction(recTestStep, updateConditionMethod, updateStepActionName, unSavedRecTestStep) {
  return (dispatch, getState) => {
    let updateStepName = recTestStep.recTestStepProperty?.stepName;
    let recTestStepActionTag = recTestStep?.tag;
    let condition =
      unSavedRecTestStep.recTestStepProperty?.isStepHasPrecondition === 1
        ? STEP_ITEMS.WHEN_TO_RUN
        : unSavedRecTestStep.recTestStepProperty?.isLoopStep === 1
        ? STEP_ITEMS.REPEAT_TILL
        : "";
    let data = RecTestStepCommonAction.prepareUpdateRecTestStepActionObj(
      recTestStep,
      updateConditionMethod,
      condition,
      updateStepName
    );
    new Promise((resolve) =>
      resolve(
        dispatch(RecTestStepCommonAction._updateRecTestStepAction(data, updateConditionMethod, condition, recTestStep))
      )
    ).then((response) => {
      dispatch({
        type: UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE,
        response,
        data,
        recTestStepId: unSavedRecTestStep.id,
        recTestStepTag: unSavedRecTestStep.tag,
        updateStepName,
        recTestStep,
      });
      dispatch({
        type: REQUESTED_UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE,
        recTestStepActionId: unSavedRecTestStep.id,
        recTestStepActionTag,
      });
      if (response.status === SUCCESS_STATUS && updateConditionMethod) {
        dispatch(updateConditionMethod(recTestStepActionTag, condition));
      }
      dispatch(recordingStop());
    });
  };
}
export function getPreviewDetail(data) {
  return async (dispatch, getState) => {
    previewResultEventSource = prepareEventSource(
      "recording/getPreviewDetail",
      {
        runId: data.runId,
      },
      ""
    );
    dispatch({ type: UPDATE_DEVICE_STATE, state: DEVICE_STATUS.PREVIEW });
    previewResultEventSource.onmessage = (event: any) => {
      if (event.data != "") {
        let data = event.data;
        let eventParseResponse = JSON.parse(data);
        if (
          eventParseResponse.status === SUCCESS_STATUS &&
          eventParseResponse.data.length > 0 &&
          eventParseResponse.data[0]["runStatus"] === "DONE"
        ) {
          previewResultEventSource && previewResultEventSource.close();
          dispatch({ type: UPDATE_DEVICE_STATE, state: DEVICE_STATUS.INTERACTION });
          dispatch({ type: CHANGE_DEVICE_INTERACTION_MODE, value: DEVICE_INTERACTION_MODE.INTERACTIVE });
          dispatch({ type: PREVIEW_COMPLETED });
          dispatch({ type: UPDATE_PREVIEW_STATUS_WITH_REC_TEST_STEP, response: eventParseResponse });
          dispatch({ type: UPDATE_PREVIEW_STATUS, response: eventParseResponse });
        } else if (eventParseResponse.status === ERROR_STATUS && eventParseResponse.message === RunKilled) {
          previewResultEventSource && previewResultEventSource.close();
          dispatch({ type: UPDATE_DEVICE_STATE, state: DEVICE_STATUS.INTERACTION });
          dispatch({ type: CHANGE_DEVICE_INTERACTION_MODE, value: DEVICE_INTERACTION_MODE.INTERACTIVE });
          dispatch({ type: PREVIEW_COMPLETED });
          showNotification("error", "Preview Was Unexpectedly Killed.", "");
          dispatch(stopPreview(data.serverId, data.targetUniqueId));
          dispatch({ type: UPDATE_PREVIEW_STATUS_WITH_REC_TEST_STEP, response: eventParseResponse });
          dispatch({ type: UPDATE_PREVIEW_STATUS, response: eventParseResponse });
        } else {
          dispatch({ type: UPDATE_PREVIEW_STATUS, response: eventParseResponse });
          dispatch({ type: UPDATE_PREVIEW_STATUS_WITH_REC_TEST_STEP, response: eventParseResponse });
        }
      }
    };
  };
}

export function _addConditionToStep(condition, unSavedRecTestStep, newRecElementGuid) {
  return (dispatch, getState) => {
    new Promise((resolve) =>
      resolve(dispatch(RecTestStepCommonAction._addConditionToStep(condition, unSavedRecTestStep)))
    ).then(() => {
      dispatch({
        type: UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW,
        updatedRecTestStep: unSavedRecTestStep,
        stepType: getState().RecTestStepCommonViewReducer.stepType,
      });
    });
  };
}

export function _removeLoopConditionFromRecTestStep(selectedRecTestStepForLoopCondition) {
  return async (dispatch, getState) => {
    new Promise((resolve) =>
      resolve(dispatch(RecTestStepCommonAction._removeLoopConditionFromRecTestStep(selectedRecTestStepForLoopCondition)))
    ).then((response) => {
      dispatch({
        type: UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW,
        updateRecTestStep: response,
      });
      dispatch(RecTestStepCommonAction.resetEditRecTestStep());
    });
  };
}

export function _clearPreview(runId, serverId) {
  return (dispatch, getState) => {
    if (runId) {
      new Promise((resolve) => resolve(dispatch(RecTestStepCommonAction._removeRunData(runId, serverId)))).then(
        (response) => {
          dispatch({ type: CLEAR_PREVIEW_IN_SINGLE_COLUMN_VIEW });
        }
      );
    } else {
      dispatch({ type: CLEAR_PREVIEW_IN_SINGLE_COLUMN_VIEW });
    }
  };

}

export function _generateScenarioSteps(data,selectedRecTestStep) {
  return async (dispatch, getState) => {
    const { stepType,selectedParentStepforAIGenerateStep,scenarioGenerateStepType } = getState().RecTestStepCommonViewReducer;
    let { recTestStepList, selectedRecTestScenarioId } = getState().RecTestStepReducer;
    const projectId = getState().ProjectsReducer.projectId;
    data={
      ...data,
      recTestScenarioId:selectedRecTestScenarioId
    }
    if(scenarioGenerateStepType[selectedRecTestStep?.id]){
      let recTestScenarioRecTestStepsAssocs = recTestStepList.map(item => {return {id:item.recTestScenarioRecTestStepAssocId}});
      let requestData = {
        isDeleteReference:0,
        projectId,
        recTestScenarioId: selectedRecTestScenarioId,
        recTestScenarioRecTestStepsAssocs,
      };
      if(selectedParentStepforAIGenerateStep){
        recTestScenarioRecTestStepsAssocs = selectedParentStepforAIGenerateStep.recTestSteps.map(item => {return {id:item.recTestScenarioRecTestStepAssocId}})
        requestData = {
          isDeleteReference:0,
          projectId,
          recTestScenarioId: selectedRecTestScenarioId,
          recTestScenarioRecTestStepsAssocs,
        };
      }
      let response = await RecTestStepCommonAction.callDeleteRecTestStep(requestData)
      response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          recTestStepList = response.data
          dispatch({
            type: DELETE_REC_TEST_STEP,
            deleteFromUnSavedStep: false,
            undefined,
            undefined,
            response,
            undefined,
            stepType: getState().RecTestStepCommonViewReducer.stepType,
          });
        }
    }
    if(selectedParentStepforAIGenerateStep && (selectedParentStepforAIGenerateStep.recTestSteps ==undefined || selectedParentStepforAIGenerateStep.recTestSteps.length==0 || 
      (selectedParentStepforAIGenerateStep.recTestSteps && selectedParentStepforAIGenerateStep.recTestSteps.length>0 && selectedParentStepforAIGenerateStep.recTestSteps[0].recTestStepProperty.action!=STEP))){
          dispatch(RecTestStepCommonAction._generateScenarioSteps(data,selectedRecTestStep));
    }else{
        let recTestStep = getRecTestStepObjForSingleColumn(recTestStepList, selectedParentStepforAIGenerateStep, projectId);
        recTestStep["recTestStepProperty"]["stepName"]=data["objective"]
        recTestStep = {
          ...recTestStep,
          recTestScenario: {
            id: selectedRecTestScenarioId,
          },
        };
        RecTestStepCommonAction.callAddRecTestStep(recTestStep).then(response => {
          dispatch({type: ADD_REC_TEST_STEP,response,stepType});
          dispatch({type:CHANGE_PARENT_REC_TEST_STEP_FOR_AI_GENERATED_STEP,recTestStep:response.data});
          dispatch(RecTestStepCommonAction._generateScenarioSteps(data,selectedRecTestStep));
        });
    }
  };
}

  export function _stopGenerateStepsFromAIModal(data) {
    return async (dispatch,getState) => {
      const { selectedRecTestScenarioId } = getState().RecTestStepReducer;
      data = {
        ...data,
        recTestScenarioId:selectedRecTestScenarioId
      }
      dispatch(RecTestStepCommonAction._stopGenerateStepsFromAIModal(data));
    };
  }
  
  export function _fetchGenerateStepStatus(data,recTestStep) {
    return async (dispatch,getState) => {
      const { selectedRecTestScenarioId } = getState().RecTestStepReducer;
      data = {
        ...data,
        recTestScenarioId:selectedRecTestScenarioId
      }
      dispatch(RecTestStepCommonAction._fetchGenerateStepStatus(data,recTestStep));
    };
  }  

