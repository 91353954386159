import { faAngleDoubleRight, faCamera, faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Popover, Row, Spin, Tooltip } from "antd";
import { default as React, useState } from "react";
import ReactJson from "react-json-view";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { INFO_ICON } from "../../../CdnImagePath";
import {
  ACCESSIBILITY_REPORT,
  ACTION,
  LOCAL_REPORT,
  PRE_CONDITION,
  STEP,
  TEST_PLAN_RESULT_TYPE,
  TOOLTIP_BG_COLOR,
  VERIFICATION,
  VERIFICATIONS,
  defaultDateTimeFormat,
} from "../../../Constants";
import { ACCESSIBILITY_ICON, DARK_GREEN, ICON_AI, LOCAL_REPORT_ICON } from "../../../Constants/SvgConstants";
import {
  accessibilityOrLocalIconColor,
  getDateTimeInFormat,
  getDateTimeInUTCFormat,
  renderAccessibilityOrLocalIcon,
} from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import {
  DeviceCardHeader,
  DeviceCardTitle,
  StyledCol,
} from "../../TestPlan/TestPlanResultDetails/TestPlanResultDetailsStyled";
import styles from "./TestPlanResultDetailsV2.module.scss";
import { durationTime, highlightResult, isShowDuration } from "./TestPlanResultScenarioDetailsV2";
import { LoadingOutlined } from "@ant-design/icons";

const StepsDataRow = styled.div`
  display: flex;
  font-size: 14px;
  border-radius: 5px;
  width: 100%;
  color: "#65676b";
  background-color: "#338fcc";
`;

const StepsDataCol = styled.div`
  width: 100%;
  height: auto;
`;

const StepsData = styled.span`
  font-weight: 500;
  margin-right: 5px;
`;

const StyledSpan = styled.span`
  margin-left: 10px;
`;

const SkippedSpan = styled.span`
  color: #ffcb32;
  margin-left: 5px;
`;

const StylesCaptureScreenshot = styled.span`
  margin-right: 10px;
  color: #338fcc;
  width: 10px;
  line-height: 2.5em;
`;

const showArrow = (continueAfterFail, subChildStepFailedAndHaveContinueAfterFailFlag) => {
  if (continueAfterFail === 1 || subChildStepFailedAndHaveContinueAfterFailFlag) {
    return (
      <Tooltip
        color={TOOLTIP_BG_COLOR}
        title="Ignore failure, skip grouped-steps and move to execution of next step."
        placement="bottom"
      >
        <FontAwesomeIcon className="font-color-orange mr-5 mt-5" icon={faAngleDoubleRight} />
      </Tooltip>
    );
  }
};

const showRepeatStepCount = (isLoopStep, loopStepCount, executedLoopCount, parentLoopStep, parentLoopStepCount) => {
  if (isLoopStep === 1) {
    return (
      <Tooltip
        color={TOOLTIP_BG_COLOR}
        title={"Repeat Steps till count reach to " + loopStepCount + "."}
        placement="bottom"
      >
        <FontAwesomeIcon className="font-color-orange mr-5 mt-5" icon={faReply} />
      </Tooltip>
    );
  } else if (parentLoopStep === 1) {
    return (
      <Tooltip
        color={TOOLTIP_BG_COLOR}
        title={"Repeat Steps till count reach to " + parentLoopStepCount + "."}
        placement="bottom"
      >
        <FontAwesomeIcon className="font-color-orange mr-5 mt-5" icon={faReply} />
      </Tooltip>
    );
  }
};

export const getResultElement = (
  result,
  isShowAccessibilityIcon,
  isShowLocaleReportIcon,
  isSpinVisible,
  runResultDetails
) => {
  const renderIconsOrSpin = () => {
    const tooltipTitle =
      (runResultDetails?.accessibilityReport === 1 || runResultDetails?.accessibilityReport === 2) &&
      (runResultDetails?.localeReport === 1 || runResultDetails?.localeReport === 2)
        ? "Accessibility & Locale Reports are being generated, please wait"
        : (runResultDetails?.accessibilityReport === 1 || runResultDetails?.accessibilityReport === 2) &&
          runResultDetails?.localeReport === 0
        ? "Accessibility Report is being generated, please wait"
        : runResultDetails?.accessibilityReport === 0 &&
          (runResultDetails?.localeReport === 1 || runResultDetails?.localeReport === 2)
        ? "Locale Report is being generated, please wait"
        : "";
    return isSpinVisible ? (
      <Tooltip title={tooltipTitle} color={TOOLTIP_BG_COLOR}>
        <div>
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        </div>
      </Tooltip>
    ) : (
      <>
        {isShowAccessibilityIcon}
        {isShowLocaleReportIcon}
      </>
    );
  };
  switch (result) {
    case TEST_PLAN_RESULT_TYPE.IN_PROGRESS:
    case TEST_PLAN_RESULT_TYPE.IN_PROGRESS_TXT:
      return (
        <div className="display-flex">
          {renderIconsOrSpin()}
          <Spin size="small" className="mr-5" />
          <span className="badge bg-info p-5 font-weight-900  "> {TEST_PLAN_RESULT_TYPE.IN_PROGRESS_TXT}</span>
        </div>
      );
    case TEST_PLAN_RESULT_TYPE.PASSED:
      return (
        <div className={styles["icons_And_Result_Tag"]}>
          {renderIconsOrSpin()}
          <span className="badge bg-success p-5 font-weight-900  w-60">{TEST_PLAN_RESULT_TYPE.PASSED}</span>
        </div>
      );

    case TEST_PLAN_RESULT_TYPE.QUEUED_WAITING_FOR_DEVICE_AVAILABILITY:
      return (
        <div className={styles["icons_And_Result_Tag"]}>
          {renderIconsOrSpin()}
          <span className="badge bg-scheduled p-5 font-weight-900 w-60 ">
            {TEST_PLAN_RESULT_TYPE.QUEUED_WAITING_FOR_DEVICE_AVAILABILITY_TXT}
          </span>
        </div>
      );
    case TEST_PLAN_RESULT_TYPE.UPLOADING_RESULT:
      return (
        <div className={styles["icons_And_Result_Tag"]}>
          {renderIconsOrSpin()}
          <span className="badge bg-uploading p-5 font-weight-900 w-60 ">
            {TEST_PLAN_RESULT_TYPE.UPLOADING_RESULT_TXT}
          </span>
        </div>
      );
    case TEST_PLAN_RESULT_TYPE.FAILED_ACTION_VERIFICATION_FAILED:
      return (
        <div className={styles["icons_And_Result_Tag"]}>
          {renderIconsOrSpin()}
          <Tooltip
            color={TOOLTIP_BG_COLOR}
            title={TEST_PLAN_RESULT_TYPE.FAILED_ACTION_VERIFICATION_FAILED_FOR_SCENARIO}
          >
            <span className="badge bg-danger p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.FAILED}</span>
          </Tooltip>
        </div>
      );
    case TEST_PLAN_RESULT_TYPE.ABORTED:
      return (
        <div className={styles["icons_And_Result_Tag"]}>
          {renderIconsOrSpin()}
          <span className="badge bg-abort text-dark p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.ABORTED}</span>
        </div>
      );
    case TEST_PLAN_RESULT_TYPE.TERMINATED:
      return (
        <div className={styles["icons_And_Result_Tag"]}>
          {renderIconsOrSpin()}
          <span className="badge bg-abort text-dark p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.TERMINATED}</span>
        </div>
      );
    case TEST_PLAN_RESULT_TYPE.SKIPPED:
      return (
        <div className={styles["icons_And_Result_Tag"]}>
          {renderIconsOrSpin()}
          <span className="badge bg-warning text-dark p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.SKIPPED}</span>
        </div>
      );
    case TEST_PLAN_RESULT_TYPE.FAILED:
      return (
        <div className={styles["icons_And_Result_Tag"]}>
          {renderIconsOrSpin()}
          <div className="align-self-center">
            <div className="badge bg-danger p-5 font-weight-700 m-5">{TEST_PLAN_RESULT_TYPE.FAILED}</div>
          </div>
        </div>
      );
    case TEST_PLAN_RESULT_TYPE.FAILED_IGNORE_AND_CONTINUE:
      return (
        <div className={styles["icons_And_Result_Tag"]}>
          {renderIconsOrSpin()}
          <Tooltip color={TOOLTIP_BG_COLOR} title={TEST_PLAN_RESULT_TYPE.FAILED_IGNORE_AND_CONTINUE_FOR_SCENARIO}>
            <span className="badge bg-danger p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.FAILED}</span>
          </Tooltip>
        </div>
      );
    case TEST_PLAN_RESULT_TYPE.FAILED_PRECONDITION_NOT_MET:
      return (
        <div className={styles["icons_And_Result_Tag"]}>
          {renderIconsOrSpin()}
          <Tooltip color={TOOLTIP_BG_COLOR} title={TEST_PLAN_RESULT_TYPE.FAILED_PRECONDITION_NOT_MET_FOR_SCENARIO}>
            <span className="badge bg-danger p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.FAILED}</span>
          </Tooltip>
        </div>
      );
    case TEST_PLAN_RESULT_TYPE.SKIPPED_PRECONDITION_FAILED:
      return (
        <div className={styles["icons_And_Result_Tag"]}>
          {renderIconsOrSpin()}
          <Tooltip color={TOOLTIP_BG_COLOR} title={TEST_PLAN_RESULT_TYPE.SKIPPED_PRECONDITION_FAILED_FOR_SCENARIO}>
            <span className="badge bg-warning p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.SKIPPED}</span>
          </Tooltip>
        </div>
      );
    case TEST_PLAN_RESULT_TYPE.STEP_DISABLED_SKIPPED:
      return (
        <div className={styles["icons_And_Result_Tag"]}>
          {renderIconsOrSpin()}
          <Tooltip color={TOOLTIP_BG_COLOR} title={TEST_PLAN_RESULT_TYPE.STEP_DISABLED_SKIPPED}>
            <span className="badge bg-warning p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.SKIPPED}</span>
          </Tooltip>
        </div>
      );
    default:
      return <span className="default-result-text">{result}</span>;
  }
};

const TestPlanResultDetailStepV2 = (props) => {
  const {
    data,
    preConditionList,
    modifiedResult,
    duration,
    startTime,
    endTime,
    afterActionScreenshotUrl,
    isTestBlock,
    setVisibleReportModal,
    scenarioData,
    setVisibleSubStepReportModal,
  } = props;
  const { testBlockResultDataByRunId, runResultDetails, isLoadingAIReportStatus, accessibilityReportDetails } =
    useSelector((state) => state.TestPlanReducer);

  const isTestBlockReportData = isTestBlock === 1 && testBlockResultDataByRunId[runResultDetails[0]?.runId];
  const parentStepOfTestBlock =
    isTestBlock === 1 && testBlockResultDataByRunId[runResultDetails[0]?.runId]?.parentSteps[0];

  const captureScreenshotUrl =
    afterActionScreenshotUrl !== null ? afterActionScreenshotUrl : data?.afterActionScreenshotUrl;

  const result = data.modifiedResult !== null ? data.modifiedResult : data?.result;

  let accessibilityIconColor =
    isTestBlock === 1 &&
    !data?.parentStepId &&
    accessibilityOrLocalIconColor(
      isTestBlockReportData?.accessibilityReport,
      parentStepOfTestBlock?.reportData?.accessibilityStatus,
      parentStepOfTestBlock?.reportData
    );

  let localReportIconColor =
    isTestBlock === 1 &&
    !data?.parentStepId &&
    accessibilityOrLocalIconColor(
      isTestBlockReportData?.localeReport,
      parentStepOfTestBlock?.reportData?.localeStatus,
      parentStepOfTestBlock?.reportData
    );

  const id =
    isTestBlock === 1
      ? testBlockResultDataByRunId[runResultDetails[0]?.runId].parentSteps[0]?.id
      : scenarioData?.scenarioId;

  const isShowAccessibilityIcon =
    isTestBlock === 1 && !data?.parentStepId
      ? renderAccessibilityOrLocalIcon(
          !data?.parentStepId &&
            isTestBlockReportData?.accessibilityReport !== 0 &&
            !isLoadingAIReportStatus &&
            parentStepOfTestBlock?.reportData !== null &&
            parentStepOfTestBlock?.reportData?.accessibilityStatus !== "NONE",
          parentStepOfTestBlock?.reportData?.errorMessage
            ? parentStepOfTestBlock?.reportData?.errorMessage
            : parentStepOfTestBlock?.reportData?.accessibilityStatus === "PENDING"
            ? "Accessibility Report Pending"
            : "Accessibility Report",
          ACCESSIBILITY_ICON,
          accessibilityIconColor,
          (e) => {
            if (parentStepOfTestBlock?.reportData?.accessibilityStatus === "DONE") {
              e.stopPropagation();
              setVisibleReportModal({ type: ACCESSIBILITY_REPORT, data });
            }
          }
        )
      : renderAccessibilityOrLocalIcon(
          accessibilityReportDetails !== null &&
            accessibilityReportDetails[id]?.items?.some((i) => i?.stepId === data?.id),
          data?.reportData?.errorMessage
            ? data?.reportData?.errorMessage
            : data?.accessibilityReportData?.status === "PENDING"
            ? "Accessibility Report Pending"
            : "Accessibility Report",
          ACCESSIBILITY_ICON,
          DARK_GREEN,
          (e) => {
            e.stopPropagation();
            setVisibleSubStepReportModal({ type: ACCESSIBILITY_REPORT, data });
          }
        );

  const isShowLocaleReportIcon =
    isTestBlock === 1 && !data?.parentStepId
      ? renderAccessibilityOrLocalIcon(
          !data?.parentStepId &&
            isTestBlockReportData?.localeReport !== 0 &&
            parentStepOfTestBlock?.reportData !== null &&
            parentStepOfTestBlock?.reportData?.localeStatus !== "NONE",
          parentStepOfTestBlock?.reportData?.errorMessage
            ? parentStepOfTestBlock?.reportData?.errorMessage
            : parentStepOfTestBlock?.reportData?.status === "PENDING"
            ? "Local Report Pending"
            : "Local Report",
          LOCAL_REPORT_ICON,
          localReportIconColor,
          (e) => {
            if (data?.reportData?.localeStatus === "DONE") {
              e.stopPropagation();
              setVisibleReportModal({ type: LOCAL_REPORT, data });
            }
          }
        )
      : renderAccessibilityOrLocalIcon(
          accessibilityReportDetails !== null &&
            accessibilityReportDetails[id]?.localeItems?.some((i) => i?.stepId === data?.id),
          data?.reportData?.errorMessage
            ? data?.reportData?.errorMessage
            : data?.accessibilityReportData?.status === "PENDING"
            ? "Local Report Pending"
            : "Local Report",
          LOCAL_REPORT_ICON,
          DARK_GREEN,
          (e) => {
              e.stopPropagation();
              setVisibleSubStepReportModal({ type: LOCAL_REPORT, data });
            }
        );

  return (
    <>
      <div className="display-flex justify-space-between w-100">
        <StepsDataRow>
          {showArrow(data.continueAfterFail, data.subChildStepFailedAndHaveContinueAfterFailFlag)}
          {data?.isAiStep == 1 && (
            <span className="align-self-center">
              {data?.aiGeneratedStep ? (
                <Popover
                  overlayStyle={{ width: "60%" }}
                  content={
                    <div>
                      <ReactJson src={JSON.parse(data?.aiGeneratedStep)} />
                    </div>
                  }
                  title="AI Generated Step"
                >
                  <span>
                    <SvgLoader iconName={ICON_AI} infoIcon={true} />
                  </span>
                </Popover>
              ) : (
                <span>
                  <SvgLoader iconName={ICON_AI} infoIcon={true} />
                </span>
              )}
            </span>
          )}
          {data?.isCaptureScreenshot === 1 && (
            <span className="align-self-center">
              <Popover
                placement="right"
                content={
                  <>
                    <img
                      src={captureScreenshotUrl ? captureScreenshotUrl : INFO_ICON}
                      height={captureScreenshotUrl && 450}
                      weight={captureScreenshotUrl && 225}
                      alt={
                        data?.result !== null ? " Failed to capture screenshot" : "Captured screenshot will appear here"
                      }
                    />
                    {!captureScreenshotUrl && (
                      <span className="ml-5">
                        {data?.result !== null
                          ? " Failed to capture screenshot"
                          : "Captured screenshot will appear here"}
                      </span>
                    )}
                  </>
                }
                title="Captured Screenshot Image"
                trigger="hover"
              >
                <StylesCaptureScreenshot>
                  {modifiedResult === null ||
                  modifiedResult === TEST_PLAN_RESULT_TYPE.IN_PROGRESS ||
                  modifiedResult === TEST_PLAN_RESULT_TYPE.IN_PROGRESS_TXT ? (
                    <FontAwesomeIcon icon={faCamera} />
                  ) : captureScreenshotUrl !== null && modifiedResult !== null ? (
                    <FontAwesomeIcon className="green-icon" icon={faCamera} />
                  ) : (
                    <FontAwesomeIcon className="red-icon" icon={faCamera} />
                  )}
                </StylesCaptureScreenshot>
              </Popover>
            </span>
          )}
          {showRepeatStepCount(
            data.isLoopStep,
            data.loopStepCount,
            data.executedLoopCount,
            data.isParentLoopStep,
            data.parentLoopStepCount
          )}
          <StepsDataCol className="align-self-center">
            <span className="mr-5">
              {data.action !== STEP ? (data.action === VERIFICATIONS ? `${VERIFICATION}:` : `${ACTION}:`) : null}
            </span>
            <StepsData>
              {data.name}
              {data.retryCounts !== 0 ? "(" + data.retryCounts + ")" : ""}
            </StepsData>
            {isShowDuration(duration) && (
              <div>
                <span>Duration:</span>
                <Popover
                  placement="bottom"
                  title={
                    <DeviceCardHeader>
                      <DeviceCardTitle>{"Step Execution Details"}</DeviceCardTitle>
                    </DeviceCardHeader>
                  }
                  content={
                    <Row gutter={[16, 16]}>
                      <StyledCol>
                        <Col className="p-0" span={12}>
                          Start Time:
                        </Col>
                        {getDateTimeInFormat(startTime ? startTime : data?.startTime)} (
                        {getDateTimeInUTCFormat(startTime ? startTime : data?.startTime, defaultDateTimeFormat)} GMT)
                        {/* {displayUTCDateFormatWithToolTip(data?.startTime, defaultDateTimeFormat)} */}
                      </StyledCol>
                      <Row className="pl-10 font-size-12 w-100">
                        <Col className="p-0" span={12}>
                          End Time:
                        </Col>
                        {getDateTimeInFormat(endTime ? endTime : data?.endTime)} (
                        {getDateTimeInUTCFormat(endTime ? endTime : data?.endTime, defaultDateTimeFormat)} GMT)
                        {/* {displayUTCDateFormatWithToolTip(data?.endTime, defaultDateTimeFormat)} */}
                      </Row>
                    </Row>
                  }
                >
                  <span className="font-weight-500 ml-5">{durationTime(duration)}</span>
                </Popover>
              </div>
            )}
            {data.action === "launchApp" && (
              <div>
                <Row>
                  <Col>App Package:</Col>
                  <Col className="font-weight-500 ml-5">{data?.packageName}</Col>
                </Row>
                <Row>
                  <Col>App Activity:</Col>
                  <Col className="font-weight-500 ml-5">{data?.packageActivity}</Col>
                </Row>
                <Row>
                  <Col>Clear previous cache:</Col>
                  <Col className="font-weight-500 ml-5">{data?.removeAppPreviousSession}</Col>
                </Row>
              </div>
            )}
            {data.modifiedResult === TEST_PLAN_RESULT_TYPE.SKIPPED &&
            data.isSkipStep === 0 &&
            data.isStepHasPreCondition === 1 ? (
              <SkippedSpan>Skipped due to precondition failed</SkippedSpan>
            ) : data.isSkipStep === 1 ? (
              <span className="badge bg-secondary text-dark p-5 font-weight-900">Step Disabled</span>
            ) : null}
          </StepsDataCol>
          <StyledSpan>
            {getResultElement(
              result,
              isShowAccessibilityIcon,
              isShowLocaleReportIcon,
              data?.reportData !== null &&
                isLoadingAIReportStatus &&
                isTestBlock === 1 &&
                !data?.parentStepId &&
                (data?.reportData?.localeStatus === "IN_PROGRESS" ||
                  data?.reportData?.accessibilityStatus === "IN_PROGRESS"),
              runResultDetails[0]
            )}
          </StyledSpan>
        </StepsDataRow>
      </div>
      {data.isLoopStep === 0 && preConditionList && (
        <>
          {preConditionList.map((v, k) => {
            return (
              <div className="mt-10" key={k}>
                <StepsDataRow>
                  <StepsDataCol>
                    <span className="mr-5">{PRE_CONDITION}:</span>
                    <StepsData>{v.preConditionTitle}</StepsData>
                  </StepsDataCol>
                  <div>
                    {v.preconditionResult
                      ? highlightResult(v.preconditionResult, modifiedResult, "", "", "", data, false, false)
                      : ""}
                  </div>
                </StepsDataRow>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};
export default TestPlanResultDetailStepV2;
