import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Input, Select, Tooltip } from "antd";
import { SYSTEM_KEYS, TOOLTIP_BG_COLOR } from "../../../../../../../../Constants";
import {
  changeTestDataCustomValue,
  changeTestDataIsCustomReferenceTestData,
  changeTestDataReferenceTestData,
} from "../../../../../../../../actions/TestDataSet/TestDataSetRedirectAction";
import styles from "../AssignedTestDataListViewV2.module.scss";

const { Option } = Select;

const CustomValueColumn = ({ testDataValue, isForView = false, refTestDataList }) => {
  const dispatch = useDispatch();
  const { testDataSetView } = useSelector((state) => state.TestDataSetReducer);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const {
    scope,
    isForDefaultRefTestDataSetAssoc,
    isCustomChecked,
    refTestDataSetAssocId,
    testDataSetAssocId,
    testDataCustomValue,
  } = testDataValue;

  const tooltipTitle =
    refTestDataList && refTestDataList.find((i) => i.testDataSetAssocId === refTestDataSetAssocId) ? (
      <div>
        <div>
          <b>Key:</b> (S-
          {refTestDataList.find((i) => i.testDataSetAssocId === refTestDataSetAssocId).stepOrderPath || ""})
          {refTestDataList.find((i) => i.testDataSetAssocId === refTestDataSetAssocId).name}
        </div>
        <div>
          <b>Value:</b> {refTestDataList.find((i) => i.testDataSetAssocId === refTestDataSetAssocId).value || ""}
        </div>
      </div>
    ) : (
      ""
    );

  return (
    (scope !== "System Key" || testDataSetView === SYSTEM_KEYS) &&
    isForDefaultRefTestDataSetAssoc === 0 && (
      <div className={styles.tooltipOrCheckBox}>
        {testDataSetView !== SYSTEM_KEYS && (
          <Tooltip title="Reference Test Data" color={TOOLTIP_BG_COLOR}>
            <Checkbox
              disabled={isForView}
              checked={isCustomChecked || refTestDataSetAssocId !== 0}
              onClick={(e) => dispatch(changeTestDataIsCustomReferenceTestData(e.target.checked, testDataValue))}
              className={styles.checkBox}
            />
          </Tooltip>
        )}
        {testDataSetView !== SYSTEM_KEYS && (isCustomChecked || refTestDataSetAssocId !== 0) ? (
          <Tooltip
            title={tooltipTitle}
            color={TOOLTIP_BG_COLOR}
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
            visible={tooltipVisible && !!refTestDataSetAssocId}
          >
            <Select
              disabled={isForView}
              placeholder="Please Select The Custom Value"
              className="select-app-for-default select-Test-Data"
              value={refTestDataSetAssocId || undefined}
              onChange={(e) => dispatch(changeTestDataReferenceTestData(e, testDataValue))}
              onMouseEnter={() => setTooltipVisible(true)}
              onMouseLeave={() => setTooltipVisible(false)}
              onClick={() => setTooltipVisible(false)}
            >
              {refTestDataList &&
                refTestDataList.length > 0 &&
                refTestDataList.map((i) => {
                  if (i.testDataSetAssocId !== testDataSetAssocId) {
                    const displayText = `(S-${i.stepOrderPath}) ${i.name}`;
                    const tooltipText = `key: ${displayText}\nvalue: ${i?.value || ""}\n Used For: ${i?.useFor || ""}`;

                    return (
                      <Option
                        value={i.testDataSetAssocId}
                        key={i.testDataSetAssocId}
                        title={tooltipText}
                        label={displayText}
                        onMouseEnter={() => setTooltipVisible(false)}
                      >
                        {displayText}
                      </Option>
                    );
                  }
                  return null;
                })}
            </Select>
          </Tooltip>
        ) : (
          <Input
            disabled={isForView}
            className={styles.input}
            size="large"
            placeholder="Enter Custom Test Data Value"
            name="testDataCustomValue"
            value={testDataCustomValue}
            onChange={(e) => {
              dispatch(changeTestDataCustomValue(e.target.value, testDataValue));
            }}
          />
        )}
      </div>
    )
  );
};

export default CustomValueColumn;
