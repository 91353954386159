import _ from "lodash";
import {
  ADD_TEST_DATA_ROW_IN_NEW_TEST_DATA_SET,
  APPLICATION_STRING_INFO,
  ASSIGN_TEST_DATA_SET,
  BUILD_TEST_DATA_SET,
  CHANGE_TEST_DATA_CUSTOM_VALUE_IN_TEST_DATA_SET_FORM,
  CHANGE_TEST_DATA_DEFAULT_VALUE_IN_TEST_DATA_SET_FORM,
  CHANGE_TEST_DATA_NAME_IN_TEST_DATA_SET_FORM,
  CHANGE_TEST_DATA_SET_DEVICE_ASSIGN_TEST_DATA_SET,
  CHANGE_TEST_DATA_SET_LOCALE,
  CHANGE_TEST_DATA_SET_NAME,
  DEVICE_STRING_INFO,
  GET_LIST_OF_TEST_DATA_BY_SCENARIO_ID,
  GET_TEST_DATA_BASE_LIST,
  GET_TEST_DATA_BASE_LIST_LOADING,
  GET_TEST_DATA_SET_BY_ID,
  GET_TEST_PLAN_ID_NAME_LIST,
  GET_TEST_STEPS_DATA_LIST,
  GET_TEST_STEPS_DATA_LIST_APPLICATION_STRING,
  GET_TEST_STEPS_DATA_LIST_DEVICE_STRING,
  GET_TEST_STEPS_DATA_LOADING,
  HIDE_ADD_TEST_DATA_SET_FORM,
  HIDE_TEST_BUILD_DATA_SET_TAB,
  HIDE_TEST_DATA_SET_SELECTION_MODAL,
  LIST_OF_LOCALES,
  LOADING_EXECUTION_TEST_DATA_SET_ASSOC_LIST,
  REMOVE_PREVIOUS_TEST_DATA_SET,
  REMOVE_TEST_DATA_ROW_IN_NEW_TEST_DATA_SET,
  RESET_MANAGE_KEYS_DATA,
  RESET_PREVIOUS_TEST_DATA_SET_FOR_EDIT,
  RESET_STATE,
  RESET_TEST_DATA_SET,
  RESET_TEST_DATA_SET_LIST_FOR_SELECTION,
  RESET_TEST_DATA_SET_VIEW,
  SAVE_TEST_DATA_SET,
  SELECTED_TEST_DATA_SET_TAB,
  SET_APPLICATION_STRING_ASSIGNEE_DETAIL,
  SET_DEVICE_STRING_ASSIGNEE_DETAIL,
  SET_TEST_DATA_SET_ASSIGNEE_DETAIL,
  SET_TEST_DATA_SET_VIEW,
  SHOW_ADD_TEST_DATA_SET_FORM,
  SHOW_TEST_DATA_SET_SELECTION_MODAL,
  SYSTEM_KEY_LIST,
  TEST_DATA_LIST_BY_TEST_DATASET_ID,
  TEST_DATA_LIST_FOR_TEST_DATA_SET,
  TEST_DATA_SET_INFO_MODAL,
  TEST_DATA_SET_LIST,
  TEST_DATA_SET_LIST_FOR_SELECTION,
  TEST_DATA_SET_LIST_FOR_SELECTION_REQUEST,
  TEST_DATA_SET_LIST_FOR_SELECTION_REQUESTED,
  TEST_DATA_SET_LIST_REQUEST,
  TEST_DATA_SET_LIST_REQUESTED,
  UPDATE_TEST_DATA_SET_DATA,
} from "../Constants";
import { getUniqueId, isArrayNotUndefinedNotNullNotEmpty } from "../Util";
// import { isArrayNotUndefinedNotNullNotEmpty, isNotNullAndNotEmptyAndNotUndefined } from "../../../Util/index";

let defaultState = {
  testDataSetList: undefined,
  testDataSetForEdit: undefined,
  previous_testDataSetForEdit: undefined,
  showTestDataVisibility: {},
  fetchingTestDataSetList: false,
  addTestDataFormVisible: false,
  newTestDataName: undefined,
  newTestDataValue: undefined,
  requestSaveTestDataForm: false,
  testDataListForTestPlan: undefined,
  listOfTestDataByScenarioId: undefined,
  saveTestDataSet: undefined,
  addNewTestDataSetFormVisibility: false,
  newTestDataSetName: "",
  newTestDataVariables: [],
  totalCounts: 0,
  currentPage: 1,
  totalPageCount: 0,
  pageItems: "",
  requestTestDataSetList: false,
  testPlanIdNameList: [],
  selectedTestDataSet: undefined,
  testDataSetAssigneeDetail: undefined,
  applicationStringAssigneeDetail: undefined,
  deviceStringAssigneeDetail: undefined,
  systemKeyList: [],
  listOfLocales: [],
  testDataSetView: undefined,
  selectedTestDataSetTab: BUILD_TEST_DATA_SET,
  testStepsDataLoading: false,
  getApplicationStringsData: [],
  getDeviceStringsData: [],
  getTestStepsDataList: [],
  getTestScenarioDetailList: [],
  getSystemKeys: [],
  testDataBase: [],
  visibleTestDataSetInfoModal: false,
  selectedDataKey: null,
  selectedStepsKey: null,
  selectedStepsValue: null,
  testDataBaseListLoading: false,
  applicationStringInfo: {},
  deviceStringInfo: {},
  loadingExecutionTestDataSetAssocList: false
};

export default function TestDataSetReducer(state = defaultState, action) {
  let newTestDataVariables = [];
  let response = undefined;
  let testDataSetList = undefined;
  switch (action.type) {
    case GET_TEST_PLAN_ID_NAME_LIST:
      response = action?.response;
      return {
        ...state,
        testPlanIdNameList: response?.data,
      };
    case SYSTEM_KEY_LIST:
      return {
        ...state,
        systemKeyList: action.response.data,
      };
    case TEST_DATA_SET_LIST:
      if (action.response) {
        state.testDataSetList = action?.response?.data?.data;
        if (state.testDataSetList && state.testDataSetList.filter((item) => item.isDefault === 1).length === 0) {
          state.testDataSetList[0]["isDefault"] = 1;
        }
        state.totalCounts = action.response.data.totalItems;
        state.currentPage = action.response.data.currentPage;
        state.totalPageCount = action.response.data.totalCount;
        state.pageItems = action.response.data.pageItems;
      }
      return {
        ...state,
      };
    case LIST_OF_LOCALES:
      return {
        ...state,
        listOfLocales: action.response.data,
      };
    case GET_TEST_DATA_SET_BY_ID:
      if (action.response) {
        response = action.response;
        state.testDataSetForEdit = response.data;
      } else {
        state.testDataSetForEdit = action.response;
      }
      return {
        ...state,
      };
    case RESET_TEST_DATA_SET:
      state.testDataSetForEdit = _.cloneDeep(state.previous_testDataSetForEdit);
      state.previous_testDataSetForEdit = undefined;
      return {
        ...state,
      };
    case REMOVE_PREVIOUS_TEST_DATA_SET:
      state.previous_testDataSetForEdit = undefined;
      return {
        ...state,
      };
    case TEST_DATA_SET_LIST_FOR_SELECTION:
      testDataSetList = undefined;
      if (action.response) {
        testDataSetList = action.response.data;
        if (testDataSetList && testDataSetList.filter((item) => item.isDefault === 1).length === 0) {
          testDataSetList[0]["isDefault"] = 1;
        }
      }
      return {
        ...state,
        testDataSetList,
      };
    case RESET_TEST_DATA_SET_LIST_FOR_SELECTION:
      return {
        ...state,
        testDataSetList: undefined,
      };
    case TEST_DATA_SET_LIST_FOR_SELECTION_REQUEST:
      return {
        ...state,
        requestTestDataSetList: true,
      };
    case TEST_DATA_SET_LIST_FOR_SELECTION_REQUESTED:
      return {
        ...state,
        requestTestDataSetList: false,
      };
    case SAVE_TEST_DATA_SET:
      response = action.response;
      return {
        ...state,
        saveTestDataSet: response,
      };
    case TEST_DATA_SET_LIST_REQUEST:
      return {
        ...state,
        fetchingTestDataSetList: true,
      };
    case TEST_DATA_SET_LIST_REQUESTED:
      return {
        ...state,
        fetchingTestDataSetList: false,
      };
    case GET_LIST_OF_TEST_DATA_BY_SCENARIO_ID:
      response = action.response;
      return {
        ...state,
        listOfTestDataByScenarioId: response.data,
      };
    case TEST_DATA_LIST_FOR_TEST_DATA_SET:
      if (isArrayNotUndefinedNotNullNotEmpty(action.response.data)) {
        action.response.data.map((v, k) => {
          let testDataRow = {};
          testDataRow["testData"] = {};
          testDataRow["testData"]["id"] = v.id;
          testDataRow["testData"]["name"] = v.name;
          testDataRow["testData"]["value"] = v.value;
          testDataRow["tag"] = getUniqueId();
          testDataRow["value"] = v.testDataCustomValue;
          testDataRow["id"] = v.testDataSetAssocId;
          newTestDataVariables.push(testDataRow);
        });
      }
      return {
        ...state,
        testDataListForTestPlan: action.response.data,
        newTestDataVariables,
      };
    case TEST_DATA_LIST_BY_TEST_DATASET_ID:
      if (isArrayNotUndefinedNotNullNotEmpty(action.response.data)) {
        action.response.data.map((v, k) => {
          let testDataRow = {};
          testDataRow["testData"] = {};
          testDataRow["testData"]["id"] = v.id;
          testDataRow["testData"]["name"] = v.name;
          testDataRow["testData"]["value"] = v.value;
          testDataRow["testData"]["affectedScenarioCount"] = v.affectedScenarioCount;
          testDataRow["testData"]["affectedScenarios"] = v.affectedScenarios;
          testDataRow["tag"] = getUniqueId();
          testDataRow["value"] = v.testDataCustomValue;
          testDataRow["id"] = v.testDataSetAssocId;
          newTestDataVariables.push(testDataRow);
        });
      }
      return {
        ...state,
        testDataListForTestPlan: action.response.data,
        newTestDataVariables,
      };
    case ADD_TEST_DATA_ROW_IN_NEW_TEST_DATA_SET:
      newTestDataVariables = [...state.newTestDataVariables];
      newTestDataVariables.push({ testData: { name: "", value: "" }, tag: getUniqueId() });
      return {
        ...state,
        newTestDataVariables,
      };
    case REMOVE_TEST_DATA_ROW_IN_NEW_TEST_DATA_SET:
      newTestDataVariables = [...state.newTestDataVariables];
      newTestDataVariables = newTestDataVariables.filter((item) => item.tag !== action.tag);
      return {
        ...state,
        newTestDataVariables,
      };
    case SHOW_ADD_TEST_DATA_SET_FORM:
      return {
        ...state,
        addNewTestDataSetFormVisibility: true,
      };
    case HIDE_ADD_TEST_DATA_SET_FORM:
      return {
        ...state,
        addNewTestDataSetFormVisibility: false,
        newTestDataSetName: "",
        newTestDataVariables: [],
      };

    case CHANGE_TEST_DATA_NAME_IN_TEST_DATA_SET_FORM:
      newTestDataVariables = [...state.newTestDataVariables];
      newTestDataVariables.map((v, k) => {
        if (v.tag === action.tag) {
          v["testData"]["name"] = action.value;
        }
      });
      return {
        ...state,
      };
    case CHANGE_TEST_DATA_DEFAULT_VALUE_IN_TEST_DATA_SET_FORM:
      newTestDataVariables = [...state.newTestDataVariables];
      newTestDataVariables.map((v, k) => {
        if (v.tag === action.tag) {
          v["testData"]["value"] = action.value;
        }
      });
      return {
        ...state,
      };
    case CHANGE_TEST_DATA_CUSTOM_VALUE_IN_TEST_DATA_SET_FORM:
      newTestDataVariables = [...state.newTestDataVariables];
      newTestDataVariables.map((v, k) => {
        if (v.tag === action.tag) {
          v.value = action.value;
        }
      });
      return {
        ...state,
      };
    case SHOW_TEST_DATA_SET_SELECTION_MODAL:
      return {
        ...state,
        assignTestDataSetModalVisible: true,
        testDataSetAssigneeDetail: action.testDataSetAssigneeDetail,
      };
    case HIDE_TEST_DATA_SET_SELECTION_MODAL:
      return {
        ...state,
        assignTestDataSetModalVisible: false,
        testDataSetAssigneeDetail: undefined,
        selectedTestDataSet: undefined,
      };
    case HIDE_TEST_BUILD_DATA_SET_TAB:
      return {
        ...state,
        testDataSetAssigneeDetail: undefined,
        selectedTestDataSet: undefined,
        applicationStringAssigneeDetail: undefined,
        deviceStringAssigneeDetail: undefined,
      };
    case RESET_STATE:
      return {
        ...state,
        testDataSetAssigneeDetail: undefined,
        selectedTestDataSet: undefined,
        applicationStringAssigneeDetail: undefined,
        deviceStringAssigneeDetail: undefined,
      };
    case ASSIGN_TEST_DATA_SET:
      return {
        ...state,
        selectedTestDataSet: action.selectedTestDataSet,
      };
    case SET_TEST_DATA_SET_VIEW:
      return {
        ...state,
        testDataSetView: action.testDataSetView,
      };
    case RESET_TEST_DATA_SET_VIEW:
      return {
        ...state,
        testDataSetView: undefined,
      };
    case SET_TEST_DATA_SET_ASSIGNEE_DETAIL:
      return {
        ...state,
        testDataSetAssigneeDetail: action.testDataSetAssigneeDetail,
      };
    case SET_APPLICATION_STRING_ASSIGNEE_DETAIL:
      return {
        ...state,
        applicationStringAssigneeDetail: action.applicationStringAssigneeDetail,
      };
    case SET_DEVICE_STRING_ASSIGNEE_DETAIL:
      return {
        ...state,
        deviceStringAssigneeDetail: action.deviceStringAssigneeDetail,
      };
    case CHANGE_TEST_DATA_SET_NAME:
      state.previous_testDataSetForEdit =
        state.previous_testDataSetForEdit === undefined
          ? _.cloneDeep(state.testDataSetForEdit)
          : state.previous_testDataSetForEdit;
      state.testDataSetForEdit = updateValueOfTestDataSet({ ...state.testDataSetForEdit }, "name", action.value);
      return {
        ...state,
      };
    case CHANGE_TEST_DATA_SET_LOCALE:
      state.previous_testDataSetForEdit =
        state.previous_testDataSetForEdit === undefined
          ? _.cloneDeep(state.testDataSetForEdit)
          : state.previous_testDataSetForEdit;
      state.testDataSetForEdit = updateValueOfTestDataSet({ ...state.testDataSetForEdit }, "locale", action.value);
      return {
        ...state,
      };
    case CHANGE_TEST_DATA_SET_DEVICE_ASSIGN_TEST_DATA_SET:
      state.previous_testDataSetForEdit =
        state.previous_testDataSetForEdit === undefined
          ? _.cloneDeep(state.testDataSetForEdit)
          : state.previous_testDataSetForEdit;
      state.testDataSetForEdit = updateValueOfTestDataSet({ ...state.testDataSetForEdit }, "systemKey", {
        id: action.value,
      });
      state.testDataSetForEdit = updateValueOfTestDataSet({ ...state.testDataSetForEdit }, "systemKeyId", action.value);
      return {
        ...state,
      };
    case UPDATE_TEST_DATA_SET_DATA:
      if (state.previous_testDataSetForEdit === undefined) {
        state.previous_testDataSetForEdit = _.cloneDeep(state.testDataSetForEdit);
      }
      state.testDataSetForEdit = action.updateTestDataSetForEdit;
      return {
        ...state,
      };
    case RESET_PREVIOUS_TEST_DATA_SET_FOR_EDIT:
      return {
        ...state,
        previous_testDataSetForEdit: undefined,
      };

    case SELECTED_TEST_DATA_SET_TAB:
      return {
        ...state,
        selectedTestDataSetTab: action.selectedTab,
      };

    case GET_TEST_STEPS_DATA_LOADING:
      return {
        ...state,
        testStepsDataLoading: action.response.isLoading,
      };

    case GET_TEST_STEPS_DATA_LIST:
      return {
        ...state,
        getTestStepsDataList: action?.response?.testDataKeys,
        getTestScenarioDetailList: action?.response?.scenarioDetails,
        getApplicationStringsData: action?.response?.applicationStrings,
        getDeviceStringsData: action?.response?.deviceStrings,
        getSystemKeys: action?.response?.systemKeys,
      };
    case RESET_MANAGE_KEYS_DATA:
      return {
        ...state,
        getTestStepsDataList: [],
        getTestScenarioDetailList: [],
      };

    case GET_TEST_STEPS_DATA_LIST_APPLICATION_STRING:
      return {
        ...state,
        getApplicationStringsData: action?.response,
      };

    case LOADING_EXECUTION_TEST_DATA_SET_ASSOC_LIST:
      return {
        ...state,
        loadingExecutionTestDataSetAssocList: action?.isLoading,
      };

    case GET_TEST_STEPS_DATA_LIST_DEVICE_STRING:
      return {
        ...state,
        getDeviceStringsData: action?.response,
      };

    case GET_TEST_DATA_BASE_LIST:
      return {
        ...state,
        testDataBase: action?.response,
      };
    case APPLICATION_STRING_INFO:
      let applicationStringInfo = state.applicationStringInfo;
      if (action.applicationStringId) {
        applicationStringInfo[action.applicationStringId] = action?.response;
      }

      return {
        ...state,
        applicationStringInfo,
      };
    case DEVICE_STRING_INFO:
      let deviceStringInfo = state.deviceStringInfo;
      if (action.deviceStringId) {
        deviceStringInfo[action.deviceStringId] = action?.response;
      }

      return {
        ...state,
        deviceStringInfo,
      };
    case TEST_DATA_SET_INFO_MODAL:
      return {
        ...state,
        visibleTestDataSetInfoModal: action?.visible,
        selectedDataKey: action?.selectedKeys,
        testDataKeys: action?.testDataKeys,
        selectedStepsKey: action?.selectedStepsKey,
        selectedStepsValue: action?.selectedStepsValue,
      };

    case GET_TEST_DATA_BASE_LIST_LOADING:
      return {
        ...state,
        testDataBaseListLoading: action?.response.isLoading,
      };
    default:
      return state;
  }
}

function updateValueOfTestDataSet(testDataSetForEdit, key, value) {
  testDataSetForEdit[key] = value;
  return testDataSetForEdit;
}
