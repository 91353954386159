import { Col, Collapse, Row, Skeleton, Tooltip } from "antd";
import _ from "lodash";
import { default as React } from "react";
import { Chart } from "react-google-charts";
import styled from "styled-components";
import {
  defaultDateTimeFormat,
  FAIL_COLOR,
  INPROGRESS_COLOR,
  PARALLEL_OS,
  PARALLEL_SPEED,
  PASS_COLOR,
  TEST_PLAN_RESULT_TYPE,
  TOTALCASE_COLOR,
} from "../../../Constants";

import { StyledCol, DeviceCardTitle, TotalCaseContainer } from "./TestPlanResultDetailsStyled";
import { TestPlanResultDetailsScenario } from "./TestPlanResultDetails";
import {
  highlightResult,
  isNotNullAndNotEmptyAndNotUndefined,
  redirectToURL,
  displayUTCDateFormatWithToolTip,
} from "../../../Util";
import { useSelector } from "react-redux";
import DeviceHoverCard from "../../CommonComponents/DeviceHoverCard/DeviceHoverCard";
const { Panel } = Collapse;

export const StyleSpinner = styled.div`
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: center;
`;

const totalUniqueLocale = (scenarios) => {
  return Object.keys(
    _(scenarios)
      .countBy((item) => item.locale)
      .value()
  ).length;
};

const TestPlanResultDetailsList = ({ isTestBlock }) => {
  const { runResultDetails, deviceResultDetails, testPlanResultDetails, testRailDetails } = useSelector(
    (state) => state.TestPlanReducer
  );

  const res =
    testPlanResultDetails &&
    testPlanResultDetails.length > 0 &&
    testPlanResultDetails[0].result &&
    testPlanResultDetails[0].result.split(",");

  let result = {};
  res &&
    res.forEach((data) => {
      const a = data.split(":");
      result[a[0].trim()] = parseInt(a[1].trim());
    });
  return (
    <React.Fragment>
      <TestPlanResultDetailsScenario>
        {(isTestBlock === 0 &&
          runResultDetails &&
          runResultDetails.length > 0 &&
          testPlanResultDetails &&
          testPlanResultDetails.length > 0) ||
        (isTestBlock === 1 && runResultDetails && runResultDetails.length > 0) ? (
          <Collapse expandIconPosition="right">
            <Panel
              key="1"
              showArrow={true}
              header={
                <>
                  <DeviceCardTitle className="font-size-14">
                    <span className=" font-weight-500">Job Name: </span>
                    {isTestBlock === 0 && isNotNullAndNotEmptyAndNotUndefined(testPlanResultDetails[0]?.jobName)
                      ? testPlanResultDetails[0].jobName
                      : "NA"}
                  </DeviceCardTitle>
                  <DeviceCardTitle className="font-size-14">
                    <span className="ml-15 font-weight-500">Run Number: </span>
                    {runResultDetails && runResultDetails.length > 0 ? runResultDetails[0].runNumber : ""}
                  </DeviceCardTitle>
                </>
              }
            >
              {(testPlanResultDetails && testPlanResultDetails.length > 0) || isTestBlock ? (
                <Row>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    {isTestBlock === 0 && (
                      <>
                        <Collapse expandIconPosition="right">
                          <Panel
                            key="1"
                            showArrow={true}
                            header={
                              <TotalCaseContainer>
                                <DeviceCardTitle className="font-size-14 font-weight-500">
                                  Job Execution Summary
                                </DeviceCardTitle>
                              </TotalCaseContainer>
                            }
                          >
                            <StyledCol span={24}>
                              <Col span={6} className="font-weight-600">
                                Test Plan:
                              </Col>
                              <Col span={6}>
                                {runResultDetails && runResultDetails.length > 0
                                  ? runResultDetails[0].testPlanName
                                  : "NA"}
                              </Col>
                              <Col span={6} className="font-weight-600">
                                Scenarios In Test Plan:
                              </Col>
                              <Col span={6}>
                                {runResultDetails && runResultDetails.length > 0
                                  ? runResultDetails[0].uniqueScenario
                                  : ""}
                              </Col>
                            </StyledCol>
                            <StyledCol span={24}>
                              <Col span={6} className="font-weight-600">
                                Execution Type:
                              </Col>
                              <Col span={18}>
                                {isNotNullAndNotEmptyAndNotUndefined(testPlanResultDetails[0].executionType)
                                  ? (testPlanResultDetails &&
                                      testPlanResultDetails[0]?.executionType === PARALLEL_OS &&
                                      "Repeat Test Plan On All Devices") ||
                                    (testPlanResultDetails &&
                                      testPlanResultDetails[0]?.executionType === PARALLEL_SPEED &&
                                      "Distribute Test Plan Across All Devices") ||
                                    (testPlanResultDetails &&
                                      testPlanResultDetails[0]?.executionType &&
                                      testPlanResultDetails[0]?.executionType)
                                  : "NA"}
                              </Col>
                            </StyledCol>
                            <StyledCol span={24}>
                              <Col span={6} className="font-weight-600">
                                No Of Devices To Execute On:
                              </Col>
                              <Col span={6}>
                                {runResultDetails && runResultDetails.length > 0
                                  ? runResultDetails[0]?.deviceIds?.split(",")?.length
                                  : "NA"}
                              </Col>
                              <Col span={6} className="font-weight-600">
                                No Of Unique Locales:
                              </Col>
                              <Col span={6}>{deviceResultDetails ? totalUniqueLocale(deviceResultDetails) : "NA"}</Col>
                            </StyledCol>
                            <StyledCol span={24}>
                              <Col span={6} className="font-weight-600">
                                {" "}
                                Scheduled To Start On:
                              </Col>
                              <Col span={6}>
                                {displayUTCDateFormatWithToolTip(
                                  testPlanResultDetails && testPlanResultDetails[0]?.scheduleDateTime,
                                  defaultDateTimeFormat
                                )}
                              </Col>
                              <Col span={6} className="font-weight-500">
                                Start Time:
                              </Col>
                              <Col span={6}>
                                {displayUTCDateFormatWithToolTip(
                                  runResultDetails && runResultDetails[0]?.startTime,
                                  defaultDateTimeFormat
                                )}
                              </Col>
                            </StyledCol>
                            <StyledCol span={24}>
                              <Col span={6} className="font-weight-600">
                                End Time:
                              </Col>
                              <Col span={6}>
                                {displayUTCDateFormatWithToolTip(
                                  runResultDetails && runResultDetails[0]?.endTime,
                                  defaultDateTimeFormat
                                )}
                              </Col>
                              <Col span={6} className="font-weight-600">
                                Duration Of Run:
                              </Col>
                              <Col span={6}>{(runResultDetails && runResultDetails[0]?.duration) || "NA"}</Col>
                            </StyledCol>
                            <StyledCol span={24}>
                              <Col span={6} className="font-weight-600">
                                Result:
                              </Col>
                              <Col span={18}>{highlightResult(testPlanResultDetails[0].result)}</Col>
                            </StyledCol>
                            <StyledCol span={24}>
                              <Col span={6} className="font-weight-600">
                                Testrail Result:
                              </Col>
                              <Col span={6}>
                                {!_.isEmpty(testRailDetails)
                                  ? testRailDetails.map((v, k) => {
                                      return (
                                        <span
                                          className="font-color-blue cursor-pointer text-decoration-underline mr-5"
                                          onClick={() => redirectToURL(v.url)}
                                        >
                                          {v.name}
                                        </span>
                                      );
                                    })
                                  : "NA"}
                              </Col>
                              <Col span={6} className="font-weight-600">
                                Results Emailed To:
                              </Col>
                              <Col span={6}>
                                {runResultDetails[0]?.scheduleEmail ? runResultDetails[0]?.scheduleEmail : "NA"}
                              </Col>
                            </StyledCol>
                          </Panel>
                        </Collapse>

                        <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
                          <Panel
                            key="2"
                            showArrow={true}
                            className="mt-10"
                            header={
                              <TotalCaseContainer>
                                <DeviceCardTitle className="font-size-14 font-weight-500">
                                  Additional Job Details
                                </DeviceCardTitle>
                              </TotalCaseContainer>
                            }
                          >
                            <>
                              <StyledCol span={24}>
                                <Col span={6} className="font-weight-600">
                                  Execution Frequency:
                                </Col>
                                <Col span={6}>
                                  {isNotNullAndNotEmptyAndNotUndefined(testPlanResultDetails[0].frequency)
                                    ? testPlanResultDetails[0]?.frequency
                                    : "NA"}
                                </Col>
                                <Col span={6} className="font-weight-600">
                                  Retry Count:
                                </Col>
                                <Col span={6}>
                                  {isNotNullAndNotEmptyAndNotUndefined(testPlanResultDetails[0].retryIndex)
                                    ? testPlanResultDetails[0]?.retryIndex
                                    : "NA"}
                                </Col>
                              </StyledCol>
                              <StyledCol span={24}>
                                <Col span={6} className="font-weight-600">
                                  Video Capture:
                                </Col>
                                <Col span={6}>
                                  {testPlanResultDetails && testPlanResultDetails[0]?.isVideo === 1 ? "Yes" : "No"}
                                </Col>
                                <Col span={6} className="font-weight-600">
                                  Logs Capture:
                                </Col>
                                <Col span={6}>
                                  {testPlanResultDetails && testPlanResultDetails[0]?.isLogs === 1 ? "Yes" : "No"}
                                </Col>
                              </StyledCol>
                              <StyledCol span={24} className="pl-10">
                                <Col span={6} className="font-weight-600">
                                  Comments:
                                </Col>
                                <Col span={6}>{runResultDetails[0]?.comments}</Col>
                                <Col span={6} className="font-weight-600">
                                  {" "}
                                  Notes
                                </Col>
                                <Col span={6}>
                                  {runResultDetails[0]?.scheduleNote ? runResultDetails[0]?.scheduleNote : "NA"}
                                </Col>
                              </StyledCol>
                              <StyledCol span={24} className="pl-10">
                                <Col span={6} className="font-weight-600">
                                  Accessibility Report:
                                </Col>
                                <Col span={6}>
                                  {runResultDetails[0]?.accessibilityReport === 1 ||
                                  runResultDetails[0]?.accessibilityReport === 2
                                    ? "Yes"
                                    : "No"}
                                </Col>
                                <Col span={6} className="font-weight-600">
                                  {" "}
                                  Localization Report:
                                </Col>
                                <Col span={6}>
                                  {runResultDetails[0]?.localeReport === 1 || runResultDetails[0]?.localeReport === 2
                                    ? "Yes"
                                    : "No"}
                                </Col>
                              </StyledCol>
                            </>
                          </Panel>
                        </Collapse>
                      </>
                    )}

                    <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
                      <Panel
                        key="2"
                        showArrow={true}
                        className="mt-10"
                        header={
                          <TotalCaseContainer>
                            <DeviceCardTitle className="font-size-14 font-weight-500">
                              Device And Locale Details
                            </DeviceCardTitle>
                          </TotalCaseContainer>
                        }
                      >
                        <>
                          <StyledCol span={24}>
                            <Col span={6} className="font-weight-600">
                              Farm:
                            </Col>
                            <Col span={18}>{runResultDetails && runResultDetails[0]?.deviceServiceFarm}</Col>
                          </StyledCol>
                          <StyledCol span={24}>
                            <Col span={6} className="font-weight-600">
                              Devices:
                            </Col>
                            <Col span={18}>
                              {isTestBlock === 0 ? (
                                deviceResultDetails &&
                                deviceResultDetails.map((i, j) => <DeviceHoverCard deviceDetails={i} deviceIndex={j} />)
                              ) : (
                                <DeviceHoverCard deviceDetails={deviceResultDetails} />
                              )}
                            </Col>
                          </StyledCol>
                        </>
                      </Panel>
                    </Collapse>
                  </Col>
                  {isTestBlock === 0 && (
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      {testPlanResultDetails && testPlanResultDetails ? (
                        <Chart
                          className="chart-height"
                          chartType="PieChart"
                          data={[
                            [TEST_PLAN_RESULT_TYPE.PASSED, TEST_PLAN_RESULT_TYPE.FAILED],
                            [TEST_PLAN_RESULT_TYPE.PASSED, result.Passed || 0],
                            [TEST_PLAN_RESULT_TYPE.FAILED, result.Failed || 0],
                            [TEST_PLAN_RESULT_TYPE.IN_PROGRESS, result[TEST_PLAN_RESULT_TYPE.IN_PROGRESS] || 0],
                            [
                              TEST_PLAN_RESULT_TYPE.TOTAL_PENDING,
                              result[TEST_PLAN_RESULT_TYPE.TOTAL_SCENARIO_TO_EXECUTE] -
                                (result.Passed ? result.Passed : 0) -
                                (result.Failed ? result.Failed : 0) -
                                (result[TEST_PLAN_RESULT_TYPE.IN_PROGRESS]
                                  ? result[TEST_PLAN_RESULT_TYPE.IN_PROGRESS]
                                  : 0) || 0,
                            ],
                          ]}
                          legendToggle
                          options={{
                            legend: {
                              maxLines: 1,
                              textStyle: {
                                fontSize: 12,
                              },
                            },
                            colors: [PASS_COLOR, FAIL_COLOR, INPROGRESS_COLOR, TOTALCASE_COLOR],
                          }}
                        />
                      ) : (
                        <Skeleton avatar size="large" />
                      )}
                    </Col>
                  )}
                </Row>
              ) : (
                <div>
                  <Skeleton paragraph={{ rows: 10 }} size="large" />
                </div>
              )}
            </Panel>
          </Collapse>
        ) : null}
      </TestPlanResultDetailsScenario>
    </React.Fragment>
  );
};
export default TestPlanResultDetailsList;
