import { Col, Row, Space, Spin } from "antd";
import { cloneDeep, isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CHANGE_AWS_DEVICE_FARM_TAB_DATA,
  GET_AWS_PROJECTS,
  UPDATE_PREVIOUS_AWS_DEVICE_FARM_TAB_DETAILS,
} from "../../../Constants";
import {
  getAwsDeviceFarmPoolsLists,
  getAwsProjectsList,
  saveAwsDeviceFarmProject,
} from "../../../actions/ProjectSettingsActions";
import SaveResetButtonV2 from "../../CoreComponents/SaveResetButtonV2/SaveResetButtonV2";
import { BuildAWSDeviceFarmModal } from "../../ProjectSettings/BuildProjectSettingModal";
import AWSDeviceFarmKeysV2 from "./AWSDeviceFarmKeysV2";
import AWSDeviceFarmProjectsV2 from "./AWSDeviceFarmProjectsV2";
import styles from "../ProjectSettingsV2.module.scss";

const AWSDeviceFarmManagerV2 = (props) => {
  const { projectId, selectedTab } = props;
  const dispatch = useDispatch();
  const {
    awsDeviceFarmTabDetails,
    projectLoading,
    previous_awsDeviceFarmTabDetails,
    getAWSDeviceFarms,
    awsDeviceLoading,
  } = useSelector((state) => state.ProjectSettingsReducer);

  useEffect(() => {
    dispatch({ type: UPDATE_PREVIOUS_AWS_DEVICE_FARM_TAB_DETAILS });
  }, [projectId]);

  const resetAwsDeviceFarmData = () => {
    if (previous_awsDeviceFarmTabDetails.accessKeyId === undefined) {
      dispatch({
        type: CHANGE_AWS_DEVICE_FARM_TAB_DATA,
        data: new BuildAWSDeviceFarmModal(getAWSDeviceFarms),
      });
      dispatch({ type: GET_AWS_PROJECTS, response: { data: [] } });
    } else {
      dispatch({
        type: CHANGE_AWS_DEVICE_FARM_TAB_DATA,
        data: cloneDeep(previous_awsDeviceFarmTabDetails),
      });
      dispatch(getAwsDeviceFarmPoolsLists());
    }
  };
  return (
    <Spin spinning={projectLoading || awsDeviceLoading} tip="Loading" className="spinner-center">
      <Row justify="space-between" align="middle" className={styles["main_Header"]}>
        <Col>
          <div className={styles["main-Header-Text"]}>AWS Device Farm</div>
        </Col>
        <Col>
          <Space>
            <SaveResetButtonV2
              submitLabel={"Save"}
              resetLabel={"Reset"}
              submitDisable={
                isEmpty(awsDeviceFarmTabDetails?.awsDeviceFarmProjects) ||
                JSON.stringify(awsDeviceFarmTabDetails?.awsDeviceFarmProjects) ===
                  JSON.stringify(previous_awsDeviceFarmTabDetails?.awsDeviceFarmProjects)
              }
              resetDisable={
                JSON.stringify(awsDeviceFarmTabDetails) === JSON.stringify(previous_awsDeviceFarmTabDetails)
              }
              handleSubmit={() => dispatch(saveAwsDeviceFarmProject(projectId))}
              handleReset={resetAwsDeviceFarmData}
            />
          </Space>
        </Col>
      </Row>

      <Row justify="center" className={styles["page_Scroll"]}>
        <Col span={16}>
          <AWSDeviceFarmKeysV2 selectedTab={selectedTab} />
          <AWSDeviceFarmProjectsV2 />
        </Col>
      </Row>
    </Spin>
  );
};

export default AWSDeviceFarmManagerV2;
