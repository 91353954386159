import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { Button, Checkbox, Drawer, Spin, Tooltip } from "antd";
import {
  clearAllPreviews,
  fetchPreviewRunById,
  setRecTestScenarioIdForPreview,
} from "../../actions/RecTestScenarioAction";
import { DELETE_ICON } from "../../CdnImagePath";
import {
  defaultDateTimeFormat,
  TOOLTIP_BG_COLOR,
  TEST_PLAN_RESULT_TYPE,
  FETCHING_REC_TEST_SCENARIO_LIST,
} from "../../Constants";
import PaginationComponent from "../CoreComponents/PaginationComponent/PaginationComponent";
import CommonSearch from "../CoreComponents/SearchComponent/CommonSearch";
import { Label } from "../CoreComponents/StyledComponent/StyledComponents";
import TableComponent from "../CoreComponents/TableComponent/TableComponent";
import DatePickerBox from "../CommonComponents/DatePickerBox";
import DeviceHoverCard from "../CommonComponents/DeviceHoverCard/DeviceHoverCard";
import { deleteModal, isSelectedAllIds, removeSelectedIds, displayUTCDateFormatWithToolTip } from "../../Util";
import { resultTag } from "../TestPlan/TestPlanResult/TestPlanResultLists/TestPlanResult";
import { deleteTestPlanRunResult } from "../../actions/TestPlanAction";
import SelectAllBox from "../CommonComponents/SelectAllBox";
import { durationTime } from "../TestPlan/TestPlanResultDetails/TestPlanResultScenarioDetails";

const PreviewRunDrawer = (props) => {
  const dispatch = useDispatch();

  const { previewRunsByScenarioId, fetchingRecTestScenarioList, recTestScenarioIdForPreview } = useSelector(
    (state) => state.RecTestScenarioReducer
  );
  const { openPreviewRunDrawer, setOpenPreviewRunDrawer, recTestScenarioName, projectId } = props;

  const [searchKeyword, setSearchKeyword] = useState("");
  const [dateRange, setDateRange] = useState(["", ""]);
  const [selectAllRun, setSelectAllRun] = useState([]);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (recTestScenarioIdForPreview) {
      dispatch(fetchPreviewRunById());
    }
  }, [recTestScenarioIdForPreview]);

  useEffect(() => {
    if (recTestScenarioIdForPreview) {
      intervalRef.current = setInterval(
        () => (dispatch(fetchPreviewRunById()), dispatch({ type: FETCHING_REC_TEST_SCENARIO_LIST, status: false })),
        4000
      );
      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [recTestScenarioIdForPreview]);

  useEffect(() => {
    setSelectAllRun([]);
  }, [openPreviewRunDrawer]);

  const columns = [
    {
      title: "Run Details",
      dataIndex: "runDetails",
    },
    {
      title: "Result",
      dataIndex: "result",
      align:"center"
    },
    {
      title: "Actions",
      dataIndex: "action",
      align: "right",
    },
  ];

  const deleteConfirm = (id, name) => {
    deleteModal(
      name
        ? `Do you want to delete the selected Run Result "${name}" ?`
        : "Do you want to delete Selected all Run Results ?",
      () => {
        dispatch(
          deleteTestPlanRunResult(
            {
              runIds: id ? [id] : selectAllRun,
              projectId: projectId,
              recTestScenarioId: recTestScenarioIdForPreview,
            },
            projectId,
            "",
            setSelectAllRun
          )
        );
      }
    );
  };

  const clearConfirm = () => {
    deleteModal("Are you sure you want to clear all Preview Runs?", () => {
      dispatch(clearAllPreviews(setOpenPreviewRunDrawer));
    });
  };
  const previewRunsDetails =
    previewRunsByScenarioId &&
    previewRunsByScenarioId?.data?.map((row) => ({
      key: row?.runResultId,
      runDetails: (
        <div className="display-flex">
          <Checkbox
            className="mr-10"
            checked={selectAllRun && selectAllRun?.includes(row?.runResultId)}
            onChange={(e) => {
              e.target.checked
                ? setSelectAllRun([...selectAllRun, row?.runResultId])
                : setSelectAllRun(selectAllRun && selectAllRun?.filter((i) => i !== row?.runResultId));
            }}
          />
          <div>
            <div>
              <Label>Run number: </Label>
              {row?.runNumber || "NA"}
            </div>
            {row?.startTime && (
              <div>
                <Label>Started On: </Label>
                {row?.startTime && displayUTCDateFormatWithToolTip(row.startTime, defaultDateTimeFormat)}
                {row?.result &&
                  row?.duration &&
                  (row.result === TEST_PLAN_RESULT_TYPE.PASSED ||
                  row.result === TEST_PLAN_RESULT_TYPE.FAILED ||
                  row.result === TEST_PLAN_RESULT_TYPE.ABORTED
                    ? ` and took ${durationTime(row?.duration.split(":"))}`
                    : "")}
              </div>
            )}
            <div>
              <Label className="mr-5">Device detail: </Label>
              <DeviceHoverCard deviceDetails={row} />
            </div>
            <div>
              <Label>Locale: </Label>
              {row?.locale || "NA"}
            </div>
          </div>
        </div>
      ),
      result: <div className="mt-5">{resultTag(row?.result)}</div>,
      action: (
        <div>
          <Tooltip color={TOOLTIP_BG_COLOR} title="Delete Preview">
            <img
              src={DELETE_ICON}
              alt="Detail Result"
              className="pr-10 cursor-pointer"
              onClick={() => deleteConfirm(row?.runResultId, row?.name)}
            />
          </Tooltip>
        </div>
      ),
    }));

  return (
    <Drawer
      title={recTestScenarioName}
      placement="right"
      width={"736"}
      onClose={() => {
        setOpenPreviewRunDrawer(false);
        setSearchKeyword("");
        setDateRange(["", ""]);
        dispatch(setRecTestScenarioIdForPreview(null));
        setSelectAllRun([]);
      }}
      visible={openPreviewRunDrawer}>
      <Spin spinning={fetchingRecTestScenarioList} tip="Loading" className="spinner-center">
        {!_.isEmpty(previewRunsByScenarioId?.data) && (
          <div className="display-flex  justify-flex-end mb-10 ">
            <Button className="orange ml-15" size="large" onClick={() => clearConfirm()}>
              Clear All Previews
            </Button>
          </div>
        )}
        <div className="display-flex justify-flex-end">
          <div className="display-flex align-items-center ">
            <DatePickerBox
              dateRange={dateRange}
              dateChange={(dateStrings) => {
                dispatch(
                  fetchPreviewRunById(
                    searchKeyword,
                    dateStrings !== null ? dateStrings[0] : "",
                    dateStrings !== null ? dateStrings[1] : ""
                  )
                );
                setDateRange([dateStrings !== null ? dateStrings[0] : "", dateStrings !== null ? dateStrings[1] : ""]);
              }}
            />
          </div>
          {openPreviewRunDrawer && (
            <div className="ml-10 mr-5">
              <CommonSearch
                placeHolder="Search By Run Number, Test Plan Name"
                searchDetails={(searchText) => dispatch(fetchPreviewRunById(searchText))}
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
              />
            </div>
          )}
        </div>
        <div className="mt-10 display-flex justify-space-between">
          <div>
            <SelectAllBox
              isChecked={isSelectedAllIds(
                selectAllRun,
                previewRunsByScenarioId.data?.map((i) => ({ id: i?.runResultId }))
              )}
              selectAllIds={selectAllRun}
              handleChangeCheck={(isChecked) => {
                if (isChecked) {
                  setSelectAllRun([
                    ...selectAllRun,
                    ...(
                      previewRunsByScenarioId.data &&
                      previewRunsByScenarioId.data?.filter(
                        (i) => !selectAllRun.includes(i?.runResultId) && i?.runResultId
                      )
                    )?.map((i) => i.runResultId),
                  ]);
                } else {
                  setSelectAllRun(removeSelectedIds(selectAllRun, previewRunsByScenarioId.data));
                }
              }}
              deleteConfirm={deleteConfirm}
              isDisable={_.isEmpty(previewRunsByScenarioId.data?.map((i) => ({ id: i?.runResultId })))}
            />
          </div>
          {!_.isEmpty(previewRunsByScenarioId?.data) && (
            <PaginationComponent
              currentPageItems={previewRunsByScenarioId?.pageItems}
              totalItems={previewRunsByScenarioId?.totalItems}
              currentPage={previewRunsByScenarioId?.currentPage}
              totalPage={previewRunsByScenarioId?.totalCount}
              callPaginationAPI={(pageNumber) =>
                dispatch(
                  fetchPreviewRunById(searchKeyword, dateRange && dateRange[0], dateRange && dateRange[1], pageNumber)
                )
              }
            />
          )}
        </div>
        <div className="mb-20 mt-10">
          <TableComponent columns={columns} data={previewRunsDetails} selected={selectAllRun} />
        </div>
      </Spin>
    </Drawer>
  );
};

export default PreviewRunDrawer;
