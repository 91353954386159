import React from "react";
import { useSelector } from "react-redux";
import { Modal, Popover, Spin } from "antd";
import { isEmpty } from "lodash";
import { ACCESSIBILITY_REPORT, LOCAL_REPORT } from "../../../Constants";
import ShowTooltipV2 from "../../CommonComponents/ShowTooltipV2/ShowTooltipV2";
import TableComponentV2 from "../../CoreComponents/TableComponent/TableComponentV2";
import styles from "./TestPlanResultDetailsV2.module.scss";

const AIReportDetailsSubStepModalV2 = (props) => {
  const {
    visibleReportModal,
    setVisibleReportModal,
    scenarioData,
    isTestBlock,
  } = props;
  const {
    accessibilityReportDetails,
    isLoadingAccessibilityReport,
    runResultDetails,
    testBlockResultDataByRunId,
  } = useSelector((state) => state.TestPlanReducer);
  const id =
    isTestBlock === 1
      ? testBlockResultDataByRunId[runResultDetails[0]?.runId].parentSteps[0]
          ?.id
      : scenarioData?.scenarioId;
  const reportDetails =
    visibleReportModal?.type === ACCESSIBILITY_REPORT ? "items" : "localeItems";
  const AIStepReportDetails =
    accessibilityReportDetails !== null &&
    accessibilityReportDetails[id]?.[reportDetails]?.filter(
      (i) => i?.stepId === visibleReportModal?.data?.id
    );

  const columns = [
    {
      title: "Step No",
      dataIndex: "no",
      width: 300,
    },
    {
      title: "Step Name",
      dataIndex: "stepName",
      width: 300,
    },
    {
      title: "Level",
      dataIndex: "level",
      width: 300,
      hidden: visibleReportModal?.type !== ACCESSIBILITY_REPORT,
    },
    {
      title: "Title",
      dataIndex: "title",
      width: 300,
      hidden: visibleReportModal?.type !== ACCESSIBILITY_REPORT,
    },
    {
      title: "Desc",
      dataIndex: "decs",
      width: 300,
      hidden: visibleReportModal?.type !== ACCESSIBILITY_REPORT,
    },
    {
      title: "Criterion",
      dataIndex: "criterion",
      width: 300,
      hidden: visibleReportModal?.type !== ACCESSIBILITY_REPORT,
    },
    {
      title: "Recommended Translation",
      dataIndex: "recommendedTranslation",
      width: 300,
      hidden: visibleReportModal?.type !== LOCAL_REPORT,
    },
    {
      title: "Images",
      dataIndex: "images",
      width: 300,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      width: 300,
      hidden: visibleReportModal?.type !== ACCESSIBILITY_REPORT,
    },
  ]?.filter((item) => !item.hidden);

  const moreDetailColumns = [
    {
      title:
        visibleReportModal?.type === ACCESSIBILITY_REPORT
          ? "Recommendations"
          : "Untranslated Text",
      dataIndex: "hints",
      width: 500,
    },
    {
      title: "Element Attrs",
      dataIndex: "elemAttrs",
      width: 500,
    },
  ];

  const getRowDetails = (item, type) => {
    if (!item)
      return { hints: "N/A", untranslatedText: "N/A", elemAttrs: "N/A" };

    const hints =
      type === ACCESSIBILITY_REPORT
        ? item.hints
          ? Object.entries(item.hints).map(([parentKey, nestedObj], index) => {
              const isNestedObject =
                typeof nestedObj === "object" && nestedObj !== null;
              const entries = isNestedObject
                ? Object.entries(nestedObj)
                : [[parentKey, nestedObj]];
              return (
                <div key={index}>
                  {isNestedObject && (
                    <div className={styles["label_Key"]}>{parentKey}:</div>
                  )}
                  {entries.map(([key, value]) => (
                    <div key={key} className={styles["key_Value"]}>
                      <div className={styles["label_Key"]}> {key}:</div>
                      <ShowTooltipV2
                        text={value}
                        className={`${styles["step-name"]} ${styles["hint_Value"]}`}
                      />
                    </div>
                  ))}
                </div>
              );
            })
          : "N/A"
        : item?.untranslatedText
        ? Object.entries(item.untranslatedText).map(
            ([parentKey, nestedObj], index) => {
              const isNestedObject =
                typeof nestedObj === "object" && nestedObj !== null;
              const entries = isNestedObject
                ? Object.entries(nestedObj)
                : [[parentKey, nestedObj]];
              return (
                <div key={index}>
                  {isNestedObject && (
                    <div className={styles["label_Key"]}>{parentKey}:</div>
                  )}
                  {entries.map(([key, value]) => (
                    <div key={key} className={styles["key_Value"]}>
                      <div className={styles["label_Key"]}> {key}:</div>
                      <ShowTooltipV2
                        text={value}
                        className={`${styles["step-name"]} ${styles["hint_Value"]}`}
                      />
                    </div>
                  ))}
                </div>
              );
            }
          )
        : "N/A";

    const untranslatedText = item.untranslatedText
      ? Object.entries(item.untranslatedText).map(
          ([parentKey, nestedObj], index) => {
            const isNestedObject =
              typeof nestedObj === "object" && nestedObj !== null;
            const entries = isNestedObject
              ? Object.entries(nestedObj)
              : [[parentKey, nestedObj]];
            return (
              <div key={index}>
                {isNestedObject && (
                  <div className={styles["label_Key"]}>{parentKey}:</div>
                )}
                {entries.map(([key, value]) => (
                  <div key={key} className={styles["key_Value"]}>
                    <div className={styles["label_Key"]}> {key}:</div>
                    <ShowTooltipV2
                      text={value}
                      className={`${styles["step-name"]} ${styles["hint_Value"]}`}
                    />
                  </div>
                ))}
              </div>
            );
          }
        )
      : "N/A";

    const elemAttrs = item.elemAttrs
      ? Object.entries(item.elemAttrs).map(([key, value], index) => (
          <div key={index} className={styles["key_Value"]}>
            <div className={styles["label_Key"]}> {key}:</div>{" "}
            <ShowTooltipV2 text={value} className={styles["step-name"]} />
          </div>
        ))
      : "N/A";

    return { hints, untranslatedText, elemAttrs };
  };

  const aIStepReportsItems =
    !isEmpty(AIStepReportDetails) &&
    AIStepReportDetails?.map((row, ind) => {
      const rowDetails = getRowDetails(row, visibleReportModal?.type);

      return {
        key: ind,
        no: (
          <div className={styles["report_Details"]}>
            {row?.stepOrderPath ? `S${row?.stepOrderPath}` : ind + 1}
          </div>
        ),
        stepName: (
          <div className={styles["report_Details"]}>
            {row?.stepName || "N/A"}
          </div>
        ),
        level: (
          <div className={styles["report_Details"]}>
            {row?.wcagLevel || "N/A"}
          </div>
        ),
        title: (
          <div className={styles["report_Details"]}>{row?.title || "N/A"}</div>
        ),
        decs: (
          <ShowTooltipV2
            text={row?.description || "N/A"}
            className={`${styles["desc_And_Reason"]} ${styles["hint_Value"]}`}
          />
        ),
        criterion: (
          <div className={styles["report_Details"]}>
            {row?.criterion || "N/A"}
          </div>
        ),
        recommendedTranslation: (
          <div className={styles["report_Details"]}>
            {row?.recommendedTranslation || "N/A"}
          </div>
        ),
        images: row?.screenshotUrl ? (
          <Popover
            content={
              <img
                className="cursor__pointer"
                src={row?.screenshotUrl}
                height={500}
                weight={600}
                alt="Screenshot"
              />
            }
            placement="left"
            title="Step Image"
            trigger="hover"
          >
            <img
              className="cursor__pointer"
              src={row?.screenshotUrl}
              height={50}
              weight={50}
              alt="Screenshot"
            />
          </Popover>
        ) : (
          "-"
        ),
        reason: (
          <div className={styles["report_Details"]}>{row?.reason || "N/A"}</div>
        ),
        description: (
          <div className="table_Details__wrapper">
            <TableComponentV2
              data={[rowDetails]}
              columns={moreDetailColumns}
              className="table__wrapper"
            />
          </div>
        ),
      };
    });

  return (
    <Modal
      visible={visibleReportModal}
      title={`${
        visibleReportModal?.type === ACCESSIBILITY_REPORT
          ? "Accessibility"
          : "Locale"
      } Report Details for "${visibleReportModal?.data?.name}"`}
      onCancel={() => setVisibleReportModal(null)}
      width="75%"
      destroyOnClose
      footer={[]}
      className="accessibility_Report_Modal"
    >
      <Spin
        spinning={isLoadingAccessibilityReport}
        className={styles["spinner_Center"]}
      >
        <div className="table_Details__wrapper">
          <TableComponentV2
            data={aIStepReportsItems}
            columns={columns}
            className="table__wrapper"
            rowExpandable
            handleExpand={() => {}}
          />
        </div>
      </Spin>
    </Modal>
  );
};

export default AIReportDetailsSubStepModalV2;
