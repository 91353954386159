import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faCheckCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { CURRENT_JOB, INVALID_ICON, PENDING_ICON } from "../../../../../CdnImagePath";
import {
  ANDROID,
  APP_MANAGER,
  BUILD_URL,
  DEVICE_EXECUTION_FARM,
  NONE,
  PARALLEL_OS,
  PARALLEL_OS_FOR_DISPLAY,
  PARALLEL_SPEED,
  PARALLEL_SPEED_FOR_DISPLAY,
  SCHEDULE_TEST_JOB,
  SERIAL,
  SERIAL_FOR_DISPLAY,
  TOOLTIP_BG_COLOR,
  USE_LATEST_BUILD,
} from "../../../../../Constants";
import { checkNotUndefined, checkNull } from "../../../../../Util";
import { changeSelectedTestJobCriteria } from "../../../../../actions/TestJobActions";
import styles from "./JobCriteriaV2.module.scss";
import { fetchTestDataSetByProfileId } from "../../../../../actions/TestDataSet/TestDataSetRedirectAction";

const TestJobCriteriaWrapV2 = (props) => {
  const dispatch = useDispatch();
  const { getTestRailSuitesByTestPlanID, testJobDetail, selectedTestJobCriteria, testJobErrorDetail } = useSelector(
    (state) => state.TestJobReducer
  );
  const { getSearchDeviceList } = useSelector((state) => state.ManageFarmReducer);
  const { platform } = useSelector((state) => state.ProjectsReducer);

  //Destructure of object
  const {
    devices,
    testPlan,
    testDataSet,
    buildType,
    executionType,
    isCaptureVideo,
    isLogs,
    updateResultToTestRail,
    fileUrl,
    deviceServiceFarm,
    buildDetail,
    testRailTestSuiteIds,
    isUseLatestBuild,
    appActivity,
    accessibilityReport,
    localeReport,
  } = testJobDetail;

  const deviceInfoNotification =
    getSearchDeviceList &&
    getSearchDeviceList.filter((deviceData) => devices.includes(deviceData.device.targetUniqueId)).length;

  useEffect(() => {
    if (testJobDetail?.testPlan?.id) {
      dispatch(fetchTestDataSetByProfileId(testJobDetail?.testPlan?.id, "", 1, false));
    }
  }, [testJobDetail?.testPlan?.id]);
  const testJobDetailDevicesCount = useMemo(
    () =>
      getSearchDeviceList &&
      getSearchDeviceList.filter((deviceData) => devices.includes(deviceData.device.targetUniqueId))?.length,
    [getSearchDeviceList, devices]
  );

  const executionSettingForDisplay = (value) => {
    if (value === SERIAL) {
      return SERIAL_FOR_DISPLAY;
    } else if (value === PARALLEL_OS) {
      return PARALLEL_OS_FOR_DISPLAY;
    } else if (value === PARALLEL_SPEED) {
      return PARALLEL_SPEED_FOR_DISPLAY;
    } else {
      return value;
    }
  };

  const attachedDevices = testJobDetail && devices.length;

  const isDeviceOffline =
    getSearchDeviceList &&
    getSearchDeviceList.filter(
      (deviceData) => devices.includes(deviceData.device.targetUniqueId) && deviceData.device.isDeviceOffline
    ).length > 0;

  const CriteriaItem = ({ isSelected, onClick, validationIcon, children }) => (
    <Row className={`${styles["criteria_Item"]} ${isSelected && styles["selected_Job_Criteria"]}`} onClick={onClick}>
      <Col span={24} className={styles["steps_Wrap"]}>
        <Col span={3} className={styles["validation_Icon"]}>
          {validationIcon}
        </Col>
        <Col span={20} className={styles["criteria_Details"]}>
          {children}
        </Col>
      </Col>
    </Row>
  );

  const getValidationIcon = (condition) => {
    if (condition.valid) {
      return <ValidDataNotification />;
    } else if (condition.current) {
      return <CurrentDataNotification />;
    } else if (condition.pending) {
      return <PendingDataNotification />;
    } else if (condition.invalid) {
      return <InValidDataNotification />;
    }
  };

  const fileUrlIsNotEmpty =
    buildType === BUILD_URL && checkNotUndefined(fileUrl) && fileUrl !== "https://" && !checkNull(fileUrl);
  const isValidStep3 =
    buildType === BUILD_URL && testJobDetail?.deviceServiceFarm !== DEVICE_EXECUTION_FARM.XPRESS && platform === ANDROID
      ? fileUrlIsNotEmpty && !checkNull(appActivity) && appActivity !== ""
      : fileUrlIsNotEmpty;

  return (
    <div className={styles["job_Criteria_Wrap"]}>
      {[
        {
          step: SCHEDULE_TEST_JOB.STEP1,
          condition: {
            valid: testPlan?.name,
            current: selectedTestJobCriteria === SCHEDULE_TEST_JOB.STEP1,
            invalid: testJobErrorDetail[SCHEDULE_TEST_JOB.STEP1],
          },
          content: (
            <>
              <div>Test Plan : {testPlan?.name || ""}</div>
              <Col className={styles["job_Criteria_Col"]}>
                Test Data set : {testDataSet?.name && <span className={styles["test_data"]}>{testDataSet.name}</span>}
              </Col>
            </>
          ),
        },
        {
          step: SCHEDULE_TEST_JOB.STEP2,
          condition: {
            valid: !isEmpty(devices),
            current: selectedTestJobCriteria === SCHEDULE_TEST_JOB.STEP2,
            pending: selectedTestJobCriteria < SCHEDULE_TEST_JOB.STEP2,
            invalid: testJobErrorDetail[SCHEDULE_TEST_JOB.STEP2],
          },
          content: (
            <>
              <div>Execution Farm : {deviceServiceFarm || ""}</div>
              <div>
                Execution Device Count :
                {deviceInfoNotification > 0 ? deviceInfoNotification : testJobDetailDevicesCount || devices?.length}
              </div>
              {deviceInfoNotification !== attachedDevices ||
                (isDeviceOffline && (
                  <Col>
                    <Tooltip color={TOOLTIP_BG_COLOR} title="Some Selected Devices are Offline">
                      <InfoDataNotification />
                    </Tooltip>
                  </Col>
                ))}
            </>
          ),
        },
        {
          step: SCHEDULE_TEST_JOB.STEP3,
          condition: {
            valid:
              (deviceServiceFarm === DEVICE_EXECUTION_FARM.XPRESS && buildType === NONE) ||
              (buildType === APP_MANAGER &&
                buildDetail?.id !== 0 &&
                (buildDetail?.displayName !== undefined || isUseLatestBuild !== 0)) ||
              isValidStep3,
            current: selectedTestJobCriteria === SCHEDULE_TEST_JOB.STEP3,
            pending: selectedTestJobCriteria < SCHEDULE_TEST_JOB.STEP3,
            invalid: testJobErrorDetail[SCHEDULE_TEST_JOB.STEP3],
          },
          content: (
            <>
              <Col> Select Build : {buildType !== NONE ? buildType : ""}</Col>
              {buildType === NONE && <Col>Use Installed version on Device</Col>}
              {buildType === APP_MANAGER && isUseLatestBuild === 0 && buildDetail?.buildVersion && (
                <>
                  <Col>Name : {buildDetail?.displayName || "N/A"}</Col>
                  <Col>Version : {buildDetail?.buildVersion}</Col>
                </>
              )}
              {buildType === APP_MANAGER && isUseLatestBuild === 1 && <Col>Latest Build</Col>}
              {buildType === USE_LATEST_BUILD && <Col>Use Latest Build from App Manager</Col>}
              {buildType === BUILD_URL && fileUrl && (
                <>
                  <Col className="display__flex">
                    <span className={styles["build_Url_Label"]}>Build URL :</span>
                    <div className={styles["build_Url_Value"]}>{fileUrl ? fileUrl : ""}</div>
                  </Col>
                  {deviceServiceFarm !== DEVICE_EXECUTION_FARM.XPRESS && appActivity && platform === ANDROID && (
                    <Col className="display__flex">
                      <span className={styles["build_Url_Label"]}>App Activity :</span>
                      <div className={styles["build_Url_Value"]}>{appActivity}</div>
                    </Col>
                  )}
                </>
              )}
            </>
          ),
        },
        {
          step: SCHEDULE_TEST_JOB.STEP4,
          condition: {
            valid: testJobErrorDetail[SCHEDULE_TEST_JOB.STEP4] === false,
            pending: selectedTestJobCriteria < SCHEDULE_TEST_JOB.STEP3,
            invalid: testJobErrorDetail[SCHEDULE_TEST_JOB.STEP4] === true,
          },
          content: (
            <>
              <div>Execution Mode : {executionType ? executionSettingForDisplay(executionType) : ""}</div>
              <Col> Capture video : {isCaptureVideo ? isCaptureVideo : ""}</Col>
              <Col> Capture Logs : {isLogs ? isLogs : ""}</Col>
              {accessibilityReport !== 0 && <Col> Accessibility Report : {accessibilityReport}</Col>}
              {localeReport !== 0 && <Col>  Localization Report :{localeReport}</Col>}
              {testJobDetail && updateResultToTestRail === 1 && (
                <Col> Update Results To TestRail : {updateResultToTestRail}</Col>
              )}
              {testRailTestSuiteIds && !isEmpty(testRailTestSuiteIds) && (
                <Col>
                  Test Rail Suites:
                  {getTestRailSuitesByTestPlanID &&
                    getTestRailSuitesByTestPlanID
                      .filter((i) => testRailTestSuiteIds?.includes(i.id))
                      ?.map((j, i) => (
                        <span key={i} className={styles["test_data"]}>
                          {j.name}
                        </span>
                      ))}
                </Col>
              )}
            </>
          ),
        },
      ].map(({ step, condition, content }) => (
        <CriteriaItem
          key={step}
          step={step}
          isSelected={selectedTestJobCriteria === step}
          onClick={() => dispatch(changeSelectedTestJobCriteria(step))}
          validationIcon={getValidationIcon(condition)}
        >
          {content}
        </CriteriaItem>
      ))}
    </div>
  );
};

export default TestJobCriteriaWrapV2;

const ValidDataNotification = () => {
  return (
    <span className="font-color-lightGreen float-left font-size-16">
      <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
    </span>
  );
};

const InValidDataNotification = () => <img src={INVALID_ICON} alt="Invalid" />;

const CurrentDataNotification = () => <img src={CURRENT_JOB} alt="current" />;

const PendingDataNotification = () => <img src={PENDING_ICON} alt="current" />;

export const InfoDataNotification = () => {
  return (
    <span className="font-color-orange float-left font-size-20">
      <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
    </span>
  );
};
