import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCogs, faPlay, faPlus, faTimesCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Checkbox, Divider, Spin } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import {
  ACTION_VIEW,
  ADD_BUILDS,
  APP_BUILD_UPLOAD_STATUS,
  CUSTOM,
  MY_BUILDS,
  OWNED,
  PRIVATE,
  PUBLIC,
} from "../../../Constants";
import {
  getUniqueId,
  isNotNullAndNotEmptyAndNotUndefined,
  isNullOrUndefinedOrEmpty,
  isSelectedAllIds,
  removeSelectedIds,
  showBuildTags,
} from "../../../Util";
import { setPreviousSearchValue } from "../../../actions/CommonActions";
import {
  changeAppBUildListPageNumber,
  changeAppManagerTab,
  deleteAppBuild,
  fetchAppBuildData,
  getAllBuildDetails,
  getUploadBuildOption,
  saveBuildDetailsRights,
} from "../../../actions/Recording";
import DeviceListInstallBuild from "../../BuildManagement/DeviceListInstallBuild";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import CommonHeaderV2 from "../../CommonComponents/CommonHeaderV2/CommonHeaderV2";
import SelectAllBoxV2 from "../../CommonComponents/SelectAllBoxV2/SelectAllBoxV2";
import PaginationComponentV2 from "../../CoreComponents/PaginationComponent/PaginationComponentV2";
import PermissionDrawerV2 from "../../CoreComponents/PermissionDrawer/PermissionDrawerV2/PermissionDrawerV2";
import TableComponentV2 from "../../CoreComponents/TableComponent/TableComponentV2";
import AppBuildListActionColumnV2 from "./AppBuildListColumnsV2/AppBuildListActionColumnV2";
import BuildDetailsColumnsV2 from "./AppBuildListColumnsV2/BuildDetailsColumnsV2";
import BuildHistoryColumnV2 from "./AppBuildListColumnsV2/BuildHistoryColumnV2";
import AppBuildListHeaderV2 from "./AppBuildListHeaderV2";
import styles from "./AppBuildListV2.module.scss";

library.add(faTimesCircle);
library.add(faTrash);
library.add(faPlay);
library.add(faCogs);
library.add(faPlus);

const AppBuildListV2 = (props) => {
  const { setBuildId, projectId, executionTab, orgId, testJobDetail, changeTestJobData, stepType, devicePushBuild } =
    props;
  const dispatch = useDispatch();
  const {
    getUploadBuildOptions,
    allAppBuildDetails,
    getAWSProjectsList,
    loadingAppManagerBuild,
    pushBuildModalVisible,
  } = useSelector((state) => state.RecordingReducer);

  const [filter, setFilter] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectAllBuild, setSelectAllBuild] = useState([]);
  const [members, setMembers] = useState([]);
  const [previousPermission, setPreviousPermission] = useState([]);
  const [permissionBuildDetails, setPermissionBuildDetails] = useState(null);
  const [openPermissionDrawer, setOpenPermissionDrawer] = useState(false);
  const [rights, setRights] = useState({ id: OWNED, value: MY_BUILDS });
  const [openDeleteAppBuildModal, setOpenDeleteAppBuildModal] = useState({ visible: false, data: {} });

  const intervalRef = useRef(null);

  useEffect(() => {
    if (permissionBuildDetails?.buildDetailRights?.data) {
      setMembers([...permissionBuildDetails?.buildDetailRights?.data]);
    }
  }, [permissionBuildDetails]);

  useEffect(() => {
    if (executionTab) {
      dispatch(getAllBuildDetails(false, searchKeyword, projectId, "DONE", "", stepType, executionTab ? 1 : 0));
      setFilter("DONE");
    } else {
      dispatch(getAllBuildDetails(false, searchKeyword, projectId, filter, rights?.id, stepType));
      setFilter("");
    }
    setSearchKeyword(null);
    dispatch(setPreviousSearchValue(searchKeyword));
    dispatch(getUploadBuildOption(projectId));
    setSelectAllBuild([]);
  }, [projectId]);


  useEffect(() => {
    if (
      allAppBuildDetails &&
      !isEmpty(allAppBuildDetails) &&
      !pushBuildModalVisible &&
      isNullOrUndefinedOrEmpty(searchKeyword)
    ) {
      intervalRef.current = setInterval(
        () =>
          dispatch(
            getAllBuildDetails(
              false,
              searchKeyword,
              projectId,
              executionTab ? "DONE" : "",
              executionTab ? "" : rights?.id,
              stepType,
              executionTab ? 1 : 0,
              true
            )
          ),
        4000
      );
      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [allAppBuildDetails, pushBuildModalVisible]);

  useEffect(() => {
    if (!pushBuildModalVisible) {
      dispatch(
        getAllBuildDetails(
          false,
          searchKeyword,
          projectId,
          executionTab ? "DONE" : "",
          executionTab ? "" : rights?.id,
          stepType,
          executionTab ? 1 : 0,
          true
        )
      );
    }
  }, [pushBuildModalVisible]);

  const fetchAppBuildDataById = (buildId) => {
    const appBuild = allAppBuildDetails && allAppBuildDetails.data.find((build) => build.id === buildId);

    let project =
      isNotNullAndNotEmptyAndNotUndefined(appBuild?.awsDeviceProjectId) &&
      !isEmpty(getAWSProjectsList) &&
      getAWSProjectsList.find((i) => i.id === appBuild.awsDeviceProjectId);

    const appBuildDataByID = {
      buildId: appBuild.id,
      buildVersion: appBuild?.buildVersion,
      displayName: appBuild?.displayName,
      buildDate: moment(appBuild.buildDate),
      buildTime: moment(appBuild.buildDate),
      tag: appBuild?.buildTags,
      buildType: appBuild?.buildType,
      buildName: appBuild?.fileName,
      uploadToCloud: isNotNullAndNotEmptyAndNotUndefined(appBuild.cloudProvider) ? appBuild?.cloudProvider : null,
      awsDeviceFarmProject: project?.id,
      existingBuild: {
        uid: getUniqueId(),
        name: appBuild.fileName,
        url: appBuild.fileUrl,
      },
      isPushToCloud: appBuild?.isPushToCloud,
      appActivity: appBuild?.appActivity,
    };
    dispatch(fetchAppBuildData(appBuildDataByID));
    setBuildId(buildId);
    dispatch(changeAppManagerTab(ADD_BUILDS));
  };

  const checkIsPendingOrInProgress =
    allAppBuildDetails &&
    allAppBuildDetails?.data?.every((i) =>
      [APP_BUILD_UPLOAD_STATUS.IN_PROGRESS, APP_BUILD_UPLOAD_STATUS.PENDING]?.includes(i?.uploadStatus)
    );

  const isNotIncludePendingAndInProgress =
    allAppBuildDetails &&
    allAppBuildDetails?.data?.filter(
      (j) => ![APP_BUILD_UPLOAD_STATUS.IN_PROGRESS, APP_BUILD_UPLOAD_STATUS.PENDING].includes(j?.uploadStatus)
    );

  const selectedId = selectAllBuild && selectAllBuild?.map((i) => i?.id);

  const deleteConfirm = (name, selectedBuild) => {
    setOpenDeleteAppBuildModal({ visible: true, data: selectedBuild });
  };

  const showCheckBoxInTableHeader = useMemo(
    () =>
      isEmpty(selectAllBuild) &&
      !executionTab &&
      !devicePushBuild &&
      !isEmpty(isNotIncludePendingAndInProgress) &&
      isNotIncludePendingAndInProgress?.some((i) => i?.allowDelete === ACTION_VIEW),
    [selectAllBuild, executionTab, isNotIncludePendingAndInProgress, devicePushBuild]
  );
  const columns = [
    {
      title: (
        <div className={showCheckBoxInTableHeader ? styles["checkBox-in-header"] : styles["table-header"]}>
          {showCheckBoxInTableHeader && (
            <span className={styles["header-checkBox"]}>
              <Checkbox
                className={"selection-checkBox mr-26"}
                onChange={(isChecked) => {
                  if (isChecked) {
                    setSelectAllBuild([
                      ...selectAllBuild,
                      ...isNotIncludePendingAndInProgress
                        ?.filter((i) => i.allowDelete === ACTION_VIEW && !selectedId?.includes(i?.id) && i?.id)
                        ?.map(
                          (i) =>
                            i.allowDelete === ACTION_VIEW && {
                              id: i.id,
                              folderPath: i.folderPath,
                              fileKey: i.fileKey,
                              awsDeviceFarmProjectId: i.awsDeviceProjectId,
                            }
                        ),
                    ]);
                  } else {
                    setSelectAllBuild(removeSelectedIds(selectedId, isNotIncludePendingAndInProgress));
                  }
                }}
              />
            </span>
          )}
          Build Details
        </div>
      ),
      dataIndex: "buildDetails",
    },
    {
      title: "Tags",
      dataIndex: "tags",
    },
    {
      title: "Build History",
      dataIndex: "buildHistory",
    },
    {
      title: `${!executionTab && !devicePushBuild ? "Actions" : ""}`,
      dataIndex: "action",
      align: "right",
    },
  ];

  const setPermissionData = () => {
    if (permissionBuildDetails?.isPrivate && !isEmpty(permissionBuildDetails?.buildDetailRights?.data)) {
      setPreviousPermission(permissionBuildDetails?.buildDetailRights?.data);
      setMembers([...permissionBuildDetails?.buildDetailRights?.data]);
    } else {
      setMembers([]);
      setPreviousPermission([]);
    }
  };

  const savePermission = (Value, buildData, customData) => {
    const result =
      customData &&
      customData?.map((i) => ({
        userId: i?.userId,
        isEdit: i?.isEdit,
        isDelete: i?.isDelete,
        isView: i?.isView,
        isAdmin: i?.isAdmin,
        shouldAddOrRemove: i?.shouldAddOrRemove,
      }));
    let buildRightsObj = {
      buildDetail: { id: buildData?.id },
      isPrivate: Value === PRIVATE || Value === CUSTOM ? 1 : 0,
      listOfMembers: Value === CUSTOM && !isEmpty(result) ? result : [],
    };
    dispatch(saveBuildDetailsRights(buildRightsObj, searchKeyword, projectId, filter, rights?.id));
    setOpenPermissionDrawer(false);
  };

  const handleActionChange = (actionName, buildData) => {
    setPermissionBuildDetails(() => ({ ...buildData }));
    switch (actionName) {
      case PRIVATE:
        savePermission(PRIVATE, buildData);
        break;
      case PUBLIC:
        savePermission(PUBLIC, buildData);
        break;
      case CUSTOM:
        setOpenPermissionDrawer(true);
        break;
      default:
        break;
    }
  };

  const testJobDetails =
    allAppBuildDetails &&
    allAppBuildDetails?.data?.map((row) => ({
      key: row?.id,
      buildDetails: (
        <BuildDetailsColumnsV2
          row={row}
          changeTestJobData={changeTestJobData}
          executionTab={executionTab}
          testJobDetail={testJobDetail}
          setSelectAllBuild={setSelectAllBuild}
          selectAllBuild={selectAllBuild}
          fetchAppBuildDataById={fetchAppBuildDataById}
          devicePushBuild={devicePushBuild}
        />
      ),
      buildHistory: <BuildHistoryColumnV2 row={row} />,
      tags: <div className="display-flex">{row?.buildTags && showBuildTags(row?.buildTags?.split(","))}</div>,

      action: !executionTab && !devicePushBuild && (
        <AppBuildListActionColumnV2
          row={row}
          projectId={projectId}
          fetchAppBuildDataById={fetchAppBuildDataById}
          deleteConfirm={deleteConfirm}
          handleActionChange={handleActionChange}
        />
      ),
    }));
  return (
    <>
      <Spin spinning={loadingAppManagerBuild} tip="Loading" className="spinner-center">
        {!executionTab && !devicePushBuild && (
          <CommonHeaderV2
            headerText={"App Builds"}
            label={"Upload Build"}
            handleOk={() => {
              dispatch(changeAppManagerTab(ADD_BUILDS));
            }}
          />
        )}
        <AppBuildListHeaderV2
          executionTab={executionTab}
          projectId={projectId}
          searchKeyword={searchKeyword}
          stepType={stepType}
          setSearchKeyword={setSearchKeyword}
          rights={rights}
          setRights={setRights}
          devicePushBuild={devicePushBuild}
        />
        <Divider className="m-0" />
        <div className="table_Details_scroll">
          <div className="table_Details__wrapper">
            {!executionTab && !devicePushBuild && !isEmpty(selectAllBuild) && (
              <div className={styles["select--all-box"]}>
                <SelectAllBoxV2
                  isChecked={isSelectedAllIds(
                    selectedId,
                    isNotIncludePendingAndInProgress?.filter((i) => i.allowDelete === ACTION_VIEW)
                  )}
                  isDisable={isEmpty(allAppBuildDetails?.data) || checkIsPendingOrInProgress}
                  selectAllIds={selectAllBuild}
                  handleChangeCheck={(isChecked) => {
                    if (isChecked) {
                      setSelectAllBuild([
                        ...selectAllBuild,
                        ...isNotIncludePendingAndInProgress
                          ?.filter((i) => i.allowDelete === ACTION_VIEW && !selectedId?.includes(i?.id) && i?.id)
                          ?.map(
                            (i) =>
                              i.allowDelete === ACTION_VIEW && {
                                id: i.id,
                                folderPath: i.folderPath,
                                fileKey: i.fileKey,
                                awsDeviceFarmProjectId: i.awsDeviceProjectId,
                              }
                          ),
                      ]);
                    } else {
                      setSelectAllBuild(removeSelectedIds(selectedId, isNotIncludePendingAndInProgress));
                    }
                  }}
                  deleteConfirm={deleteConfirm}
                />
              </div>
            )}
            {executionTab && (
              <div className={styles["execution_Job_Text"]}>
                <span className={styles["available_Build_Text"]}>Available Builds</span>
                <div className={styles["checkBox_Text"]}>
                  <Checkbox
                    checked={testJobDetail?.isUseLatestBuild === 1}
                    onChange={(e) => {
                      dispatch(
                        changeTestJobData({
                          ...testJobDetail,
                          isUseLatestBuild: e.target.checked ? 1 : 0,
                          buildDetail: undefined,
                        })
                      );
                      setSelectAllBuild([]);
                    }}
                    className="selection-checkBox"
                  />
                  <label>Always pick the latest Build</label>
                </div>
                <div className={styles["checkBox_Text"]}>
                  <Checkbox
                    value={testJobDetail?.unInstallBUildBeforeInstall}
                    onChange={(e) => {
                      dispatch(
                        changeTestJobData({
                          ...testJobDetail,
                          unInstallBUildBeforeInstall: e.target.checked ? 1 : 0,
                        })
                      );
                    }}
                    className="selection-checkBox"
                  />
                  <label>Uninstall the Build before Install</label>
                </div>
              </div>
            )}
            <TableComponentV2
              columns={columns}
              data={testJobDetails}
              selected={selectedId}
              className={`table__wrapper ${
                !isEmpty(selectAllBuild) && !executionTab && !devicePushBuild && " hide_column_name"
              }`}
            />
            <div className="pagination_Section">
              {" "}
              {!isEmpty(allAppBuildDetails) && (
                <PaginationComponentV2
                  currentPageItems={allAppBuildDetails?.pageItems}
                  totalItems={allAppBuildDetails.totalItems}
                  currentPage={allAppBuildDetails?.currentPage}
                  totalPage={allAppBuildDetails.totalCount}
                  callPaginationAPI={(pageNumber) => {
                    if (allAppBuildDetails?.currentPage !== pageNumber) {
                      dispatch(changeAppBUildListPageNumber(pageNumber));
                      dispatch(
                        getAllBuildDetails(false, searchKeyword, projectId, filter, executionTab ? "" : rights?.id)
                      );
                    }
                  }}
                />
              )}
            </div>{" "}
          </div>
        </div>
        <DeviceListInstallBuild projectId={projectId} orgId={orgId} />
        <PermissionDrawerV2
          openPermissionDrawer={openPermissionDrawer}
          onClose={() => setOpenPermissionDrawer(false)}
          setOpenPermissionDrawer={setOpenPermissionDrawer}
          members={members}
          permissionData={permissionBuildDetails}
          setMembers={setMembers}
          setPermissionData={setPermissionData}
          savePermission={savePermission}
          previousPermission={previousPermission}
          ownerEmailId={permissionBuildDetails?.uploadedByEmail}
          notShowExecutionCheck={true}
          drawerTitle={"App Build"}
        />
        <CommonConfirmationModalV2
          handleOk={() => {
            dispatch(
              deleteAppBuild(
                openDeleteAppBuildModal?.data || selectAllBuild,
                setSelectAllBuild,
                rights?.id,
                searchKeyword
              )
            );
            setOpenDeleteAppBuildModal({ visible: false, data: {} });
          }}
          handleCancel={() => setOpenDeleteAppBuildModal({ visible: false, data: {} })}
          modalOpen={openDeleteAppBuildModal?.visible}
          modalTitle={"Confirm Deletion"}
          modalContent={`Are you sure you want to delete ${
            selectAllBuild && selectAllBuild.length > 1 ? "these items?" : "this item?"
          } This action cannot be undone.`}
          okText={"Delete"}
          isDeleteModal={true}
        />
      </Spin>
    </>
  );
};

export default AppBuildListV2;
