import _ from "lodash";
import {
  ADD_APPLICATION_STRINGS_FORM_VISIBLE,
  ADD_APPLICATION_STRING_LOCALE_AND_VALUE,
  ADD_CONDITION_TO_STEP,
  ADD_DEVICE_STRINGS_FORM_VISIBLE,
  ADD_DEVICE_STRING_LOCALE_AND_VALUE,
  ADD_ELEMENT_INTO_STEP,
  APPLICATION_DRAWER_VISIBLE,
  APPLICATION_STRING_CURRENT_TAB,
  APPLICATION_STRING_LOADER,
  APPLICATION_STRING_SEARCH_KEYWORD,
  CAPTURE_EXPANDED_ELEMENTS,
  CAPTURE_EXPANDED_STEPS,
  CHANGE_ADVANCE_ELEMENT_SELECTOR_DRAWER_VISIBILITY,
  CHANGE_AI_SCENARIO_INSTRUCTIONS,
  CHANGE_AI_SCENARIO_OBJECTIVE,
  CHANGE_APPLICATION_STRING_NAME,
  CHANGE_APPLICATION_STRING_VALUE,
  CHANGE_DEVICE_STRING_NAME,
  CHANGE_DEVICE_STRING_VALUE,
  CHANGE_REC_TEST_STEP_ACTION_SELECTED_ELEMENT,
  CHANGE_REC_TEST_STEP_ELEMENT_XPATH_ACTIVE,
  CHANGE_REC_TEST_STEP_NAME,
  CHANGE_SEARCH_VALUE_FOR_IMPORT_REC_TEST_STEP_OR_ACTION_TREE,
  CHANGE_SELECTED_TEST_BLOCKS,
  CHANGE_SELECTOR_TYPE,
  CHANGE_SHOW_TEST_DATA_VISIBILITY,
  CHANGE_SWIPE_PERCENTAGE,
  CLEAR_EXPANDED_ELEMENTS,
  CLEAR_EXPANDED_STEPS,
  CLEAR_UNSAVED_STEP,
  CLOSE_PREVIEW_SETTING_DRAWER,
  DELETE_APPLICATION_STRING_LOCALE,
  DELETE_DEVICE_STRING_LOCALE,
  DEVICE_DRAWER_VISIBLE,
  DEVICE_STRING_LOADER,
  EDIT_REC_TEST_STEP,
  EDIT_REC_TEST_STEP_ELEMENT,
  ELEMENT_ACTION,
  ERROR_TYPE,
  ElementType,
  GET_APPLICATION_STRINGS,
  GET_DEVICE_STRINGS,
  GET_LIST_OF_LOCALS,
  HANDLE_SELECT_REC_TEST_STEP_ACTION,
  HIDE_DELETE_LOOP_CONDITION_MODAL_VISIBLE,
  HIDE_DELETE_REC_ELEMENT_CONFIRMATION_MODAL,
  HIDE_DELETE_REC_TEST_STEP_CONFIRMATION_MODAL,
  HIDE_DELETE_REC_TEST_STEP_PRE_CONDITION_CONFIRMATION_MODAL,
  HIDE_AI_GENERATE_STEPS_FROM_REC_TEST_STEP,
  HIDE_IMPORT_REC_TEST_STEP_MODAL,
  HIDE_REC_TEST_STEP_ACTION_ELEMENT_DETAIL_DRAWER,
  IS_DELETE_REC_TEST_STEP_REF,
  LOAD_EDIT_APPLICATION_STRING_DETAILS,
  LOAD_EDIT_DEVICE_STRING_DETAILS,
  MINIMUM_SWIPE_VALUE,
  MULTI_COLUMN_VIEW_DATA_LOADING,
  MULTI_COLUMN_VIEW_TEST_BLOCK_DATA_LOADING,
  OPEN_PREVIEW_SETTING_DRAWER,
  REC_TEST_STEP_INNER_DETAILS_LOADING,
  REMOVE_CONDITION_FROM_REC_TEST_STEP,
  REMOVE_ELEMENT_INTO_STEP,
  REMOVE_LOOP_CONDITION_FROM_REC_TEST_STEP,
  REQUESTED_CLEAR_PREVIEW,
  REQUESTED_IMPORT_REC_TEST_STEP_ACTION,
  REQUESTED_PERFORM_REC_TEST_STEP_ACTION,
  REQUEST_CLEAR_PREVIEW,
  REQUEST_IMPORT_REC_TEST_STEP_ACTION,
  REQUEST_PERFORM_REC_TEST_STEP_ACTION,
  RESET_APPLICATION_STRING_DATA,
  RESET_DEVICE_STRING_DATA,
  RESET_EDIT_STEP,
  RESET_SAVED_REC_TEST_STEP_ACTION,
  RESET_STATE,
  RESET_TEST_DATA_CONTENT,
  RESET_UNSAVED_ELEMENTS,
  SCENARIO_GENERATE_REQUEST_START,
  SCENARIO_GENERATED_STEPS,
  SELECTED_REC_TEST_STEP_UPDATED_STEP_NAME,
  SELECTOR_TYPE,
  SELECT_APPLICATION_STRING_LOCALE,
  SELECT_CHILD_ELEMENT_PURPOSE,
  SELECT_DEVICE_STRING_LOCALE,
  SET_APP_LOCALE_FOR_PREVIEW,
  SET_ELEMENT_DETAILS,
  SET_SELECTED_REC_STEP_TAB_KEY,
  SET_TEST_DATA_SET_PROFILE_FOR_PREVIEW,
  SHOW_DELETE_LOOP_CONDITION_MODAL_VISIBLE,
  SHOW_DELETE_REC_ELEMENT_CONFIRMATION_MODAL,
  SHOW_DELETE_REC_TEST_STEP_CONFIRMATION_MODAL,
  SHOW_DELETE_REC_TEST_STEP_PRE_CONDITION_CONFIRMATION_MODAL,
  SHOW_GENERATE_STEPS_FROM_AI_MODAL,
  SHOW_IMPORT_REC_TEST_STEP_MODAL,
  SHOW_REC_TEST_STEP_ACTION_ELEMENT_DETAIL_DRAWER,
  STEP,
  STEP_ITEMS,
  SUCCESS_STATUS,
  SWIPE,
  SWIPE_DIRECTION,
  SWIPE_OVER_ELEMENT,
  TAG_LIST_RECORD_SCENARIO,
  TEST_DATA_LIST,
  TEST_DATA_LIST_REQUEST,
  TEST_DATA_LIST_REQUESTED,
  TEST_STEP_BLOCKS,
  UPDATE_ACTION_VALUE,
  UPDATE_INSTALLED_APP_ACTIVITY_LIST,
  UPDATE_INSTALLED_APP_LIST,
  UPDATE_REFERENCE_STEP_TEST_DATA,
  UPDATE_SELECTED_REC_TEST_STEP,
  UPDATE_STEP_TYPE,
  UPDATE_UNSAVED_REC_TEST_STEP,
  UPDATE_UNSAVED_REC_TEST_STEP_AND_REC_ELEMENT,
  VISIBLE_GENERAL_SETTINGS_DRAWER,
  VISIBLE_IDENTIFICATION_RULES_DRAWER,
  VISIBLE_TEST_DATA_VISIBILITY,
  XpathGenerateMethod,
  splitValue,
  verifyApplicationStrings,
  verifyDeviceStrings,
  verifyElementTextValues,
  SCENARIO_GENERATE_REQUEST_STOP,
  CHANGE_AI_MODEL_TYPE,
  SHOW_AI_GENERATE_STEPS_ROW,
  HIDE_AI_GENERATE_STEPS_ROW,
  CHANGE_SCENARIO_GENERATE_STEP_TYPE,
  AI_SCENARIO_GENERATE_STEP_TYPE,
  CHANGE_PARENT_REC_TEST_STEP_FOR_AI_GENERATED_STEP,
  UPDATE_LAST_GENERATED_STEP_INDEX,
  TEST_SCENARIOS,
  SHOW_GENERATE_STEPS_DEBUG_FROM_AI_MODAL,
  HIDE_GENERATE_STEPS_DEBUG_FROM_AI_MODAL,
  SCENARIO_GENERATE_DEBUG_REQUEST_STOP,
  SCENARIO_GENERATE_DEBUG_REQUEST_START,
  defaultModelInstructions,
  ABORT_GENERATE_SCENARIO_STEPS,
  UPDATE_AI_STEP_OBJECTIVE,
  DEVICE_INTERACTION_SELECT_MODE,
  SET_ACCESSIBILITY_REPORT,
  SET_LOCAL_REPORT,
  VISIBLE_GENERATE_STEPS_FROM_AI_MODAL,
  HIDE_GENERATE_STEPS_FROM_AI_MODAL,
} from "../Constants";
import {
  checkActionHaveRelativePointOption,
  cloneArray,
  getNewRecElement,
  getParentRecElement,
  getRecElement,
  getUnSavedRecElement,
  removeRecElements,
  resetElementProperties,
  showNotification,
  suggestedStepName,
  updateRecElement,
  updateUnSavedRecElement,
} from "../Util";
import {
  AllChildRecTestStepOrActionsId,
  getEmptyVerificationRow,
  insertIntoRecElement,
  insertIntoRecTestStepRecTestProperty,
} from "./RecTestStepReducer";
import { string } from "prop-types";
let defaultState = {
  // New Variable
  installedApps: [],
  unSavedRecStepTag: undefined,
  unSavedRecElementGuid: undefined,
  stepType: undefined,
  selectedAppActivities: [],
  unSavedRecTestStep: undefined,
  selectedTagsLists: [],
  selectedRecTestStepIdForDelete: undefined,
  isSelectedRecTestStepForDelete: false, // Use in Delete Rec Test Step
  selectedRecTestStepTagForDelete: undefined,
  selectedRecTestStepAssocForDelete: undefined,
  deleteRecTestStepModalVisible: false,
  isDelateRecTestStepRef: 0,
  deleteRecTestStepPreconditionModalVisible: false,
  selectedRecTestStepPropertyIdForDelete: undefined,
  recTestStepActionSelectedElement: undefined,
  elementListByServer: [],
  deviceSelectedElementAttributes: undefined,
  requestingPerformRecTestStepAction: false,
  fetchingTestDataList: false,
  showTestDataVisibility: {},
  selectedRecTestStepTag: undefined,
  selectedVerificationGuid: undefined,
  selectedTabKey: {},
  //New
  selectedRecTestStepOrActionForImport: [],
  selectedRecTestStepOrActionForImportObject: [],
  requestData: [],
  isAdvanceElementSelectorDrawerVisible: false,
  searchValueForImportRecTestStepAction: undefined,
  importRecTestStepModalVisible: false,
  disableRecTestStepForImport: false,
  disableRecTestStepActionsForImport: false,
  importRecTestScenarioStepActionList: [],
  checkedTestBlockImportStep: [],
  requestingForImportRecTestStepOrAction: false,
  multiColumnViewDataLoading: false,
  recTestStepDetailsLoading: false,
  multiColumnViewTestBlockDataLoading: false,
  requestingForClearingPreview: false,
  recTestStepActionElementDetailDrawerData: undefined,
  recTestStepActionElementDetailDrawerVisible: false,
  selectedElementGuidForDetail: undefined,
  expandedStepTags: [],
  expandedStepAssocIds: [],
  expandedElementGuid: [],
  deleteLoopCondition: false,
  getApplicationString: [],
  getDeviceString: [],
  showApplicationDrawerVisible: false,
  getListOfLocales: [],
  applicationStringLoader: false,
  deviceStringLoader: false,
  totalTestDataCounts: 0,
  currentTestDataPage: 1,
  totalTestDataPageCount: 0,
  testDataPageItems: "",
  visibleGeneralSettingDrawer: false,
  visibleIdentificationRuleDrawer: false,
  actionForOpenDrawer: undefined,
  editApplicationStringData: undefined,
  applicationStringName: undefined,
  applicationStringIsImport: undefined,
  actionForGeneralActionDrawer: undefined,
  tagForOpenDrawer: undefined,
  tagForOpenRulesDrawer: undefined,
  guidForOpenApplicationDrawer: undefined,
  importApplicationStringsData: undefined,
  requestForUploadingImportApplicationStringsData: false,
  visibleImportApplicationStringForm: false,
  getApplicationStringPageCount: 1,
  getApplicationStringCurrentCount: 1,
  getApplicationStringCurrentTab: "ALL",
  getApplicationStringSearchKeyword: "",
  getDeviceStringPageCount: 1,
  getDeviceStringCurrentCount: 1,
  visibleAddDeviceStringForm: false,
  editDeviceStringData: undefined,
  deviceStringName: undefined,
  deviceStringValue: undefined,
  selectLocaleOfDeviceString: undefined,
  showDeviceDrawerVisible: false,
  guidForOpenDeviceDrawer: undefined,
  previewTestDataSetId: undefined,
  showGenerateStepsFromAiModal: false,
  scenarioObjective: undefined,
  scenarioGenerateSteps: [],
  lastAIGeneratedStepIndex: 0,
  scenarioGenerateRequestLoading: false,
  scenarioGenerateDebugRequestLoading: false,
  scenarioGenerateInstructions: defaultModelInstructions["gpt-4o-2024-05-13"],
  selectedModelType: "OpenAI",
  isShowAIGenerateStepsRow: false,
  scenarioGenerateStepType: false,
  aiGenerationsFor: TEST_SCENARIOS,
  selectedParentStepforAIGenerateStep: undefined,
  scenarioObject: {},
  isLoadingForAIStep: null,
  isLoadingForAllStepsIds: [],
  selectedRecTestStepOfPreCondition: undefined,
  selectedRecTestStepForLoopCondition: undefined,
  accessibilityReportValue: 0,
  localReportValue: 0,
  visibleGenerateStepsAiModal: false,
  selectedStepForAIGenerateStep: null,
  visibleAIGenerateRecTestStep: [],
};
export default function RecTestStepCommonViewReducer(state = defaultState, action) {
  let unSavedRecTestStep = undefined;
  let recElement = undefined;
  let recElements = [];
  let editDeviceStringData = [];
  switch (action.type) {
    case EDIT_REC_TEST_STEP:
      return {
        ...state,
        unSavedRecStepTag: action.recTestStepTag,
      };
    case EDIT_REC_TEST_STEP_ELEMENT:
      return {
        ...state,
        unSavedRecStepTag: action.recTestStepTag,
        unSavedRecElementGuid: action.recElementGuid,
      };
    case RESET_EDIT_STEP:
      return {
        ...state,
        unSavedRecStepTag: undefined,
        unSavedRecElementGuid: undefined,
        unSavedRecTestStep: undefined,
        recTestStepActionSelectedElement: undefined,
      };
    case RESET_TEST_DATA_CONTENT:
      state.showTestDataContent = action.unSavedRecTestStep["recTestStepProperty"].previous_showTestDataContent;
      state.previous_TestBlockStepName = undefined;
      return {
        ...state,
      };
    case UPDATE_UNSAVED_REC_TEST_STEP:
      let unSavedRecElementGuid = state.unSavedRecElementGuid;
      if (unSavedRecElementGuid === undefined) {
        let unSavedRecElement = action.unSavedRecTestStep?.recTestStepProperty?.recElements
          ? getUnSavedRecElement(action.unSavedRecTestStep?.recTestStepProperty?.recElements)
          : undefined;
        unSavedRecElementGuid = unSavedRecElement?.guid;
      }
      return {
        ...state,
        unSavedRecTestStep: action.unSavedRecTestStep,
        unSavedRecStepTag: action.unSavedRecTestStep?.tag,
        unSavedRecElementGuid: unSavedRecElementGuid,
      };
    case UPDATE_SELECTED_REC_TEST_STEP:
      return {
        ...state,
        unSavedRecTestStep: action.unSavedRecTestStep,
        selectedRecTestStepTag: action.unSavedRecTestStep?.tag,
      };
    case UPDATE_UNSAVED_REC_TEST_STEP_AND_REC_ELEMENT:
      return {
        ...state,
        unSavedRecTestStep: action.unSavedRecTestStep,
        unSavedRecStepTag: action.unSavedRecTestStep?.tag,
        unSavedRecElementGuid: action.recElementGuid,
      };
    case UPDATE_INSTALLED_APP_LIST:
      let appList = action.response.data;
      let arrInstalledApps = appList.map((app, index) => {
        return { key: index, name: app };
      });
      return {
        ...state,
        installedApps: arrInstalledApps,
      };
    case UPDATE_INSTALLED_APP_ACTIVITY_LIST:
      let appActivityList = action.response.data;
      return {
        ...state,
        selectedAppActivities: appActivityList,
      };
    case UPDATE_ACTION_VALUE:
      unSavedRecTestStep = { ...state.unSavedRecTestStep };
      unSavedRecTestStep["recTestStepProperty"] = {
        ...unSavedRecTestStep["recTestStepProperty"],
        previous_userInputValue: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "userInputValue"),
        previous_uniqueByIndex: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "uniqueByIndex"),
        previous_testData: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "testData"),
        previous_testDataCustomValue: getPreviousStoredValue(
          unSavedRecTestStep["recTestStepProperty"],
          "testDataCustomValue"
        ),
        previous_appendRandomText: getPreviousStoredValue(
          unSavedRecTestStep["recTestStepProperty"],
          "appendRandomText"
        ),
        previous_continueAfterFail: getPreviousStoredValue(
          unSavedRecTestStep["recTestStepProperty"],
          "continueAfterFail"
        ),
        previous_preConditionTitle: getPreviousStoredValue(
          unSavedRecTestStep["recTestStepProperty"],
          "preConditionTitle"
        ),
        previous_stepName: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "stepName"),
        previous_action: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "action"),
        previous_isStepHasPrecondition: getPreviousStoredValue(
          unSavedRecTestStep["recTestStepProperty"],
          "isStepHasPrecondition"
        ),
        previous_loopCount: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "loopCount"),
        previous_ignoreTestPlanTestData: getPreviousStoredValue(
          unSavedRecTestStep["recTestStepProperty"],
          "ignoreTestPlanTestData"
        ),
        previous_packageName: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "packageName"),
        previous_swipeToFindElement: getPreviousStoredValue(
          unSavedRecTestStep["recTestStepProperty"],
          "swipeToFindElement"
        ),
        previous_swipeCountToFindElement: getPreviousStoredValue(
          unSavedRecTestStep["recTestStepProperty"],
          "swipeCountToFindElement"
        ),
        previous_elementMaxLoadTime: getPreviousStoredValue(
          unSavedRecTestStep["recTestStepProperty"],
          "elementMaxLoadTime"
        ),
        previous_retryCount: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "retryCount"),
        previous_isLoopStep: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "isLoopStep"),
        previous_horizontalRelativePoint: getPreviousStoredValue(
          unSavedRecTestStep["recTestStepProperty"],
          "horizontalRelativePoint"
        ),
        previous_verticalRelativePoint: getPreviousStoredValue(
          unSavedRecTestStep["recTestStepProperty"],
          "verticalRelativePoint"
        ),
        previous_showTestDataContent:
          unSavedRecTestStep["recTestStepProperty"].action === ELEMENT_ACTION.SEND_KEYS ? true : false,
        userInputValue: undefined,
        uniqueByIndex: undefined,
        testData: undefined,
        testDataCustomValue: undefined,
        appendRandomText: undefined,
      };
      // Update Action
      unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "action", action.value);
      // Update Suggested Step Name
      if (unSavedRecTestStep.id === undefined) {
        unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
          unSavedRecTestStep,
          "stepName",
          suggestedStepName(unSavedRecTestStep.recTestStepProperty?.recElements, action.value)
        );
      }
      if (checkActionHaveRelativePointOption(action.value)) {
        unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "horizontalRelativePoint", 50);
        unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "verticalRelativePoint", 50);
      }
      if (action.value === SWIPE_OVER_ELEMENT) {
        unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "recSwipeAttributes", {
          direction: "",
          percentage: 50,
        });
      }
      // Update Tap by index
      if (action.value === ELEMENT_ACTION.TAP_BY_INDEX) {
        const parentRefElement = action.elementListByServer?.filter(
          (item) => item.generatedMethod === XpathGenerateMethod.PARENT_REF
        );
        if (parentRefElement?.length > 0) {
          unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
            unSavedRecTestStep,
            "uniqueByIndex",
            parentRefElement[0].currentChildIndex
          );
        }
      }
      return {
        ...state,
        unSavedRecTestStep,
      };
    // case CHANGE_ENTER_TEXT:
    //   unSavedRecTestStep = { ...state.unSavedRecTestStep };
    //   unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "userInputValue", action.value);
    //   return {
    //     ...state,
    //     unSavedRecTestStep,
    //   };
    // case CHANGE_APPEND_RANDOM_TEXT:
    //   unSavedRecTestStep = { ...state.unSavedRecTestStep };
    //   unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "appendRandomText", action.checked);
    //   return {
    //     ...state,
    //   };
    case TAG_LIST_RECORD_SCENARIO:
      state.selectedTagsLists = action.val;
      return {
        ...state,
      };
    case SELECT_CHILD_ELEMENT_PURPOSE:
      if (action.value === "ACTION") {
        let parentRecElement = getParentRecElement(
          state.unSavedRecTestStep.recTestStepProperty?.recElements,
          action.recElementGuid
        );
        if (
          parentRecElement.childRecElements.filter((childRecElement) => childRecElement.elementPurpose === "ACTION")
            .length > 0
        ) {
          showNotification(ERROR_TYPE, "One Action is allowed for child element");
          return {
            ...state,
          };
        }
      }
      recElement = getRecElement(
        _.cloneDeep(state.unSavedRecTestStep?.recTestStepProperty?.recElements),
        action.recElementGuid
      );
      recElement.elementPurpose = action.value;
      if (action.value === "VERIFICATION") {
        recElement.verificationOperator = "AND";
        if (recElement.selectorType === SELECTOR_TYPE.CUSTOM_ELEMENT) {
          let recStepVerifyElementProperty = getEmptyVerificationRow("VerifyPresence");
          recStepVerifyElementProperty["key"] = "By Locator";
          recElement.recStepVerifyElementProperties = [];
          recElement.recStepVerifyElementProperties.push(recStepVerifyElementProperty);
        }
      } else if (action.value === "ACTION") {
        recElement.verificationOperator = undefined;
        recElement.recStepVerifyElementProperties = undefined;
      }
      state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
        state.unSavedRecTestStep,
        "recElements",
        updateRecElement(_.cloneDeep(unSavedRecTestStep?.recTestStepProperty?.recElements), recElement)
      );
      return {
        ...state,
      };
    case SHOW_DELETE_REC_TEST_STEP_CONFIRMATION_MODAL:
      return {
        ...state,
        isSelectedRecTestStepForDelete: action.isSelectedRecTestStepForDelete,
        selectedRecTestStepTagForDelete: action.recTestStepTag,
        selectedRecTestStepIdForDelete: action.recTestStepId,
        selectedRecTestStepAssocForDelete: action.recTestScenarioRecTestStepAssocId,
        deleteRecTestStepModalVisible: true,
      };
    case HIDE_DELETE_REC_TEST_STEP_CONFIRMATION_MODAL:
      return {
        ...state,
        selectedRecTestStepIdForDelete: undefined,
        isSelectedRecTestStepForDelete: false,
        selectedRecTestStepTagForDelete: undefined,
        selectedRecTestStepAssocForDelete: undefined,
        deleteRecTestStepModalVisible: false,
      };
    case IS_DELETE_REC_TEST_STEP_REF:
      state.isDelateRecTestStepRef = action.flag;
      return {
        ...state,
      };
    case SHOW_DELETE_REC_ELEMENT_CONFIRMATION_MODAL:
      return {
        ...state,
        selectedRecTestStepIdForDelete: action.recTestStepId,
        selectedRecTestStepTagForDelete: action.recTestStepTag,
        selectedRecTestStepAssocForDelete: action.recTestScenarioRecTestStepAssocId,
        selectedRecElementForDelete: action.recElementGuid,
        deleteRecElementModalVisible: true,
      };
    case HIDE_DELETE_REC_ELEMENT_CONFIRMATION_MODAL:
      return {
        ...state,
        selectedRecTestStepIdForDelete: undefined,
        selectedRecTestStepTagForDelete: undefined,
        selectedRecElementForDelete: undefined,
        deleteRecElementModalVisible: false,
      };
    case SHOW_DELETE_REC_TEST_STEP_PRE_CONDITION_CONFIRMATION_MODAL:
      return {
        ...state,
        selectedRecTestStepPropertyIdForDelete: action.recTestStepPropertyId,
        selectedRecTestStepOfPreCondition:action.recTestStep,
        deleteRecTestStepPreconditionModalVisible: true,
      };
    case HIDE_DELETE_REC_TEST_STEP_PRE_CONDITION_CONFIRMATION_MODAL:
      return {
        ...state,
        selectedRecTestStepPropertyIdForDelete: undefined,
        deleteRecTestStepPreconditionModalVisible: false,
        selectedRecTestStepOfPreCondition:undefined,
      };
    case CHANGE_REC_TEST_STEP_NAME:
      unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
        { ...state.unSavedRecTestStep },
        "stepName",
        action.value
      );
      unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
        { ...unSavedRecTestStep },
        "userSuggestedStepName",
        action.value
      );
      return {
        ...state,
        unSavedRecTestStep,
      };
    case CHANGE_REC_TEST_STEP_ACTION_SELECTED_ELEMENT:
      return {
        ...state,
        recTestStepActionSelectedElement: action.selectedElement,
      };
    case SHOW_REC_TEST_STEP_ACTION_ELEMENT_DETAIL_DRAWER:
      let recTestStepActionElementDetailDrawerData = undefined;
      if (action.selectedRecTestStepDetail) {
        recTestStepActionElementDetailDrawerData = action?.selectedRecTestStepDetail;
      }

      if (action.recTestStepId === undefined) {
        recTestStepActionElementDetailDrawerData["recTestStepActionSelectedElement"] =
          state.recTestStepActionSelectedElement;
      }
      return {
        ...state,
        recTestStepActionElementDetailDrawerData,
        selectedElementGuidForDetail: action.recElementGuid,
        recTestStepActionElementDetailDrawerVisible: true,
        disableSteps: action.disableSteps,
      };
    case HIDE_REC_TEST_STEP_ACTION_ELEMENT_DETAIL_DRAWER:
      return {
        ...state,
        recTestStepActionElementDetailDrawerVisible: false,
        recTestStepActionElementDetailDrawerData: undefined,
      };

    case CHANGE_REC_TEST_STEP_ELEMENT_XPATH_ACTIVE:
      recElements = _.cloneDeep(state.recTestStepActionElementDetailDrawerData.recTestStepProperty?.recElements);
      recElement = getRecElement(recElements, action.recElementGuid);
      recElement.recElementLocatorProperties.forEach((element) => {
        if (element.value === action.value) element.isActive = action.checked ? 1 : 0;
      });
      recElements = updateRecElement(recElements, recElement);
      state.recTestStepActionElementDetailDrawerData = insertIntoRecTestStepRecTestProperty(
        state.recTestStepActionElementDetailDrawerData,
        "recElements",
        recElements
      );
      state.unSavedRecTestStep = state.recTestStepActionElementDetailDrawerData;
      state.unSavedRecStepTag = state.recTestStepActionElementDetailDrawerData?.tag;
      state.unSavedRecElementGuid = action.recElementGuid;
      return {
        ...state,
      };
    case SET_ELEMENT_DETAILS:
      action.elementDetailsFromPageSource = {
        ...action.elementDetailsFromPageSource,
        screenShot: action.elementScreenshot,
      };

      if (action.highlightElementInDevice) {
        action.elementDetailsFromServer.recElementLocatorProperties.map((recElementLocatorProperty) => {
          if (recElementLocatorProperty.mobileElements && recElementLocatorProperty.mobileElements.length > 0) {
            state.searchedForElementBounds = {
              elLocation: recElementLocatorProperty.mobileElements[0].location,
              size: recElementLocatorProperty.mobileElements[0].size,
            };
          }
        });
      }

      return {
        ...state,
        elementListByServer: action.elementDetailsFromServer,
        deviceSelectedElementAttributes: action.selectedElementAttributes,
        recTestStepActionSelectedElement: action.elementDetailsFromPageSource,
      };
    case SWIPE:
      state.unSavedRecTestStep.recTestSteps = insertIntoRecTestStepActionRecTestProperty(
        state.selectedRecTestStepDetail.recTestSteps,
        action.recTestStepActionId,
        action.recTestStepActionTag,
        "action",
        ELEMENT_ACTION.SWIPE
      );
      state.unSavedRecTestStep.recTestSteps = insertIntoRecTestStepActionRecTestProperty(
        state.selectedRecTestStepDetail.recTestSteps,
        action.recTestStepActionId,
        action.recTestStepActionTag,
        "stepName",
        ELEMENT_ACTION.SWIPE + " (" + action.swipe.direction + " - " + action.swipe.percentage + "% )"
      );
      state.unSavedRecTestStep.recTestSteps = insertIntoRecTestStepActionRecTestProperty(
        state.selectedRecTestStepDetail.recTestSteps,
        action.recTestStepActionId,
        action.recTestStepActionTag,
        "recSwipeAttributes",
        action.swipe
      );
      return {
        ...state,
      };
    case CHANGE_SWIPE_PERCENTAGE:
      if (unSavedRecTestStep) {
        switch (action.direction) {
          case SWIPE_DIRECTION.DOWN:
            unSavedRecTestStep["swipeDownPercentage"] =
              action.value.toString().trim() === "" ? MINIMUM_SWIPE_VALUE : action.value;
            break;
          case SWIPE_DIRECTION.LEFT:
            unSavedRecTestStep["swipeLeftPercentage"] =
              action.value.toString().trim() === "" ? MINIMUM_SWIPE_VALUE : action.value;
            break;
          case SWIPE_DIRECTION.RIGHT:
            unSavedRecTestStep["swipeRightPercentage"] =
              action.value.toString().trim() === "" ? MINIMUM_SWIPE_VALUE : action.value;
            break;
          case SWIPE_DIRECTION.UP:
            unSavedRecTestStep["swipeUpPercentage"] =
              action.value.toString().trim() === "" ? MINIMUM_SWIPE_VALUE : action.value;
            break;
          default:
            break;
        }
      }
      return {
        ...state,
        unSavedRecTestStep,
      };
    case REQUEST_PERFORM_REC_TEST_STEP_ACTION:
      return {
        ...state,
        requestingPerformRecTestStepAction: true,
      };
    case REQUESTED_PERFORM_REC_TEST_STEP_ACTION:
      return {
        ...state,
        requestingPerformRecTestStepAction: false,
      };
    // case ADD_VERIFICATION_ROW:
    //   if (state.unSavedRecTestStep) {
    //     let recStepVerifyElementProperties = fetchRecTestStepVerificationProperty(
    //       { ...state.unSavedRecTestStep },
    //       action.recElementGuid
    //     );
    //     recStepVerifyElementProperties = addVerificationRow(
    //       _.cloneDeep(recStepVerifyElementProperties),
    //       action.uuid,
    //       action.verificationType
    //     );
    //     state.unSavedRecTestStep = updateRecTestStepVerificationProperty(
    //       { ...state.unSavedRecTestStep },
    //       action.recElementGuid,
    //       recStepVerifyElementProperties
    //     );
    //   }
    //   return {
    //     ...state,
    //   };
    // case REMOVE_VERIFICATION_ROW:
    //   if (state.unSavedRecTestStep) {
    //     let recStepVerifyElementProperties = fetchRecTestStepVerificationProperty(
    //       { ...state.unSavedRecTestStep },
    //       action.recElementGuid
    //     );
    //     if (recStepVerifyElementProperties === undefined) {
    //       recStepVerifyElementProperties = [];
    //     } else {
    //       if (action.recElementGuid === undefined) {
    //         state.unSavedRecTestStep.recTestStepProperty?.previous_recStepVerifyElementProperties = [
    //           ...recStepVerifyElementProperties,
    //         ];
    //       }
    //       recStepVerifyElementProperties = removeVerificationRow(
    //         _.cloneDeep(recStepVerifyElementProperties),
    //         action.uuid
    //       );
    //     }
    //     state.unSavedRecTestStep = updateRecTestStepVerificationProperty(
    //       { ...state.unSavedRecTestStep },
    //       action.recElementGuid,
    //       recStepVerifyElementProperties
    //     );
    //   }
    //   return {
    //     ...state,
    //   };
    // case OPERATOR_CHANGE:
    //   if (state.unSavedRecTestStep) {
    //     let recStepVerifyElementProperties = fetchRecTestStepVerificationProperty(
    //       { ...state.unSavedRecTestStep },
    //       action.recElementGuid
    //     );
    //     if (state.unSavedRecTestStep.recTestStepProperty["previous_recStepVerifyElementProperties"] === undefined) {
    //       state.unSavedRecTestStep["recTestStepProperty"]["previous_recStepVerifyElementProperties"] =
    //         cloneArray(recStepVerifyElementProperties);
    //     }
    //     if (action.uuid === undefined && action.recElementGuid === undefined) {
    //       state.unSavedRecTestStep.recTestStepProperty?.operator = action.value;
    //     } else if (action.uuid === undefined && action.recElementGuid !== undefined) {
    //       recElement = getRecElement(state.unSavedRecTestStep.recTestStepProperty?.recElements, action.recElementGuid);
    //       recElement["verificationOperator"] = action.value;
    //     } else if (action.uuid) {
    //       recStepVerifyElementProperties = operatorChange(
    //         _.cloneDeep(recStepVerifyElementProperties),
    //         action.uuid,
    //         action.value
    //       );
    //     }
    //     state.unSavedRecTestStep = updateRecTestStepVerificationProperty(
    //       { ...state.unSavedRecTestStep },
    //       action.recElementGuid,
    //       recStepVerifyElementProperties
    //     );
    //   }
    //   return {
    //     ...state,
    //   };
    // case UPDATE_VERIFICATION_KEY:
    //   if (state.unSavedRecTestStep) {
    //     let recStepVerifyElementProperties = fetchRecTestStepVerificationProperty(
    //       { ...state.unSavedRecTestStep },
    //       action.recElementGuid
    //     );
    //     if (state.unSavedRecTestStep.recTestStepProperty["previous_recStepVerifyElementProperties"] === undefined) {
    //       state.unSavedRecTestStep["recTestStepProperty"]["previous_recStepVerifyElementProperties"] =
    //         cloneArray(recStepVerifyElementProperties);
    //     }
    //     recStepVerifyElementProperties = updateVerificationKey(
    //       _.cloneDeep(recStepVerifyElementProperties),
    //       action.uuid,
    //       action.key
    //     );
    //     state.unSavedRecTestStep = updateRecTestStepVerificationProperty(
    //       { ...state.unSavedRecTestStep },
    //       action.recElementGuid,
    //       recStepVerifyElementProperties
    //     );
    //   }
    //   return {
    //     ...state,
    //   };
    // case UPDATE_VERIFICATION_VALUE:
    //   if (state.unSavedRecTestStep) {
    //     let recStepVerifyElementProperties = fetchRecTestStepVerificationProperty(
    //       { ...state.unSavedRecTestStep },
    //       action.recElementGuid
    //     );
    //     if (state.unSavedRecTestStep.recTestStepProperty["previous_recStepVerifyElementProperties"] === undefined) {
    //       state.unSavedRecTestStep["recTestStepProperty"]["previous_recStepVerifyElementProperties"] =
    //         cloneArray(recStepVerifyElementProperties);
    //     }
    //     recStepVerifyElementProperties = updateVerificationValue(
    //       _.cloneDeep(recStepVerifyElementProperties),
    //       action.uuid,
    //       action.value
    //     );
    //     state.unSavedRecTestStep = updateRecTestStepVerificationProperty(
    //       { ...state.unSavedRecTestStep },
    //       action.recElementGuid,
    //       recStepVerifyElementProperties
    //     );
    //   }
    //   return {
    //     ...state,
    //   };
    // case UPDATE_REFERENCE_STEP_VALUE:
    //   if (state.unSavedRecTestStep) {
    //     let recStepVerifyElementProperties = fetchRecTestStepVerificationProperty(
    //       state.unSavedRecTestStep,
    //       action.recElementGuid
    //     );

    //     if (state.unSavedRecTestStep.recTestStepProperty["previous_recStepVerifyElementProperties"] === undefined) {
    //       state.unSavedRecTestStep["recTestStepProperty"]["previous_recStepVerifyElementProperties"] = cloneArray(
    //         state.unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties
    //       );
    //     }
    //     recStepVerifyElementProperties = updateReferenceStepValue(
    //       _.cloneDeep(recStepVerifyElementProperties),
    //       action.uuid,
    //       action.value
    //     );
    //     state.unSavedRecTestStep = updateRecTestStepVerificationProperty(
    //       { ...state.unSavedRecTestStep },
    //       action.recElementGuid,
    //       recStepVerifyElementProperties
    //     );
    //   }
    //   return {
    //     ...state,
    //   };
    case UPDATE_REFERENCE_STEP_TEST_DATA:
      if (state.unSavedRecTestStep) {
        if (state.unSavedRecTestStep.recTestStepProperty["previous_recStepVerifyElementProperties"] === undefined) {
          state.unSavedRecTestStep["recTestStepProperty"]["previous_recStepVerifyElementProperties"] = cloneArray(
            state.unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties
          );
        }
        state.unSavedRecTestStep.recTestStepProperty.recStepVerifyElementProperties =
          updateReferenceTestDataForVerification(
            [...state.unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties],
            action.uuid,
            action.refTestData
          );
      }
      if (action.rulesTab === true) {
        let selectedElementKey =
          unSavedRecTestStep.recTestStepProperty?.recElements[0].recStepVerifyElementProperties?.filter(
            (i) => i.key === action.selectKey
          );
        selectedElementKey[0].refTestData = action.refTestData;
      }
      return {
        ...state,
      };
    // case CHANGE_SWIPE_DIRECTION_OVER_ELEMENT:
    // if (state.unSavedRecTestStep) {
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "recSwipeAttributes",
    //     {
    //       percentage: state.unSavedRecTestStep["recTestStepProperty"]["recSwipeAttributes"]["percentage"],
    //       direction: action.direction,
    //       id: state.unSavedRecTestStep["recTestStepProperty"]["recSwipeAttributes"]["id"],
    //     }
    //   );
    //   state.unSavedRecTestStep["recTestStepProperty"]["recSwipeAttributes"]["direction"] = action.direction;
    // }
    // return {
    //   ...state,
    // };
    // case CHANGE_SWIPE_PERCENTAGE_OVER_ELEMENT:
    //   if (state.unSavedRecTestStep) {
    //     state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //       { ...state.unSavedRecTestStep },
    //       "recSwipeAttributes",
    //       {
    //         percentage: action.percentage,
    //         direction: state.unSavedRecTestStep["recTestStepProperty"]["recSwipeAttributes"]["direction"],
    //         id: state.unSavedRecTestStep["recTestStepProperty"]["recSwipeAttributes"]["id"],
    //       }
    //     );
    //   }
    //   return {
    //     ...state,
    //   };
    // case CHANGE_PAUSE_TIME:
    //   if (state.unSavedRecTestStep) {
    //     state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //       { ...state.unSavedRecTestStep },
    //       "pauseTime",
    //       action.pauseTime
    //     );
    //   }
    //   return {
    //     ...state,
    //   };
    // case CHANGE_IS_CAPTURE_SCREEN_SHOT:
    //   if (state.unSavedRecTestStep) {
    //     state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //       { ...state.unSavedRecTestStep },
    //       "isCaptureScreenshot",
    //       action.isCaptureScreenshot
    //     );
    //     if (action.isCaptureScreenshot) {
    //       state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //         { ...state.unSavedRecTestStep },
    //         "captureScreenShotDelayTime",
    //         2000
    //       );
    //     } else {
    //       state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //         { ...state.unSavedRecTestStep },
    //         "captureScreenShotDelayTime",
    //         0
    //       );
    //     }
    //   }
    //   return {
    //     ...state,
    //   };
    // case CHANGE_CAPTURE_SCREEN_SHOT_DELAY_TIME:
    //   if (state.unSavedRecTestStep) {
    //     state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //       { ...state.unSavedRecTestStep },
    //       "captureScreenShotDelayTime",
    //       action.captureScreenShotDelayTime
    //     );
    //   }
    //   return {
    //     ...state,
    //   };
    // case CHANGE_IS_LOOP_STEP:
    //   if (state.unSavedRecTestStep) {
    //     state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //       { ...state.unSavedRecTestStep },
    //       "isLoopStep",
    //       action.checked ? 1 : 0
    //     );
    //   }
    //   return {
    //     ...state,
    //   };
    // case CHANGE_STEP_NAME:
    //   state.unSavedRecTestStep.recTestStepProperty?.stepName = action.value;
    //   return {
    //     ...state,
    //     changedRecTestStepName: true,
    //   };
    // case CHANGE_SWIPE_TO_FIND_ELEMENT:
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     _.cloneDeep(state.unSavedRecTestStep),
    //     "swipeToFindElement",
    //     action.value ? 1 : 0
    //   );
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     _.cloneDeep(state.unSavedRecTestStep),
    //     "swipeDirection",
    //     action.value ? SWIPE_DIRECTION.UP : undefined
    //   );
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     _.cloneDeep(state.unSavedRecTestStep),
    //     "swipeCountToFindElement",
    //     action.value ? 3 : 0
    //   );

    // recElement = undefined;
    // recElement = getRecElement(state.unSavedRecTestStep.recTestStepProperty?.recElements, state.unSavedRecElementGuid);
    // if (recElement) {
    //   recElement = {
    //     ...recElement,
    //     swipeToFindElement: action.value ? 1 : 0,
    //     swipeDirection: action.value ? SWIPE_DIRECTION.UP : undefined,
    //     swipeCountToFindElement: action.value ? 3 : 0,
    //   };
    //   state.unSavedRecTestStep.recTestStepProperty?.recElements = updateRecElement(
    //     [...state.unSavedRecTestStep.recTestStepProperty?.recElements],
    //     recElement
    //   );
    // }
    //   return {
    //     ...state,
    //   };
    // case CHANGE_SWIPE_TO_BRING_ELEMENT_TO_CENTER:
    //   recElement = undefined;
    //   recElement = getRecElement(state.unSavedRecTestStep.recTestStepProperty?.recElements, action.recElementGuid);
    //   if (recElement) {
    //     recElement["swipeToBringElementToCenter"] = action.value ? 1 : 0;
    //     state.unSavedRecTestStep.recTestStepProperty?.recElements = updateRecElement(
    //       [...state.unSavedRecTestStep.recTestStepProperty?.recElements],
    //       recElement
    //     );
    //   }
    //   return {
    //     ...state,
    //   };

    // case CHANGE_SWIPE_COUNT_TO_FIND_ELEMENT:
    //   // recElement = undefined;
    //   // recElement = getRecElement(state.unSavedRecTestStep.recTestStepProperty?.recElements, state.unSavedRecElementGuid);
    //   // if (recElement) {
    //   //   recElement = {
    //   //     ...recElement,
    //   //     swipeCountToFindElement: action.value,
    //   //   };
    //   //   state.unSavedRecTestStep.recTestStepProperty?.recElements = updateRecElement(
    //   //     [...state.unSavedRecTestStep.recTestStepProperty?.recElements],
    //   //     recElement
    //   //   );
    //   // }
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "swipeCountToFindElement",
    //     action.value
    //   );
    //   return {
    //     ...state,
    //   };

    // case CHANGE_SWIPE_DIRECTION_TO_FIND_ELEMENT:
    //   // recElement = undefined;
    //   // recElement = getRecElement(state.unSavedRecTestStep.recTestStepProperty?.recElements, state.unSavedRecElementGuid);
    //   // if (recElement) {
    //   //   recElement = {
    //   //     ...recElement,
    //   //     swipeDirection: action.value,
    //   //   };
    //   //   state.unSavedRecTestStep.recTestStepProperty?.recElements = updateRecElement(
    //   //     [...state.unSavedRecTestStep.recTestStepProperty?.recElements],
    //   //     recElement
    //   //   );
    //   // }
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     _.cloneDeep(state.unSavedRecTestStep),
    //     "swipeDirection",
    //     action.value ? action.value : SWIPE_DIRECTION.UP
    //   );
    //   return {
    //     ...state,
    //   };
    // case CHANGE_ELEMENT_LOAD_TIME:
    //   recElements = _.cloneDeep(state.unSavedRecTestStep.recTestStepProperty?.recElements);
    //   recElement = getRecElement(recElements, state.unSavedRecElementGuid);
    //   recElement.elementMaxLoadTime = action.value;
    //   recElements = updateRecElement(recElements, recElement);
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "recElements",
    //     recElements
    //   );
    //   return {
    //     ...state,
    //   };
    // case CHANGE_RETRY_COUNT:
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "retryCount",
    //     action.value
    //   );
    //   return {
    //     ...state,
    //   };
    // case CHANGE_INVERT_RESULT:
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "invertResult",
    //     action.checked
    //   );
    //   return {
    //     ...state,
    //   };
    // case CHANGE_HORIZONTAL_RELATIVE_POINT:
    //   recElement = undefined;
    //   recElement = getRecElement(state.unSavedRecTestStep.recTestStepProperty?.recElements, action.recElementGuid);
    //   if (recElement) {
    //     recElement["horizontalRelativePoint"] = action.value;
    //     state.unSavedRecTestStep.recTestStepProperty?.recElements = updateRecElement(
    //       [...state.unSavedRecTestStep.recTestStepProperty?.recElements],
    //       recElement
    //     );
    //   }
    //   return {
    //     ...state,
    //   };
    // case CHANGE_VERTICAL_RELATIVE_POINT:
    //   recElement = undefined;
    //   recElement = getRecElement(state.unSavedRecTestStep.recTestStepProperty?.recElements, action.recElementGuid);
    //   if (recElement) {
    //     recElement["verticalRelativePoint"] = action.value;
    //     state.unSavedRecTestStep.recTestStepProperty?.recElements = updateRecElement(
    //       [...state.unSavedRecTestStep.recTestStepProperty?.recElements],
    //       recElement
    //     );
    //   }
    //   return {
    //     ...state,
    //   };
    // case CHANGE_CONTINUE_AFTER_FAILURE:
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "continueAfterFail",
    //     action.checked ? 1 : 0
    //   );
    //   return {
    //     ...state,
    //   };

    // case CHANGE_DISABLE_STEP:
    //   // action.recTestStep.isSkipStep = action.checked;
    //   // state.unSavedRecTestStep = { ...action.recTestStep, isSkipStep: action.checked };
    //   // state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //   //   { ...action.recTestStep },
    //   //   "isSkipStep",
    //   //   action.checked
    //   // );
    //   // if (state.unSavedRecTestStep?.recTestSteps?.length >= 0) {
    //   //   state.unSavedRecTestStep["recTestSteps"] = allChildDisabled(
    //   //     state.unSavedRecTestStep?.recTestSteps,
    //   //     action.checked,
    //   //     action.stepType
    //   //   );
    //   // }
    //   return {
    //     ...state,
    //   };

    // case CHANGE_STEP_HAS_PRECONDITION:
    //   // Store Previous value
    //   if (state.unSavedRecTestStep.recTestStepProperty["previous_isStepHasPrecondition"] === undefined) {
    //     state.unSavedRecTestStep.recTestStepProperty["previous_isStepHasPrecondition"] =
    //       state.unSavedRecTestStep.recTestStepProperty["isStepHasPrecondition"];
    //   }
    //   state.unSavedRecTestStep.recTestStepProperty["isStepHasPrecondition"] = action.checked ? 1 : 0;
    //   if (action.checked) {
    //     state.unSavedRecTestStep.recTestStepProperty["uuid"] = getUniqueId();
    //   }
    //   // else if (state.unSavedRecTestStep.recTestSteps && state.unSavedRecTestStep.recTestSteps.length > 0) {
    //   //   state.activeRecTestStepAction = state.unSavedRecTestStep.recTestSteps[0];
    //   // } else {
    //   //   state.activeRecTestStepAction = undefined;
    //   // }

    //   return {
    //     ...state,
    //   };
    // case CHANGE_STEP_PRECONDITION_TITLE:
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "preConditionTitle",
    //     action.value
    //   );
    //   return {
    //     ...state,
    //   };
    case SHOW_DELETE_LOOP_CONDITION_MODAL_VISIBLE:
      return {
        ...state,
        selectedRecTestStepTagForDelete: action.recTestStepTag,
        deleteRecTestStepLoopConditionModalVisible: true,
        deleteLoopCondition: action.deleteLoopCondition,
        selectedRecTestStepForLoopCondition:action?.recTestStep
      };
    case HIDE_DELETE_LOOP_CONDITION_MODAL_VISIBLE:
      return {
        ...state,
        selectedRecTestStepTagForDelete: undefined,
        deleteRecTestStepLoopConditionModalVisible: false,
        deleteLoopCondition: false,
        selectedRecTestStepForLoopCondition:undefined
      };
    case REMOVE_LOOP_CONDITION_FROM_REC_TEST_STEP:
      unSavedRecTestStep = { ...state.unSavedRecTestStep };
      unSavedRecTestStep["recTestStepProperty"] = {
        ...unSavedRecTestStep["recTestStepProperty"],
        preConditionTitle: undefined,
        addLoopCondition: false,
        recStepVerifyElementProperties: [],
      };
      delete unSavedRecTestStep["recTestStepProperty"]["previous_preConditionTitle"];
      delete unSavedRecTestStep["recTestStepProperty"]["previous_recStepVerifyElementProperties"];
      return {
        ...state,
        unSavedRecTestStep,
        selectedRecTestStepTagForDelete: undefined,
        deleteRecTestStepPreconditionModalVisible: false,
        recTestStepActionSelectedElement: undefined,
      };
    // case CHANGE_REC_TEST_STEP_LOOP_COUNT:
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "loopCount",
    //     action.value
    //   );
    //   return {
    //     ...state,
    //   };
    // case ADD_VERIFICATION_FOR_REPEAT_STEP:
    //   state.unSavedRecTestStep["recTestStepProperty"] = {
    //     ...state.unSavedRecTestStep["recTestStepProperty"],
    //     addLoopCondition: true,
    //     recStepVerifyElementProperties: [],
    //     recElements: [
    //       getNewRecElement(state.unSavedRecTestStep.recTestStepProperty?.action, action.unSavedRecElementGuid),
    //     ],
    //     previous_recStepVerifyElementProperties: [],
    //     previous_loopCount: undefined,
    //     operator: "AND",
    //     selectorType: SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN,
    //   };
    //   return {
    //     ...state,
    //   };
    case CHANGE_SHOW_TEST_DATA_VISIBILITY:
      if (action.checked) {
        state.showTestDataVisibility[state.unSavedRecStepTag] = action.checked;
      } else {
        state.showTestDataVisibility = {};
      }
      return {
        ...state,
      };

    case VISIBLE_TEST_DATA_VISIBILITY:
      if (!unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_testData")) {
        state.showTestDataVisibility = {};
      }
      return {
        ...state,
      };
    case TEST_DATA_LIST:
      return {
        ...state,
        testDataList: action?.response?.data,
        totalTestDataCounts: action?.response?.totalItems,
        currentTestDataPage: action?.response?.currentPage,
        totalTestDataPageCount: action?.response?.totalCount,
        testDataPageItems: action?.response?.pageItems,
      };
    case TEST_DATA_LIST_REQUEST:
      return {
        ...state,
        fetchingTestDataList: true,
      };
    case TEST_DATA_LIST_REQUESTED:
      return {
        ...state,
        fetchingTestDataList: false,
      };
    // case SELECT_TEST_DATA:
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...state.unSavedRecTestStep }, "testData", {
    //     id: action.testDataId,
    //     value: action.testDataValue,
    //   });
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "testDataCustomValue",
    //     undefined
    //   );
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     state.unSavedRecTestStep,
    //     "userInputValue",
    //     undefined
    //   );
    //   return {
    //     ...state,
    //   };
    // case SELECT_OUTPUT_TEST_DATA:
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "outputTestData",
    //     {
    //       id: action.testDataId,
    //     }
    //   );
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     state.unSavedRecTestStep,
    //     "outputTestDataId",
    //     undefined
    //   );
    //   return {
    //     ...state,
    //   };
    // case CHANGE_INSERT_VALUE_TO_TEST_DATA:
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "insertValueToTestData",
    //     action.value
    //   );
    //   return {
    //     ...state,
    //   };
    // case CHANGE_TEST_DATA_VALUE_FOR_ACTION:
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "testDataCustomValue",
    //     action.value
    //   );
    //   return {
    //     ...state,
    //   };
    // case CHANGE_IGNORE_TEST_PLAN_DATA:
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "ignoreTestPlanTestData",
    //     action.checked ? 1 : 0
    //   );
    //   return {
    //     ...state,
    //   };
    // case SELECT_LAUNCH_APP:
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     state.unSavedRecTestStep,
    //     "packageName",
    //     action.data.selectedApp
    //   );
    //   return {
    //     ...state,
    //   };
    // case SELECT_LAUNCH_APP_ACTIVITY:
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     state.unSavedRecTestStep,
    //     "packageActivity",
    //     action.data.selectedApp
    //   );
    //   return {
    //     ...state,
    //   };
    // case CHANGE_REMOVE_APP_PREVIOUS_SESSION:
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     state.unSavedRecTestStep,
    //     "removeAppPreviousSession",
    //     action.checked
    //   );
    //   return {
    //     ...state,
    //   };
    // case CHANGE_ELEMENT_NAME_INTO_STEP:
    //   recElements = _.cloneDeep(state.unSavedRecTestStep.recTestStepProperty?.recElements);
    //   recElement = getRecElement(recElements, state.unSavedRecElementGuid);
    //   recElement.name = action.name;
    //   recElements = updateRecElement(recElements, recElement);
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "recElements",
    //     recElements
    //   );
    //   return {
    //     ...state,
    //   };
    // case CHANGE_DISABLE_ELEMENT:
    //   recElements = _.cloneDeep(state.unSavedRecTestStep.recTestStepProperty?.recElements);
    //   recElement = getRecElement(recElements, state.unSavedRecElementGuid);
    //   recElement.isActive = action.checked ? 1 : 0;
    //   recElements = updateRecElement(recElements, recElement);
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "recElements",
    //     recElements
    //   );
    //   return {
    //     ...state,
    //   };
    // case CHANGE_ELEMENT_DESCRIPTION_INTO_STEP:
    //   recElements = _.cloneDeep(state.unSavedRecTestStep.recTestStepProperty?.recElements);
    //   recElement = getRecElement(recElements, state.unSavedRecElementGuid);
    //   recElement.description = action.description;
    //   recElements = updateRecElement(recElements, recElement);
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "recElements",
    //     recElements
    //   );
    //   return {
    //     ...state,
    //   };
    // case CHANGE_ELEMENT_TAGS_INTO_STEP:
    //   recElements = _.cloneDeep(state.unSavedRecTestStep.recTestStepProperty?.recElements);
    //   recElement = getRecElement(recElements, state.unSavedRecElementGuid);
    //   recElement.tags = action.tags;
    //   recElements = updateRecElement(recElements, recElement);
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "recElements",
    //     recElements
    //   );
    //   return {
    //     ...state,
    //   };
    // case CHANGE_CUSTOM_ELEMENT_SEARCH_KEY:
    //   recElements = _.cloneDeep(state.unSavedRecTestStep.recTestStepProperty?.recElements);
    //   recElement = getRecElement(recElements, state.unSavedRecElementGuid);
    //   recElement.searchKey = action.value;
    //   recElements = updateRecElement(recElements, recElement);
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "recElements",
    //     recElements
    //   );
    //   return {
    //     ...state,
    //   };
    // case SELECT_OUTPUT_TEST_DATA_REC_ELEMENT:
    //   recElements = _.cloneDeep(state.unSavedRecTestStep.recTestStepProperty?.recElements);
    //   recElement = getRecElement(recElements, state.unSavedRecElementGuid);
    //   recElement.testData = { id: action.testDataId, name: action.testDataName, value: action.testDataValue };
    //   recElement.name = "Custom Element by test data '" + action.testDataName + "'";
    //   recElements = updateRecElement(recElements, recElement);
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "recElements",
    //     recElements
    //   );
    //   return {
    //     ...state,
    //   };
    // case CHANGE_CUSTOM_ELEMENT_SEARCH_VALUE:
    //   recElements = _.cloneDeep(state.unSavedRecTestStep.recTestStepProperty?.recElements);
    //   recElement = getRecElement(recElements, state.unSavedRecElementGuid);
    //   recElement.searchValue = action.value;
    //   recElement.name = "Custom Element by text '" + action.value + "'";
    //   recElements = updateRecElement(recElements, recElement);
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "recElements",
    //     recElements
    //   );
    //   if (_.isEmpty(state.unSavedRecTestStep.userSuggestedStepName)) {
    //     state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //       state.unSavedRecTestStep,
    //       "stepName",
    //       suggestedStepName(recElements, state.unSavedRecTestStep.recTestStepProperty?.action)
    //     );
    //   }
    //   return {
    //     ...state,
    //   };
    // case CHANGE_CUSTOM_ELEMENT_FILTER_TYPE_VALUE:
    //   recElements = _.cloneDeep(state.unSavedRecTestStep.recTestStepProperty?.recElements);
    //   recElement = getRecElement(recElements, state.unSavedRecElementGuid);
    //   recElement.filterType = action.value;
    //   recElements = updateRecElement(recElements, recElement);
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "recElements",
    //     recElements
    //   );
    //   return {
    //     ...state,
    //   };
    // case CHANGE_CUSTOM_ELEMENT_ADD_TEST_DATA:
    //   recElements = _.cloneDeep(state.unSavedRecTestStep.recTestStepProperty?.recElements);
    //   recElement = getRecElement(recElements, state.unSavedRecElementGuid);
    //   recElement.useTestData = action.checked ? 1 : 0;
    //   recElement.searchValue = action.checked ? undefined : recElement.searchValue;
    //   recElement.testData = !action.checked ? undefined : recElement.testData;
    //   recElements = updateRecElement(recElements, recElement);
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "recElements",
    //     recElements
    //   );
    //   return {
    //     ...state,
    //   };
    // case CHANGE_CUSTOM_ELEMENT_ADD_FILTER_TYPE:
    //   recElements = _.cloneDeep(state.unSavedRecTestStep.recTestStepProperty?.recElements);
    //   recElement = getRecElement(recElements, state.unSavedRecElementGuid);
    //   recElement.useFilterType = action.checked ? 1 : 0;
    //   recElement.filterType = !action.checked ? undefined : recElement.filterType;
    //   recElements = updateRecElement(recElements, recElement);
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     { ...state.unSavedRecTestStep },
    //     "recElements",
    //     recElements
    //   );
    //   return {
    //     ...state,
    //   };

    case SELECTED_REC_TEST_STEP_UPDATED_STEP_NAME:
      let updateSelectedRecTestStepDetail = { ...state.unSavedRecTestStep };
      if (action.response.status === SUCCESS_STATUS) {
        if (state.unSavedRecTestStep?.recTestSteps.some((i) => i.id === action.recTestStepId)) {
          updateSelectedRecTestStepDetail.recTestSteps.map((obj) => {
            if (obj.id === action.recTestStepId) {
              obj.recTestStepProperty.stepName = action.data.stepName;
            }
            return obj;
          });
        }
      }
      return {
        ...state,
        unSavedRecTestStep: updateSelectedRecTestStepDetail,
      };
    case REMOVE_ELEMENT_INTO_STEP:
      recElements = _.cloneDeep(action.unSavedRecTestStep.recTestStepProperty?.recElements);
      recElements = removeRecElements(recElements, action.recElementGuid);
      action.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
        action.unSavedRecTestStep,
        "recElements",
        recElements
      );
      delete action.unSavedRecTestStep.recTestStepProperty["previous_recElements"];
      return {
        ...state,
        unSavedRecTestStep: action.unSavedRecTestStep,
        selectedRecTestStepIdForDelete: undefined,
        selectedRecTestStepTagForDelete: undefined,
        selectedRecElementForDelete: undefined,
        deleteRecElementModalVisible: false,
      };
    case ADD_ELEMENT_INTO_STEP:
      recElements = _.cloneDeep(state.unSavedRecTestStep.recTestStepProperty?.recElements);
      let updateRecTestStepElementDetail = getNewRecElement(state.unSavedRecTestStep?.recTestStepProperty?.action);
      if (action.parentElementGuid !== undefined) {
        recElements.map((recElement) => {
          if (action.parentElementGuid === recElement.guid) {
            recElement.elementType = ElementType.PARENT_ELEMENT;
            if (recElement.childRecElements === undefined) {
              recElement.childRecElements = [];
            }
            updateRecTestStepElementDetail = {
              ...updateRecTestStepElementDetail,
              elementPurpose:
                recElement?.childRecElements.filter((item) => item.elementPurpose === "ACTION").length === 0
                  ? "ACTION"
                  : "VERIFICATION",
            };
            updateRecTestStepElementDetail = {
              ...updateRecTestStepElementDetail,
              verificationOperator: "AND",
              recStepVerifyElementProperties: [],
            };
            state.selectedTabKey[state.unSavedRecStepTag + state.unSavedRecElementGuid] =
              updateRecTestStepElementDetail.elementPurpose === "VERIFICATION" ? "VERIFICATION_PROPERTIES" : undefined;
            recElement.childRecElements.push(updateRecTestStepElementDetail);
          }
        });
      } else {
        recElements.push(updateRecTestStepElementDetail);
      }
      state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
        state.unSavedRecTestStep,
        "recElements",
        recElements
      );
      state.unSavedRecElementGuid = updateRecTestStepElementDetail.guid;
      return {
        ...state,
      };
    case SET_SELECTED_REC_STEP_TAB_KEY:
      let selectedTabKey = { ...state.selectedTabKey };
      let tag = {};
      tag[state.unSavedRecStepTag + state.unSavedRecElementGuid] = action.key;
      selectedTabKey = {
        ...state.selectedTabKey,
        ...tag,
      };
      return {
        ...state,
        selectedTabKey,
      };
    case RESET_UNSAVED_ELEMENTS:
      recElements = _.cloneDeep(action.unSavedRecTestStep.recTestStepProperty?.recElements);
      recElements = recElements?.filter((recElement) => recElement.id !== undefined && recElement.id !== 0);
      action.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
        action.unSavedRecTestStep,
        "recElements",
        recElements
      );
      delete action.unSavedRecTestStep["recTestStepProperty"]["previous_recElements"];
      return {
        ...state,
        unSavedRecTestStep: action.unSavedRecTestStep,
        unSavedRecElementGuid: undefined,
      };
    case UPDATE_STEP_TYPE:
      return {
        ...state,
        stepType: action.stepType,
      };
    case RESET_STATE:
      return {
        ...state,
        unSavedRecStepTag: undefined,
        unSavedRecElementGuid: undefined,
        unSavedRecTestStep: undefined,
        selectedRecTestStepIdForDelete: undefined,
        isSelectedRecTestStepForDelete: false, // Use in Delete Rec Test Step
        selectedRecTestStepTagForDelete: undefined,
        selectedRecTestStepAssocForDelete: undefined,
        deleteRecTestStepModalVisible: false,
        isDelateRecTestStepRef: 0,
        deleteRecTestStepPreconditionModalVisible: false,
        selectedRecTestStepPropertyIdForDelete: undefined,
        recTestStepActionSelectedElement: undefined,
        elementListByServer: [],
        deviceSelectedElementAttributes: undefined,
        requestingPerformRecTestStepAction: false,
        fetchingTestDataList: false,
        testDataList: undefined,
        showTestDataVisibility: {},
        selectedRecTestStepTag: undefined,
        selectedTabKey: {},
        isShowAIGenerateStepsRow: false,
      };
    case HANDLE_SELECT_REC_TEST_STEP_ACTION:
      let _requestData = [...state.requestData];
      let _selectedRecTestStepOrActionForImport = [...state.selectedRecTestStepOrActionForImport];
      let _selectedRecTestStepOrActionForImportObject = [...state.selectedRecTestStepOrActionForImportObject];
      if (_selectedRecTestStepOrActionForImport?.includes(action.selectedId)) {
        _selectedRecTestStepOrActionForImport.splice(
          _selectedRecTestStepOrActionForImport.indexOf(action.selectedId),
          1
        );
        _selectedRecTestStepOrActionForImportObject = _selectedRecTestStepOrActionForImportObject.filter((item) =>
          _selectedRecTestStepOrActionForImport.includes(
            item?.recTestScenarioRecTestStepAssocId ? item?.recTestScenarioRecTestStepAssocId : item?.id
          )
        );
      } else {
        _selectedRecTestStepOrActionForImportObject.push(action.recTestStep);
        _selectedRecTestStepOrActionForImport.push(action.selectedId);
      }
      if (action.currentActionTab === "IMPORT_FROM_SCENARIO") {
        const importParentRecTestSteps = action?.importRecTestScenarioStepActionList?.map((i) =>
          i?.recTestSteps.parentRecTestSteps.map((j) => ({ ...j, parentRecTestScenarioRecTestStepsAssocId: i.id }))
        );
        const importRecTestChildData = [];
        action.importRecTestScenarioStepActionList.forEach((i) => {
          Object.values(i?.recTestSteps.childRecTestSteps).forEach((element) => {
            importRecTestChildData.push(...element);
          });
        });
        const parentRecTestStepsResult = importParentRecTestSteps.reduce((r, e) => (r.push(...e), r), []);
        const importRecTestData = [...parentRecTestStepsResult, ...importRecTestChildData];
        action.importRecTestScenarioStepActionList.forEach((element) =>
          importRecTestData.push({ ...element, recTestScenarioRecTestStepAssocId: element.id })
        );

        let importRecTestAllChildData = {};
        action.importRecTestScenarioStepActionList.forEach((i) => {
          importRecTestAllChildData = { ...importRecTestAllChildData, ...i?.recTestSteps.childRecTestSteps };
        });
        action.importRecTestScenarioStepActionList.forEach((element) => {
          importRecTestAllChildData[element.id] = element.recTestSteps.parentRecTestSteps;
        });
        AllChildRecTestStepOrActionsId(
          action.selectedId,
          _selectedRecTestStepOrActionForImport,
          action.childRecTestSteps
        );
      }

      return {
        ...state,
        selectedRecTestStepOrActionForImport: _selectedRecTestStepOrActionForImport,
        selectedRecTestStepOrActionForImportObject: _selectedRecTestStepOrActionForImportObject,
        requestData: _requestData,
        selectedRecTestStep: action.recTestStep,
      };
    case CHANGE_ADVANCE_ELEMENT_SELECTOR_DRAWER_VISIBILITY:
      return {
        ...state,
        isAdvanceElementSelectorDrawerVisible: action.visibleAction,
      };
    case CHANGE_SELECTOR_TYPE:
      recElement = getRecElement(
        state.unSavedRecTestStep.recTestStepProperty?.recElements,
        state.unSavedRecElementGuid
      );
      if (action.value === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN) {
        recElement.name = "Image Element";
      }
      recElement.elementSelectorType = action.value;
      if (action.value === SELECTOR_TYPE.CUSTOM_ELEMENT) {
        recElement.searchKey = "text";
      }

      updateRecElement(state.unSavedRecTestStep.recTestStepProperty?.recElements, recElement);
      state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
        { ...state.unSavedRecTestStep },
        "recElements",
        state.unSavedRecTestStep.recTestStepProperty?.recElements
      );
      return {
        ...state,
      };
    case RESET_SAVED_REC_TEST_STEP_ACTION:
      state.unSavedRecTestStep["recTestStepProperty"] = {
        ...state.unSavedRecTestStep["recTestStepProperty"],
        userInputValue:
          state.unSavedRecTestStep["recTestStepProperty"].previous_userInputValue !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_userInputValue
            : state.unSavedRecTestStep["recTestStepProperty"].userInputValue,
        continueAfterFail:
          state.unSavedRecTestStep["recTestStepProperty"].previous_continueAfterFail !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_continueAfterFail
            : state.unSavedRecTestStep["recTestStepProperty"].continueAfterFail,
        preConditionTitle:
          state.unSavedRecTestStep["recTestStepProperty"].previous_preConditionTitle !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_preConditionTitle
            : state.unSavedRecTestStep["recTestStepProperty"].preConditionTitle,
        stepName:
          state.unSavedRecTestStep["recTestStepProperty"].previous_stepName !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_stepName
            : state.unSavedRecTestStep["recTestStepProperty"].stepName,
        action:
          state.unSavedRecTestStep["recTestStepProperty"].previous_action !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_action
            : state.unSavedRecTestStep["recTestStepProperty"].action,
        uniqueByIndex:
          state.unSavedRecTestStep["recTestStepProperty"].previous_uniqueByIndex !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_uniqueByIndex
            : state.unSavedRecTestStep["recTestStepProperty"].uniqueByIndex,
        isStepHasPrecondition:
          state.unSavedRecTestStep["recTestStepProperty"].previous_isStepHasPrecondition !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_isStepHasPrecondition
            : state.unSavedRecTestStep["recTestStepProperty"].isStepHasPrecondition,
        testData:
          state.unSavedRecTestStep["recTestStepProperty"].previous_testData !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_testData
            : state.unSavedRecTestStep["recTestStepProperty"].testData,
        testDataCustomValue:
          state.unSavedRecTestStep["recTestStepProperty"].previous_testDataCustomValue !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_testDataCustomValue
            : state.unSavedRecTestStep["recTestStepProperty"].testDataCustomValue,
        ignoreTestPlanTestData:
          state.unSavedRecTestStep["recTestStepProperty"].previous_ignoreTestPlanTestData !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_ignoreTestPlanTestData
            : state.unSavedRecTestStep["recTestStepProperty"].ignoreTestPlanTestData,
        packageName:
          state.unSavedRecTestStep["recTestStepProperty"].previous_packageName !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_packageName
            : state.unSavedRecTestStep["recTestStepProperty"].packageName,
        appendRandomText:
          state.unSavedRecTestStep["recTestStepProperty"].previous_appendRandomText !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_appendRandomText
            : state.unSavedRecTestStep["recTestStepProperty"].appendRandomText,
        swipeToFindElement:
          state.unSavedRecTestStep["recTestStepProperty"].previous_swipeToFindElement !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_swipeToFindElement
            : state.unSavedRecTestStep["recTestStepProperty"].swipeToFindElement,
        swipeCountToFindElement:
          state.unSavedRecTestStep["recTestStepProperty"].previous_swipeCountToFindElement !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_swipeCountToFindElement
            : state.unSavedRecTestStep["recTestStepProperty"].swipeCountToFindElement,
        elementMaxLoadTime:
          state.unSavedRecTestStep["recTestStepProperty"].previous_elementMaxLoadTime !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_elementMaxLoadTime
            : state.unSavedRecTestStep["recTestStepProperty"].elementMaxLoadTime,
        retryCount:
          state.unSavedRecTestStep["recTestStepProperty"].previous_retryCount !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_retryCount
            : state.unSavedRecTestStep["recTestStepProperty"].retryCount,
        invertResult:
          state.unSavedRecTestStep["recTestStepProperty"].previous_invertResult !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_invertResult
            : state.unSavedRecTestStep["recTestStepProperty"].invertResult,
        testDataId:
          state.unSavedRecTestStep["recTestStepProperty"].previous_testDataId !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_testDataId
            : state.unSavedRecTestStep["recTestStepProperty"].testDataId,
        recStepVerifyElementProperties:
          state.unSavedRecTestStep["recTestStepProperty"].previous_recStepVerifyElementProperties !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_recStepVerifyElementProperties
            : state.unSavedRecTestStep["recTestStepProperty"].recStepVerifyElementProperties,
        recSwipeAttributes:
          state.unSavedRecTestStep["recTestStepProperty"].previous_recSwipeAttributes !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_recSwipeAttributes
            : state.unSavedRecTestStep["recTestStepProperty"].recSwipeAttributes,
        pauseTime:
          state.unSavedRecTestStep["recTestStepProperty"].previous_pauseTime !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_pauseTime
            : state.unSavedRecTestStep["recTestStepProperty"].pauseTime,
        isLoopStep:
          state.unSavedRecTestStep["recTestStepProperty"].previous_isLoopStep !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_isLoopStep
            : state.unSavedRecTestStep["recTestStepProperty"].isLoopStep,
        loopCount:
          state.unSavedRecTestStep["recTestStepProperty"].previous_loopCount !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_loopCount
            : state.unSavedRecTestStep["recTestStepProperty"].loopCount,
        swipeDirection:
          state.unSavedRecTestStep["recTestStepProperty"].previous_swipeDirection !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_swipeDirection
            : state.unSavedRecTestStep["recTestStepProperty"].swipeDirection,
        recElements:
          state.unSavedRecTestStep["recTestStepProperty"].previous_recElements !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_recElements
            : state.unSavedRecTestStep["recTestStepProperty"].recElements,
        horizontalRelativePoint:
          state.unSavedRecTestStep["recTestStepProperty"].previous_horizontalRelativePoint !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_horizontalRelativePoint
            : state.unSavedRecTestStep["recTestStepProperty"].horizontalRelativePoint,
        verticalRelativePoint:
          state.unSavedRecTestStep["recTestStepProperty"].previous_verticalRelativePoint !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_verticalRelativePoint
            : state.unSavedRecTestStep["recTestStepProperty"].verticalRelativePoint,
        isCaptureScreenshot:
          state.unSavedRecTestStep["recTestStepProperty"].previous_isCaptureScreenshot !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_isCaptureScreenshot
            : state.unSavedRecTestStep["recTestStepProperty"].isCaptureScreenshot,
        captureScreenShotDelayTime:
          state.unSavedRecTestStep["recTestStepProperty"].previous_captureScreenShotDelayTime !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_captureScreenShotDelayTime
            : state.unSavedRecTestStep["recTestStepProperty"].captureScreenShotDelayTime,
        outputTestDataId:
          state.unSavedRecTestStep["recTestStepProperty"].previous_outputTestDataId !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_outputTestDataId
            : state.unSavedRecTestStep["recTestStepProperty"].outputTestDataId,
        outputTestData:
          state.unSavedRecTestStep["recTestStepProperty"].previous_outputTestData !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_outputTestData
            : state.unSavedRecTestStep["recTestStepProperty"].outputTestData,
        insertValueToTestData:
          state.unSavedRecTestStep["recTestStepProperty"].previous_insertValueToTestData !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].previous_insertValueToTestData
            : state.unSavedRecTestStep["recTestStepProperty"].insertValueToTestData,
        applicationStrings:
          state.unSavedRecTestStep["recTestStepProperty"].previous_applicationStrings !== undefined
            ? state.unSavedRecTestStep["recTestStepProperty"].applicationStrings
            : state.unSavedRecTestStep["recTestStepProperty"].applicationStrings,
      };
      if (
        state.unSavedRecTestStep["recTestStepProperty"].recStepVerifyElementProperties &&
        state.unSavedRecTestStep["recTestStepProperty"].recStepVerifyElementProperties.length > 0
      ) {
        let removeIndex = [];
        state.unSavedRecTestStep["recTestStepProperty"].recStepVerifyElementProperties.map((v, k) => {
          if (v.id === undefined) {
            removeIndex.push(k);
          }
        });
        if (removeIndex.length > 0) {
          for (var i = removeIndex.length - 1; i >= 0; i--)
            state.unSavedRecTestStep["recTestStepProperty"].recStepVerifyElementProperties.splice(removeIndex[i], 1);
        }
      }
      if (
        state.unSavedRecTestStep["recTestStepProperty"].recElements &&
        state.unSavedRecTestStep["recTestStepProperty"].recElements.length > 0
      ) {
        recElements = _.cloneDeep(state.unSavedRecTestStep.recTestStepProperty?.recElements);
        recElement = getUnSavedRecElement(recElements, action.recElementGuid);
        recElements = updateUnSavedRecElement(
          recElements,
          recElement && Object.keys(recElement).length > 1
            ? resetElementProperties(recElement)
            : getNewRecElement(unSavedRecTestStep?.recTestStepProperty?.action, action.recElementGuid)
        );
        state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
          state.unSavedRecTestStep,
          "recElements",
          recElements
        );
      }
      if (state.unSavedRecTestStep.recTestStepProperty?.previous_packageName === undefined) {
        state.unSavedRecTestStep.recTestStepProperty.packageName = "";
      }
      if (state.unSavedRecTestStep.recTestStepProperty?.previous_packageActivity === undefined) {
        state.unSavedRecTestStep.recTestStepProperty.packageActivity = "";
      }

      state.showTestDataContent = state.unSavedRecTestStep["recTestStepProperty"].previous_showTestDataContent;
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_userInputValue"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_continueAfterFail"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_preConditionTitle"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_stepName"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_action"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_uniqueByIndex"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_isStepHasPrecondition"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_testData"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_testDataCustomValue"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_ignoreTestPlanTestData"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_packageName"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_appendRandomText"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_swipeToFindElement"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_swipeCountToFindElement"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_elementMaxLoadTime"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_retryCount"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_testDataId"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_showTestDataContent"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_recStepVerifyElementProperties"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_recSwipeAttributes"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_isSkipStep"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_pauseTime"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_isLoopStep"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_horizontalRelativePoint"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_verticalRelativePoint"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_outputTestData"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_loopCount"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_swipeDirection"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_recElements"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_userSuggestedStepName"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_outputTestDataId"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_insertValueToTestData"];
      delete state.unSavedRecTestStep["recTestStepProperty"]["previous_applicationStrings"];

      state.previous_TestBlockStepName = undefined;
      return {
        ...state,
      };
    case CHANGE_SEARCH_VALUE_FOR_IMPORT_REC_TEST_STEP_OR_ACTION_TREE:
      return {
        ...state,
        searchValueForImportRecTestStepAction: action.value,
      };
    case SHOW_IMPORT_REC_TEST_STEP_MODAL:
      if (action.typeText === STEP) {
        state.disableRecTestStepForImport = false;
        state.disableRecTestStepActionsForImport = true;
      } else {
        state.disableRecTestStepForImport = true;
        state.disableRecTestStepActionsForImport = false;
      }
      return {
        ...state,
        importRecTestStepModalVisible: true,
        selectedRecTestStepOrActionForImport: [],
        selectedRecTestStepDetail: action.recTestStep?.recTestScenarioRecTestStepAssocId,
        typeTextOfSteps: action.typeText,
        selectRecTestStepId: action.recTestStep?.id,
        selectRecTestStep: action.recTestStep,
        selectChildStep: action.selectChildStep,
        actionStep: action.actionStep,
        selectedRecTestStepOrActionForImportObject: [],
      };
    case HIDE_IMPORT_REC_TEST_STEP_MODAL:
      return {
        ...state,
        importRecTestStepModalVisible: false,
        disableRecTestStepForImport: false,
        disableRecTestStepActionsForImport: false,
        searchValueForImportRecTestStepAction: undefined,
        importRecTestScenarioStepActionList: [],
        selectedRecTestStepOrActionForImport: [],
        requestData: [],
        checkedTestBlockImportStep: [],
        requestingForImportRecTestStepOrAction: false,
      };
    case ADD_CONDITION_TO_STEP:
      state.unSavedRecTestStep = action.unSavedRecTestStep;
      return {
        ...state,
      };
    case MULTI_COLUMN_VIEW_DATA_LOADING:
      return {
        ...state,
        multiColumnViewDataLoading: action.response.isLoading,
      };
    case REC_TEST_STEP_INNER_DETAILS_LOADING:
      return {
        ...state,
        recTestStepDetailsLoading: action.response.isLoading,
      };

    case MULTI_COLUMN_VIEW_TEST_BLOCK_DATA_LOADING:
      return {
        ...state,
        multiColumnViewTestBlockDataLoading: action.response.isLoading,
      };
    // case RE_RECORD_REC_TEST_STEP_ELEMENT:
    //   recElements = _.cloneDeep(state.unSavedRecTestStep.recTestStepProperty?.recElements);
    //   //Remove Change ELement
    //   recElement = getUnSavedRecElement(recElements, action.recElementGuid);
    //   //Add Empty  ELement
    //   recElements = updateUnSavedRecElement(
    //     recElements,
    //     recElement && Object.keys(recElement).length > 0
    //       ? resetElementProperties(recElement)
    //       : getNewRecElement(unSavedRecTestStep?.recTestStepProperty?.action, action.recElementGuid)
    //   );
    //   state.unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     state.unSavedRecTestStep,
    //     "recElements",
    //     recElements
    //   );
    //   return {
    //     ...state,
    //   };
    case REMOVE_CONDITION_FROM_REC_TEST_STEP:
      switch (action.condition) {
        case STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_ELEMENT:
          state.unSavedRecTestStep["recTestStepProperty"] = {
            ...state.unSavedRecTestStep["recTestStepProperty"],
            preConditionTitle: undefined,
            isStepHasPrecondition: 0,
            recStepVerifyElementProperties: [],
            operator: undefined,
          };
          delete state.unSavedRecTestStep["recTestStepProperty"]["previous_preConditionTitle"];
          delete state.unSavedRecTestStep["recTestStepProperty"]["previous_recStepVerifyElementProperties"];
          break;
        case STEP_ITEMS.REPEAT_TILL:
          state.unSavedRecTestStep["recTestStepProperty"] = {
            ...state.unSavedRecTestStep["recTestStepProperty"],
            isLoopStep: 0,
            loopCount: 0,
            previous_loopCount: undefined,
          };
          delete unSavedRecTestStep["recTestStepProperty"]["previous_isLoopStep"];
          delete unSavedRecTestStep["recTestStepProperty"]["previous_loopCount"];
          break;
        default:
          break;
      }
      return {
        ...state,
        recTestStepActionSelectedElement: undefined,
      };
    case REQUEST_CLEAR_PREVIEW:
      return {
        ...state,
        requestingForClearingPreview: true,
      };
    case REQUESTED_CLEAR_PREVIEW:
      return {
        ...state,
        requestingForClearingPreview: false,
      };
    case REQUEST_IMPORT_REC_TEST_STEP_ACTION:
      return {
        ...state,
        requestingForImportRecTestStepOrAction: true,
      };
    case REQUESTED_IMPORT_REC_TEST_STEP_ACTION:
      return {
        ...state,
        requestingForImportRecTestStepOrAction: false,
        importRecTestStepModalVisible: false,
      };
    case CAPTURE_EXPANDED_STEPS:
      let expandedStepTags = _.cloneDeep(state.expandedStepTags);
      let expandedStepAssocIds = _.cloneDeep(state.expandedStepAssocIds);
      if (action.shouldAdd) {
        if (action.recTestStepTag && !expandedStepTags.includes(action.recTestStepTag)) {
          expandedStepTags.push(action.recTestStepTag);
        }
        if (
          action.recTestScenarioRecTestStepAssocId &&
          !expandedStepAssocIds.includes(action.recTestScenarioRecTestStepAssocId)
        ) {
          expandedStepAssocIds.push(action.recTestScenarioRecTestStepAssocId);
        }
      } else {
        if (action.recTestStepTag && expandedStepTags.includes(action.recTestStepTag)) {
          let index = expandedStepTags.indexOf(action.recTestStepTag);
          expandedStepTags.splice(index, 1);
        }
        if (
          action.recTestScenarioRecTestStepAssocId &&
          expandedStepAssocIds.includes(action.recTestScenarioRecTestStepAssocId)
        ) {
          let index = expandedStepAssocIds.indexOf(action.recTestScenarioRecTestStepAssocId);
          expandedStepAssocIds.splice(index, 1);
        }
      }
      return {
        ...state,
        expandedStepTags,
        expandedStepAssocIds,
      };
    case CLEAR_EXPANDED_STEPS:
      return {
        ...state,
        expandedStepTags: [],
        expandedStepAssocIds: [],
      };
    case CAPTURE_EXPANDED_ELEMENTS:
      let expandedElementGuid = _.cloneDeep(state.expandedElementGuid);
      let value =
        action.recElementGuid +
        splitValue +
        action.recTestStepTag +
        splitValue +
        action.recTestScenarioRecTestStepAssocId;

      if (action.shouldAdd) {
        if (action.recTestStepTag && !expandedElementGuid.includes(action.value)) {
          expandedElementGuid.push(value);
        }
      } else {
        if (expandedElementGuid.includes(value)) {
          let index = expandedElementGuid.indexOf(value);
          expandedElementGuid.splice(index, 1);
        }
      }
      return {
        ...state,
        expandedElementGuid,
      };
    case CLEAR_EXPANDED_ELEMENTS:
      return {
        ...state,
        expandedElementGuid: [],
      };
    case GET_LIST_OF_LOCALS:
      return {
        ...state,
        getListOfLocales: action?.data,
      };
    case GET_APPLICATION_STRINGS:
      const filterApplicationStringsList = state.getListOfLocales && state.getListOfLocales?.find(
        (i) => i?.locales === action.locale
      )?.displayCountry;
      return {
        ...state,
        getApplicationString: action?.data,
        selectedLocale: action?.locale,
        filterFetchingApplicationStringsList: filterApplicationStringsList,
        selectedElementGuidForDetail: action.recElementGuid,
        selectedRecTestStepTag: action?.recTestStepTag,
        selectedVerificationGuid: action?.verificationGuid,
        getApplicationStringPageCount: getPageCount(
          action?.data?.pageItems ? String(action?.data?.pageItems).split("-") : 1,
          action?.data?.currentPage
        ),
        getApplicationStringCurrentCount: action?.data?.currentPage,
      };
    case GET_DEVICE_STRINGS:
      const filterDeviceStringsList = state.getListOfLocales &&state.getListOfLocales?.find((i) => i?.locales === action.locale)?.displayCountry;
      return {
        ...state,
        getDeviceString: action?.data,
        selectedLocale: action?.locale,
        filterFetchingDeviceStringsList: filterDeviceStringsList,
        selectedElementGuidForDetail: action.recElementGuid,
        selectedRecTestStepTag: action?.recTestStepTag,
        selectedVerificationGuid: action?.verificationGuid,
        getDeviceStringPageCount: getPageCount(
          action?.data?.pageItems ? String(action?.data?.pageItems).split("-") : 1,
          action?.data?.currentPage
        ),
        getDeviceStringCurrentCount: action?.data?.currentPage,
      };
    case APPLICATION_STRING_CURRENT_TAB:
      return {
        ...state,
        getApplicationStringCurrentTab: action.tab,
      };
    case APPLICATION_STRING_SEARCH_KEYWORD:
      return {
        ...state,
        getApplicationStringSearchKeyword: action.search,
      };
    case APPLICATION_DRAWER_VISIBLE:
      return {
        ...state,
        showApplicationDrawerVisible: action?.visible,
        guidForOpenApplicationDrawer: action?.guidForOpenApplicationDrawer,
      };
    case DEVICE_DRAWER_VISIBLE:
      return {
        ...state,
        showDeviceDrawerVisible: action?.visible,
        guidForOpenDeviceDrawer: action?.guidForOpenDeviceDrawer,
      };
    case APPLICATION_STRING_LOADER:
      return {
        ...state,
        applicationStringLoader: action?.isLoading,
      };
    case DEVICE_STRING_LOADER:
      return {
        ...state,
        deviceStringLoader: action?.isLoading,
      };

    case ADD_DEVICE_STRINGS_FORM_VISIBLE:
      return {
        ...state,
        visibleAddDeviceStringForm: action?.visible,
        editDeviceStringData: undefined,
        deviceStringName: undefined,
      };

    case RESET_DEVICE_STRING_DATA:
      return {
        ...state,
        deviceStringName: undefined,
        deviceStringValue: undefined,
        selectLocaleOfDeviceString: undefined,
      };
    case ADD_APPLICATION_STRINGS_FORM_VISIBLE:
      return {
        ...state,
        visibleAddApplicationStringForm: action?.visible,
        editApplicationStringData: undefined,
        applicationStringName: undefined,
      };

    case RESET_APPLICATION_STRING_DATA:
      return {
        ...state,
        applicationStringName: undefined,
        applicationStringValue: undefined,
        selectLocaleOfApplicationString: undefined,
      };
    case VISIBLE_GENERAL_SETTINGS_DRAWER:
      return {
        ...state,
        visibleGeneralSettingDrawer: action.visible,
        actionForGeneralActionDrawer: action?.actionForOpenDrawer,
        tagForOpenDrawer: action?.recTestStepTag,
      };

    case VISIBLE_IDENTIFICATION_RULES_DRAWER:
      return {
        ...state,
        visibleIdentificationRuleDrawer: action.visible,
        tagForOpenRulesDrawer: action?.tagForOpenDrawer,
      };
    case LOAD_EDIT_APPLICATION_STRING_DETAILS:
      return {
        ...state,
        visibleAddApplicationStringForm: true,
        editApplicationStringData: action?.data,
        applicationStringName: action?.editApplicationStringKey,
        applicationStringIsImport: action?.isImport,
        previous_editApplicationStringData: _.cloneDeep(action?.data),
      };

    case CHANGE_APPLICATION_STRING_NAME:
      const editKey = state.editApplicationStringData?.map((i) => {
        return {
          ...i,
          key: action?.value,
        };
      });
      return {
        ...state,
        applicationStringName: action?.value,
        editApplicationStringData: editKey,
      };

    case SELECT_APPLICATION_STRING_LOCALE:
      const editData = state.editApplicationStringData?.map((i) => {
        if (i?.id === action?.editId) {
          i.locale = action?.value;
        }
        return i;
      });
      return {
        ...state,
        selectLocaleOfApplicationString: action?.value,
        editApplicationStringData: editData,
      };

    case CHANGE_APPLICATION_STRING_VALUE:
      const editValue = state.editApplicationStringData?.map((i) => {
        if (i?.id === action?.editId) {
          i.value = action?.value;
        }
        return i;
      });
      return {
        ...state,
        applicationStringValue: action?.value,
        editApplicationStringData: editValue,
      };

    case DELETE_APPLICATION_STRING_LOCALE:
      const data = state.editApplicationStringData?.filter((i) => i?.id !== action?.deleteApplicationStringLocaleId);
      return {
        ...state,
        editApplicationStringData: data,
      };
    case ADD_APPLICATION_STRING_LOCALE_AND_VALUE:
      state.editApplicationStringData.push({
        id: state.editApplicationStringData?.length,
        key: state.applicationStringName,
        locale: "",
        value: "",
      });
      return {
        ...state,
        editApplicationStringData: state.editApplicationStringData,
      };

    ///////////////////
    case LOAD_EDIT_DEVICE_STRING_DETAILS:
      return {
        ...state,
        visibleAddDeviceStringForm: true,
        editDeviceStringData: action?.data,
        deviceStringName: action?.editDeviceStringKey,
        previous_editDeviceStringData: _.cloneDeep(action?.data),
      };

    case CHANGE_DEVICE_STRING_NAME:
      editDeviceStringData = state.editDeviceStringData ? [...state.editDeviceStringData] : undefined;
      editDeviceStringData = editDeviceStringData?.map((i) => {
        return {
          ...i,
          key: action?.value,
        };
      });
      return {
        ...state,
        deviceStringName: action?.value,
        editDeviceStringData,
      };

    case SELECT_DEVICE_STRING_LOCALE:
      editDeviceStringData = state.editDeviceStringData ? [...state.editDeviceStringData] : undefined;
      editDeviceStringData = editDeviceStringData?.map((i) => {
        if (i?.id === action?.editId) {
          i.locale = action?.value;
        }
        return i;
      });
      return {
        ...state,
        selectLocaleOfDeviceString: action?.value,
        editDeviceStringData,
      };

    case CHANGE_DEVICE_STRING_VALUE:
      editDeviceStringData = state.editDeviceStringData ? [...state.editDeviceStringData] : undefined;
      editDeviceStringData = editDeviceStringData?.map((i) => {
        if (i?.id === action?.editId) {
          i.value = action?.value;
        }
        return i;
      });
      return {
        ...state,
        deviceStringValue: action?.value,
        editDeviceStringData,
      };

    case DELETE_DEVICE_STRING_LOCALE:
      editDeviceStringData = [...state.editDeviceStringData];
      editDeviceStringData = editDeviceStringData?.filter((i) => i?.id !== action?.deleteDeviceStringLocaleId);
      return {
        ...state,
        editDeviceStringData,
      };
    case ADD_DEVICE_STRING_LOCALE_AND_VALUE:
      editDeviceStringData = [...state.editDeviceStringData];
      let newId = editDeviceStringData[editDeviceStringData?.length - 1].id + 1;
      editDeviceStringData.push({
        id: newId,
        key: state.deviceStringName,
        locale: "",
        value: "",
      });
      return {
        ...state,
        editDeviceStringData,
      };
    case SET_TEST_DATA_SET_PROFILE_FOR_PREVIEW:
      return {
        ...state,
        previewTestDataSetId:
          action.testDataSetId && action.testDataSetId != "undefined" ? action.testDataSetId : undefined,
        previewTestDataSetName: action.testDataSetName,
      };
    case SET_APP_LOCALE_FOR_PREVIEW:
      return {
        ...state,
        previewAppLocale: action.appLocale,
      };
    case OPEN_PREVIEW_SETTING_DRAWER:
      return {
        ...state,
        previewSettingDrawerVisible: true,
      };
    case CLOSE_PREVIEW_SETTING_DRAWER:
      return {
        ...state,
        previewSettingDrawerVisible: false,
      };
    case CHANGE_SELECTED_TEST_BLOCKS:
      let selectedRecTestStepOrActionForImportObject = [...state.selectedRecTestStepOrActionForImportObject];
      let selectedRecTestStepOrActionForImport = [...state.selectedRecTestStepOrActionForImport];
      if (action.actionType === "REMOVE") {
        selectedRecTestStepOrActionForImport = selectedRecTestStepOrActionForImport.filter(
          (item) => item.recTestStep.id !== action.testBlock?.id
        );
        selectedRecTestStepOrActionForImportObject = selectedRecTestStepOrActionForImportObject.filter((item) =>
          selectedRecTestStepOrActionForImport.includes(item.id)
        );
      } else if (action.actionType === "ADD") {
        selectedRecTestStepOrActionForImportObject.push(action.testBlock);
        selectedRecTestStepOrActionForImport.push({
          recTestStep: { id: action.testBlock?.id },
          project: { id: action.projectId },
        });
      }

      return {
        ...state,
        selectedRecTestStepOrActionForImport: selectedRecTestStepOrActionForImport,
        selectedRecTestStepOrActionForImportObject: selectedRecTestStepOrActionForImportObject,
      };
    case CLEAR_UNSAVED_STEP:
      return {
        ...state,
        selectedRecTestStepOrActionForImport: action.requestData,
        selectedRecTestStepDetail: undefined,
      };
    case SHOW_GENERATE_STEPS_FROM_AI_MODAL:
      return {
        ...state,
        showGenerateStepsFromAiModal: true,
        scenarioGenerateSteps: [],
        scenarioGenerateRequestLoading: false,
        aiGenerationsFor: action.aiGenerationsFor,
        selectedParentStepforAIGenerateStep: action.selectedRecTestStep,
        visibleAIGenerateRecTestStep: [...state.visibleAIGenerateRecTestStep, action.selectedRecTestStep?.id],
      };
    case HIDE_AI_GENERATE_STEPS_FROM_REC_TEST_STEP:
      return {
        ...state,
        showGenerateStepsFromAiModal: false,
        scenarioGenerateSteps: [],
        scenarioGenerateRequestLoading: false,
        aiGenerationsFor: TEST_SCENARIOS,
        selectedParentStepforAIGenerateStep: undefined,
        visibleAIGenerateRecTestStep: state?.visibleAIGenerateRecTestStep?.filter(
          (i) => i !== action?.hiddenAIGenerateRecTestStep
        ),
      };
    case SHOW_GENERATE_STEPS_DEBUG_FROM_AI_MODAL:
      return {
        ...state,
        showGenerateStepsDebugFromAiModal: true,
        scenarioGenerateSteps: [],
        scenarioGenerateDebugRequestLoading: false,
        scenarioObjective: undefined,
        aiGenerationsFor: TEST_SCENARIOS,
        selectedParentStepforAIGenerateStep: undefined,
      };
    case HIDE_GENERATE_STEPS_DEBUG_FROM_AI_MODAL:
      return {
        ...state,
        showGenerateStepsDebugFromAiModal: false,
        scenarioGenerateSteps: [],
        scenarioGenerateDebugRequestLoading: false,
        scenarioObjective: undefined,
        aiGenerationsFor: TEST_SCENARIOS,
        selectedParentStepforAIGenerateStep: undefined,
      };

    case SHOW_AI_GENERATE_STEPS_ROW:
      return {
        ...state,
        isShowAIGenerateStepsRow: true,
        scenarioGenerateSteps: [],
        scenarioGenerateRequestLoading: false,
        scenarioObjective: undefined,
        selectedParentStepforAIGenerateStep: undefined,
      };
    case HIDE_AI_GENERATE_STEPS_ROW:
      return {
        ...state,
        isShowAIGenerateStepsRow: false,
        scenarioGenerateSteps: [],
        scenarioGenerateRequestLoading: false,
        scenarioObjective: undefined,
        selectedParentStepforAIGenerateStep: undefined,
        scenarioObject: {},
        isLoadingForAIStep: null,
      };
    case CHANGE_AI_SCENARIO_OBJECTIVE:
      return {
        ...state,
        scenarioObjective: action.value,
        scenarioObject: {
          ...state.scenarioObject,
          [action.recTestStepId]: action.value,
        },
      };
    case CHANGE_SCENARIO_GENERATE_STEP_TYPE:
      return {
        ...state,
        scenarioGenerateStepType:  {
          ...state.scenarioGenerateStepType,
          [action.recTestStepId]: action.value,
        },
      };
    case CHANGE_AI_SCENARIO_INSTRUCTIONS:
      return {
        ...state,
        scenarioGenerateInstructions: action.value,
      };
    case CHANGE_AI_MODEL_TYPE:
      let instructions = defaultModelInstructions[action.value];
      return {
        ...state,
        selectedModelType: action.value,
        scenarioGenerateInstructions: instructions,
      };
    case SCENARIO_GENERATED_STEPS:
      return {
        ...state,
        scenarioGenerateSteps: action.data,
      };
    case UPDATE_LAST_GENERATED_STEP_INDEX:
      return {
        ...state,
        lastAIGeneratedStepIndex: action.value,
      };
    case SCENARIO_GENERATE_DEBUG_REQUEST_START:
      return {
        ...state,
        scenarioGenerateDebugRequestLoading: true,
      };
    case SCENARIO_GENERATE_DEBUG_REQUEST_STOP:
      return {
        ...state,
        scenarioGenerateDebugRequestLoading: false,
      };
    case SCENARIO_GENERATE_REQUEST_START:
      return {
        ...state,
        scenarioGenerateRequestLoading: true,
        lastAIGeneratedStepIndex: 0,
        isLoadingForAIStep: action?.isLoadingStepId,
        isLoadingForAllStepsIds: AIGeneratingParentSteps(action?.recTestStepList, action?.isLoadingStepId),
      };
    case SCENARIO_GENERATE_REQUEST_STOP:
      return {
        ...state,
        scenarioGenerateRequestLoading: false,
        isLoadingForAIStep: null,
        visibleGenerateStepsAiModal: false,
        isLoadingForAllStepsIds: [],
      };
    case CHANGE_PARENT_REC_TEST_STEP_FOR_AI_GENERATED_STEP:
      return {
        ...state,
        selectedParentStepforAIGenerateStep: action.recTestStep,
      };
    
      case DEVICE_INTERACTION_SELECT_MODE:
        return{
          ...state,
          isDeviceInteractionSelectMode:!state.isDeviceInteractionSelectMode
        }

    case SET_ACCESSIBILITY_REPORT:
      return{
        ...state,
        accessibilityReportValue:action?.value
      }

    case SET_LOCAL_REPORT:
      return{
        ...state,
        localReportValue: action?.value,
      };

    case VISIBLE_GENERATE_STEPS_FROM_AI_MODAL:
      return {
        ...state,
        visibleGenerateStepsAiModal: true,
        scenarioGenerateSteps: [],
        scenarioGenerateRequestLoading: false,
        aiGenerationsFor: action.aiGenerationsFor,
        selectedParentStepforAIGenerateStep: action.selectedRecTestStep,
      };
    case HIDE_GENERATE_STEPS_FROM_AI_MODAL:
      return {
        ...state,
        visibleGenerateStepsAiModal: false,
        scenarioGenerateSteps: [],
        scenarioGenerateRequestLoading: false,
        aiGenerationsFor: null,
        selectedParentStepforAIGenerateStep: null,
      };
    default:
      return {
        ...state,
      };
  }
}

export const getPreviousStoredValue = (data, value) => {
  return data["previous_" + value] ? data["previous_" + value] : data[value];
};

function insertIntoRecTestStepActionRecTestProperty(
  selectedRecTestStepActions,
  recTestStepActionId,
  recTestStepActionTag,
  key,
  value
) {
  selectedRecTestStepActions.map((v, k) => {
    if (v.id === recTestStepActionId || v.tag === recTestStepActionTag) {
      if (v["recTestStepProperty"]["previous_" + key] === undefined) {
        v["recTestStepProperty"]["previous_" + key] = v["recTestStepProperty"][key];
      }
      v["recTestStepProperty"][key] = value;
    }
  });
  return selectedRecTestStepActions;
}

export function fetchRecTestStepVerificationProperty(recTestStep, recElementGuid) {
  let recStepVerifyElementProperties = [];
  if (recTestStep && recElementGuid === undefined) {
    recStepVerifyElementProperties = recTestStep.recTestStepProperty?.recStepVerifyElementProperties;
  } else if (recTestStep && recElementGuid) {
    let recElement = getRecElement(recTestStep.recTestStepProperty?.recElements, recElementGuid);
    recStepVerifyElementProperties = recElement?.recStepVerifyElementProperties;
  }
  return recStepVerifyElementProperties;
}

export function updateRecTestStepVerificationProperty(recTestStep, recElementGuid, recStepVerifyElementProperties) {
  if (recTestStep && recElementGuid === undefined) {
    recTestStep = insertIntoRecTestStepRecTestProperty(
      recTestStep,
      "recStepVerifyElementProperties",
      recStepVerifyElementProperties
    );
  } else if (recTestStep && recElementGuid) {
    let recElement = getRecElement(_.cloneDeep(recTestStep?.recTestStepProperty?.recElements), recElementGuid);
    recElement = insertIntoRecElement(recElement, "recStepVerifyElementProperties", recStepVerifyElementProperties);
    recTestStep.recTestStepProperty.recElements = updateRecElement(
      _.cloneDeep(recTestStep.recTestStepProperty?.recElements),
      recElement
    );
  }

  return recTestStep;
}

export function removeVerificationForApplicationStrings(recStepVerifyElementProperties) {
  if (recStepVerifyElementProperties.length > 0) {
    recStepVerifyElementProperties.map((v, k) => {
      if (v.type === "GROUP") {
        removeVerificationForApplicationStrings(recStepVerifyElementProperties[k].childRecStepVerifyElementProperties);
      } else if (verifyApplicationStrings.includes(recStepVerifyElementProperties[k].key)) {
        recStepVerifyElementProperties.splice(k, 1);
      } else if (verifyDeviceStrings.includes(recStepVerifyElementProperties[k].key)) {
        recStepVerifyElementProperties.splice(k, 1);
      }
    });
    return recStepVerifyElementProperties;
  }
}

export function removeVerificationForDeviceStrings(recStepVerifyElementProperties) {
  if (recStepVerifyElementProperties.length > 0) {
    recStepVerifyElementProperties.map((v, k) => {
      if (v.type === "GROUP") {
        removeVerificationForDeviceStrings(recStepVerifyElementProperties[k].childRecStepVerifyElementProperties);
      } else if (verifyDeviceStrings.includes(recStepVerifyElementProperties[k].key)) {
        recStepVerifyElementProperties.splice(k, 1);
      }
    });
    return recStepVerifyElementProperties;
  }
}

export function removeVerificationRow(recStepVerifyElementProperties, uuid) {
  if (recStepVerifyElementProperties.length > 0) {
    recStepVerifyElementProperties.map((v, k) => {
      if (v.type === "GROUP") {
        if (recStepVerifyElementProperties[k].uuid === uuid || recStepVerifyElementProperties[k].id === uuid) {
          recStepVerifyElementProperties.splice(k, 1);
          // currentStepVerificationList.data[k].remove();
        } else {
          removeVerificationRow(recStepVerifyElementProperties[k].childRecStepVerifyElementProperties, uuid);
        }
      } else if (v.uuid === uuid || v.id === uuid) {
        recStepVerifyElementProperties.splice(k, 1);
      }
    });
    return recStepVerifyElementProperties;
  }
}

export function addVerificationRow(recStepVerifyElementProperties, uuid, type, newUuid) {
  if (uuid === undefined) {
    if (recStepVerifyElementProperties === undefined) {
      recStepVerifyElementProperties = [];
    }
    recStepVerifyElementProperties.push(getEmptyVerificationRow(type, "", newUuid));
  } else {
    recStepVerifyElementProperties &&
      recStepVerifyElementProperties.map((v, k) => {
        if (recStepVerifyElementProperties[k].type === "GROUP") {
          if (recStepVerifyElementProperties[k].uuid === uuid || recStepVerifyElementProperties[k].id === uuid) {
            recStepVerifyElementProperties[k].childRecStepVerifyElementProperties.push(getEmptyVerificationRow(type));
          } else {
            addVerificationRow(recStepVerifyElementProperties[k].childRecStepVerifyElementProperties, uuid, type);
          }
        } else if (v.uuid === uuid || v.id === uuid) {
          recStepVerifyElementProperties[k].push(getEmptyVerificationRow(type));
        } else if (uuid === undefined) {
          recStepVerifyElementProperties.push(getEmptyVerificationRow(type));
        }
      });
  }
  return recStepVerifyElementProperties;
}
export function operatorChange(recStepVerifyElementProperties, uuid, value) {
  recStepVerifyElementProperties &&
    recStepVerifyElementProperties.map((v, k) => {
      if (v.type === "GROUP") {
        if (v.uuid === uuid || v.id === uuid) {
          v.operator = value;
        } else {
          operatorChange(recStepVerifyElementProperties[k].childRecStepVerifyElementProperties, uuid, value);
        }
      }
    });
  return recStepVerifyElementProperties;
}

export function updateReferenceStepValue(recStepVerifyElementProperties, uuid, value) {
  recStepVerifyElementProperties &&
    recStepVerifyElementProperties.map((v, k) => {
      if (v.type === "GROUP") {
        updateReferenceStepValue(recStepVerifyElementProperties[k].childRecStepVerifyElementProperties, uuid, value);
      } else if (v.uuid === uuid) {
        v.referenceStep = value
          ? value.includes(splitValue)
            ? { id: value.split(splitValue)[1], tag: value.split(splitValue)[0] }
            : undefined
          : undefined;
      }
    });
  return recStepVerifyElementProperties;
}

export function updateReferenceTestDataForVerification(recStepVerifyElementProperties, uuid, refTestData) {
  recStepVerifyElementProperties &&
    recStepVerifyElementProperties.map((v, k) => {
      if (v.type === "GROUP") {
        updateReferenceTestDataForVerification(
          recStepVerifyElementProperties[k].childRecStepVerifyElementProperties,
          uuid,
          refTestData
        );
      } else if (v.uuid === uuid) {
        v.refTestData = refTestData;
      }
    });
  return recStepVerifyElementProperties;
}

export function updateVerificationValue(recStepVerifyElementProperties, uuid, value) {
  recStepVerifyElementProperties &&
    recStepVerifyElementProperties.map((v, k) => {
      if (v.type === "GROUP") {
        updateVerificationValue(recStepVerifyElementProperties[k].childRecStepVerifyElementProperties, uuid, value);
      } else if (uuid !== undefined && (v.uuid === uuid || v.id === uuid)) {
        if (Array.isArray(value)) {
          v["child"] = value;
        } else {
          v.value = value;
        }
      }
    });
  return recStepVerifyElementProperties;
}

export function updateVerificationKey(recStepVerifyElementProperties, uuid, key) {
  recStepVerifyElementProperties &&
    recStepVerifyElementProperties.map((v, k) => {
      if (v.type === "GROUP") {
        updateVerificationKey(recStepVerifyElementProperties[k].childRecStepVerifyElementProperties, uuid, key);
      } else if (uuid !== undefined && (v.uuid === uuid || v.id === uuid)) {
        v.key = key;
        v.value = verifyElementTextValues[v.key] !== verifyElementTextValues[key] ? "" : v.value;
        v.referenceStep = undefined;
        v.testData = undefined;
      }
    });
  return recStepVerifyElementProperties;
}

export const allChildDisabled = (childRecTestSteps, checked, stepType) => {
  if (!_.isEmpty(childRecTestSteps)) {
    return childRecTestSteps.map((obj) => {
      if (stepType === TEST_STEP_BLOCKS) {
        obj.recTestStepProperty.isSkipStep = checked;
      } else {
        obj.isSkipStep = checked;
      }
      allChildDisabled(obj.recTestSteps, checked, stepType);
      return obj;
    });
  }
};

const getPageCount = (pageCount, currentPage) => {
  return pageCount instanceof Array && pageCount.length === 2
    ? parseInt(pageCount[1]) - parseInt(pageCount[0]) > 0
      ? currentPage
      : currentPage > 1
      ? currentPage - 1
      : 1
    : 1;
};

export const AIGeneratingParentSteps = (recTestStepList, isLoadingForAIStep) => {
  const arr = [];

  const getParentId = (elements) => {
    for (const element of elements) {
      if (element.id === isLoadingForAIStep) {
        arr.push(element.id);
        return true;
      }

      if (element.recTestSteps && element.recTestSteps.length > 0) {
        const foundInChild = getParentId(element.recTestSteps);
        if (foundInChild) {
          arr.push(element.id);
          return true;
        }
      }
    }
    return false;
  };

  getParentId(recTestStepList);
  return arr;
};