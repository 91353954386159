import { Checkbox, Input } from "antd";
import { isEmpty } from "lodash";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CAPTURE_SCREENSHOT_AFTER,
  DELAY_EXECUTION_ACTION,
  POST_OPERATION_SETTINGS,
  REPEAT_EXECUTION_STEP,
  SELECTOR_TYPE,
  SELECT_TEXT_TO_TEST_DATA,
  STEP,
} from "../../../../Constants";
import {
  CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP,
  OPEN_COLLAPSE_ICON_FOR_PARENT_STEP,
} from "../../../../Constants/SvgConstants";
import {
  hasNotAllowedNegativeValue,
  isNotNullAndNotEmptyAndNotUndefined,
  isStepHavePrecondition,
} from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import {
  changeCaptureScreenShotDelayTime,
  changeInvertResult,
  changeIsCaptureScreenshot,
  changeIsLoopStep,
  changePauseTime,
  changeRecTestStepLoopCount,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import ManageTestDataV2 from "../ManageTestDataV2/ManageTestDataV2";
import SelectOutputTestDataV2 from "./SelectOutputTestDataV2";
import styles from "./RecTestStepPostActionSettingsV2.module.scss";

const RecTestStepPostActionSettingsV2 = (props) => {
  const dispatch = useDispatch();
  const { data, disabled, isSkipStep } = props;
  const { unSavedRecStepTag } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { openTestDataSetDrawerByTabName, tagForOpenDrawer } = useSelector((props) => props.TestDataReducer);

  const [activeCollapse, setActiveCollapse] = useState(false);

  //Destructure of object
  const { tag, recTestStepProperty } = data ? data : {};
  const {
    isCaptureScreenshot,
    isLoopStep,
    action,
    recElements,
    isStepHasPrecondition,
    pauseTime,
    captureScreenShotDelayTime,
    invertResult,
    loopCount,
  } = data && data?.recTestStepProperty ? data?.recTestStepProperty : {};

  const postActionMapping = {
    sendKeys: [SELECT_TEXT_TO_TEST_DATA, CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    tap: [SELECT_TEXT_TO_TEST_DATA, CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    longPress: [SELECT_TEXT_TO_TEST_DATA, CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    tapByIndex: [SELECT_TEXT_TO_TEST_DATA, CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    BACK: [CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    HOME: [CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    ENTER: [CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    clear: [CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    launchApp: [CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION],
    swipeOverElement: [
      SELECT_TEXT_TO_TEST_DATA,
      CAPTURE_SCREENSHOT_AFTER,
      DELAY_EXECUTION_ACTION,
      REPEAT_EXECUTION_STEP,
    ],
    Swipe: [CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    pause: [CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION],
    captureScreenshot: [DELAY_EXECUTION_ACTION],
    clearText: [SELECT_TEXT_TO_TEST_DATA, CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    insertValueToOutputTestData: [CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    setAppLocale: [],
    verifications: [SELECT_TEXT_TO_TEST_DATA, CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION],
    actions: [SELECT_TEXT_TO_TEST_DATA, CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    step: [SELECT_TEXT_TO_TEST_DATA, CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
  };

  const disabledInput = useMemo(
    () =>
      disabled || (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== tag) || isSkipStep,
    [disabled, isSkipStep, unSavedRecStepTag, tag]
  );
  const disabledCaptureScreenshot = useMemo(
    () => disabledInput || isCaptureScreenshot === 0,
    [disabledInput, isCaptureScreenshot]
  );

  const disabledRepeatExecution = useMemo(() => disabledInput || isLoopStep === 0, [disabledInput, isLoopStep]);

  return (
    <div className={styles["rec_Test_Step_Post_Actions"]}>
      <div className="display__flex align_Items__Center">
        <span className={styles["post_Action_Title"]}>On Completion</span>
        <div className={styles["collapse_Icon"]} onClick={() => setActiveCollapse(!activeCollapse)}>
          <SvgLoader
            iconName={activeCollapse ? OPEN_COLLAPSE_ICON_FOR_PARENT_STEP : CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP}
          />
        </div>
      </div>
      {activeCollapse && (
        <div>
          {((recTestStepProperty &&
            (recTestStepProperty.isAIStep == 1 ||
              (!isEmpty(recElements) &&
                recElements.filter((item) => item.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN)
                  .length !== recElements.length)) &&
            postActionMapping[action].includes(SELECT_TEXT_TO_TEST_DATA)) ||
            (action === STEP && (isStepHasPrecondition === 1 || isLoopStep === 1))) && (
            <SelectOutputTestDataV2 disabled={disabled} isSkipStep={isSkipStep} data={data} />
          )}
          <div
            className={`${styles["postActionSettings"]} ${
              recTestStepProperty && postActionMapping[action].includes(REPEAT_EXECUTION_STEP) && styles["repeatStep"]
            }`}
          >
            {recTestStepProperty && postActionMapping[action].includes(CAPTURE_SCREENSHOT_AFTER) && (
              <div className={styles["actions"]}>
                <div>
                  <Checkbox
                    className="selection-checkBox"
                    disabled={
                      disabled ||
                      isSkipStep ||
                      (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== tag)
                    }
                    checked={isCaptureScreenshot === 1 ? true : false}
                    onChange={(e) => dispatch(changeIsCaptureScreenshot(e.target.checked ? 1 : 0, tag))}
                  />
                  <span className={styles["label"]}>Capture Screenshot after</span>
                </div>
                <div
                  className={`${styles["input_And_Label"]} ${
                    disabledCaptureScreenshot && styles["disable_Input_And_Label"]
                  }`}
                >
                  <Input
                    disabled={disabledCaptureScreenshot}
                    value={captureScreenShotDelayTime}
                    type="number"
                    min={0}
                    onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
                    onChange={(e) => dispatch(changeCaptureScreenShotDelayTime(e.target.value, tag))}
                    placeholder="Pause Before Taking Screenshot"
                    className={`${disabledCaptureScreenshot ? styles["disabled_Input_Box"] : styles["input_Box"]}`}
                  />
                  <span className={styles["input_Label"]}>msecs</span>
                </div>
              </div>
            )}
            <div className={styles["actions"]}>
              <span className={styles["label"]}>Delay Execution for</span>
              <div className={`${styles["input_And_Label"]} ${disabledInput && styles["disable_Input_And_Label"]}`}>
                <Input
                  disabled={disabledInput}
                  value={pauseTime}
                  type="number"
                  min={0}
                  onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
                  onChange={(e) => dispatch(changePauseTime(e.target.value, tag))}
                  placeholder="Pause after performing action"
                  className={`${disabledInput ? styles["disabled_Input_Box"] : styles["input_Box"]}`}
                />
                <span className={styles["input_Label"]}>msecs</span>
              </div>
            </div>
            {isStepHavePrecondition(data) ? (
              <div className={`${styles["actions"]} ${styles["invertResult"]}`}>
                <Checkbox
                  className="selection-checkBox"
                  disabled={
                    disabled ||
                    isSkipStep ||
                    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== tag)
                  }
                  checked={invertResult === 1}
                  onChange={(e) => dispatch(changeInvertResult(e.target.checked ? 1 : 0, tag))}
                >
                  Invert Result
                </Checkbox>
              </div>
            ) : (
              ""
            )}
            {recTestStepProperty &&
              postActionMapping[action].includes(REPEAT_EXECUTION_STEP) &&
              isStepHasPrecondition === 0 && (
                <div className={styles["actions"]}>
                  <div>
                    <Checkbox
                      className="selection-checkBox"
                      disabled={
                        disabled ||
                        isSkipStep ||
                        (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== tag)
                      }
                      checked={isLoopStep === 1 ? true : false}
                      onChange={(e) => dispatch(changeIsLoopStep(e.target.checked, tag, data))}
                    />
                    <span className={styles["label"]}>Repeat Execution of this step</span>
                  </div>
                  <div
                    className={`${styles["input_And_Label"]} ${
                      disabledRepeatExecution && styles["disable_Input_And_Label"]
                    }`}
                  >
                    <Input
                      disabled={disabledRepeatExecution}
                      value={loopCount}
                      type="number"
                      min={0}
                      onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
                      onChange={(e) => dispatch(changeRecTestStepLoopCount(e.target.value, tag))}
                      placeholder="Pause Before Taking Screenshot"
                      className={`${disabledRepeatExecution ? styles["disabled_Input_Box"] : styles["input_Box"]}`}
                    />
                    <span className={styles["input_Label"]}>times</span>
                  </div>
                </div>
              )}
            {openTestDataSetDrawerByTabName === POST_OPERATION_SETTINGS && data?.tag === tagForOpenDrawer && (
              <ManageTestDataV2 {...props} tabName={POST_OPERATION_SETTINGS} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RecTestStepPostActionSettingsV2;
