import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Divider, Row } from "antd";
import { changeAwsDeviceFarmTabData, getAwsProjectsList } from "../../../actions/ProjectSettingsActions";
import { GET_AWS_PROJECTS } from "../../../Constants";
import { isTrim } from "../../../Util";
import CommonInputFieldV2 from "../../CommonComponents/CommonInputFieldV2/CommonInputFieldV2";
import styles from "../ProjectSettingsV2.module.scss";

const AWSDeviceFarmKeysV2 = () => {
  const dispatch = useDispatch();
  const { awsDeviceFarmTabDetails, previous_awsDeviceFarmTabDetails } = useSelector(
    (state) => state.ProjectSettingsReducer
  );

  const isSameKeys =
    (JSON.stringify(awsDeviceFarmTabDetails?.accessKeyId) ===
      JSON.stringify(previous_awsDeviceFarmTabDetails?.accessKeyId) ||
      isTrim(awsDeviceFarmTabDetails?.accessKeyId)) &&
    (JSON.stringify(awsDeviceFarmTabDetails?.secretAccessKey) ===
      JSON.stringify(previous_awsDeviceFarmTabDetails?.secretAccessKey) ||
      isTrim(awsDeviceFarmTabDetails?.secretAccessKey));

  const searchAwsProjects = () => {
    if (!isSameKeys) {
      dispatch({ type: GET_AWS_PROJECTS, response: { data: [] } });
    }
  };

  const inputFields = [
    {
      label: "Access Key",
      name: "accessKeyId",
      placeholder: "Please Enter Access Key",
      value: awsDeviceFarmTabDetails.accessKeyId,
    },
    {
      label: "Secret Key",
      name: "secretAccessKey",
      placeholder: "Please Enter Secret Key",
      value: awsDeviceFarmTabDetails.secretAccessKey,
    },
  ];

  const handleInputChange = (fieldName) => (e) => {
    dispatch(
      changeAwsDeviceFarmTabData({
        ...awsDeviceFarmTabDetails,
        [fieldName]: e.target.value,
      })
    );
  };

  return (
    <div className={styles["project_Details"]}>
      <Row justify="center">
        <Col span={20}>
          <div className={styles["header_Title"]}>Basic Details</div>

          {inputFields.map(({ label, name, placeholder, value }) => (
            <CommonInputFieldV2
              key={name}
              label={label}
              name={name}
              placeholder={placeholder}
              value={value}
              handleChange={handleInputChange(name)}
              labelBackgroundClass={"label_Background"}
              required={true}
            />
          ))}
          <Button
            className={styles["Search_And_validate_Btn"]}
            disabled={!awsDeviceFarmTabDetails.accessKeyId || !awsDeviceFarmTabDetails.secretAccessKey || isSameKeys}
            onClick={searchAwsProjects}
          >
            Search
          </Button>
        </Col>
      </Row>
      <Divider className={styles["divider"]} />
    </div>
  );
};

export default AWSDeviceFarmKeysV2;
