import { Button, Col, Row, Select } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAwsDeviceFarmTabData,
  getAwsDeviceFarmPoolsLists,
  getAwsProjectsList,
} from "../../../actions/ProjectSettingsActions";
import { DELETE_ICON } from "../../../CdnImagePath";
import { REMOVE_AWS_POOLS } from "../../../Constants";
import { Label, Required } from "../../CoreComponents/StyledComponent/StyledComponents";
import { SelectCol } from "../../DeviceDetails/DeviceAssignmentModal";
import { Container } from "./AWSDeviceFarmManager";

const { Option } = Select;

const AWSDeviceFarmProjects = () => {
  const dispatch = useDispatch();
  const { awsProjects, awsDeviceFarmTabDetails, awsPools, previous_awsDeviceFarmTabDetails } = useSelector(
    (state) => state.ProjectSettingsReducer
  );

  const AwsProjectsList = [];
  if (awsProjects) {
    for (let i = 0; i < awsProjects.length; i++) {
      AwsProjectsList.push(
        <Option key={awsProjects[i].arn} value={awsProjects[i].arn}>
          {awsProjects[i].name}
        </Option>
      );
    }
  }

  const debounce = _.debounce(() => {
    dispatch(getAwsDeviceFarmPoolsLists());
  }, 1000);

  const debouncedChangeHandler = useCallback(
    (projectArn) => {
      let _awsDeviceFarmTabDetails = { ...awsDeviceFarmTabDetails };
      //Remove the Project
      _awsDeviceFarmTabDetails.awsDeviceFarmProjects = _awsDeviceFarmTabDetails.awsDeviceFarmProjects.filter(
        (awsDeviceFarmProject) => projectArn.includes(awsDeviceFarmProject.awsProjectArn)
      );
      // Add Project
      awsProjects.map((awsProject) => {
        if (
          projectArn.includes(awsProject.arn) &&
          _awsDeviceFarmTabDetails.awsDeviceFarmProjects.filter(
            (awsDeviceFarmProject) => awsProject.arn == awsDeviceFarmProject.awsProjectArn
          ).length === 0
        ) {
          _awsDeviceFarmTabDetails.awsDeviceFarmProjects.push({
            awsProjectArn: awsProject.arn,
            awsProjectName: awsProject.name,
            awsDevicePool: [],
          });
        }
      });
      dispatch(changeAwsDeviceFarmTabData(_awsDeviceFarmTabDetails));
      let awsDeviceFarmProject = {
        accessKeyId: _awsDeviceFarmTabDetails?.accessKeyId,
        secretAccessKey: _awsDeviceFarmTabDetails?.secretAccessKey,
        awsDeviceFarmProjects: _awsDeviceFarmTabDetails?.awsDeviceFarmProjects,
      };
      debounce(awsDeviceFarmProject);
    },
    [awsProjects]
  );

  useEffect(() => {
    if (
      awsDeviceFarmTabDetails &&
      awsDeviceFarmTabDetails.accessKeyId &&
      awsDeviceFarmTabDetails.secretAccessKey &&
      awsDeviceFarmTabDetails.awsDeviceFarmProjects &&
      previous_awsDeviceFarmTabDetails === undefined
    ) {
      dispatch(getAwsDeviceFarmPoolsLists());
    }
  }, [awsDeviceFarmTabDetails, previous_awsDeviceFarmTabDetails]);

  const handleSelectPool = (e, selectedProjectArn) => {
    let _awsDeviceFarmTabDetails = { ...awsDeviceFarmTabDetails };
    _awsDeviceFarmTabDetails.awsDeviceFarmProjects.forEach((element) => {
      if (element.awsProjectArn === selectedProjectArn) {
        if (e.length !== 0) {
          e.map((item) => {
            element.awsDevicePool = [];
            let awsDeviceProject = awsPools.filter((projects) => projects.awsProjectArn === selectedProjectArn);
            let finalAwsDevicePool = awsDeviceProject[0].awsDevicePool.filter((awsDevicePool) =>
              e.includes(awsDevicePool.arn)
            );
            element.awsDevicePool = [...finalAwsDevicePool];
          });
        } else {
          element.awsDevicePool = [];
        }
      }
    });
    dispatch(changeAwsDeviceFarmTabData(_awsDeviceFarmTabDetails));
  };

  let selectedProjects = [];
  let selectedAwsDevicePoolArn = {};
  if (awsDeviceFarmTabDetails.awsDeviceFarmProjects && awsDeviceFarmTabDetails.awsDeviceFarmProjects) {
    // Selected Project
    awsDeviceFarmTabDetails.awsDeviceFarmProjects.forEach((awsDeviceProject) => {
      selectedProjects.push(awsDeviceProject.awsProjectArn);
      // Selected Device pool
      selectedAwsDevicePoolArn[awsDeviceProject.awsProjectArn] = [];
      awsDeviceProject.awsDevicePool.forEach((devicePool) => {
        selectedAwsDevicePoolArn[awsDeviceProject.awsProjectArn].push(devicePool.arn);
      });
    });
  }

  const removeAWSDevicePool = (projectArn) => {
    let _awsDeviceFarmTabDetails = { ...awsDeviceFarmTabDetails };
    const removedDevicePool =
      _awsDeviceFarmTabDetails &&
      _awsDeviceFarmTabDetails?.awsDeviceFarmProjects?.filter((data, j) => data.awsProjectArn !== projectArn);
    dispatch(changeAwsDeviceFarmTabData({ ..._awsDeviceFarmTabDetails, awsDeviceFarmProjects: removedDevicePool }));
    const removeAwsPool = awsPools.filter((data, j) => data.awsProjectArn !== projectArn);
    dispatch({ type: REMOVE_AWS_POOLS, removeAwsPool });
  };

  return (
    awsDeviceFarmTabDetails &&
    awsDeviceFarmTabDetails?.secretAccessKey &&
    awsDeviceFarmTabDetails?.accessKeyId &&
    !_.isEmpty(awsProjects) && (
      <Col span={16}>
        <Row className="mt-30 mb-30">
          <Col span={4} offset={1} className="font-size-16 font-weight-500 font-color-darkBlue">
            Project & Device
          </Col>
          <Col span={19} className="text">
            <Label>Projects</Label>
            <Select
              className="w-100 mb-20 mt-5"
              onChange={(e) => debouncedChangeHandler(e)}
              value={selectedProjects}
              mode="tags"
              placeholder="Select Project"
            >
              {AwsProjectsList}
            </Select>
            {!_.isEmpty(awsPools) && (
              <>
                {!_.isEmpty(awsPools) &&
                  awsPools?.map((pools, i) => {
                    return (
                      <>
                        <Label>{pools.awsProjectName}</Label>
                        <div className="display-flex">
                          <SelectCol
                            placeholder="Add Device Pool"
                            mode="tags"
                            className="w-100 mr-10 mb-20 mt-5"
                            tokenSeparators={[","]}
                            value={selectedAwsDevicePoolArn[pools.awsProjectArn]}
                            onChange={(e) => {
                              handleSelectPool(e, pools.awsProjectArn);
                            }}
                          >
                            {!_.isEmpty(pools.awsDevicePool) &&
                              pools?.awsDevicePool?.map((poolsOption, i) => {
                                return (
                                  <Option key={i} value={poolsOption.arn}>
                                    {poolsOption.name}
                                  </Option>
                                );
                              })}
                          </SelectCol>
                          <div
                            className="display-flex align-items-center cursor-pointer"
                            onClick={() => removeAWSDevicePool(pools?.awsProjectArn)}
                          >
                            <img src={DELETE_ICON} alt="delete" />
                            <span>Delete</span>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </>
            )}
          </Col>
        </Row>
      </Col>
    )
  );
};

export default AWSDeviceFarmProjects;
