import { Button, Col, Row, Select, Spin } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTestRailConfig,
  getTestRailProject,
  getTestRailProjectOnLoad,
  updateTestRailConfig,
  resetTestRailDetails,
} from "../../actions/ProjectSettingsActions";
import { ERROR_STATUS, TEST_RAILS_CONFIG } from "../../Constants";
import {
  isArrayNotUndefinedNotNullNotEmpty,
  isNotNullAndNotEmptyAndNotUndefined,
  isNullOrUndefinedOrEmpty,
  showNotification,
} from "../../Util";
import ErrorComponent from "../CoreComponents/ErrorComponent/ErrorComponent";
import SaveResetButton from "../CoreComponents/SaveResetComponent/SaveResetButton";
import { Label, Required, StyledInput, Title } from "../CoreComponents/StyledComponent/StyledComponents";

const initialTestRailConfigData = {
  host: "",
  userName: "",
  password: "",
  apiKey: "",
  testRailProjectId: "",
  isTestSuiteAvailable: "",
};
const { Option } = Select;

const TestRailsConfig = (props) => {
  const { projectId, selectedTab } = props;
  const dispatch = useDispatch();
  const { getTestRailConfigData, projectLoading, getTestRailConfigProjectList, fetchProjectError, testRailLoading } =
    useSelector((state) => state.ProjectSettingsReducer);
  const { getProjectByOrgId } = useSelector((state) => state.ProjectsReducer);

  const [testRailConfigData, setTestRailConfigData] = useState(initialTestRailConfigData);
  const [testRailProjectName, setTestRailProjectName] = useState(undefined);
  const [isError, setIsError] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    dispatch(resetTestRailDetails);
    setTestRailConfigData(initialTestRailConfigData);
  }, [getProjectByOrgId]);

  useEffect(() => {
    if (!_.isEmpty(getTestRailConfigData)) {
      setTestRailConfigData({
        host: getTestRailConfigData.host,
        userName: getTestRailConfigData.userName,
        password: getTestRailConfigData.password,
        apiKey: getTestRailConfigData.apiKey,
        testRailProjectId: getTestRailConfigData.testRailProjectId,
      });
      dispatch(
        getTestRailProjectOnLoad({
          host: getTestRailConfigData.host,
          userName: getTestRailConfigData.userName,
          password: getTestRailConfigData.password || "",
          apiKey: getTestRailConfigData.apiKey || "",
        })
      );
    }
  }, [getTestRailConfigData]);

  useEffect(() => {
    if (getTestRailConfigProjectList) {
      let projectName =
        getTestRailConfigProjectList &&
        getTestRailConfigProjectList.find((i) => i.id === parseInt(getTestRailConfigData.testRailProjectId));
      setTestRailProjectName(projectName && projectName.name);
    }
  }, [getTestRailConfigProjectList]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTestRailConfigData({ ...testRailConfigData, [name]: value });
    setIsError(false);
    setIsSearch(true);
    setTestRailProjectName(undefined);
    setIsDisabled(true);
  };

  const searchForTestRailProject = () => {
    if (
      isNotNullAndNotEmptyAndNotUndefined(testRailConfigData.host) &&
      isNotNullAndNotEmptyAndNotUndefined(testRailConfigData.userName)
    ) {
      if (
        isNotNullAndNotEmptyAndNotUndefined(testRailConfigData.password) ||
        isNotNullAndNotEmptyAndNotUndefined(testRailConfigData.apiKey)
      ) {
        let preObj = {
          host: testRailConfigData.host,
          userName: testRailConfigData.userName,
          password: testRailConfigData.password || "",
          apiKey: testRailConfigData.apiKey || "",
        };

        dispatch(getTestRailProject(preObj));
        setIsError(false);
        setIsSearch(false);
      } else {
        showNotification("info", "Please Enter Password Or ApiKey.");
      }
    } else {
      showNotification("info", "Please Enter All Details.");
    }
    setTestRailProjectName(undefined);
  };
  let projectsList = [];
  if (isArrayNotUndefinedNotNullNotEmpty(getTestRailConfigProjectList)) {
    for (let i = 0; i < getTestRailConfigProjectList.length; i++) {
      projectsList.push(
        <Option key={getTestRailConfigProjectList[i].id}>{getTestRailConfigProjectList[i].name}</Option>
      );
    }
  }

  const handleProjectChange = (e) => {
    setTestRailConfigData({ ...testRailConfigData, testRailProjectId: parseInt(e) });
    let projectName = getTestRailConfigProjectList && getTestRailConfigProjectList.find((i) => i.id === parseInt(e));
    setTestRailProjectName(projectName && projectName.name);
    setIsDisabled(false);
  };

  const saveTestRailConfig = () => {
    if (testRailConfigData && testRailConfigData.testRailProjectId) {
      let suiteId =
        getTestRailConfigProjectList &&
        getTestRailConfigProjectList.find((i) => i.id === testRailConfigData.testRailProjectId);
      dispatch(
        updateTestRailConfig({
          ...testRailConfigData,
          isTestSuiteAvailable: suiteId.suiteMode,
          projectId: parseInt(projectId),
        })
      );
    }
  };

  useEffect(() => {
    if (fetchProjectError && fetchProjectError.status === ERROR_STATUS) {
      setIsError(true);
    }
  }, [fetchProjectError]);

  useEffect(() => {
    if (projectLoading) {
      setIsDisabled(true);
    }
  }, [projectLoading]);

  const resetTestRailConfigData = () => {
    if (!_.isEmpty(getTestRailConfigData)) {
      setTestRailConfigData({
        host: getTestRailConfigData.host,
        userName: getTestRailConfigData.userName,
        password: getTestRailConfigData.password,
        apiKey: getTestRailConfigData.apiKey,
        testRailProjectId: getTestRailConfigData.testRailProjectId,
      });
      dispatch(
        getTestRailProjectOnLoad({
          host: getTestRailConfigData.host,
          userName: getTestRailConfigData.userName,
          password: getTestRailConfigData.password || "",
          apiKey: getTestRailConfigData.apiKey || "",
        })
      );

      setIsError(false);
      setIsDisabled(true);
      setIsSearch(false);
    } else {
      setTestRailConfigData(initialTestRailConfigData);
      setTestRailProjectName("");
    }
  };

  const isResetDisable = () => {
    if (!_.isEmpty(getTestRailConfigData)) {
      return (
        _.isEqual(testRailConfigData.host, getTestRailConfigData.host) &&
        _.isEqual(testRailConfigData.userName, getTestRailConfigData.userName) &&
        _.isEqual(testRailConfigData.password, getTestRailConfigData.password) &&
        _.isEqual(testRailConfigData.apiKey, getTestRailConfigData.apiKey) &&
        _.isEqual(testRailConfigData.testRailProjectId, getTestRailConfigData.testRailProjectId)
      );
    } else {
      return Object.values(testRailConfigData).every((i) => isNullOrUndefinedOrEmpty(i));
    }
  };

  const disableValidateCredential = () =>
    (!_.isNull(getTestRailConfigData) &&
      _.isEqual(testRailConfigData.host, getTestRailConfigData.host) &&
      _.isEqual(testRailConfigData.userName, getTestRailConfigData.userName) &&
      _.isEqual(testRailConfigData.password, getTestRailConfigData.password) &&
      _.isEqual(testRailConfigData.apiKey, getTestRailConfigData.apiKey)) ||
    isNullOrUndefinedOrEmpty(testRailConfigData.host) ||
    isNullOrUndefinedOrEmpty(testRailConfigData.userName) ||
    (isNullOrUndefinedOrEmpty(testRailConfigData.password) && isNullOrUndefinedOrEmpty(testRailConfigData.apiKey));

  return (
    <Spin spinning={projectLoading || testRailLoading} tip="Loading">
      <Row justify="space-between" className="innerHeader p-12">
        <div className="mainHeaderText ml-10">Testrail Configuration</div>
        <SaveResetButton
          submitLabel={!_.isEmpty(getTestRailConfigData) ? "Update" : "Save"}
          resetLabel={"Reset"}
          submitDisable={isDisabled}
          resetDisable={isResetDisable()}
          handleSubmit={saveTestRailConfig}
          handleReset={resetTestRailConfigData}
        />
      </Row>
      <Row justify="center">
        <Col xs={{ span: 24 }} lg={{ span: 14 }}>
          <Row className="mt-30 mb-30" gutter={50}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 1 }}>
              <h3 className="font-size-16 font-weight-500 font-color-darkBlue">Basic Details</h3>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 17 }} className="text">
              <Label>Host</Label>
              <Required>*</Required>
              <StyledInput
                className="mb-20 mt-5"
                name="host"
                placeholder="Please Enter Host "
                value={testRailConfigData.host}
                onChange={(e) => handleChange(e)}
                autoFocus
              />
              <Label>User Name</Label>
              <Required>*</Required>
              <StyledInput
                className="mb-20 mt-5"
                name="userName"
                value={testRailConfigData.userName}
                placeholder="Please Enter User Name"
                onChange={(e) => handleChange(e)}
              />
              <Label>Password</Label>
              <StyledInput
                className="mb-20 mt-5"
                name="password"
                value={testRailConfigData.password}
                placeholder="Please Enter Password"
                onChange={(e) => handleChange(e)}
              />
              <Label>API key</Label>
              <StyledInput
                className="mb-20 mt-5"
                name="apiKey"
                value={testRailConfigData.apiKey}
                placeholder="Please Enter API key"
                onChange={(e) => handleChange(e)}
              />
              <Button
                className="light-outline mb-20"
                onClick={searchForTestRailProject}
                disabled={disableValidateCredential()}
              >
                Validate credentials
              </Button>
              {isError && <ErrorComponent errorMessage="Please check credentials is invalid." />}
              <Row>
                {!_.isEmpty(getTestRailConfigProjectList) && !isSearch && !testRailLoading && (
                  <>
                    <Label>
                      Select Testrail Project<Required>*</Required>
                    </Label>
                    <Select
                      className="w-100 h-40 mt-5"
                      placeholder="Select Testrail Project"
                      onChange={(e) => handleProjectChange(e)}
                      value={testRailProjectName || undefined}
                    >
                      {projectsList}
                    </Select>
                  </>
                )}
              </Row>
              {!isDisabled && !_.isEmpty(getTestRailConfigData) && (
                <ErrorComponent
                  errorMessage={"Note: Changing the project will remove attached test case's with Test scenario."}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default TestRailsConfig;
