import React from "react";

import { ReactComponent as addIcon } from "../images/CommonImage/addIcon.svg";
import { ReactComponent as addSubStepIconOnHover } from "../images/CommonImage/addSubStepIconOnHover.svg";
import { ReactComponent as androidLogo } from "../images/CommonImage/androidLogo.svg";
import { ReactComponent as applebrand } from "../images/CommonImage/applebrand.svg";
import { ReactComponent as buildDataSetHeaderIcon } from "../images/CommonImage/buildDataSetHeaderIcon.svg";
import { ReactComponent as buildDataSetIcon } from "../images/CommonImage/buildDataSetIcon.svg";
import { ReactComponent as clearResultIcon } from "../images/CommonImage/clearResultIcon.svg";
import { ReactComponent as clockIcon } from "../images/CommonImage/clockIcon.svg";
import { ReactComponent as closeCollapseIconForParentStep } from "../images/CommonImage/closeCollapseIconForParentStep.svg";
import { ReactComponent as closeCollapseIconForSubStep } from "../images/CommonImage/closeCollapseIconForSubStep.svg";
import { ReactComponent as closeIcon } from "../images/CommonImage/closeIcon.svg";
import { ReactComponent as closeSessionIcon } from "../images/CommonImage/closeSessionIcon.svg";
import { ReactComponent as downArrow } from "../images/CommonImage/downArrow.svg";
import { ReactComponent as dragAndDropIcon } from "../images/CommonImage/dragAndDropIcon.svg";
import { ReactComponent as emptyRecTestStepsImage } from "../images/CommonImage/emptyRecTestStepsImage.svg";
import { ReactComponent as helpIcon } from "../images/CommonImage/helpIcon.svg";
import { ReactComponent as hideDeviceViewIcon } from "../images/CommonImage/hideDeviceViewIcon.svg";
import { ReactComponent as addActionIcon } from "../images/CommonImage/iconAddAction.svg";
import { ReactComponent as iconAddDelayAction } from "../images/CommonImage/iconAddDelayAction.svg";
import { ReactComponent as iconAddSubStep } from "../images/CommonImage/iconAddSubStep.svg";
import { ReactComponent as iconAssignValueToTestDataAction } from "../images/CommonImage/iconAssignValueToTestDataAction.svg";
import { ReactComponent as iconClearTextAction } from "../images/CommonImage/iconClearTextAction.svg";
import { ReactComponent as iconConditionStep } from "../images/CommonImage/iconConditionalStep.svg";
import { ReactComponent as iconDisable } from "../images/CommonImage/iconDisable.svg";
import { ReactComponent as iconDownArrow } from "../images/CommonImage/iconDownArrow.svg";
import { ReactComponent as iconDuplicate } from "../images/CommonImage/iconDuplicate.svg";
import { ReactComponent as iconEnterTextAction } from "../images/CommonImage/iconEnterTextAction.svg";
import { ReactComponent as iconGeneralAction } from "../images/CommonImage/iconGeneralAction.svg";
import { ReactComponent as iconImportStep } from "../images/CommonImage/iconImportStep.svg";
import { ReactComponent as iconKeypadEnterAction } from "../images/CommonImage/iconKeypadEnterAction.svg";
import { ReactComponent as iconLongPressAction } from "../images/CommonImage/iconLongPressAction.svg";
import { ReactComponent as iconLunchAppAction } from "../images/CommonImage/iconLunchAppAction.svg";
import { ReactComponent as iconNavigateBackAction } from "../images/CommonImage/iconNavigateBackAction.svg";
import { ReactComponent as iconNavigateHomeAction } from "../images/CommonImage/iconNavigateHomeAction.svg";
import { ReactComponent as iconPlayForSubSTep } from "../images/CommonImage/iconPlayForSubHeader.svg";
import { ReactComponent as iconPlayFromHere } from "../images/CommonImage/iconPlayFromHere.svg";
import { ReactComponent as iconPlayTillHere } from "../images/CommonImage/iconPlayTillHere.svg";
import { ReactComponent as iconReset } from "../images/CommonImage/iconReset.svg";
import { ReactComponent as iconSave } from "../images/CommonImage/iconSave.svg";
import { ReactComponent as iconSCreenShotElementAction } from "../images/CommonImage/iconScreenshotElementAction.svg";
import { ReactComponent as iconScreenshotPageAction } from "../images/CommonImage/iconScreenshotPageAction.svg";
import { ReactComponent as iconScrollPageAction } from "../images/CommonImage/iconScrollPageAction.svg";
import { ReactComponent as importIconStop } from "../images/CommonImage/iconStop.svg";
import { ReactComponent as iconSwipeElementAction } from "../images/CommonImage/iconSwipeElementAction.svg";
import { ReactComponent as iconTapAction } from "../images/CommonImage/iconTapAction.svg";
import { ReactComponent as iconTapByIndexAction } from "../images/CommonImage/iconTapByIndexAction.svg";
import { ReactComponent as addVerificationIcon } from "../images/CommonImage/iconVerification.svg";
import { ReactComponent as imageEmptyRecords } from "../images/CommonImage/imageEmptyRecords.svg";
import { ReactComponent as importActionIcon } from "../images/CommonImage/importActionIcon.svg";
import { ReactComponent as importStepIcon } from "../images/CommonImage/importStepIcon.svg";
import { ReactComponent as logo } from "../images/CommonImage/logo.svg";
import { ReactComponent as menuButton } from "../images/CommonImage/menuButton.svg";
import { ReactComponent as moreActionIconOnHover } from "../images/CommonImage/moreActionIconOnHover.svg";
import { ReactComponent as addActionIconOnHover } from "../images/CommonImage/newAction.svg";
import { ReactComponent as openCollapseIconForParentStep } from "../images/CommonImage/openCollapseIconForParentStep.svg";
import { ReactComponent as openCollapseIconForSubStep } from "../images/CommonImage/openCollapseIconForSubStep.svg";
import { ReactComponent as performAndSaveIcon } from "../images/CommonImage/performAndSaveIcon.svg";
import { ReactComponent as playIcon } from "../images/CommonImage/playIcon.svg";
import { ReactComponent as previewRunIcon } from "../images/CommonImage/previewRunIcon.svg";
import { ReactComponent as showDeviceViewIcon } from "../images/CommonImage/showDeviceViewIcon.svg";
import { ReactComponent as iconStepDelete } from "../images/CommonImage/stepDeleteIcon.svg";
import { ReactComponent as stopIcon } from "../images/CommonImage/stopIcon.svg";
import { ReactComponent as testPlanResultIcon } from "../images/CommonImage/testPlanResultIcon.svg";
import { ReactComponent as userIcon } from "../images/CommonImage/userIcon.svg";
import { ReactComponent as stepsDragAndDropIcon } from "../images/CommonImage/stepsDragAndDropIcon.svg";

import { ReactComponent as androidLogoImage } from "../images/CommonImage/androidLogoImage.svg";
import { ReactComponent as device_orientation_icon } from "../images/CommonImage/device_orientation_icon.svg";
import { ReactComponent as ignoreFailureAndContinueExecution } from "../images/CommonImage/ignoreFailure.svg";
import { ReactComponent as infoIcon } from "../images/CommonImage/infoIcon.svg";
import { ReactComponent as pressBack } from "../images/CommonImage/pressBack.svg";
import { ReactComponent as pressCloudUpload } from "../images/CommonImage/pressCloudUpload.svg";
import { ReactComponent as pressHome } from "../images/CommonImage/pressHome.svg";
import { ReactComponent as pressKeyboard } from "../images/CommonImage/pressKeyboard.svg";
import { ReactComponent as pressLock } from "../images/CommonImage/pressLock.svg";
import { ReactComponent as pressLockOpen } from "../images/CommonImage/pressLockOpen.svg";
import { ReactComponent as pressMenuOpen } from "../images/CommonImage/pressMenuOpen.svg";
import { ReactComponent as pressNotifications } from "../images/CommonImage/pressNotifications.svg";
import { ReactComponent as pressVolumeDown } from "../images/CommonImage/pressVolumeDown.svg";
import { ReactComponent as pressVolumeUp } from "../images/CommonImage/pressVolumeUp.svg";

//new design  performAction Icons(blue border)
import { ReactComponent as iconPerformSave } from "../images/CommonImage/iconPerformSave.svg";
import { ReactComponent as resetIcon } from "../images/CommonImage/resetIcon.svg";
import { ReactComponent as saveIcon } from "../images/CommonImage/saveIcon.svg";

import { ReactComponent as deleteIcon } from "../images/CommonImage/deleteIcon.svg";
import { ReactComponent as iconApplicationString } from "../images/CommonImage/iconApplicationString.svg";
import { ReactComponent as iconDeviceString } from "../images/CommonImage/iconDeviceString.svg";

import { ReactComponent as androidIcon } from "../images/CommonImage/androidIcon.svg";
import { ReactComponent as applicationStringBlackIcon } from "../images/CommonImage/applicationStringBlackIcon.svg";
import { ReactComponent as arrowDown } from "../images/CommonImage/arrowDown.svg";
import { ReactComponent as arrowLeft } from "../images/CommonImage/arrowLeft.svg";
import { ReactComponent as arrowRight } from "../images/CommonImage/arrowRight.svg";
import { ReactComponent as arrowUp } from "../images/CommonImage/arrowUp.svg";
import { ReactComponent as closeChildCollapseIconInModal } from "../images/CommonImage/closeChildCollapseIconInModal.svg";
import { ReactComponent as deleteElementIcon } from "../images/CommonImage/deleteElementIcon.svg";
import { ReactComponent as deviceImg } from "../images/CommonImage/deviceImg.svg";
import { ReactComponent as deviceInformationIcon } from "../images/CommonImage/deviceInformationIcon.svg";
import { ReactComponent as disableElementIcon } from "../images/CommonImage/disableElementIcon.svg";
import { ReactComponent as editRulesIcon } from "../images/CommonImage/editRulesIcon.svg";
import { ReactComponent as emptyElementsImg } from "../images/CommonImage/emptyElementsImg.svg";
import { ReactComponent as generalSettingIcon } from "../images/CommonImage/generalSettingIcon.svg";
import { ReactComponent as iconDelete } from "../images/CommonImage/iconDelete.svg";
import { ReactComponent as iconEdit } from "../images/CommonImage/iconEdit.svg";
import { ReactComponent as iconFilter } from "../images/CommonImage/iconFilter.svg";
import { ReactComponent as iconLock } from "../images/CommonImage/iconLock.svg";
import { ReactComponent as importIcon } from "../images/CommonImage/importIcon.svg";
import { ReactComponent as inspectorElementImg } from "../images/CommonImage/inspectorElementImg.svg";
import { ReactComponent as iosIcon } from "../images/CommonImage/iosIcon.svg";
import { ReactComponent as modalCloseIcon } from "../images/CommonImage/modalCloseIcon.svg";
import { ReactComponent as morePropertiesIcon } from "../images/CommonImage/morePropertiesIcon.svg";
import { ReactComponent as openChildCollapseIconInModal } from "../images/CommonImage/openChildCollapseIconInModal.svg";
import { ReactComponent as openCollapseIconInModal } from "../images/CommonImage/openCollapseIconInModal.svg";
import { ReactComponent as parentElementArrowIcon } from "../images/CommonImage/parentElementArrowIcon.svg";
import { ReactComponent as releaseDeviceIcon } from "../images/CommonImage/releaseDeviceIcon.svg";
import { ReactComponent as replaceElementIcon } from "../images/CommonImage/replaceElementIcon.svg";
import { ReactComponent as searchIcon } from "../images/CommonImage/searchIcon.svg";
import { ReactComponent as selectTestDataIcon } from "../images/CommonImage/selectTestDataIcon.svg";
import { ReactComponent as settingIcon } from "../images/CommonImage/settingIcon.svg";
import { ReactComponent as timerOutImage } from "../images/CommonImage/timerOutImage.svg";
import { ReactComponent as useDeviceForRunJobIcon } from "../images/CommonImage/useDeviceForRunJobIcon.svg";
import { ReactComponent as useDeviceIcon } from "../images/CommonImage/useDeviceIcon.svg";

//resultIcon
import { ReactComponent as continueAfterFailIcon } from "../images/CommonImage/continueAfterFailIcon.svg";
import { ReactComponent as failIcon } from "../images/CommonImage/failIcon.svg";
import { ReactComponent as preConditionFailIcon } from "../images/CommonImage/preConditionFailIcon.svg";
import { ReactComponent as skipIcon } from "../images/CommonImage/skipIcon.svg";
import { ReactComponent as successIcon } from "../images/CommonImage/successIcon.svg";
import { ReactComponent as warningNoElementDataFound } from "../images/CommonImage/warningNoElementDataFound.svg";

//update UI
import { ReactComponent as applicationStringIconNew } from "../images/CommonImage/applicationStringIconNew.svg";
import { ReactComponent as closeIconRed } from "../images/CommonImage/closeIconRed.svg";
import { ReactComponent as createIcon } from "../images/CommonImage/createIcon.svg";
import { ReactComponent as customIcon } from "../images/CommonImage/customIcon.svg";
import { ReactComponent as generalSettingsNewIcon } from "../images/CommonImage/generalSettingsNewIcon.svg";
import { ReactComponent as iconApplicationStringNew } from "../images/CommonImage/iconApplicationStringNew.svg";
import { ReactComponent as iconBuildTestDataSetNew } from "../images/CommonImage/iconBuildTestDataSetNew.svg";
import { ReactComponent as iconEditNew } from "../images/CommonImage/iconEditNew.svg";
import { ReactComponent as iconMoreNew } from "../images/CommonImage/iconMoreNew.svg";
import { ReactComponent as inviteMemberIcon } from "../images/CommonImage/inviteMemberIcon.svg";
import { ReactComponent as newIconSearch } from "../images/CommonImage/newIconSearch.svg";
import { ReactComponent as privateIcon } from "../images/CommonImage/privateIcon.svg";
import { ReactComponent as projectAndTeamIcon } from "../images/CommonImage/projectAndTeamIcon.svg";
import { ReactComponent as publicIcon } from "../images/CommonImage/publicIcon.svg";
import { ReactComponent as sendIcon } from "../images/CommonImage/sendIcon.svg";
import { ReactComponent as viewInvitationIcon } from "../images/CommonImage/viewInvitationIcon.svg";
import { ReactComponent as xpressLogo } from "../images/CommonImage/xpressLogo.svg";
import { ReactComponent as loadDeviceIcon } from "../images/CommonImage/loadDeviceIcon.svg";
import { ReactComponent as editRulesIconNew } from "../images/CommonImage/editRulesIconNew.svg";
import { ReactComponent as addCircleIcon } from "../images/CommonImage/addCircleIcon.svg";
import { ReactComponent as removeIcon } from "../images/CommonImage/removeIcon.svg";
import { ReactComponent as deleteIconNew } from "../images/CommonImage/deleteIconNew.svg";
import { ReactComponent as cloneIcon } from "../images/CommonImage/cloneIcon.svg";
import { ReactComponent as trendsIconNew } from "../images/CommonImage/trendsIconNew.svg";
import { ReactComponent as crossRemoveIcon } from "../images/CommonImage/crossRemoveIcon.svg";
import { ReactComponent as selectTestDataIconNew } from "../images/CommonImage/selectTestDataIconNew.svg";
import { ReactComponent as selectAreaImage } from "../images/CommonImage/selectAreaImage.svg";
import { ReactComponent as conditionDeleteImage } from "../images/CommonImage/conditionDeleteImage.svg";
import { ReactComponent as newCollapseOpenIcon } from "../images/CommonImage/newCollapseOpenIcon.svg";
import { ReactComponent as newCollapseCloseIcon } from "../images/CommonImage/newCollapseCloseIcon.svg";
import { ReactComponent as iconRobotStep } from "../images/CommonImage/robot.svg";
import { ReactComponent as iconAi } from "../images/CommonImage/iconAi.svg";
import { ReactComponent as searchDeviceIcon } from "../images/CommonImage/searchDeviceIcon.svg";
import { ReactComponent as hideDeviceViewIconNew } from "../images/CommonImage/hideDeviceViewIconNew.svg";
import { ReactComponent as showDeviceViewIconNew } from "../images/CommonImage/showDeviceViewIconNew.svg";
import { ReactComponent as infoIconNew } from "../images/CommonImage/infoIconNew.svg";
import { ReactComponent as releaseIconNew } from "../images/CommonImage/releaseIconNew.svg";
import { ReactComponent as emptyDeviceListImg } from "../images/CommonImage/emptyDeviceListImg.svg";
import { ReactComponent as iPhoneImage } from "../images/CommonImage/iPhoneImage.svg";
import { ReactComponent as androidPhoneImg } from "../images/CommonImage/androidPhoneImg.svg";
import { ReactComponent as addOrRemoveDeviceIcon } from "../images/CommonImage/addOrRemoveDeviceIcon.svg";
import { ReactComponent as iconScheduledNew } from "../images/CommonImage/iconScheduledNew.svg";
import { ReactComponent as iconDeleteNew } from "../images/CommonImage/iconDeleteNew.svg";
import { ReactComponent as filterIconV2 } from "../images/CommonImage/filterIconV2.svg";
import { ReactComponent as deleteIconWithRedBorder } from "../images/CommonImage/deleteIconWithRedBorder.svg";
import { ReactComponent as selectIcon } from "../images/CommonImage/selectIcon.svg";
import { ReactComponent as blueCloseCollapseIcon } from "../images/CommonImage/blueCloseCollapseIcon.svg";
import { ReactComponent as blueOpenCollapseIcon } from "../images/CommonImage/blueOpenCollapseIcon.svg";
import { ReactComponent as reportDetailsIcon } from "../images/CommonImage/reportDetailsIcon.svg";
import { ReactComponent as trashIcon } from "../images/CommonImage/trashIcon.svg";
import { ReactComponent as uploadBuildIcon } from "../images/CommonImage/uploadBuildIcon.svg";
import { ReactComponent as undoIcon } from "../images/CommonImage/undoIcon.svg";
import { ReactComponent as dragScenarioImg } from "../images/CommonImage/dragScenarioImg.svg";
import { ReactComponent as abortIcon } from "../images/CommonImage/abortIcon.svg";
import { ReactComponent as iconMoreOptions } from "../images/CommonImage/iconMoreOptions.svg";
import { ReactComponent as acceptInvitationIcon } from "../images/CommonImage/acceptInvitationIcon.svg";
import { ReactComponent as resendInvitationIcon } from "../images/CommonImage/resendInvitationIcon.svg";
import { ReactComponent as iconTeam } from "../images/CommonImage/iconTeam.svg";
import { ReactComponent as clearStringsIcon } from "../images/CommonImage/clearStringsIcon.svg";
import { ReactComponent as copyIcon } from "../images/CommonImage/copyIcon.svg";
import { ReactComponent as iconView } from "../images/CommonImage/iconView.svg";
import { ReactComponent as accessibilityIcon } from "../images/CommonImage/accessibilityIcon.svg";
import { ReactComponent as localReportIcon } from "../images/CommonImage/localReportIcon.svg";

//deviceImage
import { ReactComponent as googlePixel4aImg } from "../images/CommonImage/googlePixel4aImg.svg";
import { ReactComponent as samsungA02Img } from "../images/CommonImage/samsungA02Img.svg";
import { ReactComponent as onePlus6tImg } from "../images/CommonImage/onePlus6tImg.svg";
import { ReactComponent as googlePixel6proImg } from "../images/CommonImage/googlePixel6proImg.svg";
import { ReactComponent as samsungA52Img } from "../images/CommonImage/samsungA52Img.svg";
import { ReactComponent as motorolaG04Img } from "../images/CommonImage/motorolaG04Img.svg";
import { ReactComponent as samsungM04Img } from "../images/CommonImage/samsungM04Img.svg";
import { ReactComponent as samsungA05MImg } from "../images/CommonImage/samsungA05MImg.svg";
import { ReactComponent as vivoImg } from "../images/CommonImage/vivoImg.svg";
import { ReactComponent as iPhoneSEImg } from "../images/CommonImage/iPhoneSEImg.svg";
import { ReactComponent as iPhone11Img } from "../images/CommonImage/iPhone11Img.svg";

//login
import { ReactComponent as authBanner } from "../images/CommonImage/authBanner.svg";
import { ReactComponent as successSignUpImage } from "../images/CommonImage/successSignUpImage.svg";
import { ReactComponent as verifyEmailImage } from "../images/CommonImage/verifyEmailImage.svg";
import { ReactComponent as organizationStep1Img } from "../images/CommonImage/organizationStep1Img.svg";
import { ReactComponent as organizationStep2Img } from "../images/CommonImage/organizationStep2Img.svg";
import { ReactComponent as organizationStep3Img } from "../images/CommonImage/organizationStep3Img.svg";
import { ReactComponent as AIIcon } from "../images/CommonImage/AIIcon.svg";

//menu-icons
import { ReactComponent as projectMenuIcon } from "../images/CommonImage/projectMenuIcon.svg";
import { ReactComponent as teamMenuIcon } from "../images/CommonImage/teamMenuIcon.svg";
import { ReactComponent as deviceListMenuIcon } from "../images/CommonImage/deviceListMenuIcon.svg";
import { ReactComponent as executionReportMenuIcon } from "../images/CommonImage/executionReportMenuIcon.svg";
import { ReactComponent as serverManagementMenuIcon } from "../images/CommonImage/serverManagementMenuIcon.svg";
import { ReactComponent as linkIcon } from "../images/CommonImage/linkIcon.svg";

//device-list
import { ReactComponent as androidDeviceIcon } from "../images/CommonImage/androidDeviceIcon.svg";
import { ReactComponent as iOSDeviceIcon } from "../images/CommonImage/iOSDeviceIcon.svg";

//device-server
import { ReactComponent as openCollapseIconForTable } from "../images/CommonImage/openCollapseIconForTable.svg";
import { ReactComponent as closeCollapseIconForTable } from "../images/CommonImage/closeCollapseIconForTable.svg";
import { ReactComponent as deviceServerIcon } from "../images/CommonImage/deviceServerIcon.svg";

//AI-Step 
import { ReactComponent as AIStepIcon } from "../images/CommonImage/AIStepIcon.svg";
import { ReactComponent as generalSettingsIconV2 } from "../images/CommonImage/generalSettingsIconV2.svg";
import { ReactComponent as pageSourceIconV2 } from "../images/CommonImage/pageSourceIconV2.svg";
import { ReactComponent as AIJsonDataIconV2 } from "../images/CommonImage/AIJsonDataIconV2.svg";

import { ARROW_DOWN } from "../CdnImagePath";
import {
  ADD_ACTION_ICON_ON_HOVER,
  ADD_CIRCLE_ICON,
  ADD_ICON,
  ADD_SUB_STEP_ICON_ON_HOVER,
  ANDROID_ICON,
  ANDROID_LOGO,
  ANDROID_LOGO_IMAGE,
  ANDROID_PHONE_IMG,
  APPLICATION_STRING_BLACK_ICON,
  APPLICATION_STRING_ICON,
  APPLICATION_STRING_ICON_NEW,
  BUILD_DATA_SET_ICON,
  BUILD_TEST_DATA_SET_ICON,
  CLEAR_RESULT_ICON,
  CLOCK_ICON,
  CLONE_ICON,
  CLOSE_CHILD_COLLAPSE_ICON_IN_MODAL,
  CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP,
  CLOSE_COLLAPSE_ICON_FOR_SUB_STEP,
  CLOSE_ICON_RED,
  CLOSE_SESSION_ICON,
  CONDITION_DELETE_IMAGE,
  CREATE_ICON,
  CROSS_REMOVE_ICON,
  CUSTOM_ICON,
  DELETE_ELEMENT_ICON,
  DELETE_ICON,
  DELETE_ICON_NEW,
  DEVICE_IMG,
  DEVICE_INFORMATION_ICON,
  DEVICE_ORIENTATION_ICON,
  DEVICE_STRING_ICON,
  DISABLE_ELEMENT_ICON,
  DISABLE_ICON,
  DRAG_AND_DROP_ICON,
  EDIT_RULES_ICON,
  EDIT_RULES_ICON_NEW,
  EMPTY_DEVICE_LIST_IMG,
  EMPTY_ELEMENT_IMAGE,
  EMPTY_RECORDS_IMAGE,
  EMPTY_REC_TEST_STEP_IMG,
  GENERAL_SETTINGS_ICON,
  GENERAL_SETTING_NEW_ICON,
  HELP_ICON,
  HIDE_DEVICE_VIEW_ICON,
  HIDE_DEVICE_VIEW_ICON_NEW,
  ICON_ADD_ACTION,
  ICON_ADD_DELAY_ACTION,
  ICON_ADD_SUB_STEP,
  ICON_ADD_VERIFICATION,
  ICON_APPLICATION_STRING_NEW,
  ICON_ARROW_DOWN,
  ICON_ARROW_LEFT,
  ICON_ARROW_RIGHT,
  ICON_ARROW_UP,
  ICON_ASSIGN_VALUE_TO_TEST_DATA_ACTION,
  ICON_BUILD_TEST_DATA_SET_NEW,
  ICON_CLEAR_TEXT_ACTION,
  ICON_CLOSE,
  ICON_CONDITIONAL_STEP,
  ICON_DELETE_WITH_BG,
  ICON_DOWN_ARROW,
  ICON_DUPLICATE,
  ICON_EDIT,
  ICON_EDIT_NEW,
  ICON_ENTER_TEXT_ACTION,
  ICON_FILTER,
  ICON_GENERAL_ACTION,
  ICON_IMPORT_ACTION,
  ICON_IMPORT_STEP,
  ICON_KEYPAD_ENTER_ACTION,
  ICON_LAUNCH_APP_ACTION,
  ICON_LOCK,
  ICON_LONG_PRESS_ACTION,
  ICON_MORE_NEW,
  ICON_NAVIGATE_BACK_ACTION,
  ICON_NAVIGATE_HOME_ACTION,
  ICON_PERFORM_AND_SAVE,
  ICON_PERFORM_SAVE,
  ICON_PLAY,
  ICON_PLAY_FOR_SUB_HEADER,
  ICON_PLAY_FROM_HERE,
  ICON_PLAY_TILL_HERE,
  ICON_RESET,
  ICON_ROBOT_STEP,
  ICON_AI,
  ICON_SAVE,
  ICON_SCREENSHOT_ELEMENT_ACTION,
  ICON_SCREENSHOT_PAGE_ACTION,
  ICON_SCROLL_PAGE_ACTION,
  ICON_SEARCH,
  ICON_STOP,
  ICON_SWIPE_ELEMENT_ACTION,
  ICON_TAP_ACTION,
  ICON_TAP_BY_INDEX_ACTION,
  IGNORE_FAILURE_AND_CONTINUE_EXECUTION_ICON,
  IMPORT_ICON,
  IMPORT_STEP_ICON,
  INFO_ICON,
  INFO_ICON_NEW,
  INSPECTOR_ELEMENT_IMAGE,
  INVITE_MEMBER_ICON,
  IOS_ICON,
  IOS_LOGO,
  IPHONE_IMG,
  LOAD_DEVICE_ICON,
  LOGO,
  LOGO_XPRESS,
  MENU_BUTTON,
  MODAL_CLOSE_ICON,
  MORE_ACTION_ICON_ON_HOVER,
  MORE_PROPERTIES_ICON,
  NEW_COLLAPSE_CLOSE_ICON,
  NEW_COLLAPSE_OPEN_ICON,
  OPEN_CHILD_COLLAPSE_ICON_IN_MODAL,
  OPEN_COLLAPSE_ICON_FOR_PARENT_STEP,
  OPEN_COLLAPSE_ICON_FOR_SUB_STEP,
  OPEN_COLLAPSE_ICON_IN_MODAL,
  PARENT_ELEMENT_ARROW_ICON,
  PRESS_BACK_ICON,
  PRESS_CLOUD_UPLOAD,
  PRESS_HOME_ICON,
  PRESS_KEYBOARD_ICON,
  PRESS_LOCK_ICON,
  PRESS_LOCK_OPEN_ICON,
  PRESS_MENU_OPEN,
  PRESS_NOTIFICATION_ICON,
  PRESS_VOLUME_DOWN_ICON,
  PRESS_VOLUME_UP_ICON,
  PREVIEW_RUN_ICON,
  PRIVATE_ICON,
  PROJECT_AND_TEAM_ICON,
  PROJECT_DOWN_ARROW,
  PUBLIC_ICON,
  REC_TEST_PLAY_ICON,
  ADD_OR_REMOVE_DEVICE_ICON,
  RELEASE_DEVICE_ICON,
  RELEASE_ICON_NEW,
  REMOVE_ICON,
  REPLACE_ELEMENT_ICON,
  RESET_ICON,
  RESULT_CONTINUE_AFTER_FAIL_ICON,
  RESULT_FAIL_ICON,
  RESULT_PRE_CONDITION_FAIL_ICON,
  RESULT_SKIPPED_ICON,
  RESULT_SUCCESS_ICON,
  SAVE_ICON,
  SEARCH_DEVICE_ICON,
  SEARCH_ICON,
  SELECT_AREA_IMAGE,
  SELECT_TEST_DATA_ICON,
  SELECT_TEST_DATA_ICON_NEW,
  SEND_ICON,
  SETTING_ICON,
  SHOW_DEVICE_VIEW_ICON,
  SHOW_DEVICE_VIEW_ICON_NEW,
  STEP_DELETE_ICON,
  STOP_ICON,
  TEST_PLAN_RESULT_ICON,
  TIMER_OUT_IMAGE,
  TRENDS_ICON_NEW,
  USER_ICON,
  USE_DEVICE_FOR_RUN_JOB,
  USE_DEVICE_ICON,
  VIEW_INVITATION_ICON,
  WARNING_NO_ELEMENT_DATA_FOUND,
  GOOGLE_PIXEL_4A_IMG,
  SAMSUNG_A02_IMG,
  ONE_PLUS_6T_IMG,
  GOOGLE_PIXEL_6_PRO_IMG,
  SAMSUNG_A52_IMG,
  MOTOROLA_G04_IMG,
  SAMSUNG_M04_IMG,
  SAMSUNG_A05M_IMG,
  IPHONE_SE_IMG,
  IPHONE_11_IMG,
  VIVO_IMG,
  ICON_SCHEDULED_NEW,
  ICON_DELETE_NEW,
  FILTER_ICON_V2,
  DELETE_ICON_RED_BORDER,
  SELECT_ICON,
  BLUE_CLOSE_COLLAPSE_ICON,
  BLUE_OPEN_COLLAPSE_ICON,
  REPORT_DETAILS_ICON,
  TRASH_ICON,
  UPLOAD_BUILD_ICON,
  AUTH_BANNER,
  SUCCESS_SIGN_UP_IMAGE,
  VERIFY_EMAIL_IMAGE,
  ORGANIZATION_STEP_1_IMG,
  ORGANIZATION_STEP_2_IMG,
  ORGANIZATION_STEP_3_IMG,
  UNDO_ICON,
  DRAG_SCENARIO_IMG,
  ABORT_ICON,
  AI_ICON,
  ICON_MORE_OPTIONS,
  ACCEPT_INVITATION_ICON,
  RESEND_INVITATION_ICON,
  STEPS_DRAG_AND_DROP_ICON,
  ICON_TEAM,
  PROJECT_MENU_ICON,
  TEAM_MENU_ICON,
  DEVICE_LIST_MENU_ICON,
  EXECUTION_REPORT_MENU_ICON,
  SERVER_MANAGEMENT_MENU_ICON,
  LINK_ICON,
  ANDROID_DEVICE_ICON,
  IOS_DEVICE_ICON,
  OPEN_COLLAPSE_ICON_FOR_TABLE,
  CLOSE_COLLAPSE_ICON_FOR_TABLE,
  DEVICE_SERVER_ICON,
  CLEAR_STRINGS_ICON,
  ICON_COPY,
  ICON_VIEW,
  ACCESSIBILITY_ICON,
  LOCAL_REPORT_ICON,
  AI_STEP_ICON,
  GENERAL_SETTINGS_ICON_V2,
  PAGE_SOURCE_ICON_V2,
  AI_JSON_DATA_ICON_V2,
} from "../Constants/SvgConstants";

const iconMapping = {
  [ADD_ICON]: addIcon,
  [IMPORT_STEP_ICON]: importStepIcon,
  [BUILD_DATA_SET_ICON]: buildDataSetIcon,
  [PREVIEW_RUN_ICON]: previewRunIcon,
  [STOP_ICON]: stopIcon,
  [REC_TEST_PLAY_ICON]: playIcon,
  [TEST_PLAN_RESULT_ICON]: testPlanResultIcon,
  [CLEAR_RESULT_ICON]: clearResultIcon,
  [ICON_ADD_ACTION]: addActionIcon,
  [ICON_ADD_VERIFICATION]: addVerificationIcon,
  [ICON_DUPLICATE]: iconDuplicate,
  [ICON_CONDITIONAL_STEP]: iconConditionStep,
  [ICON_ROBOT_STEP]: iconRobotStep,
  [ICON_AI]: iconAi,
  [ICON_ADD_SUB_STEP]: iconAddSubStep,
  [ICON_IMPORT_STEP]: iconImportStep,
  [ICON_PLAY_FOR_SUB_HEADER]: iconPlayForSubSTep,
  [ICON_PLAY]: playIcon,
  [ARROW_DOWN]: arrowDown,
  [ICON_PLAY_FROM_HERE]: iconPlayFromHere,
  [ICON_PLAY_TILL_HERE]: iconPlayTillHere,
  [STEP_DELETE_ICON]: iconStepDelete,
  [ICON_GENERAL_ACTION]: iconGeneralAction,
  [ICON_IMPORT_ACTION]: importActionIcon,
  [ICON_STOP]: importIconStop,
  [ICON_TAP_ACTION]: iconTapAction,
  [ICON_TAP_BY_INDEX_ACTION]: iconTapByIndexAction,
  [ICON_LONG_PRESS_ACTION]: iconLongPressAction,
  [ICON_ENTER_TEXT_ACTION]: iconEnterTextAction,
  [ICON_CLEAR_TEXT_ACTION]: iconClearTextAction,
  [ICON_SWIPE_ELEMENT_ACTION]: iconSwipeElementAction,
  [ICON_SCREENSHOT_ELEMENT_ACTION]: iconSCreenShotElementAction,
  [ICON_LAUNCH_APP_ACTION]: iconLunchAppAction,
  [ICON_ADD_DELAY_ACTION]: iconAddDelayAction,
  [ICON_SCROLL_PAGE_ACTION]: iconScrollPageAction,
  [ICON_SCREENSHOT_PAGE_ACTION]: iconScreenshotPageAction,
  [ICON_NAVIGATE_HOME_ACTION]: iconNavigateHomeAction,
  [ICON_NAVIGATE_BACK_ACTION]: iconNavigateBackAction,
  [ICON_KEYPAD_ENTER_ACTION]: iconKeypadEnterAction,
  [ICON_ASSIGN_VALUE_TO_TEST_DATA_ACTION]: iconAssignValueToTestDataAction,
  [MORE_ACTION_ICON_ON_HOVER]: moreActionIconOnHover,
  [ADD_ACTION_ICON_ON_HOVER]: addActionIconOnHover,
  [ADD_SUB_STEP_ICON_ON_HOVER]: addSubStepIconOnHover,
  [DISABLE_ICON]: iconDisable,
  [EMPTY_RECORDS_IMAGE]: imageEmptyRecords,
  [EMPTY_REC_TEST_STEP_IMG]: emptyRecTestStepsImage,
  [DRAG_AND_DROP_ICON]: dragAndDropIcon,
  [OPEN_COLLAPSE_ICON_FOR_SUB_STEP]: openCollapseIconForSubStep,
  [CLOSE_COLLAPSE_ICON_FOR_SUB_STEP]: closeCollapseIconForSubStep,
  [OPEN_COLLAPSE_ICON_FOR_PARENT_STEP]: openCollapseIconForParentStep,
  [CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP]: closeCollapseIconForParentStep,
  [ICON_SAVE]: iconSave,
  [ICON_RESET]: iconReset,
  [ICON_PERFORM_AND_SAVE]: performAndSaveIcon,
  [HELP_ICON]: helpIcon,
  [ICON_CLOSE]: closeIcon,
  [PROJECT_DOWN_ARROW]: downArrow,
  [LOGO]: logo,
  [BUILD_TEST_DATA_SET_ICON]: buildDataSetHeaderIcon,
  [ICON_DOWN_ARROW]: iconDownArrow,
  [MENU_BUTTON]: menuButton,
  [USER_ICON]: userIcon,
  [HIDE_DEVICE_VIEW_ICON]: hideDeviceViewIcon,
  [SHOW_DEVICE_VIEW_ICON]: showDeviceViewIcon,
  [ANDROID_LOGO]: androidLogo,
  [IOS_LOGO]: applebrand,
  [CLOCK_ICON]: clockIcon,
  [CLOSE_SESSION_ICON]: closeSessionIcon,
  [PRESS_HOME_ICON]: pressHome,
  [PRESS_BACK_ICON]: pressBack,
  [PRESS_LOCK_OPEN_ICON]: pressLockOpen,
  [PRESS_LOCK_ICON]: pressLock,
  [PRESS_VOLUME_UP_ICON]: pressVolumeUp,
  [PRESS_NOTIFICATION_ICON]: pressNotifications,
  [PRESS_CLOUD_UPLOAD]: pressCloudUpload,
  [PRESS_MENU_OPEN]: pressMenuOpen,
  [ANDROID_LOGO_IMAGE]: androidLogoImage,
  [PRESS_VOLUME_DOWN_ICON]: pressVolumeDown,
  [DEVICE_ORIENTATION_ICON]: device_orientation_icon,
  [IGNORE_FAILURE_AND_CONTINUE_EXECUTION_ICON]: ignoreFailureAndContinueExecution,
  [PRESS_KEYBOARD_ICON]: pressKeyboard,
  [INFO_ICON]: infoIcon,
  [SAVE_ICON]: saveIcon,
  [ICON_PERFORM_SAVE]: iconPerformSave,
  [RESET_ICON]: resetIcon,
  [APPLICATION_STRING_ICON]: iconApplicationString,
  [DEVICE_STRING_ICON]: iconDeviceString,
  [DELETE_ICON]: deleteIcon,
  [ICON_EDIT]: iconEdit,
  [ICON_DELETE_WITH_BG]: iconDelete,
  [DELETE_ELEMENT_ICON]: deleteElementIcon,
  [MORE_PROPERTIES_ICON]: morePropertiesIcon,
  [REPLACE_ELEMENT_ICON]: replaceElementIcon,
  [GENERAL_SETTINGS_ICON]: generalSettingIcon,
  [SELECT_TEST_DATA_ICON]: selectTestDataIcon,
  [ICON_ARROW_UP]: arrowUp,
  [ICON_ARROW_DOWN]: arrowDown,
  [ICON_ARROW_LEFT]: arrowLeft,
  [ICON_ARROW_RIGHT]: arrowRight,
  [RESULT_SUCCESS_ICON]: successIcon,
  [RESULT_FAIL_ICON]: failIcon,
  [RESULT_SKIPPED_ICON]: skipIcon,
  [RESULT_CONTINUE_AFTER_FAIL_ICON]: continueAfterFailIcon,
  [EDIT_RULES_ICON]: editRulesIcon,
  [SEARCH_ICON]: searchIcon,
  [ICON_FILTER]: iconFilter,
  [DEVICE_IMG]: deviceImg,
  [ANDROID_ICON]: androidIcon,
  [IOS_ICON]: iosIcon,
  [USE_DEVICE_ICON]: useDeviceIcon,
  [RELEASE_DEVICE_ICON]: releaseDeviceIcon,
  [DEVICE_INFORMATION_ICON]: deviceInformationIcon,
  [RESULT_PRE_CONDITION_FAIL_ICON]: preConditionFailIcon,
  [MODAL_CLOSE_ICON]: modalCloseIcon,
  [DISABLE_ELEMENT_ICON]: disableElementIcon,
  [USE_DEVICE_FOR_RUN_JOB]: useDeviceForRunJobIcon,
  [ICON_LOCK]: iconLock,
  [PARENT_ELEMENT_ARROW_ICON]: parentElementArrowIcon,
  [IMPORT_ICON]: importIcon,
  [EMPTY_ELEMENT_IMAGE]: emptyElementsImg,
  [INSPECTOR_ELEMENT_IMAGE]: inspectorElementImg,
  [OPEN_COLLAPSE_ICON_IN_MODAL]: openCollapseIconInModal,
  [OPEN_CHILD_COLLAPSE_ICON_IN_MODAL]: openChildCollapseIconInModal,
  [WARNING_NO_ELEMENT_DATA_FOUND]: warningNoElementDataFound,
  [CLOSE_CHILD_COLLAPSE_ICON_IN_MODAL]: closeChildCollapseIconInModal,
  [APPLICATION_STRING_BLACK_ICON]: applicationStringBlackIcon,
  [TIMER_OUT_IMAGE]: timerOutImage,
  [SETTING_ICON]: settingIcon,
  [ACCESSIBILITY_ICON]:accessibilityIcon,
  [LOCAL_REPORT_ICON]:localReportIcon,

  //update UI
  [PROJECT_AND_TEAM_ICON]: projectAndTeamIcon,
  [INVITE_MEMBER_ICON]: inviteMemberIcon,
  [SEND_ICON]: sendIcon,
  [VIEW_INVITATION_ICON]: viewInvitationIcon,
  [CREATE_ICON]: createIcon,
  [ICON_SEARCH]: newIconSearch,
  [LOGO_XPRESS]: xpressLogo,
  [CLOSE_ICON_RED]: closeIconRed,
  [ICON_EDIT_NEW]: iconEditNew,
  [ICON_BUILD_TEST_DATA_SET_NEW]: iconBuildTestDataSetNew,
  [ICON_APPLICATION_STRING_NEW]: iconApplicationStringNew,
  [ICON_MORE_NEW]: iconMoreNew,
  [PRIVATE_ICON]: privateIcon,
  [PUBLIC_ICON]: publicIcon,
  [CUSTOM_ICON]: customIcon,
  [APPLICATION_STRING_ICON_NEW]: applicationStringIconNew,
  [GENERAL_SETTING_NEW_ICON]: generalSettingsNewIcon,
  [LOAD_DEVICE_ICON]: loadDeviceIcon,
  [EDIT_RULES_ICON_NEW]: editRulesIconNew,
  [ADD_CIRCLE_ICON]: addCircleIcon,
  [REMOVE_ICON]: removeIcon,
  [DELETE_ICON_NEW]: deleteIconNew,
  [CLONE_ICON]: cloneIcon,
  [TRENDS_ICON_NEW]: trendsIconNew,
  [CROSS_REMOVE_ICON]: crossRemoveIcon,
  [SELECT_TEST_DATA_ICON_NEW]: selectTestDataIconNew,
  [SELECT_AREA_IMAGE]: selectAreaImage,
  [CONDITION_DELETE_IMAGE]: conditionDeleteImage,
  [NEW_COLLAPSE_OPEN_ICON]: newCollapseOpenIcon,
  [NEW_COLLAPSE_CLOSE_ICON]: newCollapseCloseIcon,
  [NEW_COLLAPSE_CLOSE_ICON]: newCollapseCloseIcon,
  [SEARCH_DEVICE_ICON]: searchDeviceIcon,
  [HIDE_DEVICE_VIEW_ICON_NEW]: hideDeviceViewIconNew,
  [SHOW_DEVICE_VIEW_ICON_NEW]: showDeviceViewIconNew,
  [INFO_ICON_NEW]: infoIconNew,
  [RELEASE_ICON_NEW]: releaseIconNew,
  [EMPTY_DEVICE_LIST_IMG]: emptyDeviceListImg,
  [IPHONE_IMG]: iPhoneImage,
  [ANDROID_PHONE_IMG]: androidPhoneImg,
  [ADD_OR_REMOVE_DEVICE_ICON]: addOrRemoveDeviceIcon,
  [ICON_SCHEDULED_NEW]: iconScheduledNew,
  [ICON_DELETE_NEW]: iconDeleteNew,
  [FILTER_ICON_V2]: filterIconV2,
  [DELETE_ICON_RED_BORDER]: deleteIconWithRedBorder,
  [SELECT_ICON]: selectIcon,
  [BLUE_CLOSE_COLLAPSE_ICON]: blueCloseCollapseIcon,
  [BLUE_OPEN_COLLAPSE_ICON]: blueOpenCollapseIcon,
  [REPORT_DETAILS_ICON]: reportDetailsIcon,
  [TRASH_ICON]: trashIcon,
  [UPLOAD_BUILD_ICON]: uploadBuildIcon,
  [UNDO_ICON]: undoIcon,
  [DRAG_SCENARIO_IMG]: dragScenarioImg,
  [ABORT_ICON]: abortIcon,
  [ICON_MORE_OPTIONS]: iconMoreOptions,
  [ACCEPT_INVITATION_ICON]: acceptInvitationIcon,
  [RESEND_INVITATION_ICON]: resendInvitationIcon,
  [STEPS_DRAG_AND_DROP_ICON]: stepsDragAndDropIcon,
  [ICON_TEAM]: iconTeam,
  [CLEAR_STRINGS_ICON]: clearStringsIcon,
  [ICON_COPY]: copyIcon,
  [ICON_VIEW]: iconView,

  //deviceImg
  [GOOGLE_PIXEL_4A_IMG]: googlePixel4aImg,
  [SAMSUNG_A02_IMG]: samsungA02Img,
  [ONE_PLUS_6T_IMG]: onePlus6tImg,
  [GOOGLE_PIXEL_6_PRO_IMG]: googlePixel6proImg,
  [SAMSUNG_A52_IMG]: samsungA52Img,
  [MOTOROLA_G04_IMG]: motorolaG04Img,
  [SAMSUNG_M04_IMG]: samsungM04Img,
  [SAMSUNG_A05M_IMG]: samsungA05MImg,
  [IPHONE_SE_IMG]: iPhoneSEImg,
  [IPHONE_11_IMG]: iPhone11Img,
  [VIVO_IMG]: vivoImg,

  //login
  [AUTH_BANNER]: authBanner,
  [AI_ICON]: AIIcon,
  [SUCCESS_SIGN_UP_IMAGE]: successSignUpImage,
  [VERIFY_EMAIL_IMAGE]: verifyEmailImage,
  [ORGANIZATION_STEP_1_IMG]: organizationStep1Img,
  [ORGANIZATION_STEP_2_IMG]: organizationStep2Img,
  [ORGANIZATION_STEP_3_IMG]: organizationStep3Img,
  [AI_ICON]: AIIcon,

  //menu
  [PROJECT_MENU_ICON]: projectMenuIcon,
  [TEAM_MENU_ICON]: teamMenuIcon,
  [DEVICE_LIST_MENU_ICON]: deviceListMenuIcon,
  [EXECUTION_REPORT_MENU_ICON]: executionReportMenuIcon,
  [SERVER_MANAGEMENT_MENU_ICON]: serverManagementMenuIcon,
  [LINK_ICON]: linkIcon,

  //device-list
  [ANDROID_DEVICE_ICON]: androidDeviceIcon,
  [IOS_DEVICE_ICON]: iOSDeviceIcon,

  //device-server
  [OPEN_COLLAPSE_ICON_FOR_TABLE]: openCollapseIconForTable,
  [CLOSE_COLLAPSE_ICON_FOR_TABLE]: closeCollapseIconForTable,
  [DEVICE_SERVER_ICON]: deviceServerIcon,

  //AI-step
  [AI_STEP_ICON]:AIStepIcon,
  [GENERAL_SETTINGS_ICON_V2]:generalSettingsIconV2,
  [PAGE_SOURCE_ICON_V2]:pageSourceIconV2,
  [AI_JSON_DATA_ICON_V2]:AIJsonDataIconV2
};

const SvgLoader = (props) => {
  const { iconName, iconColor, width, height, handleClick, disabled, infoIcon, defaultCursor, cursorGrab } = props;
  const Icon = iconMapping[iconName];
  return (
    <div
      style={{
        color: iconColor,
        width: width,
        height: height,
        display: "flex",
        alignItems: "center",
        justifyContent: !infoIcon && "center",
        cursor: cursorGrab ? cursorGrab : !defaultCursor && (disabled ? "not-allowed" : "pointer"),
      }}
      onClick={handleClick}
    >
      <Icon />
    </div>
  );
};

export default SvgLoader;