import { async } from "../../api/automation";
import {
  CHANGE_TEST_DATA_SET_DEVICE_ASSIGN_TEST_DATA_SET,
  CHANGE_TEST_DATA_SET_LOCALE,
  CHANGE_TEST_DATA_SET_NAME,
  ERROR_TYPE,
  GET_TEST_DATA_BASE_LIST,
  GET_TEST_DATA_BASE_LIST_LOADING,
  GET_TEST_DATA_SET_BY_ID,
  GET_TEST_STEPS_DATA_LIST,
  GET_TEST_STEPS_DATA_LOADING,
  REMOVE_PREVIOUS_TEST_DATA_SET,
  RESET_TEST_DATA_SET,
  SELECTED_TEST_DATA_SET_TAB,
  SET_TEST_DATA_SET_ASSIGNEE_DETAIL,
  SET_TEST_DATA_SET_VIEW,
  SUCCESS_STATUS,
  SUCCESS_TYPE,
  TEST_DATA_APPLICATION_STRINGS,
  TEST_DATA_SET_INFO_MODAL,
  TEST_DATA_SET_LIST,
  TEST_DATA_SET_LIST_REQUEST,
  TEST_DATA_SET_LIST_REQUESTED,
  TEST_PLAN,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
  GET_TEST_STEPS_DATA_LIST_APPLICATION_STRING,
  SET_APPLICATION_STRING_ASSIGNEE_DETAIL,
  APPLICATION_STRING_INFO,
  DEVICE_STRING_INFO,
  GET_TEST_STEPS_DATA_LIST_DEVICE_STRING,
  SET_DEVICE_STRING_ASSIGNEE_DETAIL,
  LOADING_EXECUTION_TEST_DATA_SET_ASSOC_LIST,
} from "../../Constants";
import { showNotification, updateResponse } from "../../Util";

export function changeTestDataSetView(testDataSetView) {
  return (dispatch, getState) => {
    dispatch({ type: SET_TEST_DATA_SET_VIEW, testDataSetView });
  };
}

export function setTestDataSetAssigneeDetail(testDataSetAssigneeDetail) {
  return (dispatch, getState) => {
    dispatch({ type: SET_TEST_DATA_SET_ASSIGNEE_DETAIL, testDataSetAssigneeDetail });
  };
}

export function setApplicationAssigneeDetail(applicationStringAssigneeDetail) {
  return (dispatch, getState) => {
    dispatch({ type: SET_APPLICATION_STRING_ASSIGNEE_DETAIL, applicationStringAssigneeDetail });
  };
}

export function setDeviceAssigneeDetail(deviceStringAssigneeDetail) {
  return (dispatch, getState) => {
    dispatch({ type: SET_DEVICE_STRING_ASSIGNEE_DETAIL, deviceStringAssigneeDetail });
  };
}

export function _getDataProfileByItemId(data) {
  return (dispatch, getState) => {
    const projectId = getState().ProjectsReducer.projectId;
    const payload={
      ...data,
      projectId
    }
    dispatch({ type: TEST_DATA_SET_LIST_REQUEST });
    async("testDataSetBase/getDataProfileByItemId", "GET", payload).then((response) => {
      response = updateResponse(response);
      dispatch({ type: TEST_DATA_SET_LIST, response });
      dispatch({ type: TEST_DATA_SET_LIST_REQUESTED });
    });
  };
}

export function _changeTestDataSetName(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_TEST_DATA_SET_NAME, value });
  };
}

export function _changeTestDatasetLocale(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_TEST_DATA_SET_LOCALE, value });
  };
}

export function _changeTestDataSetDeviceAssignDataSet(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_TEST_DATA_SET_DEVICE_ASSIGN_TEST_DATA_SET, value });
  };
}

export function callCloneTestDataSet(data) {
  return (dispatch, getState) => {
    dispatch({ type: TEST_DATA_SET_LIST_REQUEST });
    return async("testDataSetBase/clone", "POST", data).then((response) => {
      return response;
    });
  };
}
export function _callTestDataSetById(data) {
  return (dispatch, getState) => {
    dispatch({ type: TEST_DATA_SET_LIST_REQUEST });
    dispatch({ type: GET_TEST_DATA_SET_BY_ID, response: undefined });
    return async("testDataSetBase/getById", "GET", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: GET_TEST_DATA_SET_BY_ID, response });
        dispatch({ type: TEST_DATA_SET_LIST_REQUESTED });
      }
    });
  };
}

export function updateTestDataValues(selectedTestDataRow, testDataValues, key, value) {
  return testDataValues.map((testData) => {
    if (testData.testDataSetAssocId === selectedTestDataRow.testDataSetAssocId) {
      testData[key] = value;
      if (key === "isCustomChecked" && value) {
        testData["testDataCustomValue"] = "";
      }
      if (key === "isCustomChecked" && !value) {
        testData["refTestDataSetAssocId"] = 0;
      }
    }
    return testData;
  });
}

export function _callSaveTestDataSet(testDataSetForEdit) {
  return (dispatch, getState) => {
    async("testDataSetBase/addTestDataToSet", "POST", testDataSetForEdit).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        dispatch({ type: REMOVE_PREVIOUS_TEST_DATA_SET });
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
    });
  };
}

export function _resetTestDataSet() {
  return (dispatch) => {
    dispatch({ type: RESET_TEST_DATA_SET });
  };
}

export function _callMakeTestDataSetDefault(data) {
  return (dispatch) => {
    return async("testDataSetBase/makeDefault", "POST", data).then((response) => {
      return response;
    });
  };
}

export function _changeTestDataSetTab(selectedTab) {
  return (dispatch, getState) => {
    dispatch({ type: SELECTED_TEST_DATA_SET_TAB, selectedTab });
  };
}

export function _getTestStepsData(selectedStepId) {
  return (dispatch, getState) => {
    const stepType = getState().RecTestStepCommonViewReducer.stepType;
    const data = {
      testPlanId: stepType === TEST_PLAN ? selectedStepId : 0,
      recTestStepId: stepType === TEST_STEP_BLOCKS ? selectedStepId : 0,
      recTestScenarioId: stepType === TEST_SCENARIOS ? selectedStepId : 0,
    };

    dispatch({ type: GET_TEST_STEPS_DATA_LOADING, response: { isLoading: true } });
    return async("testDataBase/getStepsData", "GET", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: GET_TEST_STEPS_DATA_LIST, response: response.data });
      } else {
        dispatch({ type: GET_TEST_STEPS_DATA_LIST, response: [] });
      }
      dispatch({ type: GET_TEST_STEPS_DATA_LOADING, response: { isLoading: false } });
    });
  };
}

export function _testDataSetInfoModal(visible, testDataKeys, selectedKeys, selectedStepsKey, selectedStepsValue) {
  return (dispatch, getState) => {
    dispatch({
      type: TEST_DATA_SET_INFO_MODAL,
      visible,
      testDataKeys,
      selectedKeys,
      selectedStepsKey,
      selectedStepsValue,
    });
  };
}

export function _getStepDataInfo(selectRecTestStepId, selectedStepId) {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    const { selectedDataKey, testDataKeys } = getState().TestDataSetReducer;
    const data = {
      // testPlanId: stepType === TEST_PLAN ? selectedStepId : 0,
      recTestStepId: selectedStepId || 0,
      recTestScenarioId: [TEST_SCENARIOS, TEST_PLAN].includes(stepType) ? selectedStepId : 0,
      applicationStringId: selectedDataKey === TEST_DATA_APPLICATION_STRINGS ? testDataKeys : 0,
      testDataId: selectedDataKey !== TEST_DATA_APPLICATION_STRINGS ? testDataKeys : 0,
    };

    dispatch({ type: GET_TEST_DATA_BASE_LIST_LOADING, response: { isLoading: true } });
    return async("testDataBase/getStepDataInfo", "GET", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: GET_TEST_DATA_BASE_LIST, response: response.data });
      } else {
        dispatch({ type: GET_TEST_DATA_BASE_LIST, response: [] });
      }
      dispatch({ type: GET_TEST_DATA_BASE_LIST_LOADING, response: { isLoading: false } });
    });
  };
}

export function _getTestStepsDataApplicationString(selectedStepId) {
  return (dispatch, getState) => {
    const stepType = getState().RecTestStepCommonViewReducer.stepType;
    const data = {
      testPlanId: stepType === TEST_PLAN ? selectedStepId : 0,
      recTestStepId: stepType === TEST_STEP_BLOCKS ? selectedStepId : 0,
      recTestScenarioId: stepType === TEST_SCENARIOS ? selectedStepId : 0,
    };
    dispatch({ type: GET_TEST_STEPS_DATA_LOADING, response: { isLoading: true } });
    return async("testDataBase/getStepsDataOfApplicationString", "GET", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: GET_TEST_STEPS_DATA_LIST_APPLICATION_STRING, response: response.data });
      } else {
        dispatch({ type: GET_TEST_STEPS_DATA_LIST_APPLICATION_STRING, response: [] });
      }
      dispatch({ type: GET_TEST_STEPS_DATA_LOADING, response: { isLoading: false } });
    });
  };
}

export function _getTestStepsDataDeviceString(selectedStepId) {
  return (dispatch, getState) => {
    const stepType = getState().RecTestStepCommonViewReducer.stepType;
    const data = {
      testPlanId: stepType === TEST_PLAN ? selectedStepId : 0,
      recTestStepId: stepType === TEST_STEP_BLOCKS ? selectedStepId : 0,
      recTestScenarioId: stepType === TEST_SCENARIOS ? selectedStepId : 0,
    };
    dispatch({ type: GET_TEST_STEPS_DATA_LOADING, response: { isLoading: true } });
    return async("testDataBase/getStepsDataOfDeviceString", "GET", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: GET_TEST_STEPS_DATA_LIST_DEVICE_STRING, response: response.data });
      } else {
        dispatch({ type: GET_TEST_STEPS_DATA_LIST_DEVICE_STRING, response: [] });
      }
      dispatch({ type: GET_TEST_STEPS_DATA_LOADING, response: { isLoading: false } });
    });
  };
}

export function _getStepDataInfoApplicationString(applicationStringId, selectedStepId) {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    const data = {
      testPlanId: stepType === TEST_PLAN ? selectedStepId : 0,
      recTestStepId: selectedStepId || 0,
      recTestScenarioId: stepType === TEST_SCENARIOS ? selectedStepId : 0,
      applicationStringId: applicationStringId,
      testDataId: 0,
    };

    dispatch({ type: GET_TEST_DATA_BASE_LIST_LOADING, response: { isLoading: true } });
    return async("testDataBase/getStepDataInfo", "GET", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: APPLICATION_STRING_INFO, response: response.data, applicationStringId });
      } else {
        dispatch({ type: APPLICATION_STRING_INFO, response: [] });
      }
      dispatch({ type: GET_TEST_DATA_BASE_LIST_LOADING, response: { isLoading: false } });
    });
  };
}

export function _getStepDataInfoDeviceString(deviceStringId, selectedStepId) {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    const data = {
      testPlanId: stepType === TEST_PLAN ? selectedStepId : 0,
      recTestStepId: selectedStepId || 0,
      recTestScenarioId: stepType === TEST_SCENARIOS ? selectedStepId : 0,
      deviceStringId: deviceStringId,
      testDataId: 0,
    };

    dispatch({ type: GET_TEST_DATA_BASE_LIST_LOADING, response: { isLoading: true } });
    return async("testDataBase/getStepDataInfo", "GET", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: DEVICE_STRING_INFO, response: response.data, deviceStringId });
      } else {
        dispatch({ type: DEVICE_STRING_INFO, response: [] });
      }
      dispatch({ type: GET_TEST_DATA_BASE_LIST_LOADING, response: { isLoading: false } });
    });
  };
}

export function getExecutionTestDataAssoc(executionTestDataSetId,setExecutionTestData) {
  return (dispatch) => {
    dispatch({ type: LOADING_EXECUTION_TEST_DATA_SET_ASSOC_LIST, isLoading: true });
    return async("executionTestDataSetAssoc/getListOfAssocByExecutionTestDataSetId", "GET", 
      {executionTestDataSetId: executionTestDataSetId}).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        setExecutionTestData(response.data)
      } else {
        setExecutionTestData([])
      }
      dispatch({ type: LOADING_EXECUTION_TEST_DATA_SET_ASSOC_LIST, isLoading: false });
    });
  };
}
