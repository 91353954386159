import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Spin, Tooltip } from "antd";
import _ from "lodash";
import styled from "styled-components";
import { GRAY_COLOR, STEP, TEST_PLAN_RESULT_TYPE, TOOLTIP_BG_COLOR } from "../../../Constants";
import {
  addAdditionalStepDetail,
  getStepAfterActionScreenshotUrl,
  getStepEndTimeById,
  getStepResultById,
  getStepStartTimeById,
  getStepTimeById,
} from "../../../Util";
import {
  getTestBlockRunResultData,
  getTestPlanStepsList,
  getTestPlanStepsResult,
  setRunResultScenarioId,
  testPlanStepsListId,
} from "../../../actions/TestPlanAction";
import ErrorInvestigationModal from "../../ErrorInvestigation/ErrorInvestigationModal";
import TestPlanResultDetailPreconditionList from "../../TestPlan/TestPlanResultDetails/TestPlanResultDetailPreconditionList";
import TestPlanResultStepElementDetails from "../../TestPlan/TestPlanResultDetails/TestPlanResultStepElementDetails";
import TestPlanResultDetailStepV2 from "./TestPlanResultDetailStepV2";
import AIReportDetailsModalV2 from "./AIReportDetailsModalV2";
import AIReportDetailsSubStepModalV2 from "./AIReportDetailsSubStepModalV2";
const { Panel } = Collapse;

const StyledSpan = styled.span`
  width: 65px;
  height: 44px;
  margin-right: 8px;
  border: 0.2 solid ${(props) => props.GRAY_COLOR};
  border-radius: 5px;
  padding-top: 10px;
  text-align: center;
  box-shadow: 0 0 10px 2px rgb(0 0 0 / 13%);
  background: white !important;
  margin-top: 10px;
`;

function fetchPreconditionSteps(testPlanResultStepsDetails, scenarioId, parentId) {
  if (
    testPlanResultStepsDetails[scenarioId] &&
    testPlanResultStepsDetails[scenarioId].preConditionStpes &&
    testPlanResultStepsDetails[scenarioId].preConditionStpes[parentId]
  ) {
    return testPlanResultStepsDetails[scenarioId].preConditionStpes[parentId];
  }
  return [];
}

function fetchChildSteps(testPlanResultStepsDetails, scenarioId, parentId) {
  if (
    testPlanResultStepsDetails &&
    testPlanResultStepsDetails[scenarioId] &&
    testPlanResultStepsDetails[scenarioId]?.childSteps &&
    testPlanResultStepsDetails[scenarioId]?.childSteps[parentId]
  ) {
    return testPlanResultStepsDetails[scenarioId].childSteps[parentId];
  }
  return [];
}

export const leftBorderColor = (data) => {
  if (data === TEST_PLAN_RESULT_TYPE.IN_PROGRESS || data === TEST_PLAN_RESULT_TYPE.IN_PROGRESS_TXT) {
    return "border-left-inProgress";
  } else if (data === TEST_PLAN_RESULT_TYPE.PASSED) {
    return "border-left-pass";
  } else if (
    data === TEST_PLAN_RESULT_TYPE.FAILED ||
    data === TEST_PLAN_RESULT_TYPE.FAILED_IGNORE_AND_CONTINUE ||
    data === TEST_PLAN_RESULT_TYPE.FAILED_ACTION_VERIFICATION_FAILED
  ) {
    return "border-left-fail";
  } else if (data === TEST_PLAN_RESULT_TYPE.SKIPPED || data === TEST_PLAN_RESULT_TYPE.SKIPPED_PRECONDITION_FAILED) {
    return "border-left-skip";
  } else if (data === TEST_PLAN_RESULT_TYPE.CONTINUE_AFTER_FAILED) {
    return "border-left-continue-after-fail";
  } else if (data === TEST_PLAN_RESULT_TYPE.ABORTED) {
    return "border-left-abort";
  } else if (data === TEST_PLAN_RESULT_TYPE.TERMINATED) {
    return "border-left-abort";
  } else {
    return "";
  }
};

export const backGroundColorForSteps = (data) => {
  if (data.isSkipStep === 1) {
    return "panel-background-color-disable mt-10";
  } else {
    return "panel-background-color mt-10";
  }
};
// Main Function
var timerID = {};

const TestPlanResultDetailStepListV2 = (props) => {
  const dispatch = useDispatch();
  const {
    scenarioData,
    isActive,
    isTestBlock,
    activeScenarioId,
    stepResultData,
    parentStep,
    testPlanResult,
    index,
    stepCount,
    currentActiveScenarioId,
    setCurrentActiveScenarioId,
    stepResultDuration,
    startTime,
    endTime,
    setTestPlanStepsResult,
    testPlanStepsResult,
    afterActionScreenshotUrl,
  } = props;

  const { runResultDetails, testPlanResultStepsDetails, testBlockResultDataByRunId, loadingSteps, previousStepId } =
    useSelector((state) => state.TestPlanReducer);
  const runId = runResultDetails?.find((i) => i)?.runId;
  const [openErrorInvestigationModal, setOpenErrorInvestigationModal] = useState(false);
  const [testStepId, setTestStepId] = useState(null);
  const [childResultSteps, setChildResultSteps] = useState([]);
  const [visibleReportModal, setVisibleReportModal] = useState(null);
  const [visibleSubStepReportModal, setVisibleSubStepReportModal] = useState(null);

  const loadTestPlanStepList = (restart = false) => {
    if (timerID[scenarioData?.scenarioId] || timerID[scenarioData?.scenarioId] === undefined || restart) {
      let isFirstCall = true;
      timerID[scenarioData?.scenarioId] = setTimeout(() => {
        isFirstCall = false;
        dispatch(
          getTestPlanStepsList(
            { scenarioId: scenarioData?.scenarioId },
            () => {
              loadTestPlanStepList();
            },
            "",
            isFirstCall
          )
        );
        dispatch(testPlanStepsListId(scenarioData?.scenarioId));
      }, 3000);
    }
  };

  const isInitialActiveParentStep =
    isActive &&
    index === 0 &&
    parentStep &&
    (scenarioData?.state === TEST_PLAN_RESULT_TYPE.IN_PROGRESS ||
      scenarioData?.state === TEST_PLAN_RESULT_TYPE.UPLOADING_RESULT ||
      scenarioData?.state === TEST_PLAN_RESULT_TYPE.WAITING_FOR_DEVICE_TO_EXECUTED);

  useEffect(() => {
    if (isInitialActiveParentStep) {
      const interval = setInterval(() => {
        dispatch(getTestPlanStepsResult(scenarioData?.scenarioId, setTestPlanStepsResult));
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [isInitialActiveParentStep, scenarioData?.scenarioId, dispatch]);

  useEffect(() => {
    if (
      isActive &&
      index === 0 &&
      (scenarioData?.state === TEST_PLAN_RESULT_TYPE.IN_PROGRESS ||
        scenarioData?.state === TEST_PLAN_RESULT_TYPE.UPLOADING_RESULT ||
        scenarioData?.state === TEST_PLAN_RESULT_TYPE.WAITING_FOR_DEVICE_TO_EXECUTED)
    ) {
      loadTestPlanStepList(true);
    } else {
      if (timerID[scenarioData?.scenarioId]) {
        clearTimeout(timerID[scenarioData?.scenarioId]);
        timerID[scenarioData?.scenarioId] = null;
      }
    }
  }, [isActive, scenarioData?.state]);

  useEffect(() => {
    return () => {
      if (timerID[scenarioData?.scenarioId]) {
        clearTimeout(timerID[scenarioData?.scenarioId]);
        timerID[scenarioData?.scenarioId] = null;
      }
    };
  }, [scenarioData?.scenarioId]);

  const [ActiveScenario, setActiveScenario] = useState(null);

  const getTestStepsDetailsOnClick = (data, k, testPlanResultStep) => {
    const scenarioId = data?.scenarioId;
    dispatch(setRunResultScenarioId(scenarioId));
    if (k !== ActiveScenario) {
      if (testPlanResultStep?.action === STEP) {
        if (isTestBlock === 1) {
          dispatch(getTestBlockRunResultData({ runId, parentStepId: testPlanResultStep?.id }, setChildResultSteps));
        } else {
          dispatch(
            getTestPlanStepsList(
              { scenarioId: scenarioId, parentStepId: testPlanResultStep?.id },
              () => {},
              setChildResultSteps,
              true
            )
          );
        }
      }
      setActiveScenario(k);
      setChildResultSteps(childResultSteps);
      setCurrentActiveScenarioId(testPlanResultStep?.id);
    } else {
      setActiveScenario(null);
      setChildResultSteps(childResultSteps);
      setCurrentActiveScenarioId(null);
    }
  };

  const childStep = (childResultSteps, parentId, scenarioId) => {
    const data = fetchChildSteps(childResultSteps, scenarioId, parentId);
    if (data) {
      const runId = runResultDetails?.find((i) => i)?.runId;
      const isLoading = loadingSteps.includes(parentId) || loadingSteps.includes(previousStepId);

      if (isLoading && _.isEmpty(data) && stepResultData.action === STEP) {
        return <Spin spinning={true} className="spinner-center-testPlanResult" />;
      }
      const processedChildSteps = data?.map((v, k) => {
        const result =
          testPlanStepsResult !== undefined
            ? getStepResultById(testPlanStepsResult[scenarioId]?.stepResults, v.id)
            : v?.result;
        return isTestBlock === 0
          ? addAdditionalStepDetail(v, result, testPlanResultStepsDetails, data?.scenarioId)
          : addAdditionalStepDetail(v, result, testBlockResultDataByRunId, runId);
      });
      return processedChildSteps?.map((step, index) => {
        const duration =
          testPlanStepsResult !== undefined
            ? getStepTimeById(testPlanStepsResult[scenarioId]?.stepResults, step.id)
            : step?.duration.length > 0 && step?.duration.split(":");
        const startTime =
          testPlanStepsResult !== undefined
            ? getStepStartTimeById(testPlanStepsResult[scenarioId]?.stepResults, step.id)
            : step?.startTime;
        const endTime =
          testPlanStepsResult !== undefined
            ? getStepEndTimeById(testPlanStepsResult[scenarioId]?.stepResults, step.id)
            : step?.endTime;
        const afterActionScreenshotUrl =
          step?.isCaptureScreenshot === 1
            ? testPlanStepsResult !== undefined
              ? getStepAfterActionScreenshotUrl(testPlanStepsResult[scenarioId]?.stepResults, step.id)
              : step?.afterActionScreenshotUrl
            : null;
        return (
          <TestPlanResultDetailStepListV2
            key={index}
            scenarioData={scenarioData}
            isActive={activeScenarioId && activeScenarioId?.includes(scenarioId)}
            isTestBlock={isTestBlock}
            stepResultData={step}
            activeScenarioId={activeScenarioId}
            index={index}
            stepCount={"S" + step?.stepOrderPath}
            testPlanResult={step?.modifiedResult !== null ? step?.modifiedResult : step?.modifiedResult}
            setCurrentActiveScenarioId={setCurrentActiveScenarioId}
            currentActiveScenarioId={currentActiveScenarioId}
            stepResultDuration={duration}
            startTime={startTime}
            endTime={endTime}
            setTestPlanStepsResult={setTestPlanStepsResult}
            testPlanStepsResult={testPlanStepsResult}
            afterActionScreenshotUrl={afterActionScreenshotUrl}
          />
        );
      });
    }
    return null;
  };

  let stepName = `${stepCount}.${stepResultData.stepIndex}`;
  return (
    <React.Fragment>
      <>
        <div className="display-flex" key={index}>
          {stepResultData.action === STEP ? (
            <Tooltip
              color={TOOLTIP_BG_COLOR}
              title={
                parentStep ? `Step-${index + 1}` : `${stepName?.length >= 12 ? stepName : `Grouped-Step-${index + 1}`}`
              }
              placement="bottom"
            >
              <StyledSpan>
                {parentStep ? stepCount : stepName?.length >= 12 ? stepResultData.stepIndex : stepCount}
              </StyledSpan>
            </Tooltip>
          ) : (
            <StyledSpan GRAY_COLOR={GRAY_COLOR}>{index + 1}</StyledSpan>
          )}
          <div className="w-98">
            <Collapse
              className={leftBorderColor(testPlanResult)}
              expandIconPosition="end"
              onChange={(e) => getTestStepsDetailsOnClick(scenarioData, index, stepResultData)}
            >
              <Panel
                className={backGroundColorForSteps(stepResultData)}
                showArrow={true}
                header={
                  <TestPlanResultDetailStepV2
                    data={stepResultData}
                    index={index}
                    preConditionList={
                      isTestBlock === 0
                        ? fetchPreconditionSteps(
                            testPlanResultStepsDetails,
                            scenarioData?.scenarioId,
                            stepResultData.id
                          )
                        : fetchPreconditionSteps(testBlockResultDataByRunId, runId, stepResultData.id)
                    }
                    modifiedResult={stepResultData.modifiedResult}
                    repeatSteps={stepResultData?.repeatSteps}
                    retrySteps={stepResultData.retrySteps}
                    duration={stepResultDuration}
                    startTime={startTime}
                    endTime={endTime}
                    afterActionScreenshotUrl={afterActionScreenshotUrl}
                    isTestBlock={isTestBlock}
                    setVisibleReportModal={setVisibleReportModal}
                    scenarioData={scenarioData}
                    setVisibleSubStepReportModal={setVisibleSubStepReportModal}
                    parentStep={parentStep}
                  />
                }
                key={stepResultData?.id}
              >
                <div
                  className={
                    !_.isEmpty(
                      isTestBlock === 0
                        ? fetchPreconditionSteps(
                            testPlanResultStepsDetails,
                            scenarioData?.scenarioId,
                            stepResultData.id
                          )
                        : fetchPreconditionSteps(testBlockResultDataByRunId, runId, stepResultData.id)
                    ) && "bg-color-gray p-10 border-radius-10"
                  }
                >
                  <TestPlanResultDetailPreconditionList
                    preConditionList={
                      isTestBlock === 0
                        ? fetchPreconditionSteps(
                            testPlanResultStepsDetails,
                            scenarioData?.scenarioId,
                            stepResultData.id
                          )
                        : fetchPreconditionSteps(testBlockResultDataByRunId, runId, stepResultData.id)
                    }
                    index={index}
                    isStepHasPreCondition={stepResultData.isStepHasPreCondition}
                    isLoopStep={stepResultData.isLoopStep}
                    recordedStepImageLink={stepResultData.recordedStepImageLink}
                    failureImage={stepResultData.failureScreenshotLink}
                    failurePageSourceLink={stepResultData?.failurePagesourceLink}
                    result={stepResultData.result}
                    executedLoopCount={
                      !_.isEmpty(testPlanResultStepsDetails) &&
                      testPlanResultStepsDetails[scenarioData?.scenarioId]?.childSteps?.[stepResultData?.id]?.length > 0
                        ? testPlanResultStepsDetails[scenarioData?.scenarioId]?.childSteps[stepResultData?.id][0]
                            ?.executedLoopCount
                        : 0
                    }
                    loopCount={stepResultData?.loopCount}
                    continueAfterFail={stepResultData.continueAfterFail}
                    modifiedResult={stepResultData.modifiedResult || stepResultData?.result}
                    testExecutionElementDetails={stepResultData.testExecutionElementDetails}
                    testDataName={stepResultData.testDataName}
                    testDataValue={stepResultData.testDataValue}
                    testDataCustomValue={stepResultData.testDataCustomValue}
                    outputTestDataName={stepResultData.outputTestDataName}
                    outputTestDataValue={stepResultData.outputTestDataValue}
                    selectedElementImageUrl={stepResultData.selectedElementImageUrl}
                    selectorType={stepResultData.selectorType}
                    retryCounts={stepResultData.retryCounts}
                    retryIndex={stepResultData.retryIndex}
                    retrySteps={stepResultData.retrySteps}
                    repeatSteps={stepResultData?.repeatSteps}
                    testStepFailDetails={stepResultData?.testStepFailDetails}
                    action={stepResultData.action}
                    testExecutionErrorInvestigationDetails={stepResultData?.testExecutionErrorInvestigationDetails}
                    text={stepResultData.textRef}
                    data={stepResultData}
                  />
                  <>
                    {isTestBlock === 0
                      ? childStep(childResultSteps, stepResultData?.id, scenarioData?.scenarioId)
                      : childStep(childResultSteps, stepResultData?.id, runId)}
                    {stepResultData.action !== STEP ? (
                      <TestPlanResultStepElementDetails
                        recordedStepImageLink={stepResultData.recordedStepImageLink}
                        testExecutionElementDetails={stepResultData.testExecutionElementDetails}
                        failureImage={stepResultData.failureScreenshotLink}
                        failurePageSourceLink={stepResultData.failurePagesourceLink}
                        continueAfterFail={stepResultData.continueAfterFail}
                        result={stepResultData.result}
                        modifiedResult={stepResultData.modifiedResult}
                        testDataName={stepResultData.testDataName}
                        testDataValue={stepResultData.testDataValue}
                        outputTestDataName={stepResultData.outputTestDataName}
                        outputTestDataValue={stepResultData.outputTestDataValue}
                        testDataCustomValue={stepResultData.testDataCustomValue}
                        selectedElementImageUrl={stepResultData.selectedElementImageUrl}
                        selectorType={stepResultData.selectorType}
                        retryCounts={stepResultData.retryCounts}
                        retryIndex={stepResultData.retryIndex}
                        retrySteps={stepResultData.retrySteps}
                        repeatSteps={stepResultData?.repeatSteps}
                        testStepFailDetails={stepResultData?.testStepFailDetails}
                        action={stepResultData.action}
                        testExecutionErrorInvestigationDetails={stepResultData?.testExecutionErrorInvestigationDetails}
                        text={stepResultData.textRef}
                        data={stepResultData}
                      />
                    ) : null}
                  </>
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      </>
      {openErrorInvestigationModal && (
        <ErrorInvestigationModal
          openErrorInvestigationModal={openErrorInvestigationModal}
          setOpenErrorInvestigationModal={setOpenErrorInvestigationModal}
          failedTestStepId={testStepId}
          projectId={runResultDetails[0]?.projectId}
          orgId={runResultDetails[0]?.organizationId}
          runNumber={runResultDetails[0]?.runNumber}
        />
      )}
      {!!visibleReportModal && (
        <AIReportDetailsModalV2
          visibleReportModal={visibleReportModal}
          setVisibleReportModal={setVisibleReportModal}
          isTestBlock={isTestBlock}
        />
      )}

      {!!visibleSubStepReportModal && (
        <AIReportDetailsSubStepModalV2
          visibleReportModal={visibleSubStepReportModal}
          setVisibleReportModal={setVisibleSubStepReportModal}
          scenarioData={scenarioData}
          isTestBlock={isTestBlock}
        />
      )}
    </React.Fragment>
  );
};
export default TestPlanResultDetailStepListV2;
