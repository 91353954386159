import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { STEP, TEST_SCENARIOS } from "../../../../../Constants";
import { ADD_SUB_STEP_ICON_ON_HOVER, ICON_ADD_SUB_STEP, ICON_IMPORT_STEP } from "../../../../../Constants/SvgConstants";
import {
  ADD_SUB_STEP,
  IMPORT_SUB_STEP_IN_TEST_BLOCK,
  IMPORT_SUB_STEP_IN_TEST_SCENARIO,
} from "../../../../../Constants/TooltipConstants";
import {
  addRecTestStep,
  showImportRecTestStepModal,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import RecTestStepOperationV2 from "./RecTestStepOperationV2";
import styles from "./StepActionsComponents.module.scss";

const RecTestStepSubStepsV2 = (props) => {
  const dispatch = useDispatch();
  const { disabled, projectId, stepType, recTestStep, recTestStepOnHover } = props;
  const { visibleAIGenerateRecTestStep } = useSelector((state) => state.RecTestStepCommonViewReducer);

  const disabledIcons = visibleAIGenerateRecTestStep?.includes(recTestStep.id);

  const actionStep = useMemo(() => {
    return (
      recTestStep?.recTestSteps?.some((i) => i?.recTestStepProperty?.action === STEP) ||
      (isEmpty(recTestStep?.recTestSteps) ? true : false)
    );
  }, [recTestStep]);

  return (
    <div className={`${styles.subHeader} ${!recTestStepOnHover && styles.actionIconInHeader}`}>
      <RecTestStepOperationV2
        name="Sub-step"
        disabled={disabled || disabledIcons}
        tooltipTitle={ADD_SUB_STEP}
        imageName={recTestStepOnHover ? ADD_SUB_STEP_ICON_ON_HOVER : ICON_ADD_SUB_STEP}
        handleClick={() => !disabled && dispatch(addRecTestStep(recTestStep))}
        recTestStepOnHover={recTestStepOnHover}
      />
      {!recTestStepOnHover && (
        <RecTestStepOperationV2
          name="Import"
          disabled={disabled || disabledIcons}
          tooltipTitle={stepType === TEST_SCENARIOS ? IMPORT_SUB_STEP_IN_TEST_SCENARIO : IMPORT_SUB_STEP_IN_TEST_BLOCK}
          imageName={ICON_IMPORT_STEP}
          handleClick={() =>
            !disabled &&
            dispatch(showImportRecTestStepModal(STEP, projectId, recTestStep, actionStep, recTestStep?.recTestSteps))
          }
        />
      )}
    </div>
  );
};

export default RecTestStepSubStepsV2;
