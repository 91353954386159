import { notification } from "antd";
import moment from "moment";
import {
  ADD_GROUP_QUERY_BUILDER,
  ADD_TAG_QUERY_BUILDER,
  CHANGED_FILTER_NAME_FIELD,
  CHANGE_AWS_DEVICE_BUILD_SELECTION,
  CHANGE_AWS_DEVICE_POOL_SELECTION,
  CHANGE_AWS_DEVICE_PROJECT_SELECTION,
  CHANGE_REPEAT_STEP,
  CHANGE_EXECUTION_FARM_SELECTION,
  CHANGE_LODESTONE_DEVICE_FARM_SELECTION,
  CHANGE_LODESTONE_DEVICE_SELECTION,
  CHANGE_QUERY_BUILDER_OPERATOR,
  CHANGE_REPORT_RESULT_TAB,
  CHANGE_SELECTED_ALL_SCENARIO_OPTION,
  CHANGE_SELECTED_COMPARISON_PREVIEW_VIEW,
  CHANGE_SELECTED_TAG_VALUE_OF_SCHEDULER,
  CHANGE_SELECTED_TEST_SCENARIO_FOR_RESULT_LOGS,
  CHANGE_TEST_DATA_LIST_FILTER_VALUE,
  CHANGE_TEST_PLAN_DETAILS,
  CHANGE_TEST_PLAN_EXECUTE_COUNT,
  CHANGE_TEST_PLAN_RESULT_LIST_PAGE_NUMBER,
  CHANGE_TEST_PLAN_TAB,
  CLICKED_SAVED_SCENARIO_FILTER,
  CLOSE_TEST_PLAN_TREND_DRAWER,
  COMPARISON_ALL_LOCALES,
  CREATE_NEW_FILTER_FLAG,
  DELETE_TEST_PLAN_DETAIL,
  DEVICE_LOADING,
  EMAIL_VALIDITY_CHECKED,
  ERROR_STATUS,
  ERROR_TYPE,
  EXPORT_SCENARIO_LOADING,
  FETCHING_TEST_PLAN_LIST,
  FETCHING_TEST_PLAN_RESULT_LIST,
  FETCHING_TEST_PLAN_STEP_LIST,
  GET_FREE_DEVICES_LIST,
  GET_QUERY_BUILD_DATA,
  GET_REF_LOCALE_FOR_PREVIEW,
  GET_REF_RUN_NUMBER_FOR_PREVIEW,
  GET_SCENARIO_FILTER,
  GET_SCENARIO_FILTER_DATA,
  GET_TAG_BY_TEST_PLAN_ID,
  GET_TEST_PLAN_BY_ID,
  GET_TEST_PLAN_SCENARIO_WISE_TREND_DATA,
  HANDLE_CHANGE_REPORT_MANAGER_DATA,
  HIDE_SCHEDULE_TEST_PLAN_MODAL,
  OPEN_TEST_PLAN_TREND_DRAWER,
  PREVIEW_ALL_LOCALES_DOWNLOAD_BY_RUN_NUMBER,
  PREVIEW_DOWNLOAD_BY_RUN_NUMBER,
  RECORD_10_PER_PAGE,
  REMOVE_GROUP_QUERY_BUILDER,
  REMOVE_TAG_QUERY_BUILDER,
  RESET_QUERY_BUILDER,
  RESET_REPORT_MANAGER_DATA,
  RESET_SCHEDULED_TEST_PLAN,
  SAVE_SCENARIO_FILTER,
  SAVE_SCHEDULED_TEST_PLAN,
  SAVE_TEST_PLAN,
  SAVE_TEST_PLAN_DATA,
  SAVE_TEST_PLAN_NAME,
  SAVE_TEST_PLAN_SCENARIO,
  SCENARIO_LOADING,
  SCHEDULER_BUILD_LABEL,
  SCHEDULER_SPINNER,
  SCHEDULE_SAVE_CHECKED,
  SELECTED_RUN_RESULT_VIEW,
  SET_RESULT_TYPE,
  SET_RUN_RESULT_SCENARIO_ID,
  SET_SCHEDULE_TEST_PLAN,
  SUCCESS_STATUS,
  SUCCESS_TYPE,
  TAG_IN_QUERY_BUILDER,
  TEST_DATA_SCOPE,
  TEST_PLAN_DETAILS_LIST,
  TEST_PLAN_LIST,
  TEST_PLAN_LOADING,
  TEST_PLAN_REPORT_LIST,
  TEST_PLAN_RESULT_BY_RUN_NUMBER,
  TEST_PLAN_RESULT_LIST,
  TEST_PLAN_STEPS_LIST,
  TEST_SCENARIOS,
  TEST_STEP_LIST_BY_SCENARIO_ID,
  UPDATED_TEST_PLAN_SAVED,
  UPDATE_TAG_VALUE_QUERY_BUILDER,
  appStatic,
  dateHourMinuteFormat,
  CHANGE_RETRY_STEP,
  RUN_DETAILS_BY_RUN_NUMBERS,
  RUN_DETAILS_LOADING,
  SET_SELECTED_SCENARIO,
  TEST_PLAN_STEPS_LIST_ID,
  REMOVE_TEST_PLAN_STEPS_LIST_ID,
  EDIT_TEST_PLAN,
  GET_TEST_BLOCK_RESULT_DATA,
  TEST_PLAN_CHILD_STEP_LOADING,
  GET_TEST_PLAN_RESULT_VIDEO_LINK,
  ACCESSIBILITY_REPORT_LOADER,
  GET_ACCESSIBILITY_REPORT_DETAILS,
  AI_REPORT_STATUS_LOADING,
} from "../Constants";
import { getFormateDateAndTime, showNotification, updateResponse } from "../Util";
import { async } from "../api/automation";
import history from "../history";
import { getAwsDeviceFarmBuilds, getAwsDeviceFarmPools } from "./AwsDeviceFarmAction";
import { fetchPreviewRunById } from "./RecTestScenarioAction";
import { clearPreview } from "./RecTestStep/RecTestStepRedirectAction";
import { getRecTestStepListByRecTestScenarioId, initialStateForScenarioDesigner } from "./RecTestStepAction";
import { changeDeviceTabActionKey } from "./Recording";
import { hideAddTestDataSetForm } from "./TestDataSetAction";
let controller = new AbortController();
let testEventSource = "";
let testListEventSource = "";
export function getTestPlanList(data) {
  return async (dispatch) => {
    dispatch({ type: FETCHING_TEST_PLAN_LIST });
    return async("testPlan/", "GET", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: TEST_PLAN_LIST, response });
    });
  };
}

export function getTestPlanResultList() {
  return async (dispatch) => {
    dispatch({ type: FETCHING_TEST_PLAN_RESULT_LIST });
    return async("runResult/", "GET").then((response) => {
      response = updateResponse(response);
      dispatch({ type: TEST_PLAN_RESULT_LIST, response });
    });
  };
}

export function goToTestPlan() {
  return () => {
    history.push("/testPlanResult");
  };
}

export function getRunDetailsByRunNumber(runNumber) {
  return async (dispatch) => {
    dispatch({ type: RUN_DETAILS_LOADING, isLoading: true });
    return async("testPlan/getDeviceDeailsByRunNumber", "GET", { runNumber: runNumber }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: RUN_DETAILS_BY_RUN_NUMBERS, response: response?.data });
      } else {
        dispatch({ type: RUN_DETAILS_BY_RUN_NUMBERS, response: [] });
      }
      dispatch({ type: RUN_DETAILS_LOADING, isLoading: false });
    });
  };
}

export function getTestPlanByTestPlanId(testPlanId) {
  return (dispatch) => {
    dispatch({ type: FETCHING_TEST_PLAN_RESULT_LIST });
    return async("recTestScenario/getByTestPlan", "GET", {
      testPlanId: testPlanId,
    }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_TEST_PLAN_BY_ID, response });
    });
  };
}

export function hideScheduleTestPlanModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_SCHEDULE_TEST_PLAN_MODAL });
  };
}

export function changeExecuteCount(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_TEST_PLAN_EXECUTE_COUNT, value });
  };
}

export function scheduleTestPlan() {
  return (dispatch, getState) => {
    let data = {
      executionCount: getState().TestPlanReducer.testPlanExecuteCount,
      testPlan: {
        id: getState().TestPlanReducer.selectedScheduleTestPlanDetail.id,
      },
    };
    return async("testPlanExecutionQueue/customSave", "POST", data).then((response) => {
      response = updateResponse(response);
      dispatch(hideScheduleTestPlanModal());
      // dispatch({ type: TEST_PLAN_RECORDED_TEST_SCENARIO_LIST, response })
    });
    // dispatch({ type: SHOW_SCHEDULE_TEST_PLAN_MODAL })
  };
}

// export function getTestPlanAndScenarioResultList(data) {
//   return (dispatch) => {
//     dispatch({ type: TEST_PLAN_LOADING, response: { isLoading: true } });
//     closeConnectionsOfResult();
//     testListEventSource = prepareEventSource(
//       "testPlan/getTestPlanAndScenarioForResult",
//       {
//         pageNumber: data.pageNumber ? data.pageNumber : "1",
//         recordPerPage: data.recordPerPage,
//         searchKeywords: isNullOrUndefined(data.searchKeywords) ? "" : data.searchKeywords,
//         startDate: data.startDate,
//         endDate: data.endDate,
//         projectId: data.projectId,
//         status: data.status ? data.status : "",
//       },
//       ""
//     );
//     testListEventSource.onmessage = (event: any) => {
//       let data = event.data;
//       let response = JSON.parse(data);
//       response = updateResponse(response);
//       dispatch({ type: TEST_PLAN_LOADING, response: { isLoading: false } });
//       dispatch({ type: TEST_PLAN_REPORT_LIST, response });
//     };
//   };
// }

export function changeTestPlanResultListPageNumber(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_TEST_PLAN_RESULT_LIST_PAGE_NUMBER, value });
  };
}

export const handleChangeReportManagerSearch = (value) => {
  return (dispatch) => {
    dispatch({ type: HANDLE_CHANGE_REPORT_MANAGER_DATA, value });
  };
};

export const resetReportManagerSearch = (value) => {
  return (dispatch) => {
    dispatch({ type: RESET_REPORT_MANAGER_DATA, value });
  };
};

export function getTestPlanAndScenarioResultList(
  projectId,
  executionReportProjectId,
  updateProjectId,
  recordPerPage,
  setApiInProgress,
) {
  return async (dispatch, getState) => {
    if (setApiInProgress) {
      setApiInProgress(true);
    }

    // Initialize AbortController
    let controller = new AbortController();
    const signal = controller.signal;

    let startDate = getState().TestPlanReducer?.reportManagerSearch?.startDate;
    let endDate = getState().TestPlanReducer?.reportManagerSearch?.endDate;

    let data = {
      pageNumber: getState().TestPlanReducer?.currentTestPlanResultPage,
      recordPerPage: recordPerPage || RECORD_10_PER_PAGE,
      searchKeywords: getState().TestPlanReducer?.reportManagerSearch?.searchKeyword,
      startDate: startDate ? getFormateDateAndTime(startDate, dateHourMinuteFormat) : "",
      endDate: endDate ? getFormateDateAndTime(endDate, dateHourMinuteFormat) : "",
      projectId: projectId || executionReportProjectId || "",
      status: getState().TestPlanReducer?.reportManagerSearch?.status,
    };

    executionReportProjectId && dispatch({ type: TEST_PLAN_LOADING, response: { isLoading: true } });

    try {
      const response = await async("testPlan/getTestPlanAndScenarioForResult", "GET", data, undefined, signal);
      const updatedResponse = updateResponse(response);
      dispatch({ type: TEST_PLAN_REPORT_LIST, response: updatedResponse });
    } catch (error) {
      console.error("Error fetching test plan and scenario result list:");
      // Handle error (dispatch an error action, show a notification, etc.)
    } finally {
      setApiInProgress && setApiInProgress(false);
      dispatch({ type: TEST_PLAN_LOADING, response: { isLoading: false } });
    }
  };
}

export function closeConnectionsOfResult() {
  testEventSource && testEventSource.close();
  testListEventSource && testListEventSource.close();
}

export function changeTestPlanTab(selectedTab) {
  return (dispatch, getState) => {
    dispatch({ type: CHANGE_TEST_PLAN_TAB, selectedTab });
  };
}

export function setScheduleTestPlan(value) {
  return (dispatch, getState) => {
    dispatch({ type: SET_SCHEDULE_TEST_PLAN, isScheduleTestPlan: value });
  };
}

export function changeReportResultTab(value) {
  return (dispatch, getState) => {
    dispatch({ type: CHANGE_REPORT_RESULT_TAB, value });
  };
}

export function setResultType(value) {
  return (dispatch, getState) => {
    dispatch({ type: SET_RESULT_TYPE, value });
  };
}

export function getTestPlanDetailsList(data) {
  data.pageNumber = data.pageNumber ? data.pageNumber : "1";
  data.rights = data.rights && data.rights != null ? data.rights : "";
  return async (dispatch) => {
    dispatch({ type: TEST_PLAN_LOADING, response: { isLoading: true } });
    return async("testPlanScenarioAssoc/getTestPlanDetails", "GET", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: TEST_PLAN_DETAILS_LIST, response });
      dispatch({ type: TEST_PLAN_LOADING, response: { isLoading: false } });
    });
  };
}

export function getTestPlanStepsList(data, onSuccess, setChildResultSteps, isFirstCall) {
  return async (dispatch, getState) => {
    const { previousScenarioId, previousStepId } = getState().TestPlanReducer;

    if (isFirstCall) {
      const loadingPayload = {
        isLoading: true,
        scenarioId: data.scenarioId,
        previousScenarioId: previousScenarioId || data.scenarioId,
      };
      const loadingChildPayload = {
        isLoading: true,
        parentStepId: data?.parentStepId,
        previousStepId: previousStepId || data?.parentStepId,
      };

      if (data?.parentStepId) {
        dispatch({ type: TEST_PLAN_CHILD_STEP_LOADING, response: loadingChildPayload });
      } else {
        dispatch({ type: TEST_PLAN_LOADING, response: loadingPayload });
      }
    }

    return async("testStep/V2/getTestStepForResult", "GET", data).then((response) => {
      dispatch({ type: TEST_PLAN_LOADING, response: { isLoading: false, scenarioId: data.scenarioId } });

      if (onSuccess) {
        onSuccess();
      }

      response = updateResponse(response);
      if (response?.status === SUCCESS_STATUS && data?.parentStepId) {
        setChildResultSteps(response?.data);
      }

      if (!data?.parentStepId) {
        dispatch({ type: TEST_PLAN_STEPS_LIST, response, scenarioId: data.scenarioId });
      }

      if (data?.parentStepId) {
        dispatch({
          type: TEST_PLAN_CHILD_STEP_LOADING,
          response: { isLoading: false, parentStepId: data?.parentStepId },
        });
      } else {
        dispatch({ type: TEST_PLAN_LOADING, response: { isLoading: false, scenarioId: data.scenarioId } });
      }

      if (!data?.parentStepId) {
        dispatch({ type: REMOVE_TEST_PLAN_STEPS_LIST_ID, scenarioId: data?.scenarioId });
      }
    });
  };
}

export function getTestPlanStepsResult(scenarioId, setTestPlanStepsResult) {
  return async (dispatch) => {
    return async("testStep/getTestStepStatusForResult", "GET", { scenarioId }).then((response) => {
      setTestPlanStepsResult && setTestPlanStepsResult(response?.data);
    });
  };
}

export const testPlanStepsListId = (scenarioId) => {
  return async (dispatch) => {
    dispatch({ type: TEST_PLAN_STEPS_LIST_ID, scenarioId });
  };
};

export function getRunResultDetailsByRunNumber(data, onSuccess, projectId) {
  return async (dispatch, getState) => {
    try {
      let response = await async("runResult/getRunResultDetailsByRunNumber", "GET", {
        runNumber: data?.runNumber,
        type: getState().TestPlanReducer?.selectedRunResultView,
        projectId,
      });
      response = updateResponse(response);

      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: TEST_PLAN_RESULT_BY_RUN_NUMBER, response });

        if (response?.data?.runDetails[0]?.isTestBlock === 1) {
          const runId = response?.data?.runDetails[0]?.runId;
          if (runId) {
            try {
              const testBlockResponse = await async("testStep/getTestStepForResultByRunId", "GET", { runId: runId });
              dispatch({ type: GET_TEST_BLOCK_RESULT_DATA, response: testBlockResponse });
            } catch (error) {
              console.error(error);
            }
          }
        }

        if (onSuccess) {
          onSuccess();
        }
      } else {
        if (response.message) {
          showNotification(ERROR_TYPE, response.message);
        }
      }
    } catch (error) {
      showNotification(ERROR_TYPE, "An error occurred while fetching the run result details.");
    } finally {
      dispatch({ type: SCENARIO_LOADING, response: { isLoading: false } });
    }
  };
}

export function setSelectedRunResultView(selectedView) {
  return async (dispatch) => {
    dispatch({ type: SELECTED_RUN_RESULT_VIEW, selectedView: selectedView });
  };
}

export function setRunResultScenarioId(scenarioId) {
  return async (dispatch) => {
    dispatch({ type: SET_RUN_RESULT_SCENARIO_ID, scenarioId: scenarioId });
  };
}
export function fetchPreviewDownloadsByRunNumber(
  runNumber,
  resultType,
  refRunNumberForPreview = "",
  refRunNumberLocaleForPreview = "",
  isForFirstTime
) {
  return async (dispatch, getState) => {
    dispatch({ type: SCENARIO_LOADING, response: { isLoading: true } });
    async("runResult/getPreviewDownloadsByRunNumber", "GET", {
      runNumber: runNumber,
      resultType: resultType || "All",
      refRunNumber: refRunNumberForPreview ? refRunNumberForPreview?.split(" ")[0] : undefined,
      refLocale: refRunNumberLocaleForPreview,
    }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: PREVIEW_DOWNLOAD_BY_RUN_NUMBER, response, isForFirstTime });
      dispatch({ type: SCENARIO_LOADING, response: { isLoading: false } });
    });
  };
}

export function fetchPreviewScreenshotsByRunNumberAndLocales(
  runNumber,
  resultType,
  refRunNumberForPreview = "",
  refRunNumberLocaleForPreview = "",
  isForFirstTime
) {
  return async (dispatch, getState) => {
    dispatch({ type: SCENARIO_LOADING, response: { isLoading: true } });
    async("runResult/getPreviewScreenshotsByRunNumberAndLocales", "GET", {
      runNumber: runNumber,
      resultType: resultType,
      refRunNumber: refRunNumberForPreview ? refRunNumberForPreview?.split(" ")[0] : undefined,
      refLocale: refRunNumberLocaleForPreview,
    }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: PREVIEW_ALL_LOCALES_DOWNLOAD_BY_RUN_NUMBER, response, isForFirstTime });
      dispatch({ type: SCENARIO_LOADING, response: { isLoading: false } });
    });
  };
}

export function fetchSameRunNumberForPreview(runNumber, locale) {
  return async (dispatch, getState) => {
    dispatch({ type: SCENARIO_LOADING, response: { isLoading: true } });
    async("runResult/getListOfRunsByRunNumberAndLocale", "GET", {
      runNumber: runNumber,
      locale: locale,
      resultType: "Passed",
    }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_REF_RUN_NUMBER_FOR_PREVIEW, response });
      dispatch({ type: SCENARIO_LOADING, response: { isLoading: false } });
    });
  };
}

export function fetchSameLocaleForPreviewByRunNumber(runNumber) {
  return async (dispatch, getState) => {
    dispatch({ type: SCENARIO_LOADING, response: { isLoading: true } });
    async("runResult/getListOfLocaleByRunNumber", "GET", {
      runNumber: runNumber,
      resultType: "Passed",
    }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_REF_LOCALE_FOR_PREVIEW, response, runNumber });
      dispatch({ type: SCENARIO_LOADING, response: { isLoading: false } });
    });
  };
}

export function saveTestPlan(data) {
  return async (dispatch, getState) => {
    return async("testPlan/save", "POST", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: SAVE_TEST_PLAN, response });
      if (response.status === SUCCESS_STATUS) {
        dispatch(
          getTestPlanDetailsList({
            pageNumber: getState().TestPlanReducer.currentTestPlanPage,
            recordPerPage: 15,
            projectId: data.testPlan.project.id,
          })
        );
        dispatch({ type: SAVE_TEST_PLAN_DATA, response });
        notification["success"]({
          message: SUCCESS_STATUS,
          description: response.message,
        });
        dispatch({ type: SAVE_TEST_PLAN_NAME, undefined });
        dispatch({ type: SAVE_TEST_PLAN_SCENARIO, undefined });
        dispatch(hideAddTestDataSetForm());
        dispatch(changeTestPlanDetails(undefined));
        dispatch({ type: UPDATED_TEST_PLAN_SAVED });
      } else {
        notification["error"]({
          message: ERROR_STATUS,
          description: response.message,
        });
      }
    });
  };
}

export function saveScheduledTestPlan(data, schedulerSpinner) {
  return async (dispatch) => {
    dispatch({ type: SCHEDULER_SPINNER, schedulerSpinner });
    return async("testPlanExecutionQueue/scheduledTestPlan", "POST_WITH_FILE", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: SAVE_SCHEDULED_TEST_PLAN, response });
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: RESET_SCHEDULED_TEST_PLAN });
      }
    });
  };
}
export function resetScheduleTestPlan() {
  return async (dispatch) => {
    dispatch({ type: RESET_SCHEDULED_TEST_PLAN });
  };
}

export function getTestStepListByTestScenarioList(testScenarioId, testScenarioName) {
  return async (dispatch) => {
    dispatch({ type: FETCHING_TEST_PLAN_STEP_LIST });
    dispatch({ type: CHANGE_TEST_DATA_LIST_FILTER_VALUE, value: TEST_DATA_SCOPE.ALL });
    async("recTestStep/getByRecTestScenarioId", "GET", {
      recTestScenarioId: testScenarioId,
    }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: TEST_STEP_LIST_BY_SCENARIO_ID, response, testScenarioId, testScenarioName });
      dispatch(clearPreview());
    });
  };
}

export function deleteTestPlanDetail(data, setSelectAllTestPlan, projectId, rights, searchKeyword) {
  return async (dispatch) => {
    return async("testPlan/delete", "POST", data).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        setSelectAllTestPlan([]);
        response = updateResponse(response);
        dispatch({ type: DELETE_TEST_PLAN_DETAIL, response });
        dispatch({ type: DELETE_TEST_PLAN_DETAIL });
        showNotification(SUCCESS_TYPE, response.message);
        dispatch(
          getTestPlanDetailsList({
            recordPerPage: RECORD_10_PER_PAGE,
            projectId: projectId,
            rights: rights,
            searchKeyword,
          })
        );
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
    });
  };
}

export function deleteTestPlanRunResult(data, projectId, executionReportProjectId, setSelectAllRun) {
  return async (dispatch, getState) => {
    dispatch({ type: TEST_PLAN_LOADING, response: { isLoading: true } });
    return async("runResult/clearRunResult", "POST", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        setSelectAllRun([]);
        showNotification(SUCCESS_TYPE, response.message);
        data.recTestScenarioId
          ? dispatch(fetchPreviewRunById())
          : dispatch(getTestPlanAndScenarioResultList(projectId, true));
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
    });
  };
}

export function abortTestPlanRunResult(data, projectId) {
  return async (dispatch) => {
    return async("runResult/abortRun", "POST", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        dispatch(getTestPlanAndScenarioResultList(projectId, true));
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
    });
  };
}
export function editScenario(scenarioData, projectId) {
  return async (dispatch, getState) => {
    new Promise((resolve) => resolve(dispatch(changeDeviceTabActionKey(TEST_SCENARIOS, projectId)))).then(() => {
      dispatch(
        initialStateForScenarioDesigner(
          projectId,
          scenarioData.tags,
          scenarioData.id,
          scenarioData.name,
          scenarioData.testRailTestCaseId,
          scenarioData.testRailTestSuiteId,
          scenarioData.platform
        )
      );
      dispatch({ type: DEVICE_LOADING, response: { isLoading: false } });
      dispatch(getRecTestStepListByRecTestScenarioId(scenarioData?.id));
    });
  };
}

export function getFreeDevicesList(recTestScenarioId, projectId, deviceFarmId) {
  return async (dispatch) => {
    async("device/getFreeDevicesByProjectAndUser", "GET", {
      projectId: projectId,
      deviceFarmId,
    }).then((response) => {
      dispatch({ type: GET_FREE_DEVICES_LIST, recTestScenarioId, response });
      dispatch({ type: CHANGE_LODESTONE_DEVICE_SELECTION, deviceFarmId, deviceIds: [] });
    });
  };
}

export function changeOperator(value, uuid, firstGroup) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_QUERY_BUILDER_OPERATOR, value, uuid, firstGroup });
  };
}

export function clickedSavedScenarioFilter(data) {
  return async (dispatch) => {
    dispatch({
      type: CLICKED_SAVED_SCENARIO_FILTER,
      data,
    });
  };
}

export function buildLabel(selectedTestPlanId, testPlanList) {
  return async (dispatch) => {
    dispatch({ type: SCHEDULER_BUILD_LABEL, selectedTestPlanId, testPlanList });
  };
}

export function changeSelectedTagValue(data) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_SELECTED_TAG_VALUE_OF_SCHEDULER, data });
  };
}

export function checkTagInQuery() {
  return async (dispatch) => {
    dispatch({ type: TAG_IN_QUERY_BUILDER });
  };
}

export function getQueryBuildData() {
  return async (dispatch) => {
    dispatch({ type: GET_QUERY_BUILD_DATA });
  };
}

export function scheduleSaveChecked(data) {
  return async (dispatch) => {
    dispatch({ type: SCHEDULE_SAVE_CHECKED, data });
  };
}

export function emailValid(data) {
  return async (dispatch) => {
    dispatch({ type: EMAIL_VALIDITY_CHECKED, data });
  };
}

export function addNewTagQueryBuilder(uuid, firstGroup) {
  return async (dispatch) => {
    dispatch({ type: ADD_TAG_QUERY_BUILDER, uuid, firstGroup });
    dispatch({ type: GET_QUERY_BUILD_DATA });
  };
}

export function changeTagValue(value, uuid) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_TAG_VALUE_QUERY_BUILDER, value, uuid });
    dispatch({ type: GET_QUERY_BUILD_DATA });
  };
}

export function addNewGroup(parentId, uuid, firstGroup) {
  return async (dispatch) => {
    dispatch({ type: ADD_GROUP_QUERY_BUILDER, uuid, firstGroup });
    dispatch({ type: GET_QUERY_BUILD_DATA });
  };
}

export function createNewFilterFlag(data) {
  return async (dispatch) => {
    dispatch({ type: CREATE_NEW_FILTER_FLAG, data });
  };
}

export function changedFilterNameField(data) {
  return async (dispatch) => {
    dispatch({ type: CHANGED_FILTER_NAME_FIELD, data });
  };
}

export function removeGroup(uuid, parentUuidId, isFirstGroup) {
  return async (dispatch) => {
    dispatch({ type: REMOVE_GROUP_QUERY_BUILDER, uuid, parentUuidId, isFirstGroup });
    dispatch({ type: GET_QUERY_BUILD_DATA });
  };
}

export function removeTag(uuid) {
  return async (dispatch) => {
    dispatch({ type: REMOVE_TAG_QUERY_BUILDER, uuid });
    dispatch({ type: GET_QUERY_BUILD_DATA });
  };
}

export function saveScenarioFilter(requestData) {
  return async (dispatch) => {
    async("scenarioFilter/saveScenarioFilter", "POST", requestData).then((response) => {
      dispatch({ type: SAVE_SCENARIO_FILTER, response });
      dispatch({ type: SAVE_SCENARIO_FILTER });
    });
  };
}

export function getFilterByTestPlan(requestData) {
  return async (dispatch) => {
    async("scenarioFilter/getByTestPlanId", "GET", requestData).then((response) => {
      dispatch({ type: GET_SCENARIO_FILTER, response });
    });
  };
}

export function getFilterDataByScenarioFilterId(requestData) {
  return async (dispatch) => {
    async("scenarioFilterQueryMapping/getFilterDataByScenarioFilterId", "GET", requestData).then((response) => {
      dispatch({ type: GET_SCENARIO_FILTER_DATA, response });
    });
  };
}

export function getTagByTestPlanId(requestData) {
  return async (dispatch) => {
    async("scenarioTagAssoc/getTagByTestPlanId", "GET", requestData).then((response) => {
      dispatch({ type: GET_TAG_BY_TEST_PLAN_ID, response });
    });
  };
}

export function resetScenarioFilter() {
  return async (dispatch) => {
    dispatch({ type: RESET_QUERY_BUILDER });
    dispatch({ type: GET_QUERY_BUILD_DATA });
  };
}

export function changeExecutionFarm(value) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_EXECUTION_FARM_SELECTION, value });
  };
}

export function changeLodestoneDeviceFarm(deviceFarmIds, projectId) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_LODESTONE_DEVICE_FARM_SELECTION, deviceFarmIds });
    dispatch(getFreeDevicesList(null, projectId, deviceFarmIds));
  };
}

export function changeLodestoneDevice(deviceFarmIds, deviceIds) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_LODESTONE_DEVICE_SELECTION, deviceFarmIds, deviceIds });
  };
}

export function changeAwsDeviceProject(awsDeviceProjectId, platform) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_AWS_DEVICE_PROJECT_SELECTION, awsDeviceProjectId });
    dispatch(getAwsDeviceFarmPools({ awsDeviceFarmProjectId: awsDeviceProjectId }));
    dispatch(getAwsDeviceFarmBuilds({ awsDeviceFarmProjectId: awsDeviceProjectId }));
  };
}
export function changeAwsDevicePool(awsDevicePoolIds) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_AWS_DEVICE_POOL_SELECTION, awsDevicePoolIds });
  };
}
export function changeAwsDeviceBuild(awsDeviceBuildArn) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_AWS_DEVICE_BUILD_SELECTION, awsDeviceBuildArn });
  };
}

export function changeTestPlanDetails(data) {
  return (dispatch) => {
    dispatch({ type: CHANGE_TEST_PLAN_DETAILS, data });
  };
}

export function changeSelectedTestScenarioForResultLogs(isChecked, testScenarioLink, selectAll, testScenarioId) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_SELECTED_TEST_SCENARIO_FOR_RESULT_LOGS,
      isChecked,
      testScenarioLink,
      selectAll,
      testScenarioId,
    });
  };
}

export function downloadResultLogs(runNumber) {
  return (dispatch, getState) => {
    const { selectedTestScenarioForResultLogs } = getState().TestPlanReducer;
    for (var i = 0; i < selectedTestScenarioForResultLogs.length; i++) {
      window.open(selectedTestScenarioForResultLogs[i], "_blank");
    }
  };
}

export function openTestPlanTrendDrawer() {
  return (dispatch, getState) => {
    dispatch({ type: OPEN_TEST_PLAN_TREND_DRAWER });
  };
}

export function closeTestPlanTrendDrawer() {
  return (dispatch, getState) => {
    dispatch({ type: CLOSE_TEST_PLAN_TREND_DRAWER });
  };
}

export function getRunInsight(testPlanData, startDate, endDate) {
  return (dispatch, getState) => {
    dispatch(openTestPlanTrendDrawer());
    async("testPlan/getRunInsight", "GET", {
      testPlanId: testPlanData.id,
      startDate: moment(startDate).format(appStatic.dateFormatForAttendance),
      endDate: moment(endDate).format(appStatic.dateFormatForAttendance),
    }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_TEST_PLAN_SCENARIO_WISE_TREND_DATA, response, testPlanData });
    });
  };
}
export function changeSelectedAllScenarioOption(checked) {
  return (dispatch, getState) => {
    dispatch({ type: CHANGE_SELECTED_ALL_SCENARIO_OPTION, checked });
  };
}

export function changeSelectedComparisonPreviewView(
  value,
  runNumber,
  resultType,
  refRunNumberForPreview,
  refRunNumberLocaleForPreview
) {
  return (dispatch, getState) => {
    switch (value) {
      case COMPARISON_ALL_LOCALES:
        dispatch(
          fetchPreviewScreenshotsByRunNumberAndLocales(
            runNumber,
            resultType,
            refRunNumberForPreview,
            refRunNumberLocaleForPreview
          )
        );
        break;
      default:
        dispatch(
          fetchPreviewDownloadsByRunNumber(runNumber, resultType, refRunNumberForPreview, refRunNumberLocaleForPreview)
        );
        break;
    }
    dispatch({ type: CHANGE_SELECTED_COMPARISON_PREVIEW_VIEW, value });
  };
}

export function exportRunComparisonDoc(runNumber, resultType, refRunNumber, refLocale) {
  return (dispatch, getState) => {
    const { selectedTestScenarioForIds } = getState().TestPlanReducer;
    dispatch({ type: EXPORT_SCENARIO_LOADING, response: { isLoading: true } });
    async("runResult/exportRunComparisonDoc", "GET_FOR_FILE", {
      runNumber: runNumber,
      refRunNumber: refRunNumber ? refRunNumber?.split(" ")[0] : undefined,
      refLocale: refLocale,
      resultType: resultType,
      selectedTestScenarioForIds: selectedTestScenarioForIds,
    }).then((response) => {
      let defaultFileName = runNumber + "_REF_" + refRunNumber + "_" + refLocale + ".zip";
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = defaultFileName;
        a.click();
      });
      // dispatch({ type: EXPORT_RUN_COMPARISON_DOC, response, runNumber, resultType, refRunNumber, refLocale });
      dispatch({ type: EXPORT_SCENARIO_LOADING, response: { isLoading: false } });
    });
  };
}
export function changeRepeatStep(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_REPEAT_STEP, value });
  };
}

export function changeRetryStep(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_RETRY_STEP, value });
  };
}

export function editTestPlan(value) {
  return (dispatch) => {
    dispatch({ type: EDIT_TEST_PLAN, value });
  };
}

export const generateExternalFarmTestURL = (scenarioId, farmName, setExternalFarmTestResultLink) => {
  return () => {
    async("testScenario/generateExternalFarmTestURL", "GET", {
      scenarioId,
      farmName,
    }).then((response) => {
      if (response?.status === SUCCESS_STATUS) {
        setExternalFarmTestResultLink(response.data);
      }
    });
  };
};

export const getTestBlockRunResultData = (data, setChildResultSteps) => {
  return () => {
    async("testStep/getTestStepForResultByRunId", "GET", data).then((response) => {
      if (data?.parentStepId) {
        setChildResultSteps(response);
      }
    });
  };
};

export const getTestPlanResultVideoUrl = (targetUniqueId, scenarioId,setTestPlanResultVideoURL) => {
  return async (dispatch, getState) => {
    const { getTestStepDetails } = getState().TestPlanReducer;

    let token =
      localStorage.getItem("xpressToken") &&
      localStorage.getItem("xpressToken") !== "undefined"
        ? `Bearer ${localStorage.getItem("xpressToken")}`
        : "";
    let requestedUser = localStorage.getItem("requestedUser");
    try {
      const url = new URL(
        getTestStepDetails?.deviceAndServerDetails?.serverAddress
      );
      url.pathname = "/device/getLiveVideoUrl";
      const payload = {
        targetUniqueId,
        scenarioId,
        serverAddress: url,
      };
      const params = new URLSearchParams(payload);
      const apiUrl = `${url}?${params.toString()}`;
      let response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          requestedUser: requestedUser,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });

      response = await response.json();
      response = updateResponse(response);

      // Check the response status
      if (response.status === "Success") {
        showNotification("Success", response.message);
        setTestPlanResultVideoURL(response.data);
        // dispatch({ type: GET_TEST_PLAN_RESULT_VIDEO_LINK, response: response });
      } else {
        showNotification("error", response.message);
      }
    } catch (error) {
      showNotification(
        "error",
        "Please start the server for your device first."
      );
    } finally {
    }
  };
};

// Accessibility and Local report
export const getAiReportData = (runNumber, projectId, orgId, entityId, entityType = "RunResult") => {
  return (dispatch) => {
    dispatch({ type: ACCESSIBILITY_REPORT_LOADER, isLoading: true });
    async("runResult/getAiReportData", "GET", {
      runNumber,
      projectId,
      orgId,
      entityId,
      entityType,
    }).then((response) => {
      if (response?.status === SUCCESS_STATUS) {
        dispatch({ type: GET_ACCESSIBILITY_REPORT_DETAILS, data: response?.data?.scenarioResultMap });
      }
      dispatch({ type: ACCESSIBILITY_REPORT_LOADER, isLoading: false });
    });
  };
};

// runResult/getAiReportStatus
export const fetchAiReportStatus = (data, dispatch, setRefreshInterval) => {
  const { runNumber, projectId, orgId, entityId } = data;

  const callApi = () => {
    dispatch({ type: ACCESSIBILITY_REPORT_LOADER, isLoading: true });

    async("runResult/getAiReportStatus", "GET", {
      runNumber,
      projectId,
      orgId,
      entityId,
      entityType: "RunResult",
    }).then((response) => {
      dispatch({ type: ACCESSIBILITY_REPORT_LOADER, isLoading: false });

      if (response?.status === SUCCESS_STATUS) {
        dispatch({ type: AI_REPORT_STATUS_LOADING, data: response?.data });

        if (!response?.data?.isRefreshNeeded) {
          if (data.refreshInterval) {
            clearInterval(data.refreshInterval);
            setRefreshInterval(null);
          }
          dispatch(getAiReportData(runNumber, projectId, orgId, entityId));
          dispatch(getRunResultDetailsByRunNumber({ runNumber }, () => {}, projectId));
        }
      }
    });
  };

  // Make the first call
  callApi();
  if (!data.refreshInterval) {
    const interval = setInterval(callApi, 50000);
    setRefreshInterval(interval);
  }
};
