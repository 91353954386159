import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import { BUILD_TEST_DATA_SET, CONFIRM_MODAL_CONTENT } from "../../../Constants";
import { setTestDataSetAssigneeDetail } from "../../../actions/TestDataSet/TestDataSetCommonAction";
import { changeTestDataSetTab } from "../../../actions/TestDataSet/TestDataSetRedirectAction";
import { resetPreviousTestDataSetForEdit } from "../../../actions/TestDataSetAction";
import BackButton from "../../CoreComponents/BackButtonComponent/BackButton";
import AllTestDataSetV2 from "../../TestPlan/TestPlanManager/TestPlanTabs/BuildTestDataSet/AllTestDataSetV2/AllTestDataSetV2";
import CommonConfirmationModalV2 from "../CommonConfirmationModalV2/CommonConfirmationModalV2";
import styles from "./TestStepsDataV2.module.scss";

const TestDataSetTabsV2 = (props) => {
  const dispatch = useDispatch();
  const { projectId, selectedStepId } = props;
  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { previous_testDataSetForEdit } = useSelector((state) => state.TestDataSetReducer);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    dispatch(changeTestDataSetTab(BUILD_TEST_DATA_SET));
  }, [stepType, dispatch]);

  const handleBackButton = useCallback(() => {
    if (previous_testDataSetForEdit) {
      setOpenConfirmModal(true);
    } else {
      dispatch(setTestDataSetAssigneeDetail(undefined));
      dispatch(resetPreviousTestDataSetForEdit());
    }
  }, [previous_testDataSetForEdit, openConfirmModal]);

  return (
    <>
      <Row justify="space-between" align="middle" className={styles["main-header"]}>
        <Col>
          <div className={styles["main-Header-Title"]}>Manage Test Data Profiles</div>
        </Col>
        <Col>
          <div className="close-Icon">
            <BackButton handleBack={handleBackButton} isBlack={true} />
          </div>
        </Col>
      </Row>
      <div>
        <AllTestDataSetV2 projectId={projectId} stepType={stepType} selectedStepId={selectedStepId} />
      </div>
      <CommonConfirmationModalV2
        handleOk={() => {
          setOpenConfirmModal(false);
          dispatch(setTestDataSetAssigneeDetail(undefined));
          dispatch(resetPreviousTestDataSetForEdit());
        }}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={CONFIRM_MODAL_CONTENT}
        modalTitle={"Discard Changes"}
        okText={"Confirm"}
        isDeleteModal={true}
      />
    </>
  );
};

export default TestDataSetTabsV2;
