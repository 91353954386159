import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Popover, Spin, Table } from "antd";
import { isEmpty } from "lodash";
import { getExecutionTestDataAssoc } from "../../../../actions/TestDataSet/TestDataSetCommonAction";
import EmptyRecords from "../../../CommonComponents/EmptyRecords/EmptyRecords";
import styles from "../../../CommonComponents/TestStepsData/TestStepsData.module.scss";

const ExecutionProfileDetailsModal = (props) => {
  const dispatch = useDispatch();
    const { setDisplayExecutionProfile, displayExecutionProfile, currentExecutionProfile} = props;
    const { loadingExecutionTestDataSetAssocList } = 
        useSelector((state) => state.TestDataSetReducer);

  const [executionTestData, setExecutionTestData] = useState([]);

  useEffect(() => {
    dispatch(
      getExecutionTestDataAssoc(
        currentExecutionProfile?.id,
        setExecutionTestData
      )
    );
  }, []);

  const testDataList = () => {
    return (
      (!isEmpty(executionTestData) &&
        executionTestData?.map((row, index) => ({
          stepOrderPath: row.stepOrderPath,
          stepName: (
            <Popover
              content={<span>{row.pathToTestData}</span>}
              title={"Step Path"}
            >
              <div className={styles.stepName}>{row.stepName}</div>
            </Popover>
          ),
          elementImages: row?.elementImage ? (
            <Popover
              content={
                <img
                  src={row?.elementImage}
                  height={450}
                  weight={225}
                  alt="Screenshot"
                />
              }
              placement="left"
              title="Element Image"
              trigger="hover"
            >
              <img
                className={styles.elementImage}
                src={row?.elementImage}
                height={50}
                weight={50}
                alt="Screenshot"
              />
            </Popover>
          ) : (
            "-"
          ),
          useFor: row?.usedFor,
          key: row.key,
          value: row?.value,
        }))) ||
      []
    );
  };

  const columns = [
    {
      title: "Step No.",
      dataIndex: "stepOrderPath",
    },
    {
      title: "Step",
      dataIndex: "stepName",
    },
    {
      title: "Element Image",
      dataIndex: "elementImages",
      align: "center",
    },
    {
      title: "Used For",
      dataIndex: "useFor",
      align: "center",
    },
    {
      title: "Key",
      dataIndex: "key",
      align: "center",
    },
    {
      title: "Value",
      dataIndex: "value",
    },
  ];
  return (
    <Modal
      visible={displayExecutionProfile}
      title={currentExecutionProfile?.name}
      footer={null}
      width={1000}
      onCancel={() => {
        setDisplayExecutionProfile(false);
      }}
      className="displayExecutionProfileModal"
    >
      <Spin
        spinning={loadingExecutionTestDataSetAssocList}
        className={styles.spinning_center}
      >
        {!isEmpty(executionTestData) ? (
          <Table
            columns={columns}
            dataSource={testDataList()}
            pagination={false}
          />
        ) : (
          !loadingExecutionTestDataSetAssocList && (
            <EmptyRecords description={"No Test Data available in Profile!"} />
          )
        )}
      </Spin>
    </Modal>
  );
};

export default ExecutionProfileDetailsModal;